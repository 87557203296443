import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Moment from 'react-moment'

import * as R from 'ramda'

import * as Wham from '../../../../../wham/components'

import { TZ } from '../../../../../constants'
import { DEFAULT, SESSION } from '../../../../../constants/debug/groups'
import { DebugContext, ViewContext } from '../../../../../context'
import { expandPathTemplate } from '../../../../../lib/path-matcher'
import { paths } from '../../../../../route-paths'

import './style.css'

const STACK_LIMIT = 3

export const icons = {
  devid: 'devices',
  ssess: 'av_timer',
  uid: 'face',
  swid: 'face',
  default: 'swap_horiz'
}

export const tooltips = {
  devid: 'Device',
  ssess: 'Session',
  uid: 'User',
  swid: 'SWID'
}

const DebugLogRecord = ({
  groupBy,
  logRecord,
  index,
  collapsed,
  onClick
}) => {
  const { partner } = useContext(ViewContext)
  const { selectedTransactionId, userCanReadBookmark } = useContext(DebugContext)

  const timer = useRef(false)
  const [tooltip, setTooltip] = useState(null)

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const {
    transactionId,
    assetId,
    breakDurationRequested,
    platform,
    podId,
    eventName,
    eventTime,
    saved,
    filled
  } = logRecord

  const { idType, label, value } = logRecord.allowlistEntry ?? {}

  const style = {
    left: collapsed ? index < STACK_LIMIT ? index * 5 : 0 : 0,
    marginBottom: collapsed ? 0 : STACK_LIMIT,
    position: collapsed && index ? 'absolute' : '',
    top: collapsed ? index < STACK_LIMIT ? index * 5 : 0 : 0,
    zIndex: collapsed ? index < STACK_LIMIT ? index * -1 : 0 : 0
  }

  const onMouseOver = el => {
    timer.current = setTimeout(() => {
      setTooltip(el)
    }, 500)
  }

  const onMouseOut = () => {
    clearTimeout(timer.current)
    setTooltip(null)
  }

  const href = collapsed && index ? null : expandPathTemplate(paths.transaction, { partnerId: partner, transactionId })
  const type = idType ? idType.toLowerCase() : 'default'
  const title = label || value || 'Orphaned Transaction'
  const description = eventName || assetId
  const ruleName = R.pathOr('', ['ruleName'], logRecord)

  return (
    <a
      className={classNames('debug-record', {
        'debug-record--highlight': groupBy === DEFAULT || (groupBy === SESSION && collapsed && index === 0) || (groupBy === SESSION && !collapsed),
        'debug-record--stacked': groupBy === SESSION && collapsed && index > 0 && index < STACK_LIMIT,
        'debug-record--hidden': groupBy === SESSION && collapsed && index >= STACK_LIMIT
      })}
      href={href}
      onClick={e => {
        e.preventDefault()

        if (href) {
          onClick(transactionId)
        }
      }}
    >
      <div
        id={`tid-${transactionId}`}
        style={style}
        className={`debug-record__tile ${transactionId === selectedTransactionId ? 'debug-record__tile--selected' : ''}`}
      >
        <div className='debug-record__head'>
          <div className='debug-record__head-left'>
            <span
              className={`debug-record__icon debug-record__icon--${type} w-icon`}
              onMouseOver={e => onMouseOver(e.target)}
              onMouseOut={() => onMouseOut()}
              data-tooltip={tooltips[type] ? `Allow Listed ${tooltips[type]} ${label ? '' : '(Without Label)'}` : 'Orphaned Transaction'}
            >{icons[type]}
            </span>
            <span className={`debug-record__title ${label ? '' : value ? 'debug-record__title--subtle' : 'debug-record__title--orphan'}`}>{title}</span>
          </div>

          <div className='debug-record__head-right'>
            <span className='debug-record__datetime'>
              <Moment format='M/D/YYYY @ h:mm a' tz={TZ}>
                {eventTime}
              </Moment>
            </span>
          </div>
        </div>

        <div className='debug-record__main'>
          <span className='debug-record__description'>{description}</span>
          {userCanReadBookmark && saved ? (
            <Wham.Icon
              iconName='bookmark'
              className='debug-record__flag'
            />
          ) : null}
        </div>

        <div className='debug-record__foot'>
          <span className='debug-record__rule-name'>{ruleName}</span>
          <span className='debug-record__platform' data-cy='debug-record__platform'>{platform}</span>
        </div>

        <div className='debug-record__side'>
          {breakDurationRequested && (
            <Wham.ProgressCircular className='debug-record__progress' sqSize={50} strokeWidth={3} progress={filled / 100} />
          )}

          <div className='debug-record__side-top'>
            {breakDurationRequested && <span className='debug-record__filled'>{filled}%</span>}
          </div>

          <div className='debug-record__side-bottom'>
            {podId && <span className='debug-record__pod'>Pod {podId}</span>}
            {breakDurationRequested && <span className='debug-record__duration'>{breakDurationRequested}s</span>}
          </div>
        </div>
      </div>

      {tooltip ? (
        <Wham.Tooltip trigger={tooltip} placement='top' />
      ) : null}
    </a>
  )
}

DebugLogRecord.propTypes = {
  groupBy: PropTypes.string.isRequired,
  logRecord: PropTypes.object.isRequired,
  index: PropTypes.number,
  collapsed: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default DebugLogRecord

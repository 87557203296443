import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import blacklist from 'blacklist'
import { WHAM_SIZES, WHAM_TYPES } from '../lib/wham'
import standardizeChangeHandler from '../lib/standardize-changehandlers'
import { splitFieldEventProps } from '../lib/eventProps'

import FormInput from '../FormInput'
import Select from '../Select'

import '../FormInput/FormInput.scss'
import './SearchInput.scss'
import '../../scss/wham.scss'

class SearchInput extends React.Component {
  static displayName = 'SearchInput'
  static propTypes = {
    type: PropTypes.oneOf(WHAM_TYPES),
    size: PropTypes.oneOf(WHAM_SIZES),
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    danger: PropTypes.bool,
    subtle: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    /**
     * Called upon changing the value.
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {string} data - The state after the change.
     */
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    onOptionChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.string,
    options: PropTypes.array,
    activeOption: PropTypes.any
  }

  render () {
    const {
      onClear = null,
      disabled,
      danger,
      type,
      size,
      className,
      subtle,
      onChange,
      onOptionChange,
      id,
      value,
      options,
      autoFocus,
      activeOption,
      ...otherProps
    } = this.props
    const [inputProps, remainingProps] = splitFieldEventProps(otherProps)
    /** If there's a maxlength, don't render values larger than it */

    // classes
    const wrapperClassnames = classNames(
      'w-search',
      {
        'w-search--disabled': disabled,
        'w-search--subtle': subtle,
        'w-search--danger': danger
      },
      (size ? ('w-search--' + size) : null),
      className
    )
    const props = blacklist({ ...otherProps, size, type, disabled }, 'subtle', 'onChange', 'value')

    return (
      <span className={wrapperClassnames} {...remainingProps}>
        {options ? <Select options={options} value={activeOption} onChange={onOptionChange} size={size} autosize subtle /> : null}
        <FormInput
          onClear={onClear}
          value={value}
          autoFocus={autoFocus}
          {...props}
          {...inputProps}
          field='search'
          size={size}
          subtle={subtle}
          onChange={onChange}
        />
      </span>
    )
  }
}

const enhancedSearchInput = standardizeChangeHandler(SearchInput)
enhancedSearchInput.displayName = SearchInput.displayName
export default enhancedSearchInput

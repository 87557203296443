/* eslint-disable space-before-function-paren */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import * as Wham from '../../wham/components'
import DateRangePicker from '../../components/date-range-picker'
import { TZ } from '../../constants'

import { useDocumentTitle } from '../../lib/custom-hooks'
import DocLink from '../../containers/doc-link'
import Header from '../../containers/header'
import * as selectors from '../../selectors'

import './style.css'

export const DAILY_TIME_RANGE_OPTIONS = [
  {
    label: 'Yesterday',
    value: 'yesterday',
    getDates: startOfDay => {
      const fromDate = startOfDay.clone().subtract(1, 'day')
      const toDate = startOfDay.clone()
      return { fromDate, toDate }
    }
  },
  {
    label: 'Last 7 days',
    value: 'last7days',
    getDates: startOfDay => {
      const fromDate = startOfDay.clone().subtract(7, 'days')
      const toDate = startOfDay.clone()
      return { fromDate, toDate }
    }
  },
  {
    label: 'Last 30 days',
    value: 'last30days',
    getDates: startOfDay => {
      const fromDate = startOfDay.clone().subtract(30, 'days')
      const toDate = startOfDay.clone()
      return { fromDate, toDate }
    }
  },
  {
    label: 'Month to date',
    value: 'monthtodate',
    getDates: startOfDay => {
      const fromDate = startOfDay.clone().startOf('month')
      const toDate = startOfDay.clone()
      return { fromDate, toDate }
    }
  },
  {
    label: 'Previous Month',
    value: 'previousmonth',
    getDates: startOfDay => {
      const fromDate = startOfDay.clone().startOf('month').subtract(1, 'month')
      const toDate = startOfDay.clone().startOf('month')
      return { fromDate, toDate }
    }
  },
  {
    label: 'Custom date range',
    value: 'custom',
    getDates: undefined
  }
]

export const HOURLY_TIME_RANGE_OPTIONS = [
  {
    label: 'Last 3 hours',
    value: 'last3hours',
    hours: 3
  },
  {
    label: 'Last 6 hours',
    value: 'last6hours',
    hours: 6
  },
  {
    label: 'Last 12 hours',
    value: 'last12hours',
    hours: 12
  },
  {
    label: 'Last 24 hours',
    value: 'last24hours',
    hours: 24
  },
  {
    label: 'Last 48 hours',
    value: 'last48hours',
    hours: 48
  },
  {
    label: 'Last 3 days',
    value: 'last3days',
    hours: 3 * 24
  },
  {
    label: 'Last 4 days',
    value: 'last4days',
    hours: 4 * 24
  },
  {
    label: 'Last 5 days',
    value: 'last5days',
    hours: 5 * 24
  }
]

const reportingConfig = window.adEngineUI?.reportingConfig || []

const AVAILABLE_REPORTS = reportingConfig.flatMap(configEntry => {
  const options = []
  const { shortName, displayName, availableDaily, availableHourly } = configEntry
  if (availableDaily) {
    options.push({
      value: `${shortName}-daily`,
      label: `${displayName} (Daily)`,
      mode: 'daily',
      shortName
    })
  }
  if (availableHourly) {
    options.push({
      value: `${shortName}-hourly`,
      label: `${displayName} (Hourly)`,
      mode: 'hourly',
      shortName
    })
  }
  return options
})

const isDailyReport = report => report.mode === 'daily'

const applicableTimeRange = report =>
  isDailyReport(report) ? DAILY_TIME_RANGE_OPTIONS : HOURLY_TIME_RANGE_OPTIONS

const makeDailyDownloadUrl = (partnerId, report, selectedTimeRange, customDateRange) => {
  const startOfDay = moment.utc().startOf('day')
  const { fromDate, toDate } = selectedTimeRange.value === 'custom'
    ? customDateRange
    : selectedTimeRange.getDates(startOfDay)
  const from = fromDate.format('YYYYMMDD')
  const to = toDate.format('YYYYMMDD')
  const shortName = report.shortName
  return `/api/partner/${partnerId}/reporting/dailyReport/${shortName}/${from}/${to}`
}

const makeHourlyDownloadUrl = (partnerId, report, selectedTimeRange) => {
  const startOfhour = moment.utc().startOf('hour')
  const fromDate = startOfhour.clone().subtract(selectedTimeRange.hours, 'hours')
  const toDate = startOfhour.clone()
  const from = fromDate.format('YYYYMMDD-H')
  const to = toDate.format('YYYYMMDD-H')
  const shortName = report.shortName
  return `/api/partner/${partnerId}/reporting/hourlyReport/${shortName}/${from}/${to}`
}

const makeDownloadUrl = (partnerId, report, selectedTimeRange, customDateRange) => {
  return isDailyReport(report)
    ? makeDailyDownloadUrl(partnerId, report, selectedTimeRange, customDateRange)
    : makeHourlyDownloadUrl(partnerId, report, selectedTimeRange)
}

export const ReportingView = ({ partnerId }) => {
  useDocumentTitle('Reporting')
  const [selectedReport, setSelectedReport] = useState(AVAILABLE_REPORTS[0])
  const [lastSelectedDailyTimeRange, setLastSelectedDailyTimeRange] = useState(DAILY_TIME_RANGE_OPTIONS[0])
  const [lastSelectedHourlyTimeRange, setLastSelectedHourlyTimeRange] = useState(HOURLY_TIME_RANGE_OPTIONS[0])
  const [selectedTimeRange, setSelectedTimeRange] = useState(() =>
    isDailyReport(selectedReport) ? lastSelectedDailyTimeRange : lastSelectedHourlyTimeRange)
  const [customDateRange, setCustomDateRange] = useState(() => {
    const startOfDay = moment.utc().startOf('day')
    return lastSelectedDailyTimeRange.getDates(startOfDay)
  })
  const [showPicker, setShowPicker] = useState(false)

  const onChangeSelectedReport = (_e, value) => {
    setSelectedReport(value)
    setSelectedTimeRange(isDailyReport(value) ? lastSelectedDailyTimeRange : lastSelectedHourlyTimeRange)
  }

  const onChangeSelectedTimeRange = (_e, value) => {
    setSelectedTimeRange(value)
    if (isDailyReport(selectedReport)) {
      setLastSelectedDailyTimeRange(value)
      if (value.value === 'custom') {
        setShowPicker(true)
      } else {
        const startOfDay = moment.utc().startOf('day')
        setCustomDateRange(value.getDates(startOfDay))
      }
    } else {
      setLastSelectedHourlyTimeRange(value)
    }
  }

  const onClosePicker = () => {
    setShowPicker(false)
  }

  const onCustom = value => {
    const fromDate = moment(value.from).utc()
    const toDate = moment(value.to).utc()
    setCustomDateRange({ fromDate, toDate })
    setShowPicker(false)
  }

  const downloadUrl = makeDownloadUrl(partnerId, selectedReport, selectedTimeRange, customDateRange)

  return (
    <div>
      <Header />
      <Wham.Layout fullWidth fullHeight style={{ maxHeight: '100%' }}>
        <Wham.LayoutRow type='fill'>
          <Wham.LayoutColumn verticalAlign='center' horizontalAlign='center'>
            <div className='reporting'>
              <div className='reporting__label'>Report Type</div>
              <div className='reporting__field'>
                <Wham.Select
                  data-cy='report-type'
                  className='reporting__field__select'
                  options={AVAILABLE_REPORTS}
                  value={selectedReport}
                  onChange={onChangeSelectedReport}
                />
              </div>

              <div className='reporting__label'>Time Range</div>
              <div className='reporting__field' style={{ display: 'flex', alignItems: 'center' }}>
                <Wham.Select
                  data-cy='time-range'
                  className='reporting__field__select'
                  options={applicableTimeRange(selectedReport)}
                  value={selectedTimeRange}
                  onChange={onChangeSelectedTimeRange}
                />
                {(isDailyReport(selectedReport) && selectedTimeRange.value === 'custom')
                  ? <span className='reporting__field__icon w-icon' onClick={() => setShowPicker(true)}>date_range</span>
                  : null
                }
              </div>

              {showPicker && (
                <div className='reporting__picker'>
                  <DateRangePicker
                    timeInterval={60 * 24}
                    tzOverride={TZ}
                    now={moment.utc().valueOf()}
                    from={customDateRange.fromDate.valueOf()}
                    to={customDateRange.toDate.valueOf()}
                    onClose={onClosePicker}
                    onCustom={onCustom}
                    pending={false}
                  />
                </div>
              )}

              <Wham.Button type="primary" href={downloadUrl}>Download Report</Wham.Button>
            </div>
          </Wham.LayoutColumn>
        </Wham.LayoutRow>
      </Wham.Layout>

      <DocLink topic="reporting" />
    </div>
  )
}

ReportingView.propTypes = {
  partnerId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const partnerId = selectors.activePartnerGlobalSelector(state)
  return {
    partnerId
  }
}

export default connect(mapStateToProps)(ReportingView)

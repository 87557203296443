import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

import './style.css'

const Dialog = ({
  showDialog,
  header,
  children,
  footer,
  onClose
}) => showDialog ? (
  <>
    <div className='dialog--background' />
    <div className='dialog'>
      <div className='dialog--wrapper'>
        {header ? (
          <div className='dialog--header'>
            {header}
          </div>
        ) : null}

        {children ? (
          <div className='dialog--content'>
            {children}
          </div>
        ) : null}

        {footer ? (
          <div className='dialog--footer'>
            {footer}
          </div>
        ) : null}
      </div>

      <Wham.Icon className='dialog--close' iconName='close' onClick={onClose} />
    </div>
  </>
) : null

Dialog.propTypes = {
  showDialog: PropTypes.bool,
  header: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func
}

export default Dialog

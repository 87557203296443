import React from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'
import { userClaimSelector, activePartnerGlobalSelector } from '../../selectors'

export const hasRole = (partnerId, controller, action, claims) =>
  !!R.pathOr(false, [partnerId, controller, action], claims)

const mapClaimsToProps = (state, ownProps) => ({
  ...ownProps,
  partnerId: activePartnerGlobalSelector(state),
  userClaims: userClaimSelector(state)
})

export const displayIfPermissions = (controller, action, ElseComponent = () => null) =>
  Component => connect(mapClaimsToProps)(({
    userClaims,
    partnerId,
    ...otherProps
  }) => {
    return hasRole(partnerId, controller, action, userClaims)
      ? <Component {...otherProps} />
      : <ElseComponent {...otherProps} />
  })

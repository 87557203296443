import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'

import { SESSION } from '../../../constants/debug/groups'

import DebugLogGroup from './debug-log-group'

import './style.css'

const DebugLogs = ({
  logSummaries,
  groupBy,
  onClick
}) => {
  const logGroups = R.values(R.groupBy(R.prop(groupBy === SESSION ? 'ssess' : 'transactionId'), logSummaries))

  return (
    <div className='debug-logs'>
      {logGroups.map((logGroup, index) => (
        <DebugLogGroup key={index} groupBy={groupBy} logGroup={logGroup} onClick={onClick} />
      ))}
    </div>
  )
}

DebugLogs.propTypes = {
  logSummaries: PropTypes.array.isRequired,
  groupBy: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DebugLogs

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import InPageModal from '../../in-page-modal'

import UrlEditor from './url-editor'

const SimulatorModal = ({
  showModal,
  setTokenUrl,
  onSave,
  onClose
}) => {
  const [url, setUrl] = useState(setTokenUrl.value)
  const [disabled, setDisabled] = useState(false)

  return (
    <InPageModal
      showModal={showModal}
      header='Edit Your adEngine Request:'
      footer={
        <Wham.Button
          className='url-editor__save'
          data-cy='save-settoken'
          type='primary'
          disabled={disabled}
          onClick={() => onSave(url)}
        >Save Request URL</Wham.Button>
      }
      onClose={() => onClose()}
    >
      <UrlEditor
        url={setTokenUrl.value}
        onChange={changed => setUrl(changed)}
        onValid={() => setDisabled(false)}
        onInvalid={() => setDisabled(true)}
      />
    </InPageModal>
  )
}

SimulatorModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setTokenUrl: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default SimulatorModal

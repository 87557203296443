import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import VisibilitySensor from 'react-visibility-sensor'

import * as R from 'ramda'
import * as Wham from '../../wham/components'

import {
  closeWhitelistSidebar,
  fetchFurtherDebugSummaries,
  fetchPendingDebugSummaries,
  openWhitelistSidebar
} from '../../actions/debug'
import { setWhitelistFormField } from '../../actions/form'
import { goToDebugResults, goTo } from '../../actions/navigators'

import {
  activePartnerGlobalSelector,
  debuggingLogEntriesGlobalSelector,
  debuggingNewLogsSelector,
  debuggingErrorSelector,
  debuggingFinalSelector,
  debuggingGroupBySelector,
  debuggingPendingSelector,
  debuggingUpdatedSelector,
  debugDurationSelector,
  whitelistSearchSelector,
  swidPartnerSelector,
  userClaimSelector,
  userFeaturesSelector,
  userRolesSelector,
  whitelistOpenSelector
} from '../../selectors'

import * as F from '../../common/features'
import * as P from '../../common/permissions'

import { hasRole } from '../../components/authorized'
import DebugFilters from '../../containers/debug-filters'
import DocLink from '../../containers/doc-link'

import { useDocumentTitle } from '../../lib/custom-hooks'
import { ViewContext, DebugContext } from '../../context'

import DebugDates from '../../containers/debug-dates'
import HeaderDebug from '../../containers/header/header-debug'
import WhitelistSidebar from '../../containers/whitelist-sidebar'

import { enrichSummaries } from '../../lib/debug'
import { expandPathTemplate } from '../../lib/path-matcher'

import { paths } from '../../route-paths'

import DebugLogs from './debug-logs'

import './style.css'

const DebugView = ({
  dispatch,
  claims,
  features,
  roles,
  partner,
  swid,
  currentSummaries,
  pendingSummaries,
  duration,
  search,
  groupBy,
  whitelistOpen,
  selectedTransactionId,
  pending,
  updated,
  final,
  error
}) => {
  useDocumentTitle('Debugging')

  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(closeWhitelistSidebar())
    }
  }, [])

  useEffect(() => {
    if (duration === 'today') {
      dispatch(fetchPendingDebugSummaries())
      dispatch(setWhitelistFormField('search', ''))
    }
  }, [duration])

  useEffect(() => {
    if (!pending) {
      if (selectedTransactionId && !scrolled) {
        const mainContent = document.querySelector('.debug-view__content')

        if (mainContent) {
          const item = mainContent.querySelector(`#tid-${selectedTransactionId}`)

          if (item) {
            const offset = item.parentNode.parentNode.parentNode
            const parent = item.parentNode.parentNode.parentNode.parentNode

            mainContent.scrollTop = offset.offsetTop - parent.offsetTop

            setScrolled(true)
          }
        }
      }
    }
  }, [pending])

  useEffect(() => {
    if (updated) {
      const scrollPane = document.querySelector('.debug-view__content-container')
      scrollPane.scrollTop = 0
    }
  }, [updated])

  const enrichedSummaries = enrichSummaries(currentSummaries)

  const userCanReadBookmark = hasRole(partner, F.DEBUGGING_FLAGGING, P.READ, claims)

  const viewContext = {
    claims,
    features,
    roles,
    partner,
    swid
  }

  const debugContext = {
    selectedTransactionId,
    userCanReadBookmark
  }

  return (
    <ViewContext.Provider value={viewContext}>
      <DebugContext.Provider value={debugContext}>
        <div className='debug-view'>
          <div className='debug-view__header'>
            <HeaderDebug
              whitelist={{
                open: whitelistOpen,
                onOpen: () => {
                  dispatch(openWhitelistSidebar())
                },
                onClose: () => {
                  dispatch(closeWhitelistSidebar())
                }
              }}
            />
          </div>

          <div className='debug-view__controls'>
            <div className='debug-view__controls-container debug-view__controls-container--facets'>
              <div className='debug-view__pending'>
                {pendingSummaries.length && duration === 'today' ? (
                  <span
                    className='debug-view__pending-prompt'
                    onClick={() => { dispatch(goToDebugResults({ features })) }}
                  >{`${pendingSummaries.length} new transactions`}
                  </span>
                ) : (
                  <span className='debug-view__pending-placeholder'>&nbsp;</span>
                )}
              </div>

              <div className='debug-view__filters debug-view__filters--facets'>
                <div className='debug-view__date-picker debug-view__date-picker--facets'>
                  <DebugDates />
                </div>
              </div>
            </div>
          </div>

          <div className='debug-view__content'>
            <div className='debug-view__content-container'>
              <div className='debug-view__content-lhs'>
                <DebugFilters count={enrichedSummaries.length} />
              </div>

              <div className='debug-view__transactions'>
                <div>
                  <DebugLogs
                    logSummaries={enrichedSummaries}
                    groupBy={groupBy}
                    onClick={transactionId => {
                      dispatch(push(expandPathTemplate(paths.transaction, { partnerId: partner, transactionId })))
                    }}
                  />

                  <div className='debug-view__loader'>
                    {pending ? enrichedSummaries.length === 0 ? (
                      <div>&nbsp;</div> /* Only show progress slider at top of page */
                    ) : (
                      <Wham.ProgressCircular active type='indeterminate' />
                    ) : error ? (
                      <div>
                        <span className='debug-view__loader-prompt'>Error Loading Transactions</span>
                      </div>
                    ) : final && enrichedSummaries.length === 0 ? (
                      <div>
                        <span className='debug-view__loader-prompt'>No Transactions Found</span>
                      </div>
                    ) : (
                      <div>
                        <span className='debug-view__loader-prompt'>No Additional Transactions Found</span>
                      </div>
                    )}
                  </div>

                  <VisibilitySensor
                    onChange={visible => {
                      const offset = currentSummaries.length

                      if (!pending && visible && offset) {
                        dispatch(fetchFurtherDebugSummaries({ offset }))
                      }
                    }}
                  >
                    <div>&nbsp;</div>
                  </VisibilitySensor>
                </div>
              </div>

              <div className='debug-view__content-rhs'>
              </div>
            </div>

            {
              whitelistOpen && (
                <div className='debug-view__sidebar debug-view__sidebar--open'>
                  <WhitelistSidebar
                    onOpen={() => {
                      dispatch(closeWhitelistSidebar())
                      dispatch(goTo({ to: expandPathTemplate(paths.whitelist, { partnerId: partner }), state: { search } }))
                    }}
                    onClose={() => {
                      dispatch(closeWhitelistSidebar())
                    }}
                  />
                </div>
              )
            }
          </div>
          <DocLink topic="debugging" />
        </div>
      </DebugContext.Provider>
    </ViewContext.Provider>
  )
}

DebugView.propTypes = {
  client: PropTypes.shape({
    query: PropTypes.func.isRequired
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  partner: PropTypes.string.isRequired,
  swid: PropTypes.shape({
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  currentSummaries: PropTypes.array.isRequired,
  pendingSummaries: PropTypes.array.isRequired,
  duration: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  groupBy: PropTypes.string.isRequired,
  whitelistOpen: PropTypes.bool.isRequired,
  selectedTransactionId: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  updated: PropTypes.bool.isRequired,
  final: PropTypes.bool.isRequired,
  error: PropTypes.any
}

const mapStateToProps = (state, ownProps) => {
  const claims = userClaimSelector(state)
  const features = userFeaturesSelector(state)
  const roles = userRolesSelector(state)
  const partner = activePartnerGlobalSelector(state)

  const swid = swidPartnerSelector(partner)(state)

  const duration = debugDurationSelector(partner)(state)
  const search = whitelistSearchSelector(partner)(state)

  const currentSummaries = debuggingLogEntriesGlobalSelector(partner)(state)
  const pendingSummaries = debuggingNewLogsSelector(partner)(state)
  const groupBy = debuggingGroupBySelector(partner)(state)

  const whitelistOpen = whitelistOpenSelector(partner)(state)

  const pending = debuggingPendingSelector(partner)(state)
  const updated = debuggingUpdatedSelector(partner)(state)
  const final = debuggingFinalSelector(partner)(state)
  const error = debuggingErrorSelector(partner)(state)

  const hash = R.path(['location', 'hash'], ownProps)
  const selectedTransactionId = hash ? hash.slice(1) : undefined

  return {
    claims,
    features,
    roles,
    partner,
    swid,
    duration,
    search,
    currentSummaries,
    pendingSummaries,
    groupBy,
    whitelistOpen,
    selectedTransactionId,
    pending,
    updated,
    final,
    error
  }
}

export default connect(mapStateToProps)(DebugView)

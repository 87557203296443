import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import * as FEATURE from '../../../common/features'
import { ViewContext } from '../../../context'

import { paths } from '../../../route-paths'
import { expandPathTemplate } from '../../../lib/path-matcher'
import { goTo } from '../../../actions/navigators'
import { gql, useLazyQuery } from '@apollo/client'

import './style.css'

const GQL_LOOKUP_MEZZFILE = gql`
  query Creatives($query: CreativesQueryInput) {
    creatives(query: $query) {
      records {
        id
      }
    }
  }
`

const MODE_INITIAL = 0
const MODE_IN_FLIGHT = 1
const MODE_ONE_CREATIVE_FOUND = 2
const MODE_NO_CREATIVES_FOUND = 3
const MODE_MULTIPLE_CREATIVES_FOUND = 4
const MODE_ERROR = 5

const NavigateToCreative = ({ mezzFile, dispatch }) => {
  const {
    partner: partnerId,
    claims
  } = useContext(ViewContext)

  const [tooltip, setTooltip] = useState(null)
  const [currentState, setCurrentState] = useState({ mode: MODE_INITIAL, count: 0, error: '' })

  const onMouseOver = e => {
    setTooltip(e.target)
  }

  const onMouseOut = () => {
    setTooltip(null)
  }

  const [lookupMezzFileLazyQuery, lookupMezzFileLazyQueryResult] = useLazyQuery(GQL_LOOKUP_MEZZFILE, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (lookupMezzFileLazyQueryResult.error) {
      setCurrentState({ mode: MODE_ERROR, error: 'Failed to lookup creative record by mezz file' })
      return
    }
    if (lookupMezzFileLazyQueryResult.data) {
      const records = lookupMezzFileLazyQueryResult.data.creatives.records
      const count = records.length
      console.log(`lookupMezzFileLazyQueryResult: number of records found: ${count}`)
      switch (count) {
        case 0:
          setCurrentState({ mode: MODE_NO_CREATIVES_FOUND })
          break
        case 1: {
          setCurrentState({ mode: MODE_ONE_CREATIVE_FOUND })
          const creativeId = records[0].id
          const to = expandPathTemplate(paths.creative, { partnerId, creativeId })
          dispatch(goTo({ to }))
          break
        }
        default:
          setCurrentState({ mode: MODE_MULTIPLE_CREATIVES_FOUND, count })
          break
      }
    }
  }, [lookupMezzFileLazyQueryResult])

  const onClick = () => {
    if (currentState.mode !== MODE_INITIAL) return
    setTooltip(null)
    setCurrentState({ mode: MODE_IN_FLIGHT })
    lookupMezzFileLazyQuery({
      variables: {
        query: {
          query: mezzFile
        }
      }
    })
  }

  const renderSpinner = () => {
    return (
      <Wham.ProgressCircular className='navigate-to-creative__button-icon-wrapper' active type='indeterminate' sqSize={16} />
    )
  }

  const renderNavigateIcon = () => {
    return (
      <>
        <div
          className='navigate-to-creative__button-icon-wrapper'
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          data-tooltip='Navigate to Creative'
        >
          <Wham.Icon className='navigate-to-creative__button-icon' iconName='description' />
        </div>
        {tooltip && <Wham.Tooltip trigger={tooltip} />}
      </>
    )
  }

  const renderWarningIcon = tooltipText => {
    return (
      <>
        <div
          className='navigate-to-creative__button-icon-wrapper'
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          data-tooltip={tooltipText}
        >
          <Wham.Icon className='navigate-to-creative__button-icon' iconName='warning' color='var(--w-color-warning)' />
        </div>
        {tooltip && <Wham.Tooltip trigger={tooltip} />}
      </>
    )
  }

  const renderButtonContent = () => {
    switch (currentState.mode) {
      case MODE_INITIAL: return renderNavigateIcon()
      case MODE_IN_FLIGHT: return renderSpinner()
      case MODE_ONE_CREATIVE_FOUND: return null
      case MODE_NO_CREATIVES_FOUND: return renderWarningIcon('No creative records found for this mezz file')
      case MODE_MULTIPLE_CREATIVES_FOUND: return renderWarningIcon(`Multiple creative records found for this mezz file (${currentState.count})`)
      case MODE_ERROR: return renderWarningIcon(currentState.error)
      default: return renderWarningIcon('Unknown mode')
    }
  }

  return claims[partnerId] && claims[partnerId][FEATURE.CREATIVE_ASSETS] ? (
    <span className='navigate-to-creative'>
      <Wham.Button className='navigate-to-creative__button' onClick={onClick}>
        {renderButtonContent()}
      </Wham.Button>
    </span>
  ) : null
}

NavigateToCreative.propTypes = {
  mezzFile: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default NavigateToCreative

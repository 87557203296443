import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import * as Wham from '../../../wham/components'

import SimulatedTransaction from './simulated-transaction'

const SimulatedTransactions = ({
  simulatedTransactions,
  selectedTransactionId,
  onRemove,
  onRemoveAll,
  onSelect
}) => {
  const [expanded, setExpanded] = useState(false)
  const [tooltip, setTooltip] = useState(null)

  const simulatedTransactionsToShow = expanded
    ? simulatedTransactions
    : R.take(1, simulatedTransactions)

  return (
    <div className='simulator__transactions'>

      <div className='simulator__transactions__header'>
        <Wham.Heading level={5}>Simulated Transactions</Wham.Heading>
        <span className='simulator__transactions__clear-all' onClick={onRemoveAll}>Clear All</span>
      </div>

      <Wham.Table>
        <thead>
          <tr>
            <th>Simulated At</th>
            <th>Break Duration</th>
            <th>
              <Wham.Icon
                className='simulator__transactions__expand-collapse'
                iconName={expanded ? 'unfold_less' : 'unfold_more'}
                onClick={() => setExpanded(!expanded)}
                onMouseOver={e => setTooltip(e.target)}
                onMouseOut={() => setTooltip(null)}
                data-tooltip='Expand/Collapse'
              />
              {tooltip && (
                <Wham.Tooltip
                  trigger={tooltip}
                  placement='top'
                  style={{ position: 'absolute' }}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {simulatedTransactionsToShow.map(simulatedTransaction => (
            <SimulatedTransaction
              key={simulatedTransaction.transactionId}
              simulatedTransaction={simulatedTransaction}
              isSelected={simulatedTransaction.transactionId === selectedTransactionId}
              onRemove={onRemove}
              onSelect={onSelect}
            />
          ))}
        </tbody>
      </Wham.Table>
    </div>
  )
}

SimulatedTransactions.propTypes = {
  simulatedTransactions: PropTypes.array.isRequired,
  selectedTransactionId: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onSelect: PropTypes.func
}

export default SimulatedTransactions

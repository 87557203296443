import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as R from 'ramda'
import log from 'loglevel'
import axios from 'axios'

import './index.css'
import '@adtech/rule-builder-ui/dist/index.css'

ReactDOM.render(<App />, document.getElementById('root'))

// So that we can change the current log level in a browser's developer tools window
// e.g. log.setLevel('info')
window.log = log

const onFulfilled = R.identity

const CUSTOM_FORBIDDEN_ERROR = new Error('You do not have account permissions to perform this task')

const onRejected = error => {
  const status = R.path(['response', 'status'], error)
  return Promise.reject(status === 403 ? CUSTOM_FORBIDDEN_ERROR : error)
}

axios.interceptors.response.use(onFulfilled, onRejected)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import MarkdownTemplateDefault from './Templates/Default'
import MarkdownTemplateReleaseNotes from './Templates/ReleaseNotes'
import MarkdownTemplateDocumentation from './Templates/Documentation'

import './Markdown.scss'
import '../../scss/wham.scss'

const MARKDOWN_TEMPLATES = ['release-notes', 'documentation']
const MARKDOWN_SIZES = ['small', 'medium', 'large']

class Markdown extends React.Component {
  static propTypes = {
    markdown: PropTypes.string,
    className: PropTypes.string,
    template: PropTypes.oneOf(MARKDOWN_TEMPLATES),
    size: PropTypes.oneOf(MARKDOWN_SIZES)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.template) {

    }
  }

  render () {
    const {
      markdown = '',
      className,
      template,
      size,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-markdown',
      (size ? 'w-markdown--' + size : null),
      className
    )

    let Element

    switch (template) {
      case 'release-notes':
        Element = MarkdownTemplateReleaseNotes
        break
      case 'documentation':
        Element = MarkdownTemplateDocumentation
        break
      default:
        Element = MarkdownTemplateDefault
        break
    }

    return (
      <Element className={componentClass} markdown={markdown} {...otherProps} />
    )
  }
};

export default Markdown

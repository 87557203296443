import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { gql, useQuery } from '@apollo/client'

import * as Wham from '../../wham/components'

import * as selectors from '../../selectors'

import {
  setDebugFormField,
  setWhitelistFormField
} from '../../actions/form'
import { goToDebugResults } from '../../actions/navigators'

import FilterBar from '../../components/filter-bar'
import WhitelistSidebarItem from '../../components/whitelist/whitelist-sidebar-item'

import { matches } from '../../lib/whitelist'

import './style.css'

export const GQL_ALLOWLIST = gql`
  query Allowlist {
    allowlist {
      edges {
        node {
          id
          idType
          value
          label
        }
      }
    }
  }
`

const WhitelistSidebar = ({
  features,
  search,
  onOpen,
  onClose,
  dispatch
}) => {
  const [allowlist, setAllowlist] = useState([])
  const allowlistQueryResult = useQuery(GQL_ALLOWLIST)

  useEffect(() => {
    if (allowlistQueryResult.data) {
      const edges = allowlistQueryResult.data.allowlist.edges
      const nodes = edges.map(edge => edge.node)
      setAllowlist(nodes.map(node => ({
        ...node,
        id: Number(node.id),
        idType: node.idType.toLowerCase(),
        label: node.label || ''
      })))
    }
  }, [allowlistQueryResult])

  const onClick = allowlistEntry => {
    dispatch(setDebugFormField('searchQuery', allowlistEntry.value))
    dispatch(setWhitelistFormField('search', ''))
    dispatch(goToDebugResults({ features }))
  }

  const allowlistFiltered = allowlist.filter(({ value, label }) => !search || matches(search, value) || matches(search, label))

  const renderAllowlistEntries = () => {
    if (allowlistQueryResult.loading) {
      return (
        <div className='whitelist-sidebar__loading-label'>
          <span>Loading your allowlist...</span>
        </div>
      )
    }

    if (allowlist.length === 0) {
      <div className='whitelist-sidebar__empty-label'>
        <span>Your allowlist is empty.</span>
      </div>
    }

    return allowlistFiltered.map(allowlistEntry => (
      <WhitelistSidebarItem key={allowlistEntry.id} item={allowlistEntry} onClick={() => onClick(allowlistEntry)} />
    ))
  }

  return (
    <div className='whitelist-sidebar'>
      <header className='whitelist-sidebar__header'>
        <h1 className='whitelist-sidebar__header-head'>
          <span className='whitelist-sidebar__title'>
            Saved IDs ({allowlist.length})
          </span>

          <span className='whitelist-sidebar__settings'>
            <Wham.Button
              className='whitelist-sidebar__settings-button'
              id='whitelist-sidebar__spanner-icon'
              data-cy='whitelist-spanner-icon'
              onClick={onOpen}
            >
              <Wham.Icon className='whitelist-sidebar__settings-icon' iconName='build' />
            </Wham.Button>
          </span>

          <span className='whitelist-sidebar__close'>
            <Wham.CloseButton onClick={onClose} />
          </span>
        </h1>

        <div className='whitelist-sidebar__search'>
          <FilterBar
            value={search}
            onChange={e => {
              dispatch(setWhitelistFormField('search', e.target.value.trim()))
            }}
          />
        </div>
      </header>

      <ul id='whitelist-sidebar__items' className='whitelist-sidebar__items'>
        {renderAllowlistEntries()}
      </ul>
    </div>
  )
}

WhitelistSidebar.propTypes = {
  features: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const partner = selectors.activePartnerGlobalSelector(state)
  const features = selectors.userFeaturesSelector(state)
  const search = selectors.whitelistSearchSelector(partner)(state)

  return {
    features,
    search
  }
}

export default connect(mapStateToProps)(WhitelistSidebar)

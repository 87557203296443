import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Progress.scss'
import '../../scss/wham.scss'

const PROGRESS_TYPES = [
  'default',
  'indeterminate'
]

class Progress extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    progress: PropTypes.number,
    active: PropTypes.bool,
    type: PropTypes.oneOf(PROGRESS_TYPES),
    inverse: PropTypes.bool,
    display: PropTypes.bool
  }

  render () {
    const {
      className,
      progress,
      active,
      type,
      inverse,
      display,
      ...otherProps
    } = this.props

    // classes
    const wrapperClasses = classNames(
      'w-progress',
      (active ? 'w-progress--active' : null),
      (inverse ? 'w-progress--inverse' : null),
      (display ? 'w-progress--display' : null),
      (type ? 'w-progress--' + type : null),
      className
    )

    // render popover element
    return (
      <span className={wrapperClasses} {...otherProps}>
        <span className='w-progress__display' style={{ width: progress * 100 + '%' }} />
      </span>
    )
  }
}

export default Progress

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classNames from 'classnames'

import { gql, useLazyQuery, useMutation } from '@apollo/client'

import VisibilitySensor from 'react-visibility-sensor'

import moment from 'moment'
import 'moment-timezone'

import * as R from 'ramda'

import { useCopied, useDocumentTitle } from '../../lib/custom-hooks'

import { TZ } from '../../constants'
import * as APOLLO from '../../constants/apollo/status'

import * as selectors from '../../selectors'

import { validateAsset } from '../../actions/assets'
import { creativesReset, setCreativeIds } from '../../actions/creatives'
import { setCreativesFormField } from '../../actions/form'
import { goTo } from '../../actions/navigators'

import * as Wham from '../../wham/components'

import CreativeDates from '../../components/creative-dates'
import CreativesFilterByStatus from '../../components/creative-filters/creatives-filter-by-status'
import SearchBar from '../../components/search-bar'
import VideoPlayer from '../../components/video-player/loadable-video-player'
import CreativeSummaryRecord from '../../components/creative-summary-record'
import DocLink from '../../containers/doc-link'
import Header from '../../containers/header'

import { ViewContext } from '../../context'

import { convertDurationToEpochs } from '../../lib/durations'
import { expandPathTemplate } from '../../lib/path-matcher'
import { makeProxyUrl } from '../../lib/playlist'
import { paths } from '../../route-paths'

import './style.css'

const NEW_RECORD_IDS_POLL_INTERVAL = 120 * 1000

const GQL_CREATIVE_RECORDS = gql`
  query Creatives($query: CreativesQueryInput, $page: CreativesPageInput) {
    creatives(query: $query, page: $page) {
      updatedAt
      meta {
        ready
        blocked
        total
      }
      records {
        id
        epoch
        mezzFile
        vastIds {
          id
          path
        }
        duration
        status
        amsId
        errorMsg
      }
    }
  }
`

const GQL_CREATIVE_RECORD = gql`
  query Creative($id: String!) {
    creative(id: $id) {
      id
      epoch
      mezzFile
      vastIds {
        id
        path
      }
      duration
      status
      amsId
      errorMsg
    }
  }
`

const GQL_NEW_RECORD_IDS = gql`
  query Creatives($query: CreativesQueryInput) {
    creatives(query: $query) {
      records {
        id
      }
      meta {
        ready
        blocked
        total
      }
    }
  }
`

const GQL_CREATIVE_RETRANSCODE = gql`
  mutation CreativeRetranscode($id: ID!) {
    creativeRetranscode(id: $id) {
      id
    }
  }
`

const offsets = {
  mezz: { top: 43, left: 52 },
  vast: { top: 73, left: 14, offset: 25 },
  timestamp: { top: 38, right: 12 },
  error: { top: 125, right: 12 }
}

const copiedOffsets = {
  height: 137,
  margin: 10
}

const calcCopiedStyles = copied => {
  if (copied.length) {
    const [index, key, offset] = copied

    const topMargin = index * copiedOffsets.height
    const topOffset = offset ? offset * offsets[key].offset : 0
    const top = topMargin + topOffset + offsets[key].top

    const left = offsets[key].left ? offsets[key].left + copiedOffsets.margin : undefined
    const right = offsets[key].right ? offsets[key].right + copiedOffsets.margin : undefined

    return {
      top: `${top}px`,
      left: left ? `${left}px` : undefined,
      right: right ? `${right}px` : undefined
    }
  }
}

const CreativesView = ({
  partnerId,
  claims,
  features,
  roles,
  duration,
  epochs,
  status,
  query,
  dispatch
}) => {
  const [search, setSearch] = useState(query)

  const [timeouts, setTimeouts] = useState([])

  const [player, setPlayer] = useState({})
  const [redone, setRedone] = useState({})

  const [records, setRecords] = useState([])
  const [newRecordsPrompt, setNewRecordsPrompt] = useState('')

  const timeoutQuery = useRef(null)

  const latest = useRef({})
  const offset = useRef({})
  const clearErrorTimers = useRef([])

  const checkForNewer = duration === 'today' || duration.startsWith('last') || duration.startsWith('this')

  const [creativeRecordsLazyQuery, creativeRecordsLazyQueryResult] = useLazyQuery(GQL_CREATIVE_RECORDS, {
    fetchPolicy: 'no-cache'
  })

  const [newRecordIdsLazyQuery, newRecordIdsLazyQueryResult] = useLazyQuery(GQL_NEW_RECORD_IDS, {
    fetchPolicy: 'no-cache'
  })

  const [creativeRecordLazyQuery, creativeRecordLazyQueryResult] = useLazyQuery(GQL_CREATIVE_RECORD, {
    fetchPolicy: 'no-cache'
  })

  const [creativeRetranscodeMutation, creativeRetranscodeMutationResult] = useMutation(GQL_CREATIVE_RETRANSCODE, {
    onError: error => {
      setRedone(redone => ({
        ...redone,
        [redone.inProgressId]: {
          ...redone[redone.inProgressId],
          error: Boolean(error)
        },
        inProgressId: undefined
      }))
      clearErrorTimers.current.push(
        setTimeout(id => {
          setRedone(redone => {
            const { [id]: ignore, ...keep } = redone
            return keep
          })
        }, 3000, redone.inProgressId)
      )
    },
    onCompleted: () => {
      setRedone(redone => ({
        ...redone,
        [redone.inProgressId]: {
          ...redone[redone.inProgressId],
          done: true
        },
        inProgressId: undefined
      }))
      updateCreativeRecord(redone.inProgressId)
    }
  })

  const fetchNewRecordIds = () => {
    try {
      const from = duration === 'today' ? epochs.from : epochs.now
      const until = duration === 'today' ? epochs.until : moment().tz(TZ).valueOf()

      newRecordIdsLazyQuery({
        variables: {
          query: {
            from,
            until,
            status,
            query
          }
        }
      })
    } finally {
      timeoutQuery.current = setTimeout(fetchNewRecordIds, NEW_RECORD_IDS_POLL_INTERVAL)
    }
  }

  useEffect(() => {
    if (newRecordIdsLazyQueryResult.data) {
      let newTotal = newRecordIdsLazyQueryResult.data.creatives.meta.total
      let newCount = newRecordIdsLazyQueryResult.data.creatives.records.length
      if (duration === 'today') {
        newTotal -= records.length
        newCount -= records.length
      }
      switch (newCount) {
        case 0:
          setNewRecordsPrompt('')
          break
        case 1:
          setNewRecordsPrompt('1 new record')
          break
        default:
          {
            const newCountLabel = newTotal > newCount ? `${newCount}+` : `${newCount}`
            setNewRecordsPrompt(`${newCountLabel} new records`)
          }
          break
      }
    }
  }, [newRecordIdsLazyQueryResult])

  useDocumentTitle('Creative Assets')

  useEffect(() => {
    return () => {
      dispatch(creativesReset())

      clearTimeout(timeoutQuery.current)
      clearErrorTimers.current.forEach(timerId => clearTimeout(timerId))
    }
  }, [])

  useEffect(() => {
    dispatch(creativesReset())
  }, [partnerId])

  useEffect(() => {
    clearTimeout(timeoutQuery.current)

    if (checkForNewer) {
      timeoutQuery.current = setTimeout(fetchNewRecordIds, NEW_RECORD_IDS_POLL_INTERVAL)
    }

    setNewRecordsPrompt('')
  }, [duration, epochs])

  useEffect(() => {
    setPlayer({})
    setRedone({})

    creativeRecordsLazyQuery({
      variables: {
        query: {
          from: epochs.from,
          until: epochs.until,
          status,
          query
        }
      }
    })
  }, [partnerId, epochs, status, query])

  const scrollToTop = () => {
    const scrollArea = document.querySelector('.creatives-view__records')
    const scrollTop = scrollArea.scrollTop
    if (scrollTop === 0) return true

    const scrollHeight = scrollArea.scrollHeight
    const distance = scrollTop / scrollHeight
    const easedSpeed = 1500 * distance
    const speed = easedSpeed > 50 ? easedSpeed : 50

    scrollArea.scrollTop = scrollTop - speed
    document.documentElement.scrollTop = scrollTop

    const timeout = setTimeout(() => {
      setTimeouts(R.filter(id => id !== timeout, timeouts))
      scrollToTop()
    }, 30)

    setTimeouts(R.append(timeout, timeouts))
  }

  const updateWithNewer = async () => {
    scrollToTop()

    setNewRecordsPrompt('')
    setRedone({})

    if (duration === 'today') {
      creativeRecordsLazyQueryResult.refetch()
    } else {
      const { now, from, to: until } = convertDurationToEpochs(duration)
      dispatch(setCreativesFormField('epochs', { now, from, until }))
    }
  }

  const [onCopy, isCopied, copied] = useCopied()

  const onPlay = (id, uri) => {
    const url = makeProxyUrl(uri)
    setPlayer({ id, url, loading: true })
  }

  const onPlayerReady = () => {
    setPlayer({
      ...player,
      loading: undefined,
      playing: true
    })
  }

  const onPlayerEnded = () => {
    setPlayer({})
  }

  const onPlayerError = () => {
    setPlayer({
      ...player,
      error: 'Media asset unavailable'
    })
  }

  const onPlayerReset = () => {
    setPlayer({})
  }

  const onRedo = id => {
    setRedone(redone => ({
      ...redone,
      inProgressId: id
    }))

    creativeRetranscodeMutation({
      variables: {
        id
      }
    })
  }

  useEffect(() => {
    const recordsLocal = creativeRecordsLazyQueryResult.data?.creatives?.records ?? []
    // Apollo caches original results and appends new records; need to re-sort...
    recordsLocal.sort((a, b) => b.epoch - a.epoch)
    setRecords(recordsLocal)
  }, [creativeRecordsLazyQueryResult])

  const updateCreativeRecord = id => {
    creativeRecordLazyQuery({
      variables: {
        id
      }
    })
  }

  useEffect(() => {
    const record = creativeRecordLazyQueryResult.data?.creative
    if (record) {
      setRecords(records => records.map(r => r.id === record.id ? record : r))
    }
  }, [creativeRecordLazyQueryResult])

  if (records.length) {
    const first = records[0]
    const last = records[records.length - 1]

    latest.current = first.id
    offset.current = {
      id: last.id,
      time: last.epoch
    }
  }

  dispatch(setCreativeIds(records))

  const context = {
    claims,
    features,
    roles,
    partner: partnerId
  }

  const copiedStyles = calcCopiedStyles(copied)

  const makeDownloadUrl = () => {
    let url = `/api/partner/${partnerId}/creatives/csv?from=${epochs.from}&until=${epochs.until}`

    if (status) {
      url += `&status=${encodeURIComponent(status.toLowerCase())}`
    }

    if (query) {
      url += `&query=${encodeURIComponent(query)}`
    }

    return url
  }

  const downloadUrl = makeDownloadUrl()

  return (
    <ViewContext.Provider value={context}>
      <div className='creatives-view'>
        <div className='creatives-view__header'>
          <Header
            middle={
              <div className='creatives-view__header-middle'>
                <SearchBar
                  value={search}
                  pending={creativeRecordsLazyQueryResult.loading || creativeRetranscodeMutationResult.loading}
                  onChange={changed => {
                    setSearch(changed)
                  }}
                  onClear={() => {
                    setSearch(undefined)
                    dispatch(setCreativesFormField('query', undefined))
                  }}
                  onSubmit={() => {
                    dispatch(setCreativesFormField('query', search))
                  }}
                />

                <div className='creatives-view__download'>
                  <div className='creatives-view__download-container'>
                    <Wham.Button type="primary" href={downloadUrl}>Download CSV</Wham.Button>
                  </div>
                </div>
              </div>
            }
          />

          <div className='creatives-view__pending'>
            <Wham.Progress type='indeterminate' active={creativeRecordsLazyQueryResult.loading} />
          </div>
        </div>

        <div className={classNames('creatives-view__controls', { 'creatives-view__controls--newer': checkForNewer && newRecordsPrompt })}>
          <div className='creatives-view__controls-container'>
            {checkForNewer && newRecordsPrompt
              ? (
                <div className='creatives-view__newer'>
                  <span
                    className='creatives-view__newer-prompt'
                    onClick={() => updateWithNewer()}
                  >
                    {newRecordsPrompt}
                  </span>
                </div>
              )
              : null}

            <CreativeDates
              duration={duration}
              epochs={epochs}
              pending={creativeRecordsLazyQueryResult.loading || creativeRetranscodeMutationResult.loading}
              onChange={epochs => {
                dispatch(setCreativesFormField('epochs', epochs))
              }}
              onSelect={selected => {
                dispatch(setCreativesFormField('duration', selected))
              }}
            />
          </div>
        </div>

        <div className='creatives-view__content'>
          <div className='creatives-view__content-container'>
            <div className='creatives-view__content-lhs'>
              <div className='creatives-view__filters'>
                <CreativesFilterByStatus
                  selected={status}
                  pending={creativeRecordsLazyQueryResult.loading || creativeRetranscodeMutationResult.loading}
                  onClick={option => {
                    dispatch(setCreativesFormField('status', option === status ? undefined : option))
                  }}
                />
              </div>
            </div>

            <div className='creatives-view__content-main'>
              <div>
                <div className='creatives-view__records-wrapper'>
                  <div className='creatives-view__records' data-cy='creative-records'>
                    {records.map((record, index) => (
                      <CreativeSummaryRecord
                        key={record.id}
                        record={record}
                        play={{
                          loading: player.loading && record.id === player.id,
                          playing: player.playing && record.id === player.id,
                          error: player.error && record.id === player.id,
                          disabled: (player.loading || player.playing) && record.id !== player.id
                        }}
                        redo={{
                          pending: creativeRetranscodeMutationResult.loading && record.id === redone.inProgressId,
                          complete: redone[record.id]?.done,
                          error: redone[record.id]?.error,
                          disabled: creativeRetranscodeMutationResult.loading || record.status === 'Retranscode' || record.status === 'Pending'
                        }}
                        pending={creativeRecordsLazyQueryResult.loading}
                        onClick={path => dispatch(push(path))}
                        onCopy={(key, offset) => onCopy(index, key, offset)}
                        isCopied={(key, offset) => isCopied(index, key, offset)}
                        onPlay={uri => onPlay(record.id, uri)}
                        onRedo={onRedo}
                        onValidate={uri => {
                          const to = expandPathTemplate(paths.assetValidator, { partnerId })
                          dispatch(validateAsset({ uri }))
                          dispatch(goTo({ to }))
                        }}
                      />
                    ))}
                  </div>

                  {copied.length ? (
                    <span className='creatives-view__copied' style={copiedStyles}>Copied to Clipboard</span>
                  ) : null}
                </div>

                <div className='creatives-view__loader'>
                  {creativeRecordsLazyQueryResult.loading ? (
                    <Wham.ProgressCircular active type='indeterminate' />
                  ) : creativeRecordsLazyQueryResult.networkStatus === APOLLO.error ? (
                    <div>
                      <span className='creatives-view__loader-prompt'>Error Loading Records</span>
                    </div>
                  ) : records.length === 0 ? (
                    <div>
                      <span className='creatives-view__loader-prompt'>No Records Found</span>
                    </div>
                  ) : (
                    <div>
                      <span className='creatives-view__loader-prompt'>No Additional Records Found</span>
                    </div>
                  )}
                </div>

                <VisibilitySensor
                  onChange={visible => {
                    if (visible && Object.keys(offset).length && creativeRecordsLazyQueryResult.networkStatus === APOLLO.ready && creativeRecordsLazyQueryResult.fetchMore) {
                      creativeRecordsLazyQueryResult.fetchMore({
                        variables: {
                          page: {
                            offset: offset.current
                          }
                        }
                      })
                    }
                  }}
                >
                  <div>&nbsp;</div>
                </VisibilitySensor>
              </div>
            </div>

            <div className='creatives-view__content-rhs' />
          </div>
        </div>

        {player.url ? (
          <>
            <VideoPlayer
              url={player.url}
              onReady={() => onPlayerReady()}
              onEnded={() => onPlayerEnded()}
              onError={() => onPlayerError()}
            />

            {player.loading ? null : (
              <button className='creatives-view__cancel w-btn' onClick={onPlayerReset}>
                <span className='creatives-view__cancel-icon w-icon'>close</span>
              </button>
            )}
          </>
        ) : null}

        <DocLink topic='creative-assets' />
      </div>
    </ViewContext.Provider>
  )
}

CreativesView.propTypes = {
  partnerId: PropTypes.string,
  claims: PropTypes.object,
  features: PropTypes.object,
  roles: PropTypes.array,
  dispatch: PropTypes.func,
  duration: PropTypes.string.isRequired,
  epochs: PropTypes.shape({
    now: PropTypes.number.isRequired,
    from: PropTypes.number.isRequired,
    until: PropTypes.number.isRequired
  }).isRequired,
  status: PropTypes.string,
  query: PropTypes.string
}

const mapStateToProps = state => {
  const partnerId = selectors.activePartnerGlobalSelector(state)
  const claims = selectors.userClaimSelector(state)
  const features = selectors.userFeaturesSelector(state)
  const roles = selectors.userRolesSelector(state)
  const { media } = selectors.assetValidatorSelector(partnerId)(state)
  const { uris } = media

  const duration = selectors.creativesDurationSelector(partnerId)(state)
  const epochs = selectors.creativesEpochsSelector(partnerId)(state)
  const status = selectors.creativesStatusSelector(partnerId)(state)
  const query = selectors.creativesQuerySelector(partnerId)(state)

  return {
    partnerId,
    claims,
    features,
    roles,
    uris,
    duration,
    epochs,
    status,
    query
  }
}

export default connect(mapStateToProps)(CreativesView)

import * as R from 'ramda'

import { Facets, Groups } from '../../../constants/debug'

export const changeGroupBy = (groupBy, search) => {
  if (groupBy === Groups.SESSION) {
    return typeof search.group === 'undefined' || search.group === 'false'
  }

  return search.group === 'true'
}

export const makeFacets = search => {
  const entries = Facets.map(({ name }) => [name, search[name] ?? []])
  return Object.fromEntries(entries)
}

export const makeOpenFilters = (search, groupBy) => {
  const openFacetFilters = R.compose(
    R.fromPairs(),
    R.map(({ control }) => [control, { opened: true }]),
    R.filter(({ name }) => Boolean(search[name]))
  )(Facets)

  const maybeOpenGroupByFilter = changeGroupBy(groupBy, search)
    ? { sessions: { opened: true } }
    : undefined

  return {
    ...openFacetFilters,
    ...maybeOpenGroupByFilter
  }
}

export const valid = search => {
  const values = Object.keys(search)
    .filter(key => {
      if (key !== 'feature[facets]') {
        const facetNames = Facets.map(({ name }) => name)
        return !facetNames.includes(key)
      }

      return true
    })
    .map(filter => search[filter])

  if (R.any(Array.isArray, values)) {
    return false
  }

  if (!search.from || !search.to) {
    return false
  }

  const from = Number(search.from)
  const to = Number(search.to)

  if (!Number.isInteger(from) || !Number.isInteger(to) || from < 0 || to < 0 || to < from) {
    return false
  }

  if (search.group && search.group !== 'true') {
    return false
  }

  return true
}

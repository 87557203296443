import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

const partnerNames = {
  mlb: 'Major League Baseball',
  espn: 'ESPN',
  nhl: 'NHL'
}

const partnerLogos = {
  mlb: Wham.MLBMark,
  espn: Wham.ESPNMark,
  nhl: Wham.NHLMark
}

export const getNameForPartner = partnerId => partnerNames[partnerId] || partnerId.toUpperCase()

export const getLogoForPartner = partnerId => partnerLogos[partnerId] || Wham.BAMTECHMark

const PartnerLogo = ({
  partnerId,
  ...otherProps
}) => {
  const Logo = getLogoForPartner(partnerId)

  return (
    <Logo {...otherProps} />
  )
}

PartnerLogo.propTypes = {
  partnerId: PropTypes.string.isRequired
}

export default PartnerLogo

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { WHAM_SIZES, WHAM_TYPES } from '../lib/wham'
import { splitFieldEventProps } from '../lib/eventProps'

import './Select.scss'
import '../../scss/wham.scss'

const createOptionTag = ({ id, label, value }, i) => (<option key={id || `${i}`} value={value}>{label}</option>) //eslint-disable-line

const getAutosizeLabel = (newValue, options) => {
  if (typeof newValue === 'undefined') return ''
  if (!newValue.label && options.length === 0) return ''
  if (newValue.label) return newValue.label
  const foundValue = options.find(({ value }) => (value === newValue))
  return foundValue ? foundValue.label : ''
}

export default class Select extends React.Component {
  static displayName = 'Select'
  static propTypes = {
    alwaysValidate: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Called upon changing the value.
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {object} data - The element from the options array that has been selected.
     */
    onChange: PropTypes.func,
    /** An array of Options object, of the form:  [{label: "Displayed in the drop down", value: "Emitted with e.target.value"}] */
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
    requiredMessage: PropTypes.string,
    subtle: PropTypes.bool,
    value: PropTypes.any,
    primary: PropTypes.bool,
    danger: PropTypes.bool,
    size: PropTypes.oneOf(WHAM_SIZES),
    /* DEPRECATION NOTICE: Consider using the boolean props 'primary' and 'danger' to choose a style, this feature will be removed */
    type: PropTypes.oneOf(WHAM_TYPES),
    optional: PropTypes.bool,
    defaultText: PropTypes.string,
    readOnly: PropTypes.bool,
    autosize: PropTypes.bool
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedOption: null,
      autosizeLabel: ''
    }
  }

  renderIfExists = arrayElems => {
    /* If there's no groups, don't display 'undefined' but a blank string */
    return (arrayElems.length === 0) ? '' : arrayElems
  }

  callOnChangeWithAdditionalData = e => {
    const { options, onChange, autosize } = this.props
    const selectedOption = options.find(({ value }) => value === e.target.value)
    onChange(e, selectedOption)
    if (autosize) {
      const autosizeLabel = getAutosizeLabel(e.target.value, options)
      this.setState({
        ...this.state,
        autosizeLabel
      })
    }
  }

  UNSAFE_componentWillReceiveProps ({ value, options }) {
    const autosizeLabel = getAutosizeLabel(value, options)
    if (this.props.autosize && autosizeLabel) {
      this.setState({
        ...this.state,
        autosizeLabel
      })
    }
  }

  UNSAFE_componentWillMount () {
    const { value, options } = this.props
    const autosizeLabel = getAutosizeLabel(value, options)
    if (this.props.autosize && autosizeLabel) {
      this.setState({
        ...this.state,
        autosizeLabel
      })
    }
  }

  render () {
    const {
      autosize,
      size = 'medium',
      primary,
      danger,
      type,
      className,
      readOnly,
      optional,
      defaultText,
      onChange,
      value,
      subtle,
      options,
      ...otherProps
    } = this.props
    const [fieldProps, wrapperProps] = splitFieldEventProps(otherProps)
    const extractedValue = (typeof value === 'string' || typeof value === 'number') ? value : (value ? value.value : null)

    const {
      selectedOption
    } = this.state
    const localOptions = selectedOption ? [selectedOption] : options

    // classes
    var componentClass = classNames('w-select', {
      'w-select--fullWidth': !autosize,
      [`w-select--${type}`]: type,
      [`w-select--${size}`]: size,
      'w-select--subtle': subtle,
      'w-select--autosize': autosize,
      'w-select--readonly': readOnly,
      'w-select--danger': (type === 'danger' || danger),
      [`w-select--${type}`]: type
    }, className)

    // optGroups
    const optionsWithoutGroups = localOptions.filter(option => !option.group)
    const optionsWithGroups = localOptions.filter(option => option.group)
    const optionsInGroupsMap = optionsWithGroups.reduce((prev, cur) => {
      const { group, ...optionProps } = cur
      prev[group] = prev[group] || []
      prev[group].push(optionProps)
      return prev
    }, {})

    const renderedOptGroups = []
    for (const key in optionsInGroupsMap) {
      if (Object.prototype.hasOwnProperty.call(optionsInGroupsMap, key)) {
        const optionsRendered = optionsInGroupsMap[key].map(createOptionTag)
        renderedOptGroups.push(
          <optgroup key={key} label={key}>
            {optionsRendered}
          </optgroup>
        )
      }
    }

    const renderedOptions = optionsWithoutGroups
      .map(createOptionTag)

    const defaultPlaceholder = optional ? (
      <option value={null}>{defaultText || null}</option>
    ) : null

    if (autosize) {
      setTimeout(() => {
        const selectItem = this.selectRef
        const selectLabel = this.selectLabelRef
        if (selectItem && selectLabel && this.state.autosizeLabel) {
          selectItem.style.width = selectLabel.offsetWidth + 'px'
        }
      }, 0)
    }

    return (
      <span
        {...wrapperProps}
        className={componentClass}
      >
        <span ref={el => { this.selectLabelRef = el }} className='w-select__value'>{this.state.autosizeLabel}</span>
        <select
          {...fieldProps}
          ref={el => { this.selectRef = el }}
          readOnly={readOnly}
          value={extractedValue}
          onChange={onChange && this.callOnChangeWithAdditionalData}
          onMouseDown={() => {
            // ADENGWEB-1385
            // The problem seems to be caused by the call to setState which,
            // in Firefox, results in an additional re-render which re-establishes
            // the old 'extractedValue' thus losing any newly selected value.
            // this.setState({ selectedOption: null })
          }}
          onKeyDown={() => {
            this.setState({ selectedOption: null })
          }}
        >
          {defaultPlaceholder}
          {this.renderIfExists(renderedOptions)}
          {this.renderIfExists(renderedOptGroups)}
        </select>
      </span>
    )
  }
}

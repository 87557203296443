import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import * as Wham from '../../../../../wham/components'
import './style.css'

const WHITELIST_PARAMS = ['uid', 'devid', 'swid']

const getOptionsForWhitelistParam = (whitelist, type) => {
  const entries = whitelist.filter(R.propEq('idType', type))
  const options = entries.map(entry => ({
    label: entry.label ? `${entry.label} (${entry.value})` : entry.value,
    value: entry.value
  }))
  return options
}

const SetTokenParamValue = ({
  simpleParam,
  whitelist,
  onChangeParamValue
}) => {
  if (!simpleParam.isCustom && WHITELIST_PARAMS.includes(simpleParam.name)) {
    const options = getOptionsForWhitelistParam(whitelist, simpleParam.name)
    const matchingOption = options.find(el => el.value === simpleParam.value)
    if (!matchingOption) {
      const value = options[0].value
      onChangeParamValue(simpleParam, { target: { value } })
    }
    return (
      <Wham.Select
        className='settoken-params__value'
        optional={false}
        options={options}
        value={simpleParam.value}
        onChange={e => onChangeParamValue(simpleParam, e)}
        size='medium'
      />
    )
  } else {
    return (
      <Wham.FormInput
        className='settoken-params__value'
        value={simpleParam.value}
        onChange={e => onChangeParamValue(simpleParam, e)}
        size='medium'
      />
    )
  }
}

SetTokenParamValue.propTypes = {
  simpleParam: PropTypes.shape({
    name: PropTypes.string.isRequired,
    friendlyName: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    isCustom: PropTypes.bool,
    isEmpty: PropTypes.bool,
    isDuplicate: PropTypes.bool
  }).isRequired,
  whitelist: PropTypes.array,
  onChangeParamValue: PropTypes.func.isRequired
}

export const configureSyncWithModal = (whitelist, defaultSetTokenUrl, setTokenParams) =>
  (currentSetTokenUrl, simpleParams) => {
    const simpleParamNames = R.pluck('name', simpleParams)
    const parsedDefaultUrl = new URL(defaultSetTokenUrl)
    const parsedCurrentUrl = new URL(currentSetTokenUrl)
    const defaultKeys = Array.from(parsedDefaultUrl.searchParams.keys())
    const currentKeys = Array.from(parsedCurrentUrl.searchParams.keys())
    const customKeys = R.difference(currentKeys, defaultKeys)

    const newRegularParams = R.chain(setTokenParam => {
      const { name } = setTokenParam
      if (parsedCurrentUrl.searchParams.has(name) && !simpleParamNames.includes(name)) {
        const value = parsedCurrentUrl.searchParams.get(name)
        if (value !== setTokenParam.defaultValue) {
          return [{ ...setTokenParam, value }]
        }
      }
      return []
    }, setTokenParams)

    const newCustomParams = R.chain(customKey => {
      if (!simpleParamNames.includes(customKey)) {
        return {
          name: customKey,
          friendlyName: customKey,
          defaultValue: '',
          value: parsedCurrentUrl.searchParams.get(customKey),
          isCustom: true
        }
      }
      return []
    }, customKeys)

    const updatedCurrentParams = simpleParams.map(param => {
      if (parsedCurrentUrl.searchParams.has(param.name)) {
        const value = parsedCurrentUrl.searchParams.get(param.name)
        return { ...param, value }
      }
      return param
    })

    const isInvalidWhitelistParam = param => {
      if (param.isCustom || !WHITELIST_PARAMS.includes(param.name)) return false
      const options = getOptionsForWhitelistParam(whitelist, param.name)
      return options.findIndex(option => option.value === param.value) < 0
    }

    const isRemovedCustomParam = param =>
      param.isCustom && !customKeys.includes(param.name)

    const isRemovedRegularParam = param =>
      !param.isCustom && !currentKeys.includes(param.name)

    const newSimpleParams = R.compose(
      R.reject(isInvalidWhitelistParam),
      R.reject(isRemovedCustomParam),
      R.reject(isRemovedRegularParam),
      R.concat(newCustomParams),
      R.concat(newRegularParams),
      R.concat(updatedCurrentParams)
    )([])
    return newSimpleParams
  }

const validateSimpleParams = (simpleParams, defaultSetTokenUrl) => {
  const parsedDefaultUrl = new URL(defaultSetTokenUrl)
  const defaultKeys = Array.from(parsedDefaultUrl.searchParams.keys()).map(R.toLower)
  const validatedSimpleParams = simpleParams.map(simpleParam => {
    if (!simpleParam.isCustom) return simpleParam
    const name = R.toLower(simpleParam.name)
    const otherSimpleParamNames = R.compose(
      R.map(R.toLower),
      R.pluck('name'),
      R.without([simpleParam])
    )(simpleParams)
    const isEmpty = R.isEmpty(R.trim(name))
    const isDuplicateSimpleParam = otherSimpleParamNames.includes(name)
    const isDuplicateDefaultKey = defaultKeys.includes(name)
    const isDuplicate = !isEmpty && (isDuplicateSimpleParam || isDuplicateDefaultKey)
    return { ...simpleParam, isEmpty, isDuplicate }
  })
  return validatedSimpleParams
}

const SIMPLE_PARAM_ADD = Symbol('SIMPLE_PARAM_ADD')
const SIMPLE_PARAM_REMOVE = Symbol('SIMPLE_PARAM_REMOVE')
const SIMPLE_PARAM_UPDATE = Symbol('SIMPLE_PARAM_UPDATE')

const SetTokenParams = ({
  whitelist,
  defaultSetTokenUrl,
  setTokenUrl,
  setTokenParams,
  onChangeSettokenUrl,
  setCustomParamsErrorFlag
}) => {
  const [simpleParams, setSimpleParams] = useState([])
  const [nextCustomParamNumber, setNextCustomParamNumber] = useState(1)
  useEffect(() => {
    try {
      const syncWithModal = configureSyncWithModal(whitelist, defaultSetTokenUrl, setTokenParams)
      const updatedSimpleParams = syncWithModal(setTokenUrl, simpleParams)
      setSimpleParams(updatedSimpleParams)
    } catch (error) {
      // ignore
    }
  }, [setTokenUrl])

  const getRemainingOptions = () =>
    setTokenParams
      .filter(p => !simpleParams.find(R.eqProps('name', p)))
      .map(p => ({
        label: p.friendlyName,
        value: p.name
      }))
      .concat({
        label: 'Custom',
        value: 'custom'
      })

  const hasErrorFlag = sp => sp.isEmpty || sp.isDuplicate

  const applyValidatedSimpleParams = details => {
    const applyChanges = details => {
      switch (details.actionType) {
        case SIMPLE_PARAM_ADD:
          return simpleParams.concat(details.newSimpleParam)
        case SIMPLE_PARAM_REMOVE:
          return R.without([details.existingSimpleParam], simpleParams)
        case SIMPLE_PARAM_UPDATE:
          return simpleParams.map(simpleParam =>
            simpleParam === details.existingSimpleParam
              ? details.updatedSimpleParam : simpleParam)
        default:
          return simpleParams
      }
    }
    const updatedSimpleParams = applyChanges(details)
    const validatedSimpleParams = validateSimpleParams(updatedSimpleParams, defaultSetTokenUrl)
    setSimpleParams(validatedSimpleParams)
    const valid = R.none(hasErrorFlag, validatedSimpleParams)
    if (valid) {
      const parsedDefaultUrl = new URL(defaultSetTokenUrl)
      const parsedCurrentUrl = new URL(setTokenUrl)
      const defaultKeys = Array.from(parsedDefaultUrl.searchParams.keys())
      const currentKeys = Array.from(parsedCurrentUrl.searchParams.keys())
      const unknownKeys = R.difference(currentKeys, defaultKeys)
      for (const unknownKey of unknownKeys) {
        parsedCurrentUrl.searchParams.delete(unknownKey)
      }
      for (const simpleParam of validatedSimpleParams) {
        parsedCurrentUrl.searchParams.set(simpleParam.name, simpleParam.value)
      }
      if (details.actionType === SIMPLE_PARAM_REMOVE) {
        const simpleParam = details.existingSimpleParam
        if (!simpleParam.isCustom) {
          parsedCurrentUrl.searchParams.set(simpleParam.name, simpleParam.defaultValue)
        }
      }
      onChangeSettokenUrl(decodeURIComponent(parsedCurrentUrl.toString()))
      setCustomParamsErrorFlag(false)
    } else {
      setCustomParamsErrorFlag(true)
    }
  }

  const onChangeCustomParamName = (simpleParam, e) => {
    const newName = e.target.value
    applyValidatedSimpleParams({
      actionType: SIMPLE_PARAM_UPDATE,
      existingSimpleParam: simpleParam,
      updatedSimpleParam: {
        ...simpleParam,
        name: newName,
        friendlyName: newName
      }
    })
  }

  const onChangeParamValue = (simpleParam, e) => {
    const newValue = e.target.value
    applyValidatedSimpleParams({
      actionType: SIMPLE_PARAM_UPDATE,
      existingSimpleParam: simpleParam,
      updatedSimpleParam: {
        ...simpleParam,
        value: newValue
      }
    })
  }

  const onAddParam = e => {
    const name = e.target.value
    if (name === 'custom') {
      const customParamName = `custom${nextCustomParamNumber}`
      setNextCustomParamNumber(nextCustomParamNumber + 1)
      applyValidatedSimpleParams({
        actionType: SIMPLE_PARAM_ADD,
        newSimpleParam: {
          name: customParamName,
          friendlyName: customParamName,
          defaultValue: '',
          value: '',
          isCustom: true
        }
      })
    } else {
      const setTokenParam = setTokenParams.find(R.propEq('name', name))
      if (setTokenParam) {
        const parsedCurrentUrl = new URL(setTokenUrl)
        const currentValue = parsedCurrentUrl.searchParams.get(name)
        applyValidatedSimpleParams({
          actionType: SIMPLE_PARAM_ADD,
          newSimpleParam: {
            ...setTokenParam,
            value: currentValue || setTokenParam.defaultValue
          }
        })
      }
    }
  }

  const onRemoveParam = simpleParam => {
    applyValidatedSimpleParams({
      actionType: SIMPLE_PARAM_REMOVE,
      existingSimpleParam: simpleParam
    })
  }

  return (
    <div className='settoken-params'>
      {
        simpleParams.map((simpleParam, index) => (
          <div key={index} className='settoken-params__row'>
            <Wham.FormInput
              className='settoken-params__key'
              value={simpleParam.name}
              disabled={!simpleParam.isCustom}
              danger={hasErrorFlag(simpleParam)}
              onChange={e => {
                if (simpleParam.isCustom) {
                  onChangeCustomParamName(simpleParam, e)
                }
              }}
              size='medium'
            />
            <SetTokenParamValue
              simpleParam={simpleParam}
              whitelist={whitelist}
              onChangeParamValue={onChangeParamValue}
            />
            <Wham.Icon
              className='settoken-params__remove'
              iconName='remove_circle_outline'
              onClick={() => onRemoveParam(simpleParam)}
            />
          </div>
        ))
      }
      <Wham.Select
        className='settoken-params__key settoken-params__key--add'
        defaultText='Add a parameter'
        optional={true}
        options={getRemainingOptions()}
        value=''
        onChange={onAddParam}
        size='medium'
      />
    </div>
  )
}

SetTokenParams.propTypes = {
  defaultSetTokenUrl: PropTypes.string.isRequired,
  setTokenUrl: PropTypes.string.isRequired,
  setTokenParams: PropTypes.array.isRequired,
  whitelist: PropTypes.array,
  onChangeSettokenUrl: PropTypes.func.isRequired,
  setCustomParamsErrorFlag: PropTypes.func.isRequired
}

export default SetTokenParams

import memoize from 'lodash/memoize'

import { parseTemplate } from './template'
import { parseUrl } from './url'

const splitter = evaluationResult => {
  const { header, url } = parseTemplate(evaluationResult)
  const { base, tuples, textParts } = parseUrl(url)

  return {
    header,
    url,
    base,
    textParts,
    tuples
  }
}

export default memoize(splitter)

const isDelimiter = char => {
  return char === '&' || char === ';'
}

const isOptional = char => {
  return char === '*'
}

const isAlternative = (chars, offset) => {
  if (chars.length > (offset + 2)) {
    return isDelimiter(chars[offset + 1]) && !isOptional(chars[offset + 2])
  }

  return false
}

const isMacro = depth => {
  return depth.braces === 0 && depth.brackets === 0
}

const getFlags = optional => {
  if (optional === 'alternative') {
    return { flags: { optional: true, alternative: true } }
  }

  if (optional === 'standard') {
    return { flags: { optional: true } }
  }

  return {}
}

const parseQs = (base, qs) => {
  const textParts = []
  const tuples = []

  if (base) {
    textParts.push({ type: 'normal', text: base })
  }

  const depth = {
    braces: 0,
    brackets: 0
  }

  let $key = ''
  let $text = ''
  let $value = ''
  let $values = []
  let $separator = false

  let optional = ''
  const optionals = []

  const creteTuple = delimiter => {
    const key = $key || null
    const value = $value || null
    const values = $values.slice(0)

    const flags = getFlags(optionals.pop())

    return { key, value, values, delimiter, ...flags }
  }

  const chars = Array.from(qs)
  const last = qs.length - 1

  chars.forEach((char, offset) => {
    switch (char) {
      case '=':
        if ($key) {
          textParts.push({ type: 'key', text: $key })
        }

        textParts.push({ type: 'separator', text: '=' })

        $separator = true

        break

      case '*':
        if (isMacro(depth)) {
          if ($text) {
            textParts.push({ type: 'value', text: $text })
            $values.push({ type: 'value', text: $text })
            $text = ''
          }

          textParts.push({ type: 'optional', text: '*' })

          if (optional) {
            optionals.push(optional)
            optional = ''
          } else {
            optional = isAlternative(chars, offset) ? 'alternative' : 'standard'
          }
        } else {
          $text += char
          $value += char
        }

        break

      case '&':
      case ';': {
        if ($text) {
          textParts.push({ type: 'value', text: $text })
          $values.push({ type: 'value', text: $text })
          $text = ''
        }

        textParts.push({ type: 'delimiter', text: char })

        tuples.push(creteTuple(char))
        $values = []

        $key = ''
        $value = ''

        $separator = false

        break
      }

      case '{':
        if ($separator) {
          if ($text && isMacro(depth)) {
            textParts.push({ type: 'value', text: $text })
            $values.push({ type: 'value', text: $text })
            $text = ''
          }

          depth.braces++

          $text += char
          $value += char
        }

        break

      case '[':
        if ($separator) {
          if ($text && isMacro(depth)) {
            textParts.push({ type: 'value', text: $text })
            $values.push({ type: 'value', text: $text })
            $text = ''
          }

          depth.brackets++

          $text += char
          $value += char
        }

        break

      case '}':
        if ($separator && $text) {
          depth.braces--

          $text += char
          $value += char

          if (isMacro(depth)) {
            textParts.push({ type: 'macro', text: $text })
            $values.push({ type: 'macro', text: $text })
            $text = ''
          }
        }

        break

      case ']':
        if ($separator && $text) {
          depth.brackets--

          $text += char
          $value += char

          if (isMacro(depth)) {
            textParts.push({ type: 'macro', text: $text })
            $values.push({ type: 'macro', text: $text })
            $text = ''
          }
        }

        break

      default:
        if ($separator) {
          $text += char
          $value += char
        } else {
          $key += char
        }
    }

    if (offset === last) {
      if ($text) {
        textParts.push({ type: 'value', text: $text })
        $values.push({ type: 'value', text: $text })
      }

      if (isDelimiter(qs[last])) {
        $key = null
        $value = null
      }

      tuples.push(creteTuple())
    }
  })

  return {
    textParts,
    tuples
  }
}

export {
  parseQs
}

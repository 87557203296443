import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './SectionSelector.scss'

import Icon from '../../../Icon'
import Menu from '../../../Menu'
import MenuItem from '../../../MenuItem'

class SectionSelector extends React.Component {
  static propTypes = {
    selectLocation: PropTypes.func,
    menuItems: PropTypes.array,
    className: PropTypes.string,
    children: PropTypes.node,
    appName: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      active: false
    }
  }

  toggleMenu = () => {
    this.setState({
      ...this.state,
      active: !this.state.active
    })
  }

  collapseMenu = () => {
    this.setState({
      ...this.state,
      active: false
    })
  }

  render () {
    const {
      selectLocation = () => {},
      menuItems,
      children,
      appName,
      className,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-section-selector',
      (this.state.active ? 'w-section-selector--active' : null),
      (!children ? 'w-section-selector--empty' : null),
      className
    )

    const selectedItem = menuItems.find(item => item.selected)
    const screenName = selectedItem ? selectedItem.label : 'Section'

    return (
      <div
        className={componentClass}
        tabIndex='0'
        onBlur={this.collapseMenu}
        {...otherProps}
      >
        <div className='w-section-selector__selected' onClick={this.toggleMenu}>
          <div className='w-section-selector__selected__content'>
            {this.state.active ? <Icon color='#fff' iconName='close' /> : <span>{screenName}</span>}
          </div>
        </div>
        {this.state.active
          ? <div className='w-section-selector__menu'>
            <Menu type='contextual'>
              {menuItems.map(item => (
                <MenuItem
                  key={item.label}
                  selected={item.selected}
                  label={item.label}
                  onClick={e => selectLocation(item, e)}
                  caption={item.caption}
                />
              ))}
            </Menu>
          </div>
          : null}
      </div>

    )
  }
}

export default SectionSelector

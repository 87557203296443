import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import * as R from 'ramda'

import * as Wham from '../../../wham/components'
import Header from '../../../containers/header'
import { displayIfPermissions } from '../../../components/authorized'
import { makeErrorBoundary } from '../../../components/error-hocs'
import PartnerLogo from '../../../components/logo/partner'

import * as Selectors from '../../../selectors'

import {
  getGlobalRule,
  getRulesForPartner,
  cloneRule,
  clearAlert,
  editRuleName,
  saveRule as saveRuleAction,
  saveGlobalRule,
  toggleRuleIsActive
} from '../../../actions/rules'
import { goToRules, goToCreate } from '../view-rules/local-actions'

import * as F from '../../../common/features'
import * as P from '../../../common/permissions'
import {
  CREATE_RULE_STRING,
  GLOBAL_RULE_STRING
} from '../../../route-paths'

import { macroSplitter } from '../../../lib/macros'
import { isValidUrl } from '../../../lib/util/urls'

import { deepEquals, isExpressionComplete, normalise } from '@adtech/rule-builder-ui'

const ELayoutColumn = makeErrorBoundary(Wham.LayoutColumn)

const HasPermission = displayIfPermissions(
  F.STANDARD_RULES_ACTIVATION,
  P.UPDATE)

const isActiveRule = (rule, updating) => {
  if (rule.isGlobal) {
    return true
  }
  return updating.active !== null ? updating.active : rule.active
}

const ruleIsNotCreate = ({ id }) => id !== CREATE_RULE_STRING

const ActivateButton = HasPermission(({
  saveIsEnabled,
  toggleRuleIsActive,
  rule = {},
  updating = {},
  dispatch,
  ...props
}) => (
  <Wham.Button
    {...props}
    id='toggleActive'
    data-cy='toggleActive'
    disabled={saveIsEnabled}
    onClick={() => toggleRuleIsActive(rule)}
  >
    {isActiveRule(rule, updating) ? 'Deactivate Rule' : 'Activate Rule'}
  </Wham.Button>
))

const normaliseRule = rule => {
  let prunedRule = R.omit([
    'partnerId',
    'isDirty',
    'createdAt',
    'updatedAt',
    'active'
  ], rule)
  prunedRule = rule.id === CREATE_RULE_STRING ? R.omit(['displayPriority'], prunedRule) : prunedRule
  return prunedRule
}

const expressionsDiffer = (e1, e2) => {
  try {
    const normalised1 = normalise(e1)
    const normalised2 = normalise(e2)
    return !deepEquals(normalised1, normalised2)
  } catch {
    return false
  }
}

const rulesDiffer = (rule1, rule2) => {
  return (
    rule1.name !== rule2.name ||
    rule1.displayPriority !== rule2.displayPriority ||
    rule1.evaluationResult !== rule2.evaluationResult ||
    expressionsDiffer(rule1.expression, rule2.expression)
  )
}

const CreateHeader = ({
  alert,
  lastRule = {},
  editName = R.identity,
  error = false,
  isGlobal,
  backToRules,
  partnerId,
  saveRule,
  toggleRuleIsActive,
  cloneRule,
  goToCreate,
  rule,
  pending,
  updating,
  priorityChanged,
  clearChanges
}) => {
  const DEFAULT_RULE = { updatedAt: 0 }
  const [originalRule, setOriginalRule] = useState(DEFAULT_RULE)
  if (originalRule.updatedAt < rule.updatedAt) {
    setOriginalRule(rule)
  }

  const originalRuleNormalised = normaliseRule(originalRule)
  const propRuleNormalised = normaliseRule(rule)
  const isDirty = propRuleNormalised.cloned || rulesDiffer(originalRuleNormalised, propRuleNormalised)

  const evaluationResult = R.pathOr('', ['evaluationResult'], rule)
  const name = R.pathOr('', ['name'], rule)
  const hasName = !!R.trim(name)
  const { url = '' } = macroSplitter(evaluationResult)
  const hasUrl = isValidUrl(url)
  const hasExpression = isExpressionComplete(rule.expression)

  const minReqForSave = R.all(R.identity)([
    hasName,
    hasUrl,
    R.or(R.prop('isGlobal', rule), hasExpression),
    R.not(pending)
  ])

  const saveIsEnabled = isDirty && minReqForSave

  // console.log('-'.repeat(80))
  // console.log({ isDirty, minReqForSave, saveIsEnabled, originalRuleNormalised, propRuleNormalised, hasExpression })
  // console.log('-'.repeat(80))

  const customRuleIsClean = R.all(R.identity)([
    R.not(saveIsEnabled),
    minReqForSave,
    R.not(rule.isGlobal)])

  const isActive = isActiveRule(rule, updating)
  const isChanged = isDirty && isActive
  const isSaved = (!isDirty && isActive && alert)

  const ruleName = R.path(['name'], rule)

  return (
    <div className='rule-header-wrapper'>
      <Wham.LayoutRow type='fit'>
        <ELayoutColumn>
          <div className={`header-wrapper ${isActive && 'is-active'}`}>
            <Header
              left={
                <div className='rule-header-nav'>
                  <div className='rule-header-back' onClick={() => backToRules(partnerId)}>
                    <Wham.AppSubject id='backToRules' backHandler={() => backToRules(partnerId)} />
                  </div>

                  <div className='rule-header-logo'>
                    <PartnerLogo partnerId={partnerId} inverse={!isActive} />
                  </div>

                  <div className='rule-header-name'>
                    <span className={`rule-name ${ruleName ? '' : 'rule-name--untitled'}`}>{ruleName || 'Untitled rule'}</span>
                  </div>
                </div>
              }
              middle={
                !isGlobal && ruleIsNotCreate(rule) ? (
                  <Wham.AppBarCenter heading={`Rule is ${isActive ? 'active' : 'deactivated'}`} subtitle={`Updated ${moment(rule.updatedAt).fromNow()}`} />
                ) : null
              }
              right={
                <div className='rule-header-controls'>
                  <div className='rule-header-controls-group'>
                    {isGlobal || rule.cloned ? null : (
                      <Wham.Button
                        onClick={() => {
                          cloneRule({ ruleToClone: rule })
                          goToCreate(partnerId)
                        }}
                        className='clone-button'
                        disabled={!customRuleIsClean}
                        inverse={!rule.active}
                        type='subtle'
                      >
                        <Wham.Icon iconName={'flip_to_front'} />
                        <span>Clone Rule</span>
                      </Wham.Button>
                    )}

                    {customRuleIsClean ? (
                      <ActivateButton
                        rule={rule}
                        updating={updating}
                        inverse={!isActive}
                        saveIsEnabled={saveIsEnabled}
                        toggleRuleIsActive={toggleRuleIsActive}
                      />
                    ) : null}

                    {saveIsEnabled ? (
                      <Wham.Button
                        id='saveRule'
                        data-cy='saveRule'
                        disabled={pending}
                        inverse={!isActive}
                        onClick={() => saveRule({ rule, priorityChanged })}
                        type='primary'
                      >
                        {pending ? 'Saving...' : 'Save Rule'}
                      </Wham.Button>
                    ) : null}
                  </div>
                </div>
              }
            />
          </div>
        </ELayoutColumn>
      </Wham.LayoutRow>

      {isChanged ? (
        <Wham.LayoutRow>
          <Wham.Message className='create-edit-rule__message create-edit-rule__message--warning' type='warning'>
            <span data-cy='edit-rule-warning'>You’re currently editing a live Rule. Saving these changes will impact production.</span>
            <a
              className='create-edit-rule__message-action'
              data-cy='clear-changes'
              href='#'
              onClick={e => {
                e.preventDefault()
                clearChanges()
              }}
            >
              Clear changes
            </a>
          </Wham.Message>
        </Wham.LayoutRow>
      ) : null}

      {isSaved ? (
        <Wham.LayoutRow>
          <Wham.Message className='create-edit-rule__message create-edit-rule__message--changed'>
            <span data-cy='edit-rule-message'>Changes saved. Your changes will go live shortly.</span>
            <a
              className='create-edit-rule__message-action'
              data-cy='revert-activation'
              href='#'
              onClick={e => {
                const payload = { rule: lastRule, revert: true }
                saveRule(payload)
              }}
            >
              Revert changes
            </a>
          </Wham.Message>
        </Wham.LayoutRow>
      ) : null}

      <Wham.LayoutRow>
        <div className='rule-input-wrapper'>
          <Wham.FormField>
            <Wham.FormInput
              id='rule-name'
              data-cy='rule-name'
              type='default'
              value={rule.name}
              onChange={e =>
                R.or(e.target.value.match(/^[\w\s[\]]+$/), R.not(e.target.value)) &&
                editName({ ...e, rule })}
              placeholder={'First, let\'s give your new rule a name...'}
            />
          </Wham.FormField>
        </div>
      </Wham.LayoutRow>

      <Wham.LayoutRow>
        <Wham.Progress type='indeterminate' active={pending} />
      </Wham.LayoutRow>

      <Wham.LayoutRow>
        {error ? <div className='error-message' data-cy='error'>{error}</div> : null}
      </Wham.LayoutRow>
    </div>
  )
}

CreateHeader.propTypes = {
  alert: PropTypes.bool,
  saveRule: PropTypes.func,
  clearChanges: PropTypes.func,
  lastRule: PropTypes.object,
  editName: PropTypes.func,
  error: PropTypes.bool,
  cloneRule: PropTypes.func,
  goToCreate: PropTypes.func,
  backToRules: PropTypes.func,
  rule: PropTypes.object,
  toggleRuleIsActive: PropTypes.func,
  name: PropTypes.string,
  partnerId: PropTypes.string,
  pending: PropTypes.bool,
  updating: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  priorityChanged: PropTypes.bool.isRequired,
  saveIsEnabled: PropTypes.bool,
  isGlobal: PropTypes.bool,
  minReqForSave: PropTypes.bool
}

const getLastRule = (ruleId, rule, lastState) => {
  if (ruleId === GLOBAL_RULE_STRING) {
    return R.pathOr(null, ['globalRule'], lastState)
  }

  return R.defaultTo(null, R.find(R.propEq('id', rule.id), R.pathOr([], ['rules'], lastState)))
}
export const mapStateToProps = (state, ownProps) => {
  const partnerId = Selectors.activePartnerGlobalSelector(state)
  const ruleId = R.path(['params', 'ruleId'], ownProps.match)
  const error = Selectors.ruleErrorSelector(partnerId)(state)
  const alert = Selectors.alertRuleSelector(partnerId)(state)
  const lastState = Selectors.lastStateSelector(partnerId)(state)
  const lastRule = getLastRule(ruleId, ownProps.rule, lastState)
  return {
    ...ownProps,
    alert,
    error,
    lastRule
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const editName = R.compose(dispatch, editRuleName)

  const backToRules = partnerId => {
    dispatch(clearAlert())
    dispatch(goToRules(partnerId))
  }

  const fetchGlobalRule = R.compose(dispatch, getGlobalRule)
  const fetchRulesForPartner = R.compose(dispatch, getRulesForPartner)
  const clearChanges = ownProps.isGlobal
    ? fetchGlobalRule
    : fetchRulesForPartner

  const saveRule = ownProps.isGlobal
    ? R.compose(dispatch, saveGlobalRule)
    : R.compose(dispatch, saveRuleAction)

  return {
    editName,
    backToRules,
    clearChanges,
    saveRule,
    cloneRule: R.compose(dispatch, cloneRule),
    goToRules: R.compose(dispatch, goToRules),
    goToCreate: R.compose(dispatch, goToCreate),
    toggleRuleIsActive: R.compose(dispatch, toggleRuleIsActive)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateHeader)

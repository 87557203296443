module.exports = {
  SYSTEM_ADMIN: 'System Admin',
  PARTNER_ADMIN: 'Partner Admin',
  PARTNER_USER: 'Partner User',
  PARTNER_AFFILIATE_USER: 'Partner Affiliate User',
  ANALYTICS_USER: 'Analytics User',

  // Alias for SYSTEM_ADMIN but with special access to latent features.
  UAT: 'UAT',

  // Alias for PARTNER_USER. Migrate users in Active Directory then remove this.
  LEGACY_USER_GROUP: 'user',

  // Alias for SYSTEM_ADMIN. Migrate users in Active Directory then remove this.
  LEGACY_ADMIN_GROUP: 'admin'
}

import { ClientError } from '../../errors'

export default (fn, name) => function * (action) {
  try {
    yield * fn(action)
  } catch (error) {
    console.error(error)
    throw new ClientError(error, name)
  }
}

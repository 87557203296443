/* eslint react/prop-types: 0 */

import React from 'react'
import Loadable from 'react-loadable'

const LoadableValidationResult = Loadable({
  loader: () => import('./loadable'),
  loading ({ pastDelay }) {
    return pastDelay ? (
      <div style={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      >
        <span style={{
          color: '#949497',
          fontSize: '1.5rem',
          textAlign: 'center'
        }}
        >Please wait...</span>
      </div>
    ) : null
  }
})

export default LoadableValidationResult

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../Icon'
import Dropzone from 'react-dropzone'

import './DropArea.scss'
import '../../scss/wham.scss'

class DropArea extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    onDrop: PropTypes.func,
    accept: PropTypes.string,
    multiple: PropTypes.string,
    overlay: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      active: false,
      urlUpload: false,
      urlValue: ''
    }
  }

  render () {
    const {
      className,
      onDrop,
      children,
      accept,
      multiple,
      overlay,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-drop-area',
      (overlay ? 'w-drop-area--overlay' : null),
      className
    )

    const childContent = overlay ? children : null

    return (
      <Dropzone
        accept={accept}
        className={componentClass}
        onDrop={onDrop}
        disableClick={overlay}
        style={{}}
        activeStyle={{}}
        acceptStyle={{}}
        rejectStyle={{}}
        disabledStyle={{}}
        activeClassName='w-drop-area--active'
        acceptClassName='w-drop-area--accepted'
        rejectClassName='w-drop-area--rejected'
        disabledClassName='w-drop-area--disabled'
        multiple={multiple}
        {...otherProps}
      >
        {childContent}
        <div className='w-drop-area__message'>
          <Icon className='w-drop-area__icon' iconName='file_upload' />
          <span className='w-drop-area__title'>Drag your files here</span>
          {accept ? <span className='w-drop-area__accepts-types'>Accepts {accept}</span> : null}
        </div>
        <div className='w-drop-area__active-message'>
          <div className='w-drop-area__active-message--accepted'>
            <Icon className='w-drop-area__icon' iconName='check' />
            <span className='w-drop-area__title'>Drop to upload</span>
          </div>
          <div className='w-drop-area__active-message--rejected'>
            <Icon className='w-drop-area__icon' iconName='close' />
            <span className='w-drop-area__title'>This file type isn&rsquo;t allowed</span>
          </div>
        </div>
      </Dropzone>
    )
  }
}

export default DropArea

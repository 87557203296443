/* global __VERSION__ */

import axios from 'axios'
import * as R from 'ramda'

axios.defaults.headers.common.Accept = 'application/json'

const outstandingRequests = []

export const cancelOutstandingRequests = (partnerId) => {
  outstandingRequests.forEach(entry => {
    if (R.not(R.startsWith(`/api/partner/${partnerId}/assets/validate`, entry.config.url))) {
      // entry.cancellationTokenSource.cancel()
    }
  })
}

const removeOutstandingRequest = entry => {
  const index = outstandingRequests.findIndex(R.equals(entry))
  if (index >= 0) outstandingRequests.splice(index, 1)
}

const BODY_METHODS = ['PUT', 'POST', 'PATCH']

const normaliseMethod = method =>
  method.toUpperCase()

const normaliseUrl = url =>
  // Replace multiple slashes with a single slash except after ':'
  url.replace(/([^:])(\/\/+)/g, '$1/')

const versionCheck = response => {
  const apiVersion = R.path(['headers', 'x-gateway-version'], response)
  if (!apiVersion || apiVersion === __VERSION__) {
    return response
  }
  window.location.reload(true)
}

export const performReq = (method = 'GET', url, payload, contentType = 'application/json') => {
  const normalisedMethod = normaliseMethod(method)
  const normalisedUrl = normaliseUrl(url)
  const isBodyMethod = BODY_METHODS.includes(normalisedMethod)
  const isGET = normalisedMethod === 'GET'
  const headers = isBodyMethod ? { 'Content-Type': contentType } : {}
  const cancellationTokenSource = isGET ? axios.CancelToken.source() : null

  const config = {
    method: normalisedMethod,
    url: normalisedUrl,
    headers,
    data: payload
  }

  if (isGET) {
    config.cancelToken = cancellationTokenSource.token
  }

  const promise = axios(config)

  if (isGET) {
    const entry = {
      config,
      promise,
      cancellationTokenSource
    }
    outstandingRequests.push(entry)
    promise
      .then(() => removeOutstandingRequest(entry))
      .catch(() => removeOutstandingRequest(entry))
  }

  return promise
    .then(versionCheck)
    .catch(err => {
      const status = R.path(['response', 'status'], err)
      if (status === 401) {
        window.location.href = '/login'
      }

      throw err
    })
}

export const getResponseData = (response = {}) => response.data

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { parseQs } from '../../../lib/macros/splitter/url/qs'

import KeyValuePairInteractive from './key-value-pair-interactive'

import './style.css'

const MACROS = [
  { text: '[foo]', description: 'Variable substitution' },
  { text: '{timestamp}', description: 'Will insert a timestamp' },
  { text: '{- [foo]}', description: 'Will lowercase the variable' },
  { text: '{+ [foo]}', description: 'Will uppercase the variable' },
  { text: '{enc [foo]}', description: 'Will URL encode the variable' },
  { text: '{dec [foo]}', description: 'Will URL decode the variable' },
  { text: '{abs {hash [foo]}}', description: 'Will create a hash of the variable and make it positive' },
  { text: '{* 1000 [foo]}', description: 'Will multiply the variable by 1000' },
  { text: '{first [foo]}', description: 'Will only return first element of a comma-separated list' },
  { text: '[foo|bar]', description: 'Will use first variable if available otherwise will use second variable' },
  { text: '[foo|bar||baz]', description: 'Will use first variable if available then second variable if available then fallback to fixed value' }
]

const ENCODINGS = [
  { text: '&', value: '%26', description: 'Encoded as %26' },
  { text: '=', value: '%3D', description: 'Encoded as %3D' },
  { text: ',', value: '%2C', description: 'Encoded as %2C' }
]

const KeyValuePairModal = ({
  tuple = {},
  macros = false,
  encodings = false,
  optionals = false,
  onClose,
  onSave
}) => {
  const [key, setKey] = useState({ text: tuple.key || '' })
  const [value, setValue] = useState({ text: tuple.value || '', parts: tuple.values || [] })
  const [optional, setOptional] = useState(tuple.flags?.optional || false)

  const split = macros || encodings

  const parseToValue = text => {
    const qs = key.text + '=' + text
    const { tuples } = parseQs(null, qs)
    return { text: text, parts: tuples[0].values }
  }

  const onChangeKey = e => {
    setKey({ text: e.target.value, error: e.target.value === '' })
  }

  const onChangeValue = e => {
    setKey({ ...key, error: key.text === '' })
    setValue(parseToValue(e.target.value))
  }

  const onDragOverKey = e => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'none'
  }

  const onDragOverValue = e => {
    e.preventDefault()
    e.dataTransfer.dropEffect = split ? e.dataTransfer.types.includes('text/plain') ? 'copy' : 'none' : 'none'
  }

  const onDropKey = e => {
    e.preventDefault()
  }

  const onDropValue = e => {
    e.preventDefault()
    setValue(parseToValue(value.text + e.dataTransfer.getData('text/plain')))
  }

  const onClick = string => {
    setValue(parseToValue(value.text + string))
  }

  return (
    <>
      <div className='key-value-pair-modal__background'>
        <span className='key-value-pair-modal__close w-icon' onClick={() => onClose()}>close</span>
      </div>

      <div className='key-value-pair-modal'>
        <h2 className='key-value-pair-modal__title'>{`${tuple.key ? 'Edit' : 'Create'} your query parameter:`}</h2>

        <div className='key-value-pair-modal__preview-container'>
          <label className='key-value-pair-modal__label key-value-pair-modal__preview-label'>Preview:</label>
          <output className='key-value-pair-modal__preview'>
            {key.error ? null : (
              <>
                {optional ? (
                  <span className='key-value-pair-modal__preview-optional'>*</span>
                ) : null}

                <span className='key-value-pair-modal__preview-key'>{key.text}</span>

                {key.text ? (
                  <span className='key-value-pair-modal__preview-separator'>=</span>
                ) : null}

                {value.parts.map(({ text, type }, i) => (
                  <span
                    key={i}
                    className={classNames('key-value-pair-modal__preview-value', {
                      'key-value-pair-modal__preview-value-macro': type === 'macro'
                    })}
                  >{text}</span>
                ))}

                {optional ? (
                  <span className='key-value-pair-modal__preview-optional'>*</span>
                ) : null}
              </>
            )}
          </output>
        </div>

        <form className='key-value-pair-modal__form' onSubmit={e => {
          e.preventDefault()
          onSave({
            key: key.text,
            value: value.text,
            values: value.parts,
            delimiter: tuple.delimiter,
            flags: optional ? { optional: true} : undefined
          })
        }}>
          <div className='key-value-pair-modal__content'>
            <div className={classNames('key-value-pair-modal__fields', { 'key-value-pair-modal__fields--split': split })}>
              <div className='key-value-pair-modal__group'>
                <label className='key-value-pair-modal__label' htmlFor='key-value-pair-modal__input-key'>Key:</label>
                <input
                  className={classNames('key-value-pair-modal__input key-value-pair-modal__key', { 'key-value-pair-modal__input--error': key.error })}
                  id='key-value-pair-modal__input-key'
                  name='key-value-pair-modal__input-key'
                  data-cy='key-value-pair-modal__input-key'
                  type='text'
                  placeholder='Enter a key'
                  spellCheck='false'
                  autoFocus
                  value={key.text}
                  onChange={onChangeKey}
                  onDragOver={onDragOverKey}
                  onDrop={onDropKey}
                />
                <p className={classNames('key-value-pair-modal__message', { 'key-value-pair-modal__message--error': key.error })}>Key is required</p>
              </div>

              <div className='key-value-pair-modal__group'>
                <label className='key-value-pair-modal__label' htmlFor='key-value-pair-modal__input-value'>Value:</label>
                <input
                  className={classNames('key-value-pair-modal__input key-value-pair-modal__key')}
                  id='key-value-pair-modal__input-value'
                  name='key-value-pair-modal__input-value'
                  data-cy='key-value-pair-modal__input-value'
                  type='text'
                  placeholder='Enter a value'
                  spellCheck='false'
                  value={value.text}
                  onChange={onChangeValue}
                  onDragOver={onDragOverValue}
                  onDrop={onDropValue}
                />
              </div>

              {optionals ? (
                <div className='key-value-pair-modal__group'>
                  <div className=' key-value-pair-modal__group--inline'>
                    <label className='key-value-pair-modal__label' htmlFor='key-value-pair-modal__input-optional'>Optional:</label>
                    <input
                      className='key-value-pair-modal__input key-value-pair-modal__optional'
                      id='key-value-pair-modal__input-optional'
                      name='key-value-pair-modal__input-optional'
                      type='checkbox'
                      checked={optional}
                      onClick={() => {
                        setOptional(!optional)
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>

            {split ? (
              <div className='key-value-pair-modal__interactives'>
                {macros ? (
                  <div className='key-value-pair-modal__interactive'>
                    <KeyValuePairInteractive
                      title='Macros:'
                      options={MACROS}
                      hint='Insert macros by clicking or dragging to the value field'
                      onClick={onClick}
                    />
                  </div>
                ) : null}

                {encodings ? (
                  <div className='key-value-pair-modal__interactive'>
                    <KeyValuePairInteractive
                      title='Encoded Characters:'
                      options={ENCODINGS}
                      hint='Insert character by clicking or dragging to the value field'
                      onClick={onClick}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className='key-value-pair-modal__controls'>
            <button
              className='key-value-pair-modal__submit w-btn--primary w-btn'
              data-cy='key-value-pair-modal__submit'
              type='submit'
              disabled={!key.text || key.error}
            >
              <span>Save Query Parameter</span>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

KeyValuePairModal.propTypes = {
  tuple: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })).isRequired,
    delimiter: PropTypes.string,
    flags: PropTypes.shape({
      optional: PropTypes.bool,
      alternative: PropTypes.bool
    })
  }).isRequired,
  macros: PropTypes.bool,
  encodings: PropTypes.bool,
  optionals: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default KeyValuePairModal

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Overlay.scss'
import '../../scss/wham.scss'

class Overlay extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
  }

  render () {
    const {
      className,
      ...otherProps
    } = this.props
    // classes
    const componentClassName = classNames(
      'w-overlay',
      className
    )

    // render popover element
    return (
      <div {...otherProps} className={componentClassName} />
    )
  }
}

export default Overlay

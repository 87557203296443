import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './BAMTECHMark.scss'
import '../../../scss/wham.scss'

const BAMTECH_MARK_SIZES = ['large', 'small', 'medium']

class BAMTECHMark extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(BAMTECH_MARK_SIZES),
    inverse: PropTypes.bool,
    monochrome: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      inverse = false,
      monochrome,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-mark',
      'w-mark--bamtech',
      (size ? 'w-mark--' + size : null),
      (inverse ? 'w-mark--inverse' : null),
      (!inverse && monochrome ? 'w-mark--monochrome' : null),
      className
    )

    return (
      <span className={componentClass} {...otherProps}>
        <svg viewBox='0 0 98 140' version='1.1' xmlns='http://www.w3.org/2000/svg'>
          <title>BAMTECH</title>
          <path className='w-mark__element' d='M98,95.66 C98.0163159,86.6822767 95.2416493,77.9214591 90.06,70.59 L89.38,69.59 L90.06,68.59 C99.3931333,55.2966746 100.555022,37.9147687 93.0741951,23.4975185 C85.593368,9.08026836 70.7125172,0.0226241006 54.47,7.10542736e-15 L0,7.10542736e-15 L0,139.23 L54.45,139.23 C78.4938133,139.191431 97.9724704,119.703828 98,95.66 L98,95.66 Z M10.64,10.66 L54.43,10.66 C68.507232,10.6618291 81.0244998,19.6170941 85.5715949,32.9397211 C90.11869,46.2623482 85.688594,61.0018534 74.55,69.61 C85.688594,78.2181466 90.11869,92.9576518 85.5715949,106.280279 C81.0244998,119.602906 68.507232,128.558171 54.43,128.56 L10.67,128.56 L10.64,10.66 Z' fill='#ff1744' />
          <polygon className='w-mark__element' points='54.45 30.94 29.31 69.58 40.05 74.44 32.89 108.03 58.04 69.48 47.3 64.61' fill='#ff1744' />
        </svg>
      </span>
    )
  }
};

export default BAMTECHMark

import moment from 'moment-timezone'

import { TZ } from '../../constants'

const units = {
  week: 'isoWeek',
  weeks: 'isoWeeks'
}

const convertDurationToLastEpochs = (current, amount, unit) => {
  const now = current.valueOf()
  const from = current.subtract(amount, unit).valueOf()
  const to = now

  return {
    now,
    from,
    to
  }
}

const convertDurationToThisEpochs = (current, unit) => {
  const $unit = units[unit] || unit

  const now = current.valueOf()
  const from = current.startOf($unit).valueOf()
  const to = current.endOf($unit).valueOf()

  return {
    now,
    from,
    to
  }
}

const convertDurationToPreviousEpochs = (current, amount, unit) => {
  const $unit = units[unit] || unit

  const origin = moment(current).startOf($unit)
  const previousStart = moment(origin).subtract(amount, $unit)
  const previousEnd = moment(previousStart).add(amount, $unit).subtract(1, 'day').endOf('day')

  const now = current.valueOf()
  const from = previousStart.valueOf()
  const to = previousEnd.valueOf()

  return {
    now,
    from,
    to
  }
}

const convertDurationToYesterdayEpochs = current => {
  const now = current.valueOf()

  const yesterday = current.subtract(1, 'day')

  const from = yesterday.startOf('day').valueOf()
  const to = yesterday.endOf('day').valueOf()

  return {
    now,
    from,
    to
  }
}

const convertDurationToTodayEpochs = current => {
  const now = current.valueOf()
  const from = current.startOf('day').valueOf()
  const to = current.endOf('day').valueOf()

  return {
    now,
    from,
    to
  }
}

const convertDurationToEpochs = (duration, current = moment().tz(TZ)) => {
  const last = duration.match(/^last-(\d+)-([a-z]+)$/)

  if (last) {
    return convertDurationToLastEpochs(current, Number(last[1]), last[2])
  }

  const $this = duration.match(/^this-([a-z]+)$/)

  if ($this) {
    return convertDurationToThisEpochs(current, $this[1])
  }

  const previous = duration.match(/^previous-(\d+)-([a-z]+)$/)

  if (previous) {
    return convertDurationToPreviousEpochs(current, Number(previous[1]), previous[2])
  }

  switch (duration) {
    case 'yesterday':
      return convertDurationToYesterdayEpochs(current)
    default:
      return convertDurationToTodayEpochs(current)
  }
}

export {
  convertDurationToEpochs
}

import React, { useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'

import { ViewContext } from '../../../context'

import * as Wham from '../../../wham/components'
import { TransactionDetails } from '../../../views/debug/debug-item'

import './style.css'

const firstSegmentIsSlate = tss => {
  const redirectUrl = R.path([0, 'response', 'redirectUrl'], tss)
  return redirectUrl && redirectUrl.search(/[?&]cid=/) < 0
}

const firstSegmentIsAd = tss => {
  const redirectUrl = R.path([0, 'response', 'redirectUrl'], tss)
  return redirectUrl && redirectUrl.search(/[?&]cid=/) >= 0
}

const SimulatorContentLower = ({
  transactionId,
  transactionPending,
  transactionDetails,
  viewed,
  onRefresh,
  onViewed,
  dispatch
}) => {
  const availableRef = useRef()

  // There seems to be a strange timing situation in Inspector
  // regarding adEDecisions. This API call seems to initially
  // return an empty array. Then, if you repeat the call later,
  // it returns the actual decisions. But, there probably aren't
  // any decisions to return if the single TS was slate. So we don't
  // regard the transaction as fully available until we see some
  // decisions (unless the TS was slate). If we don't do all this,
  // then we end up displaying a transaction with inconsistencies
  // and missing data. See ADENGWEB-1002. I guess this situation
  // only arises for transactions that have only just been created
  // a few moments ago - as is the case for newly simulated transactions.
  availableRef.current =
    transactionDetails &&
    transactionDetails.focusedLog &&
    !transactionDetails.transactionPending &&
    transactionDetails.tsData &&
    transactionDetails.adEDecisions &&
    (
      firstSegmentIsSlate(transactionDetails.tsData) ||
      (firstSegmentIsAd(transactionDetails.tsData) && transactionDetails.adEDecisions.length > 0)
    )

  useEffect(() => {
    if (!transactionDetails) return
    if (availableRef.current && !viewed) {
      onViewed(transactionDetails.transactionId)
    }
  }, [transactionDetails])

  if (!transactionId) return null

  const { partner, claims, features } = useContext(ViewContext)

  return availableRef.current
    ? (
      <TransactionDetails
        transactionDetails={transactionDetails}
        partnerId={partner}
        claims={claims}
        features={features}
        dispatch={dispatch}
      />
    )
    : (
      <div className='simulator__content-lower'>
        {
          transactionPending
            ? <Wham.ProgressCircular active type='indeterminate' sqSize={16} />
            : <div>
              Selected transaction ({transactionId}) is not available yet.
              Click <a onClick={() => onRefresh(transactionId)}>here</a> to refresh.
            </div>
        }
      </div >
    )
}

SimulatorContentLower.propTypes = {
  transactionId: PropTypes.string.isRequired,
  transactionPending: PropTypes.bool.isRequired,
  transactionDetails: PropTypes.object.isRequired,
  viewed: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onViewed: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default SimulatorContentLower

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as Wham from '../../../wham/components'

import { setDebugFormField } from '../../../actions/form'
import { goToDebugResults } from '../../../actions/navigators'

import {
  activePartnerGlobalSelector,
  debugSearchQuerySelector,
  debuggingPendingSelector,
  userFeaturesSelector
} from '../../../selectors'

import SearchBar from '../../../components/search-bar'

import './style.css'

const HeaderDebugControls = ({
  features,
  whitelist,
  searchQuery,
  pending,
  dispatch
}) => {
  const {
    open,
    onOpen,
    onClose
  } = whitelist

  return (
    <div className='header-debug-controls'>
      <SearchBar
        value={searchQuery}
        pending={pending}
        onChange={changedQuery => {
          dispatch(setDebugFormField('searchQuery', changedQuery))
        }}
        onClear={() => {
          dispatch(setDebugFormField('searchQuery', ''))
          dispatch(goToDebugResults({ features }))
        }}
        onSubmit={() => {
          dispatch(goToDebugResults({ features }))
        }}
      />

      <Wham.Button id='toggle-whitelist' className='header-debug-controls__whitelist' onClick={() => {
        !open ? onOpen() : onClose()
      }}>
        {!open ? 'Open' : 'Close'} Allow List
      </Wham.Button>
    </div>
  )
}

HeaderDebugControls.propTypes = {
  features: PropTypes.object.isRequired,
  whitelist: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  }).isRequired,
  searchQuery: PropTypes.string,
  pending: PropTypes.bool,
  dispatch: PropTypes.func
}

const headerDebugControlsStateSelector = (state, ownProps) => {
  const partner = activePartnerGlobalSelector(state)
  const features = userFeaturesSelector(state)

  const searchQuery = debugSearchQuerySelector(partner)(state)
  const pending = debuggingPendingSelector(partner)(state)

  return {
    ...ownProps,
    features,
    searchQuery,
    pending
  }
}

export default connect(headerDebugControlsStateSelector)(HeaderDebugControls)

import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { isWebUri } from 'valid-url'

import * as Wham from '../../../wham/components'

import { validateAsset } from '../../../actions/assets'
import { editFieldAction } from '../../../actions/form'

import './style.css'

const MezzForm = ({
  inputUri,
  hasUris,
  dispatch
}) => {
  const uris = inputUri.split(/[\n\r]/)

  const valid = uris
    .filter(uri => {
      return uri.length
    })
    .reduce((result, uri) => {
      return result && isWebUri(uri)
    }, true)

  const onSubmit = e => {
    e.preventDefault()

    if (valid && uris.length) {
      uris.forEach((uri, i) => {
        if (uri.length) {
          setTimeout(() => {
            dispatch(validateAsset({ uri }))
          }, 500 * i) // Avoid race-conditions with PDF generation
        }
      })

      dispatch(editFieldAction('assetValidator', 'uri', ''))
    }
  }

  return (
    <div className={classNames('mezz-form', {'mezz-form--anchored': hasUris})}>
      {hasUris ? null : (
        <div>
          <Wham.Heading className='mezz-form__header' level={3}>Drag your media file here</Wham.Heading>
          <span className='mezz-form__accepts'>Accepts file types: mp4, mov, m3u8</span>
          <span className='mezz-form__or'>or</span>
        </div>
      )}

      <form onSubmit={onSubmit}>
        <div className='mezz-form__field'>
          <div className='mezz-form__field-wrapper'>
            <textarea
              className={classNames('mezz-form__input', {'mezz-form__input--error': !valid})}
              placeholder='Type a mezz file URL (or paste multiple) and press enter'
              value={inputUri}
              rows={uris.length}
              wrap={uris.length > 1 ? 'off' : 'soft'}
              onChange={e => {
                dispatch(editFieldAction('assetValidator', 'uri', e.target.value))
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onSubmit(e)
                }
              }}
            />
          </div>

          <div className='mezz-form__field-message'>
            <p className='mezz-form__field-text mezz-form__field-text--error'>{valid ? '' : 'Contains an invalid URL'}</p>
          </div>
        </div>
      </form>
    </div>
  )
}

MezzForm.propTypes = {
  inputUri: PropTypes.string,
  hasUris: PropTypes.bool,
  dispatch: PropTypes.func
}

export default MezzForm

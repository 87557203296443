import * as R from 'ramda'

import * as selectors from '../../selectors'

import { hasRole } from '../../components/authorized'

import * as F from '../../common/features'
import * as P from '../../common/permissions'

import { SESSION } from '../../constants/debug/groups'

import { goToStandardRule, goToGlobalRule } from './local-actions'

const getRuleSummaryDataItem = (partnerId, claims, ruleId, ruleName) => {
  if (ruleId === undefined) {
    return undefined
  }

  const key = 'Rule'

  if (ruleId === -1) {
    const value = ruleName
    return { key, value }
  }

  if (ruleId === 0) {
    const canReadGlobalRule = hasRole(partnerId, F.GLOBAL_RULE, P.READ, claims)
    const value = ruleName
    const goToAction = goToGlobalRule(partnerId)
    return canReadGlobalRule
      ? { key, value, goToAction }
      : { key, value }
  }

  const canReadStandardRules = hasRole(partnerId, F.STANDARD_RULES, P.READ, claims)
  const value = ruleName || String(ruleId)
  const goToAction = goToStandardRule(partnerId, ruleId)

  return canReadStandardRules
    ? { key, value, goToAction }
    : { key, value }
}

// Ideally, keep in sync with event-names/espn/s3.js.
// This map controls the labels to be used and the order of the items.
const namesToLabels = new Map([
  ['eventName', 'Event Name'],
  ['programName', 'Program Name'],
  ['tournament', 'Tournament'],
  ['league', 'League'],
  ['sport', 'Sport'],
  ['site', 'Site'],
  ['homeTeam', 'Home Team'],
  ['awayTeam', 'Away Team'],
  ['language', 'Language'],
  ['commercialReplacement', 'Commercial Replacement'],
  ['ratingsId', 'Ratings ID'],
  ['partnerAiringId', 'Partner Airing ID'],
  ['simulcastAiringId', 'Simulcast Airing ID']
])

const getMetadataNameValues = focusedLog => {
  const namesToLabelsKeys = Array.from(namesToLabels.keys())
  const eventName = focusedLog?.eventName
  const maybeEventName = eventName ? [{ name: 'eventName', value: eventName }] : []
  const assetNameValues = R.propOr([], ['assetNameValues'], focusedLog).concat(maybeEventName)
  return R.compose(
    R.map(({ name, value }) => {
      const label = namesToLabels.get(name) || name
      return { name: label, value }
    }),
    R.sort((item1, item2) => {
      const index1 = namesToLabelsKeys.findIndex(key => key === item1.name)
      const index2 = namesToLabelsKeys.findIndex(key => key === item2.name)
      const order1 = index1 >= 0 ? index1 : 999
      const order2 = index2 >= 0 ? index2 : 999
      return order1 - order2
    })
  )(assetNameValues)
}

export const getSummaryData = (partnerId, claims, focusedLog, ruleId, ruleName) => {
  const ruleItem = getRuleSummaryDataItem(partnerId, claims, ruleId, ruleName)
  const maybeRuleItem = ruleItem ? [ruleItem] : []

  const swid = R.path(['swid'], focusedLog)
  const maybeSwidItem = swid
    ? [{ key: 'SWID', value: swid }]
    : []

  const maybeMetadataItem = focusedLog?.assetNameValues?.length ?? 0
    ? [{ key: 'Metadata', value: getMetadataNameValues(focusedLog) }]
    : []

  return [
    { key: 'POD ID', value: R.path(['podId'], focusedLog) },
    { key: 'Event Name', value: R.pathOr('No Event Information Found', ['eventName'], focusedLog) },
    ...maybeMetadataItem,
    { key: 'Asset ID', value: R.path(['assetId'], focusedLog) },
    { key: 'Device ID', value: R.path(['deviceId'], focusedLog) },
    { key: 'Platform', value: R.path(['platform'], focusedLog) },
    { key: 'UID', value: R.path(['userId'], focusedLog) },
    ...maybeSwidItem,
    { key: 'Session ID', value: R.path(['ssess'], focusedLog) },
    { key: 'Transaction ID', value: R.path(['transactionId'], focusedLog) },
    ...maybeRuleItem
  ]
}

const getCurrPrevNext = (logEntries, transactionId) => {
  const index = logEntries.findIndex(logEntry => logEntry.transactionId === transactionId)

  return {
    current: logEntries[index],
    next: logEntries[index + 1],
    prev: logEntries[index - 1]
  }
}

export const getGamNetworkId = adsRequestUrl => {
  try {
    const url = new URL(adsRequestUrl)

    // https://support.google.com/admanager/answer/7320899?hl=en#iu
    //
    //   Parameter    Description                           Example value
    //
    //   iu           Current ad unit. Follow the format:   iu=/6062/video/example_unit
    //                /network_id/directory/ad_unit
    if (url.searchParams.has('iu')) {
      const iu = url.searchParams.get('iu')
      const gamNetworkIdMatch = /^\/?(\d+)\//.exec(iu)
      return R.path([1], gamNetworkIdMatch)
    }
  } catch {
    // Ignore
  }
}

const orderSummaries = (logEntries, groupBy) => {
  if (groupBy === SESSION) {
    return R.compose(
      R.flatten,
      R.values,
      R.groupBy(logEntry => logEntry.ssess)
    )(logEntries)
  }

  return logEntries
}

export const getTransactionDetails = (state, partner, transactionId) => {
  const groupBy = selectors.debuggingGroupBySelector(partner)(state)
  const logEntries = selectors.debuggingLogEntriesGlobalSelector(partner)(state)
  const orderedSummaries = orderSummaries(logEntries, groupBy)
  const currPrevNext = getCurrPrevNext(orderedSummaries, transactionId)

  return {
    transactionId,
    focusedLog: currPrevNext.current,
    prevTransactionId: currPrevNext.prev?.transactionId,
    nextTransactionId: currPrevNext.next?.transactionId,

    selectedAds: [],
    skippedAds: [],
    viewedAds: [],
    newTs: [],
    transactionPending: false,
    summaryPending: false,
    flagChangePending: false,
    adEDecisions: [],
    adsRawResponseXML: '',
    adsRequestHeaders: [],
    adsRequestCookies: [],
    adsRequestUrl: '',
    gamNetworkId: undefined,
    tsData: [],
    sgmntToAdNumber: [],
    firstSgmntNumber: 0,
    summaryData: []
  }
}

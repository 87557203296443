import React from 'react'
import ContentLoader from 'react-content-loader'

// http://danilowoz.com/create-content-loader/

const RULE_CARD_HEIGHT = 86
const RULE_CARD_WIDTH = 644

export const RulesLoader = props => (
  <div style={{ width: `${RULE_CARD_WIDTH}px` }}>
    <ContentLoader
      height={RULE_CARD_HEIGHT}
      width={RULE_CARD_WIDTH}
      speed={2}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='4' ry='4' width={RULE_CARD_WIDTH} height={RULE_CARD_HEIGHT} />
    </ContentLoader>
    <ContentLoader
      height={RULE_CARD_HEIGHT}
      width={RULE_CARD_WIDTH}
      speed={2}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='4' ry='4' width={RULE_CARD_WIDTH} height={RULE_CARD_HEIGHT} />
    </ContentLoader>
    <ContentLoader
      height={RULE_CARD_HEIGHT}
      width={RULE_CARD_WIDTH}
      speed={2}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='4' ry='4' width={RULE_CARD_WIDTH} height={RULE_CARD_HEIGHT} />
    </ContentLoader>
    <ContentLoader
      height={RULE_CARD_HEIGHT}
      width={RULE_CARD_WIDTH}
      speed={2}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='4' ry='4' width={RULE_CARD_WIDTH} height={RULE_CARD_HEIGHT} />
    </ContentLoader>
    <ContentLoader
      height={RULE_CARD_HEIGHT}
      width={RULE_CARD_WIDTH}
      speed={2}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='4' ry='4' width={RULE_CARD_WIDTH} height={RULE_CARD_HEIGHT} />
    </ContentLoader>
    <ContentLoader
      height={RULE_CARD_HEIGHT}
      width={RULE_CARD_WIDTH}
      speed={2}
      primaryColor='#f3f3f3'
      secondaryColor='#ecebeb'
      {...props}
    >
      <rect x='0' y='0' rx='4' ry='4' width={RULE_CARD_WIDTH} height={RULE_CARD_HEIGHT} />
    </ContentLoader>
  </div>
)

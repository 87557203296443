import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import './style.css'

const messages = {
  adding: 'Problem adding entry - please try again.',
  conflict: 'Whitelist entry already exists.',
  default: 'Problem encountered - please try again.',
  deleting: 'Problem deleting entry - please try again.'
}

const WhitelistError = ({
  type,
  onClose
}) => (
  <div className='whitelist-error'>
    <div className='whitelist-error__background' />
    <Wham.Message
      active
      mode='floating'
      type='danger'
      dismissCallback={() => onClose()}
    >
      <div className='whitelist-error__message' data-cy='whitelist-error'>
        <p className='whitelist-error__text'>{messages[type] || messages.default}</p>
      </div>
    </Wham.Message>
  </div>
)

WhitelistError.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default WhitelistError

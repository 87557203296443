import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import sha1 from 'sha1'

import HeaderUserMenu from '../../../containers/header/header-user-menu'
import HeaderReleaseNotes from '../header-release-notes'

import * as Wham from '../../../wham/components'

import notes from '../../../release-notes/index.txt'

import { userAutoEnrollmentAlertSelector } from '../../../selectors'
import AutoEnrollmentDialog from './auto-enrollment-dialog'

import './style.css'

// Keys for items in local storage
const RELEASE_NOTES_KEY = 'releaseNotesVersion'
const AUTO_ENROLLMENT_ALERT_ACKNOWLEDGED_KEY = 'autoEnrollmentAlertAcknowledged'

const HeaderUser = ({ user, autoEnrollmentAlert, dispatch }) => {
  const [storedHash, setStoredHash] =
    useState(() => localStorage.getItem(RELEASE_NOTES_KEY)) // synchronous
  const [storedAutoEnrollmentAlertAcknowledged, setStoredAutoEnrollmentAlertAcknowledged] =
    useState(() => Boolean(localStorage.getItem(AUTO_ENROLLMENT_ALERT_ACKNOWLEDGED_KEY))) // synchronous
  const [showAutoEnrollmentDialog, setShowAutoEnrollmentDialog] = useState(false)

  const onAutoEnrollmentAlert = () => {
    setShowAutoEnrollmentDialog(true)
  }

  const onCloseAutoEnrollmentDialog = () => {
    setShowAutoEnrollmentDialog(false)
  }

  const onAcknowledgeAutoEnrollmentAlert = () => {
    setStoredAutoEnrollmentAlertAcknowledged(true)
    setShowAutoEnrollmentDialog(false)
  }

  useEffect(() => {
    if (storedHash && storedHash !== localStorage.getItem(RELEASE_NOTES_KEY)) { // synchronous
      localStorage.setItem(RELEASE_NOTES_KEY, storedHash) // synchronous
    }
  }, [storedHash])

  useEffect(() => {
    if (storedAutoEnrollmentAlertAcknowledged) {
      localStorage.setItem(AUTO_ENROLLMENT_ALERT_ACKNOWLEDGED_KEY, storedAutoEnrollmentAlertAcknowledged) // synchronous
    }
  }, [storedAutoEnrollmentAlertAcknowledged])

  const currentHash = sha1(notes)
  const overallAutoEnrollmentAlert = autoEnrollmentAlert && !storedAutoEnrollmentAlertAcknowledged

  return (
    <>
      <div className='header-user'>
        <div className='header-user__wrap'>
          {storedHash !== currentHash ? (
            <div className='header-user__notes'>
              <HeaderReleaseNotes
                notes={notes}
                onClick={(/* active */) => { }}
                onAcknowledge={() => {
                  setStoredHash(currentHash)
                }}
              />
            </div>
          ) : null}

          {overallAutoEnrollmentAlert ? (
            <span className='w-whats-new'>
              <Wham.Icon
                className='w-whats-new__icon header-user__auto-enrollment-alert'
                iconName='new_releases'
                onClick={onAutoEnrollmentAlert}
              />
            </span>
          ) : null}

          <div className='header-user__user'>
            <HeaderUserMenu />
          </div>
        </div>
      </div>

      <AutoEnrollmentDialog
        showDialog={showAutoEnrollmentDialog}
        onClose={onCloseAutoEnrollmentDialog}
        onAcknowledge={onAcknowledgeAutoEnrollmentAlert}
        email={user.email}
        dispatch={dispatch}
      />
    </>
  )
}

HeaderUser.propTypes = {
  user: PropTypes.object,
  autoEnrollmentAlert: PropTypes.bool,
  dispatch: PropTypes.func
}

const mapStateToProps = state => {
  const user = state.user
  const autoEnrollmentAlert = userAutoEnrollmentAlertSelector(state)

  return {
    user,
    autoEnrollmentAlert
  }
}

export default connect(mapStateToProps)(HeaderUser)

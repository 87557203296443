import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import { icons } from '../../../constants/whitelist'

import './style.css'

const WhitelistSidebarItem = ({
  item,
  onClick
}) => (
  <li className='whitelist-sidebar-item'>
    <p className='whitelist-sidebar-item__description'>
      {item.idType ? (
        <Wham.Icon iconName={icons[item.idType]} />
      ) : null}
      {item.label && `${item.label} `}
      {item.label ? `(${item.value})` : item.value}
    </p>
    <Wham.Icon className='whitelist-sidebar-item__icon--clickable' iconName='search' onClick={() => onClick()} />
  </li>
)

WhitelistSidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}

export default WhitelistSidebarItem

import * as R from 'ramda'

import assetValidatorReducer from '../asset-validator'
import creativesReducer from '../creatives'
import debugReducer from '../debugging'
import formsReducer from '../form'
import rulesReducer from '../ruleset'
import viewRulesReducer from '../ruleset/view-rules'
import simulatorReducer from '../simulator'
import whitelistReducer from '../whitelist'

import { reduxPeristenceFactory } from '../../lib/reduxPersistence'

import { LOGIN } from '../../actions/login'
import { SWITCH_PARTNER_CONTEXT, SET_ACTIVE_PARTNER } from '../../actions/partnerContext'

const resetActiveFlags = R.map(partner => ({ ...partner, active: false }))

const partnerReducer = (state = {}, action) => {
  const partnerPersistence = reduxPeristenceFactory('PartnerContext')

  let activePartnerId = Object.keys(state).find(key => R.path([key, 'active'], state))

  if (action.type === LOGIN.success) {
    const { partners } = action.payload
    const pairs = partners.map((partner, index) => [partner.id, { active: index === 0 }])
    return R.fromPairs(pairs)
  }

  if (action.type === SWITCH_PARTNER_CONTEXT) {
    const partnerContextPersistence = partnerPersistence.get()

    if (activePartnerId && activePartnerId !== action.payload) {
      partnerPersistence.set({
        ...partnerContextPersistence,
        [activePartnerId]: { location: window.location.pathname }
      })
    }

    activePartnerId = action.payload
  }

  if (action.type === SET_ACTIVE_PARTNER) {
    activePartnerId = action.payload
  }

  if (!state[activePartnerId]) {
    return resetActiveFlags(state)
  }

  const {
    creatives,
    debugging,
    ruleset,
    viewRules,
    assetValidator,
    forms,
    simulator,
    whitelist
  } = state[activePartnerId]

  return {
    ...resetActiveFlags(state),
    [activePartnerId]: {
      active: true,
      swid: { enabled: activePartnerId === 'espn' },
      forms: formsReducer(forms, action),
      debugging: debugReducer(debugging, action),
      ruleset: rulesReducer(ruleset, action),
      viewRules: viewRulesReducer(viewRules, action),
      assetValidator: assetValidatorReducer(assetValidator, action),
      creatives: creativesReducer(creatives, action),
      simulator: simulatorReducer(simulator, action),
      whitelist: whitelistReducer(whitelist, action)
    }
  }
}

export default partnerReducer

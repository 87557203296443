import { useEffect, useRef, useState } from 'react'

export const useDocumentTitle = title =>
  useEffect(() => { document.title = `adEngine - ${title}` }, [])

export const useCopied = (ms = 2000) => {
  const timeout = useRef()

  const [copied, setCopied] = useState([])

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  const onCopy = (...items) => {
    clearTimeout(timeout.current)

    setCopied(items)

    timeout.current = setTimeout(() => {
      setCopied([])
    }, ms)
  }

  const isCopied = (...items) => (
    items.length === copied.length &&
    items.every((item, index) => item === copied[index])
  )

  return [onCopy, isCopied, copied]
}

import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import MacroSplitter, { HeadPart, TextParts } from '../../macro-splitter'
import KeyValuePairTable from '../../key-value-pair/key-value-pair-table'

import { isValidUrl } from '../../../lib/util/urls'

const MacroModal = ({
  macro,
  controls,
  onChangeHeader,
  onChangeUrl,
  onDelete,
  onInsert,
  onUpdate
}) => {
  const {
    header,
    url,
    base,
    textParts,
    tuples
  } = macro

  const {
    expanded,
    onClickHeader,
    onClickField
  } = controls

  const timeout = useRef()

  const [valid, setValid] = useState(url.length === 0 || isValidUrl(url))

  useEffect(() => {
    clearTimeout(timeout.current)

    timeout.current = setTimeout(() => {
      setValid(url.length === 0 || isValidUrl(url))
    }, 500)
  }, [url])

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  return (
    <Wham.Layout className='macro-modal'>
      <Wham.LayoutRow>
        <KeyValuePairTable
          base={base}
          tuples={tuples}
          onDelete={onDelete}
          onInsert={onInsert}
          onUpdate={onUpdate}
        />

        <Wham.LayoutColumn>
          <Wham.Tabs className='macro-modal__tabs' title='Ad URL' size='small'>
            <Wham.Tab label='Edit'>
              <MacroSplitter expanded={expanded} onClickHeader={onClickHeader} onClickField={onClickField}>
                <div>
                  <Wham.FormInput className='edit-macro__url macro-modal__input-head' field='text' size='medium' spellCheck='false' autoHeight multiline value={header} onChange={e => onChangeHeader(e)} />
                </div>

                <div className='edit-macro'>
                  <Wham.FormInput className={`edit-macro__url macro-modal__input-url ${!valid ? 'edit-macro__url--invalid' : ''}`} data-cy='request-url' field='text' size='medium' spellCheck='false' autoFocus autoHeight multiline value={url} onChange={e => onChangeUrl(e)} />
                  <span className={`edit-macro__warn ${!valid ? 'edit-macro__warn--show' : 'edit-macro__warn--hide'}`} data-cy='invalid-url-warn'>Invalid URL</span>
                </div>
              </MacroSplitter>
            </Wham.Tab>

            <Wham.Tab label='View'>
              <div className='uri-field'>
                <div className='ad-field'>
                  {url ? (
                    <MacroSplitter expanded={expanded} onClickHeader={onClickHeader} onClickField={onClickField}>
                      <HeadPart headPart={header} />
                      <TextParts textParts={textParts} />
                    </MacroSplitter>
                  ) : (
                    'Enter a URL here'
                  )}
                </div>
              </div>
            </Wham.Tab>
          </Wham.Tabs>
        </Wham.LayoutColumn>
      </Wham.LayoutRow>
    </Wham.Layout>
  )
}

MacroModal.propTypes = {
  macro: PropTypes.shape({
    header: PropTypes.string,
    url: PropTypes.string,
    base: PropTypes.string,
    textParts: PropTypes.array,
    tuples: PropTypes.array
  }).isRequired,
  controls: PropTypes.shape({
    expanded: PropTypes.bool.isRequired,
    onClickHeader: PropTypes.func.isRequired,
    onClickField: PropTypes.func.isRequired
  }).isRequired,
  onChangeHeader: PropTypes.func.isRequired,
  onChangeUrl: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default MacroModal

import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

const NoData = ({ dataType = 'data' }) => (
  <div style={{
    padding: 30,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'var(--w-color-gray)'
  }}
  >
    <Wham.Icon iconName='sentiment_dissatisfied' />
    <span style={{ marginLeft: 10 }}>No {dataType}</span>
  </div>
)

NoData.propTypes = {
  dataType: PropTypes.string
}

export default NoData

export const SET_FIELD = 'SET_FIELD'
export const SET_MODERN = 'SET_MODERN'

// Deprecated
export const editFieldAction = (formName, fieldName, value) => {
  return {
    type: SET_FIELD,
    payload: {
      formName,
      fieldName,
      value
    }
  }
}

// Deprecated
export const editField = (dispatch, formName) => fieldName => (event, value) => {
  event.preventDefault && event.preventDefault()
  const normalizedValue = (typeof value !== 'undefined')
    ? value.value || value
    : event.target.value
  dispatch(editFieldAction(formName, fieldName, normalizedValue))
}

const setModernField = (formName) => {
  return (fieldName, fieldValue) => {
    return {
      type: SET_MODERN,
      payload: {
        formName,
        fieldName,
        fieldValue
      }
    }
  }
}

export const setCreativesFormField = setModernField('creatives')
export const setDebugFormField = setModernField('debug')
export const setReportingFormField = setModernField('reporting')
export const setWhitelistFormField = setModernField('whitelist')

import * as R from 'ramda'
import {
  SORT_RULE_LIST,
  SET_RULE_FILTER
} from '../../actions/rules'
import makeHandlerSelector from '../../lib/function-table-picker'

export const ALL = 'ALL'
export const ACTIVE = 'ACTIVE'
export const INACTIVE = 'INACTIVE'

export const PRIORITY = 'priority'
export const DATE = 'date'
export const NAME = 'name'

const defaultState = {
  selectedFilter: ALL,
  sortedBy: PRIORITY,
  showSort: false,
  loader: false
}

export const setRuleFilter = (state, { payload }) => {
  return R.evolve({
    selectedFilter: () => R.prop('selectedFilter', payload)
  }, state)
}

export const sortRuleList = (state, { payload }) => {
  return R.evolve({
    sortedBy: () => R.prop('sortedBy', payload)
  }, state)
}

const handlers = {
  [SET_RULE_FILTER]: setRuleFilter,
  [SORT_RULE_LIST]: sortRuleList
}
const handlerSelector = makeHandlerSelector(handlers)

export default (state = defaultState, { type: message, ...payload }) => {
  const handler = handlerSelector(message)
  return handler(state, payload)
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'

import * as Wham from '../../../wham/components'

import { CREATE_RULE_STRING } from '../../../route-paths'
import { useDocumentTitle } from '../../../lib/custom-hooks'

import {
  clearCreateRule,
  sortRuleList,
  setRuleFilter,
  setRuleOrder,
  saveRule
} from '../../../actions/rules'

import {
  activePartnerGlobalSelector,
  partnerGlobalRuleSelector,
  pendingRuleSelector,
  ruleErrorSelector,
  ruleGlobalSelector,
  userRolesSelector
} from '../../../selectors'

import Header from '../../../containers/header'

import DocLink from '../../../containers/doc-link'

import ConfirmationDialog from '../../../components/confirmation-dialog'

import { goToCreate } from './local-actions'
import { RulesLoader } from './content-loaders'
import { RuleFilters } from './rule-filters'
import { GlobalRule } from './global-rule'

import {
  PRIORITY,
  DATE,
  NAME,
  ACTIVE,
  INACTIVE,
  ALL
} from '../../../reducers/ruleset/view-rules'

import RuleCard from './rule-card'

import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import * as G from '../../../common/groups'

import './style.css'

const DragHandle = SortableHandle(({ hidden }) => {
  const maybeStyle = hidden ? { style: { visibility: 'hidden' } } : undefined
  return (
    <span className='w-icon' {...maybeStyle}>drag_indicator</span>
  )
})

const SortableRuleCard = SortableElement(({ enableDragging, ...otherProps }) => {
  return (
    <div className='rule-card-wrapper' data-cy='rule-card-wrapper'>
      <RuleCard {...otherProps} />
      <DragHandle hidden={!enableDragging} />
    </div>
  )
})

const SortableRuleCardContainer = SortableContainer(({ children }) =>
  <div>{children}</div>
)

export const ViewRules = ({
  sortedBy,
  selectedFilter,
  pending,
  dispatch,
  partnerId,
  rules,
  globalRule,
  error,
  canReorderRules
}) => {
  useDocumentTitle('Rules')

  const getCurrentFilterFunction = () => {
    switch (selectedFilter) {
      case ACTIVE: return rule => rule.active
      case INACTIVE: return rule => !rule.active
      case ALL:
      default: return R.T
    }
  }

  const getCurrentSortFunction = () => {
    switch (sortedBy) {
      case DATE:
        return R.descend(R.prop('updatedAt'))
      case NAME:
        return R.ascend(R.compose(R.toLower, R.prop('name')))
      case PRIORITY:
      default:
        return R.ascend(R.prop('displayPriority'))
    }
  }

  const refineRules = rules =>
    rules
      .filter(getCurrentFilterFunction())
      .sort(getCurrentSortFunction())

  const [showSortMenu, setShowSortMenu] = useState(false)
  const [refinedRules, setRefinedRules] = useState(() => refineRules(rules))
  const [reorderingData, setReorderingData] = useState({
    showConfirmationDialog: false,
    rule: undefined
  })

  useEffect(() => {
    setRefinedRules(refineRules(rules))
  }, [rules, selectedFilter, sortedBy])

  const dismissSortMenu = (e) => {
    if (R.includes('w-icon', e.target.classList)) return
    setShowSortMenu(false)
  }

  const toggleShowSortMenu = () => {
    setShowSortMenu(currentValue => !currentValue)
  }

  const changeSortedBy = newValue => {
    dispatch(sortRuleList({ sortedBy: newValue }))
  }

  const changeSelectedFilter = newValue => {
    dispatch(setRuleFilter({ selectedFilter: newValue }))
  }

  const onCreateNewRule = e => {
    e.preventDefault()
    dispatch(goToCreate(partnerId))
    dispatch(clearCreateRule())
  }

  const makeRuleCard = (rule, index) => (
    <SortableRuleCard
      key={rule.id}
      index={index}
      {...rule}
      enableDragging={canReorderRules && selectedFilter === ALL && sortedBy === PRIORITY}
      partnerId={partnerId}
      dispatch={dispatch}
    />
  )

  const onConfirmReordering = () => {
    const { rule } = reorderingData
    // Save the pending new ordering
    dispatch(setRuleOrder(refinedRules))
    // Update the rule that moved
    dispatch(saveRule({ rule, priorityChanged: true, editRuleAfterSave: false }))
    setReorderingData({ showConfirmationDialog: false, rule: undefined })
  }

  const onCancelReordering = () => {
    // Revert the pending new ordering
    setRefinedRules(refineRules(rules))
    setReorderingData({ showConfirmationDialog: false, rule: undefined })
  }

  return (
    <Wham.Layout
      onClick={dismissSortMenu}
      fullWidth
      fullHeight
      colSpacing='none'
      style={{ margin: '0' }}
    >
      <Wham.LayoutRow className='rule-header-row'>
        <Header
          middle={
            <div className='rule-header-row-middle'>
              <Wham.AppBar2Center heading='View Rules' />
              <Wham.Button
                className='w-tooltip-trigger'
                data-tooltip='Create a rule'
                type='subtle'
                id='addRule'
                data-cy='addRule'
                onClick={onCreateNewRule}
              >
                <Wham.Icon iconName='add' />
              </Wham.Button>
            </div>
          }
        />
      </Wham.LayoutRow>

      <Wham.LayoutRow>
        {error ? <div className='error-message'>{error}</div> : null}
      </Wham.LayoutRow>

      <div className='rule-list'>
        <RuleFilters
          selectedFilter={selectedFilter}
          changeSelectedFilter={changeSelectedFilter}
        />

        <div>
          <GlobalRule
            partnerId={partnerId}
            dispatch={dispatch}
            globalRule={globalRule}
          />

          {pending ? (
            <RulesLoader />
          ) : (
              R.length(refinedRules) ? (
                <section data-cy='standard-rules'>
                  <header>Standard rules</header>
                  <SortableRuleCardContainer
                    useDragHandle
                    lockToContainerEdges
                    lockAxis='y'
                    helperClass='rule-card-wrapper--dragging'
                    helperContainer={() => document.querySelector('.rule-list')}
                    onSortEnd={({ oldIndex, newIndex }) => {
                      if (oldIndex !== newIndex) {
                        const rule = refinedRules[oldIndex]
                        const reorderedRules = arrayMove(refinedRules, oldIndex, newIndex)
                        // Update local state with the pending new ordering
                        setRefinedRules(reorderedRules)
                        setReorderingData({ showConfirmationDialog: true, rule })
                      }
                    }}
                  >
                    {refinedRules.map(makeRuleCard)}
                  </SortableRuleCardContainer>
                </section>
              ) : (
                  <section className='no-rules-message'>
                    <header>Your team hasn&apos;t made any standard rules yet</header>
                    <Wham.Button
                      onClick={onCreateNewRule}
                      type='primary'
                    >
                      Create a rule
                  </Wham.Button>
                  </section>
                )
            )}
        </div>

        <div className='sort-button'>
          <Wham.Icon onClick={toggleShowSortMenu} iconName='sort' />
          {showSortMenu ? (
            <Wham.Menu size='small'>
              {R.map(method => (
                <Wham.MenuItem
                  key={method}
                  onClick={() => changeSortedBy(method)}
                  label={`Sort by ${method}`}
                  selected={sortedBy === method}
                />
              ), [PRIORITY, DATE, NAME])}
            </Wham.Menu>
          ) : null}
        </div>
      </div>

      <DocLink topic="rules" />

      <ConfirmationDialog
        showDialog={reorderingData.showConfirmationDialog}
        title='Please confirm this action'
        message='Are you sure you want to make this change to rule priority ?'
        onConfirm={onConfirmReordering}
        onCancel={onCancelReordering}
      />
    </Wham.Layout>
  )
}

ViewRules.propTypes = {
  sortedBy: PropTypes.string,
  selectedFilter: PropTypes.string,
  pending: PropTypes.bool,
  dispatch: PropTypes.func,
  partnerId: PropTypes.string,
  rules: PropTypes.array,
  globalRule: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  canReorderRules: PropTypes.bool.isRequired
}

const mapStateToProps = state => {
  const partnerId = activePartnerGlobalSelector(state)
  const rules = ruleGlobalSelector(partnerId)(state)
    .filter(({ id }) => id !== CREATE_RULE_STRING)
  const globalRule = partnerGlobalRuleSelector(partnerId)(state)
  const pending = pendingRuleSelector(partnerId)(state)
  const selectedFilter = R.path(['partner', partnerId, 'viewRules', 'selectedFilter'], state)
  const sortedBy = R.path(['partner', partnerId, 'viewRules', 'sortedBy'], state)
  const roles = userRolesSelector(state)
  const allowedRoles = [G.SYSTEM_ADMIN, G.PARTNER_ADMIN, G.LEGACY_ADMIN_GROUP, G.UAT]
  const canReorderRules = R.intersection(roles, allowedRoles).length > 0
  const error = ruleErrorSelector(partnerId)(state)

  return {
    sortedBy,
    selectedFilter,
    pending,
    rules,
    globalRule,
    partnerId,
    error,
    canReorderRules
  }
}

export default connect(mapStateToProps)(ViewRules)

import { createAsyncActionType } from '../async'
import { singleParamActionCreator } from '../../lib/singleParamActionCreator'

export const SELECT_VALIDATED = 'SELECT_VALIDATED'
export const CLEAR_VALIDATED = 'CLEAR_VALIDATED'
export const REMOVE_VALIDATED = 'REMOVE_VALIDATED'

export const VALIDATE_ASSET = createAsyncActionType('VALIDATE_ASSET')

export const validateAsset = singleParamActionCreator(VALIDATE_ASSET)
export const selectResult = singleParamActionCreator(SELECT_VALIDATED)
export const removeResult = singleParamActionCreator(REMOVE_VALIDATED)
export const clearResults = singleParamActionCreator(CLEAR_VALIDATED)

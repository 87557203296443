import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  resetFacet,
  resetFacets,
  updateFacetOption,
  updateOpenFilters
} from '../../actions/debug'
import { goToDebugResults } from '../../actions/navigators'
import { Facets, Groups } from '../../constants/debug'
import * as selectors from '../../selectors'

import DebugFacet from '../../components/debug-filters/debug-facet'
import DebugFilterBySession from '../../components/debug-filters/debug-filter-by-session'
import DebugFilterControls from '../../components/debug-filters/debug-filter-controls'

import {
  asBreakDurationLabel,
  asAssetLabel,
  asSourceLabel,
  asFillPercentLabel,
  asRuleLabel,
  asBookmarksLabel,
  asStatusLabel
} from './util'

import './style.css'

const asLabelMap = {
  duration: asBreakDurationLabel,
  asset: asAssetLabel,
  source: asSourceLabel,
  fillPercent: asFillPercentLabel,
  rule: asRuleLabel,
  bookmarks: asBookmarksLabel,
  status: asStatusLabel
}

const DebugFilters = ({
  features,
  count,
  total,
  openFilters,
  groupBy,
  facets,
  pending,
  dispatch
}) => {
  const selected = facets.some(facet => facet.options.some(option => option.selected))
  const filtered = selected || groupBy !== 'default'

  return facets.length ? (
    <div className='debug-filters'>
      <DebugFilterControls
        count={count}
        total={total}
        filtered={filtered}
        pending={pending}
        onRefresh={() => {
          dispatch(goToDebugResults({ features }))
        }}
        onReset={() => {
          dispatch(resetFacets())
          dispatch(goToDebugResults({ features }))
        }}
      />

      <DebugFilterBySession
        opened={openFilters.has('sessions')}
        groupBy={groupBy}
        pending={pending}
        onClick={() => {
          dispatch(goToDebugResults({
            groupBy: groupBy === Groups.DEFAULT ? Groups.SESSION : Groups.DEFAULT,
            features
          }))
        }}
        onToggle={() => {
          dispatch(updateOpenFilters({ sessions: { opened: !openFilters.has('sessions') } }))
        }}
      />

      {Facets.map(({ name, description, control }, index) => {
        return (
          <DebugFacet
            key={index}
            name={name}
            description={description}
            opened={openFilters.has(control)}
            facets={facets}
            pending={pending}
            asLabel={asLabelMap[name]}
            onClick={(facet, option) => {
              dispatch(updateFacetOption({ facet, option }))
              dispatch(goToDebugResults({ features }))
            }}
            onReset={facet => {
              dispatch(resetFacet({ facet }))
              dispatch(goToDebugResults({ features }))
            }}
            onToggle={() => {
              dispatch(updateOpenFilters({ [control]: { opened: !openFilters.has(control) } }))
            }}
          />
        )
      })}
    </div>
  ) : null
}

DebugFilters.propTypes = {
  features: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  groupBy: PropTypes.string.isRequired,
  facets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sumOtherDocCount: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      selected: PropTypes.bool.isRequired
    }))
  })),
  openFilters: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const features = selectors.userFeaturesSelector(state)
  const partner = selectors.activePartnerGlobalSelector(state)
  const total = selectors.debuggingLogEntriesTotalGlobalSelector(partner)(state)
  const groupBy = selectors.debuggingGroupBySelector(partner)(state)
  const facets = selectors.debuggingLogFacetsSelector(partner)(state)
  const openFilters = selectors.debuggingOpenFiltersSelector(partner)(state)
  const pending = selectors.debuggingPendingSelector(partner)(state)

  return {
    features,
    total,
    groupBy,
    facets,
    openFilters,
    pending
  }
}

export default connect(mapStateToProps)(DebugFilters)

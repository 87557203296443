import React from 'react'
import PropTypes from 'prop-types'
import NoData from '../no-data'

import './style.css'

const DebugKeyValueTable = ({ items = [], dataType }) => {
  return items.length
    ? (
      <table className='debug-key-value-table__items'>
        <tbody>
          {items.map((item, index) => (
            <tr className='debug-key-value-table__item' key={index}>
              <td className='debug-key-value-table__item-key'>{item.key}</td>
              <td className='debug-key-value-table__item-value'>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
    : (
      <NoData dataType={dataType} />
    )
}

DebugKeyValueTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,
  dataType: PropTypes.string.isRequired
}

export default DebugKeyValueTable

import { gql } from '@apollo/client'

import client from '../../../apollo/client'

const DEBUGLOG_SUMMARIES = gql`
  query DebuglogSummaries(
    $from: Epoch!,
    $to: Epoch!,
    $query: String,
    $offset: Int,
    $facets: [DebuglogSummariesFacetInput!]) {

    debuglogSummaries(
      from: $from,
      to: $to,
      query: $query,
      offset: $offset,
      facets: $facets) {

      meta {
        total
        facets {
          name
          displayName
          type
          sumOtherDocCount
          options {
            key
            count
            displayName
          }
        }
      }
      data {
        transactionId
        assetId
        ssess
        podId
        breakDurationRequested
        breakDurationSelected
        links {
          adDecisionServiceData
          mediaRequests
          adEngineDecisions
        }
        labelIds
        allowlistEntry {
          idType
          label
          value
        }
        eventTime
        platform
        swid
        ruleId
        ruleName
        eventName
        saved
        source {
          _type
        }
        deviceId
        userId
        assetNameValues {
          name
          value
        }
        status
        statusMessage
      }
    }
  }
`

export const queryDebugSummaries = async ({
  from,
  to,
  query,
  offset,
  facets
}) => {
  const { data } = await client.query({
    query: DEBUGLOG_SUMMARIES,
    variables: {
      from,
      to,
      query,
      offset,
      facets
    }
  })

  return data
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import './style.css'

import * as Wham from '../../wham/components'

import Header from '../../containers/header'

import DocLink from '../../containers/doc-link'

import { activePartnerGlobalSelector } from '../../selectors'
import { getInsightsBetaWidgets } from '../../lib/insightsBeta'

import { useDocumentTitle } from '../../lib/custom-hooks'

// https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
const TIME_INTERVALS = [
  {
    label: 'Live',
    value: 'PT5M'
  },
  {
    label: 'Since 1 Hour Ago',
    value: 'PT1H'
  },
  {
    label: 'Since 6 Hours Ago',
    value: 'PT6H'
  },
  {
    label: 'Since 1 Day Ago',
    value: 'P1D'
  },
  {
    label: 'Since 7 Days ago',
    value: 'P7D'
  }
]

const DEFAULT_TIME_INTERVAL = TIME_INTERVALS[0].value

const InsightsBetaView = ({ partnerId }) => {
  useDocumentTitle('Insights')

  const [timeInterval, setTimeInterval] = useState(DEFAULT_TIME_INTERVAL)
  const [waiting, setWaiting] = useState(true)

  const widgets = getInsightsBetaWidgets(partnerId, timeInterval)

  const onChangeTimeInterval = e => {
    const value = e.target.value
    setTimeInterval(value)
    setWaiting(true)
  }

  return (
    <div className='insights-beta-view'>
      <div className='insights-beta-view__header-row'>
        <Header />
      </div>

      <div className='insights-beta__controls-row'>
        <Wham.Select
          size='medium'
          subtle
          value={timeInterval}
          options={TIME_INTERVALS}
          onChange={onChangeTimeInterval}
        />
      </div>

      <div className='insights-beta-view__content-row'>
        {
          waiting && (
            <div className='insights-beta__loading-layer'>
              <div className='insights-beta__loading-message'>
                Loading New Relic Embedded Charts...
              </div>
            </div>
          )
        }
        <div className='insights-beta__widgets-layer'>
          {
            widgets.map(widget => (
              <div key={widget} className='insights-beta__iframe-wrapper'>
                <iframe
                  className='insights-beta__iframe'
                  src={`https://insights-embed.newrelic.com/embedded_widget/${widget}`}
                  onLoad={() => setWaiting(false)}
                >
                </iframe>
              </div>
            ))
          }
        </div>
      </div>
      <DocLink topic="insights" />
    </div>
  )
}

InsightsBetaView.propTypes = {
  partnerId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const partnerId = activePartnerGlobalSelector(state)
  return {
    partnerId
  }
}

export default connect(mapStateToProps)(InsightsBetaView)

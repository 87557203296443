import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import { getNameForPartner, getLogoForPartner } from '../../logo/partner'
import { HeaderMainMenuContext } from '../../../context'

import './style.css'

const HeaderPartnerMenu = ({
  partners,
  onChangePartner
}) => {
  const context = useContext(HeaderMainMenuContext)

  const {
    partner
  } = context

  const partnersMap = partners
    .map(({ id }) => ({
      id,
      title: getNameForPartner(id),
      logo: getLogoForPartner(id),
      selected: id === partner
    }))

  return (
    <div className='header-partner-menu'>
      <Wham.PartnerSelector partners={partnersMap} selectPartner={onChangePartner} />
    </div>
  )
}

HeaderPartnerMenu.propTypes = {
  partners: PropTypes.array.isRequired,
  onChangePartner: PropTypes.func.isRequired
}

export default HeaderPartnerMenu

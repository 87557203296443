import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

import './style.css'

const InPageModal = ({
  showModal,
  header,
  children,
  footer,
  onClose
}) => showModal ? (
  <div className='in-page-modal'>
    <div className='in-page-modal--background' />
    <div className='in-page-modal--wrapper'>
      {header ? (
        <div className='in-page-modal--header'>
          {header}
        </div>
      ) : null}

      {children ? (
        <div className='in-page-modal--content'>
          {children}
        </div>
      ) : null}

      {footer ? (
        <div className='in-page-modal--footer'>
          {footer}
        </div>
      ) : null}
    </div>

    <Wham.Icon className='in-page-modal--close' iconName='close' onClick={onClose} />
  </div>
) : null

InPageModal.propTypes = {
  showModal: PropTypes.bool,
  header: PropTypes.string,
  children: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func
}

export default InPageModal

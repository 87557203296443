import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../Icon'

import './AppSubject.scss'
import '../../scss/wham.scss'
export default class AppSubject extends React.Component {
  static displayName = 'AppSubject'
  static propTypes = {
    primary: PropTypes.string,
    detail: PropTypes.string,
    backHref: PropTypes.string,
    backHandler: PropTypes.func,
    backLabel: PropTypes.string,
    className: PropTypes.string
  }

  render () {
    const {
      primary = '',
      detail = '',
      backHandler,
      backLabel = false,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-app-bar__subject',
      className
    )

    return (
      <div
        className={componentClass}
        {...otherProps}
      >
        {backHandler &&
          <span
            onClick={backHandler}
            className='w-app-bar__subject__navigation'
          >
            <Icon
              data-cy='backToRules'
              iconName='navigate_before'
              className='w-app-bar__subject__navigation__icon'
            />
            {backLabel &&
              <span className='w-app-bar__subject__navigation__label'>{backLabel}</span>}
          </span>}
        <div className='w-app-bar__subject__details'>
          <div className='w-app-bar__subject__details__primary'>{primary}</div>
          <div className='w-app-bar__subject__details__secondary'>{detail}</div>
        </div>
      </div>
    )
  }
}

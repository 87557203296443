import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { ViewContext } from '../../../context'

import * as Wham from '../../../wham/components'

import { hasPermissionToRetranscode } from '../../../lib/creative-assets'

import './style.css'

const CreativeSummaryRecordControls = ({
  record,
  play,
  redo,
  pending,
  onPlay,
  onRedo,
  onValidate,
  onMouseOver,
  onMouseOut
}) => {
  const { partner, claims } = useContext(ViewContext)

  const swallowClickEvent = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className='creative-summary-record-controls'>
      {hasPermissionToRetranscode(partner, claims) ? (
        redo.pending ? (
          <span className='creative-summary-record-controls__redo-loading' onClick={swallowClickEvent}>
            <Wham.ProgressCircular active type='indeterminate' sqSize={16}/>
          </span>
        ) : redo.error ? (
          <span
            className='creative-summary-record-controls__redo-error w-icon'
            onMouseOver={e => onMouseOver(e)}
            onMouseOut={() => onMouseOut()}
            data-tooltip='Retranscode Failed'
            onClick={swallowClickEvent}
          >warning</span>
        ) : (
          <button
            className='creative-summary-record-controls__button w-btn'
            disabled={pending || redo.disabled}
            onClick={e => {
              swallowClickEvent(e)
              onMouseOut()
              onRedo(record.id)
            }}
            onMouseOver={e => onMouseOver(e)}
            onMouseOut={() => onMouseOut()}
            data-tooltip='Retranscode'
          >
            <span className='creative-summary-record-controls__icon w-icon'>redo</span>
          </button>
        )
      ) : null}

      <button
        className='creative-summary-record-controls__button w-btn'
        disabled={pending}
        onClick={e => {
          swallowClickEvent(e)
          onValidate(record.mezzFile)
        }}
        onMouseOver={e => onMouseOver(e)}
        onMouseOut={() => onMouseOut()}
        data-tooltip='Send to Validator'
      >
        <span className='creative-summary-record-controls__icon w-icon'>launch</span>
      </button>

      {play.error ? (
        <span
          className='creative-summary-record-controls__play-error w-icon'
          onMouseOver={e => onMouseOver(e)}
          onMouseOut={() => onMouseOut()}
          data-tooltip='Play Asset Failed'
          onClick={swallowClickEvent}
        >warning</span>
      ) : play.loading ? (
        <span className='creative-summary-record-controls__play-loading' onClick={swallowClickEvent}>
          <Wham.ProgressCircular active type='indeterminate' sqSize={16} />
        </span>
      ) : play.playing ? (
        <span className='creative-summary-record-controls__play-playing' onClick={swallowClickEvent}>
          <Wham.Progress active type='indeterminate' />
        </span>
      ) : (
        <button
          className='creative-summary-record-controls__button w-btn'
          disabled={pending || play.disabled}
          onClick={e => {
            swallowClickEvent(e)
            onMouseOut()
            onPlay(record.mezzFile)
          }}
          onMouseOver={e => onMouseOver(e)}
          onMouseOut={() => onMouseOut()}
          data-tooltip='Play Asset'
        >
          <span className='creative-summary-record-controls__icon w-icon'>play_circle_filled</span>
        </button>
      )}
    </div>
  )
}

CreativeSummaryRecordControls.propTypes = {
  record: PropTypes.object.isRequired,
  play: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    playing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  redo: PropTypes.shape({
    pending: PropTypes.bool.isRequired,
    complete: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  pending: PropTypes.bool.isRequired,
  onPlay: PropTypes.func.isRequired,
  onRedo: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func.isRequired,
  onMouseOut: PropTypes.func.isRequired
}

export default CreativeSummaryRecordControls

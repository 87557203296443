import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import * as Wham from '../../wham/components'

import { setDebugFormField } from '../../actions/form'
import { goToDebugResults } from '../../actions/navigators'

import * as selectors from '../../selectors'

import DateRangePicker from '../../components/date-range-picker'

import { convertDurationToEpochs } from '../../lib/durations'

import { createDebugDateLimits, beforeDebugDateLimits } from './util'

import './style.css'

const options = [
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Last 5 minutes',
    value: 'last-5-minutes'
  },
  {
    label: 'Last 15 minutes',
    value: 'last-15-minutes'
  },
  {
    label: 'Last 30 minutes',
    value: 'last-30-minutes'
  },
  {
    label: 'Last hour',
    value: 'last-1-hour'
  },
  {
    label: 'Last 2 hours',
    value: 'last-2-hours'
  },
  {
    label: 'Last 4 hours',
    value: 'last-4-hours'
  },
  {
    label: 'Last 2 days',
    value: 'last-2-days'
  },
  {
    label: 'Last 3 days',
    value: 'last-3-days'
  },
  {
    label: 'Last 4 days',
    value: 'last-4-days'
  },
  {
    label: 'Last 5 days',
    value: 'last-5-days'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

export const DebugDates = ({
  features,
  now,
  from,
  to,
  duration,
  pending,
  dispatch,
  $state = {}
}) => {
  const [picker, setPicker] = useState($state.picker || null)
  const [tooltip, setTooltip] = useState(null)
  const [warning, setWarning] = useState(false)

  const value = options.reduce((acc, option) => {
    return option.value === duration ? duration : acc
  }, options[0].value)

  const onChange = e => {
    dispatch(setDebugFormField('duration', e.target.value))

    if (e.target.value === 'custom') {
      setPicker(duration)
    } else {
      dispatch(goToDebugResults({ epochs: convertDurationToEpochs(e.target.value), features }))
    }
  }

  const onClose = () => {
    dispatch(setDebugFormField('duration', picker))
    setPicker(null)
  }

  const onCustom = epochs => {
    setPicker(null)
    dispatch(goToDebugResults({ epochs, features }))
  }

  const onDateChange = epochs => {
    setWarning(beforeDebugDateLimits(epochs))
  }

  const limits = createDebugDateLimits(now)
  const custom = value === 'custom'

  return (
    <div className='debug-dates' data-cy='debug-dates'>
      <div className='debug-dates__control debug-dates__control--facets'>
        <Wham.Select
          className={classNames('debug-dates__select', { 'debug-dates__select--custom': custom })}
          value={value}
          options={options}
          disabled={pending}
          onChange={onChange}
        />

        {custom ? (
          <span className='debug-dates__calendar'>
            <button className='debug-dates__calendar-btn w-btn' onClick={() => {
              setPicker('custom')
            }}>
              <span className='debug-dates__calendar-icon w-icon'>date_range</span>
            </button>
          </span>
        ) : null}
      </div>

      {picker ? (
        <div className='debug-dates__picker'>
          <DateRangePicker
            now={now}
            from={from}
            to={to}
            limits={limits}
            pending={pending}
            onChange={epochs => onDateChange(epochs)}
            onCustom={onCustom}
            onClose={onClose}
          />

          {warning ? (
            <div className='debug-dates__picker-warning'>
              <span
                className='debug-dates__picker-warning-icon w-icon'
                onMouseOver={e => setTooltip(e.target)}
                onMouseOut={() => setTooltip(null)}
                data-tooltip={'Only bookmarked transactions will show'}
              >error_outline</span>
            </div>
          ) : null}

          {tooltip ? (
            <Wham.Tooltip className='debug-dates__picker-warning-tooltip' trigger={tooltip} placement='bottom' offset={{ top: 289, left: 145 }} />
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

DebugDates.propTypes = {
  features: PropTypes.object.isRequired,
  now: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  duration: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  $state: PropTypes.object
}

const mapStateToProps = (state) => {
  const features = selectors.userFeaturesSelector(state)
  const partner = selectors.activePartnerGlobalSelector(state)

  const now = selectors.debugEpochNowSelector(partner)(state)
  const from = selectors.debugEpochFromSelector(partner)(state)
  const to = selectors.debugEpochToSelector(partner)(state)
  const duration = selectors.debugDurationSelector(partner)(state)
  const pending = selectors.debuggingPendingSelector(partner)(state)

  return {
    features,
    now,
    from,
    to,
    duration,
    pending
  }
}

export default connect(mapStateToProps)(DebugDates)

import * as R from 'ramda'

import { parseQs } from './qs'

const parseUrl = url => {
  const pos = url.indexOf('?')

  if (pos < 0) {
    return {
      base: url,
      textParts: [{ type: 'normal', text: url }],
      tuples: []
    }
  }

  const [base, qs] = R.splitAt(pos + 1, url)

  const { textParts, tuples } = parseQs(base, qs)

  return {
    base: base.replace(/[?]$/, ''),
    textParts,
    tuples
  }
}

export {
  parseUrl
}

import { createStore, combineReducers, applyMiddleware } from 'redux'

import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'

import { connectRouter, routerMiddleware } from 'connected-react-router'
import { history } from '../services/history'

import authSaga from '../sagas/user'
import assetValidatorSaga from '../sagas/asset-validator'
import navigationSaga from '../sagas/navigation-side-effects'
import debuggingSaga from '../sagas/debugging'
import navigatorsSaga from '../sagas/navigators'
import ruleManagementSaga from '../sagas/rule-management'
import partnerSaga from '../sagas/partner'
import whitelistSaga from '../sagas/whitelist'

import partnerReducer from '../reducers/partner'
import userReducer from '../reducers/user'
import systemReducer from '../reducers/system'
import navigatorsReducer from '../reducers/navigators'

import { reportClientError } from '../services/api'
import { config } from '../services/config'

const { IS_PRODUCTION } = config

const errorMiddleware = ({ getState }) => {
  return next => {
    return action => {
      try {
        return next(action)
      } catch (error) {
        reportClientError(error)
        return getState()
      }
    }
  }
}

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    reportClientError(error)
  }
})

let middleware = applyMiddleware(errorMiddleware, routerMiddleware(history), sagaMiddleware)

/* In development add redux-devtools integration for debugging */
if (!IS_PRODUCTION) {
  middleware = composeWithDevTools(middleware)
}

/* Construct our State-Store */
const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  user: userReducer,
  partner: partnerReducer,
  system: systemReducer,
  navigation: navigationSaga,
  navigators: navigatorsReducer
})

const store = createStore(
  createRootReducer(history),
  middleware
)

/* Enrol Sagas */
sagaMiddleware.run(authSaga)
sagaMiddleware.run(debuggingSaga)
sagaMiddleware.run(navigationSaga)
sagaMiddleware.run(navigatorsSaga)
sagaMiddleware.run(ruleManagementSaga)
sagaMiddleware.run(partnerSaga)
sagaMiddleware.run(assetValidatorSaga)
sagaMiddleware.run(whitelistSaga)

export default store

import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

// creatives

export const creativesDurationSelector = partner => R.path([
  'partner', partner, 'forms', 'creatives', 'duration'])

export const creativesEpochsSelector = partner => R.path([
  'partner', partner, 'forms', 'creatives', 'epochs'])

export const creativesStatusSelector = partner => R.path([
  'partner', partner, 'forms', 'creatives', 'status'])

export const creativesQuerySelector = partner => R.path([
  'partner', partner, 'forms', 'creatives', 'query'])

// debug

export const debugEpochNowSelector = partner => R.path([
  'partner', partner, 'forms', 'debug', 'epochNow'])
export const selectDebugEpochNow = R.compose(SAGA.select, debugEpochNowSelector)

export const debugEpochFromSelector = partner => R.path([
  'partner', partner, 'forms', 'debug', 'epochFrom'])
export const selectDebugEpochFrom = R.compose(SAGA.select, debugEpochFromSelector)

export const debugEpochToSelector = partner => R.path([
  'partner', partner, 'forms', 'debug', 'epochTo'])
export const selectDebugEpochTo = R.compose(SAGA.select, debugEpochToSelector)

export const debugDurationSelector = partner => R.path([
  'partner', partner, 'forms', 'debug', 'duration'])

export const debugSearchQuerySelector = partner => R.path([
  'partner', partner, 'forms', 'debug', 'searchQuery'])
export const selectDebugSearchQuery = R.compose(SAGA.select, debugSearchQuerySelector)

export const debugFetchSelector = partner => R.path([
  'partner', partner, 'forms', 'debug', 'fetch'])
export const selectDebugFetch = R.compose(SAGA.select, debugFetchSelector)

// reporting

export const selectedRangeSelector = partner => R.path([
  'partner', partner, 'forms', 'reporting', 'range'])

export const selectedStartDateSelector = partner => R.path([
  'partner', partner, 'forms', 'reporting', 'startDate'])

export const selectedEndDateSelector = partner => R.path([
  'partner', partner, 'forms', 'reporting', 'endDate'])

// simulator

export const selectedBrkdur = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'brkdur'])

export const selectedImplicitPodId = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'implicitPodId'])

export const selectedExplicitPodId = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'explicitPodId'])

export const selectedAssetId = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'assetId'])

export const selectedUserAgent = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'userAgent'])

export const selectedSetTokenUrl = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'setTokenUrl'])

export const selectedSetTokenMethod = partner => R.path([
  'partner', partner, 'forms', 'simulator', 'fields', 'setTokenMethod'])

// whitelist

export const whitelistOpenSelector = partner => R.path([
  'partner', partner, 'forms', 'whitelist', 'open'])

export const whitelistValueSelector = partner => R.path([
  'partner', partner, 'forms', 'whitelist', 'value'])

export const whitelistLabelSelector = partner => R.path([
  'partner', partner, 'forms', 'whitelist', 'label'])

export const whitelistTypeSelector = partner => R.path([
  'partner', partner, 'forms', 'whitelist', 'type'])

export const whitelistSearchSelector = partner => R.path([
  'partner', partner, 'forms', 'whitelist', 'search'])

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SESSION } from '../../../constants/debug/groups'

import './style.css'

const DebugFilterBySession = ({
  opened,
  groupBy,
  pending,
  onClick,
  onToggle
}) => {
  return (
    <div className={classNames('debug-filter-by-session', { 'debug-filter-by-session--pending': pending })}>
      <div className='debug-filter-by-session__header' onClick={onToggle}>
        <div className='debug-filter-by-session__title-wrapper'>
          <span className='debug-filter-by-session__title-icon w-icon'>{opened ? 'expand_more' : 'chevron_right'}</span>
          <h2 className='debug-filter-by-session__title'>Sessions</h2>
        </div>
      </div>

      {opened ? (
        <div className='debug-filter-by-session__content'>
          <div className='debug-filter-by-session__option'>
            <input className='debug-filter-by-session__option-input' type='checkbox' id='debug-filter-by-session__option-group' checked={groupBy === SESSION} disabled={pending} onClick={onClick}/>
            <label className='debug-filter-by-session__option-label' htmlFor='debug-filter-by-session__option-group'>Session Grouping</label>
          </div>
        </div>
      ) : null}
    </div>
  )
}

DebugFilterBySession.propTypes = {
  opened: PropTypes.bool.isRequired,
  groupBy: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default DebugFilterBySession

import { PANIC, ERROR, DISMISS_ERROR } from '../../actions/panic'

const defaultState = {
  healthy: false,
  error: {
    actionText: null,
    errorDescription: null,
    errorTitle: null,
    dismissable: false
  }
}

export default (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case PANIC:
      return {
        ...state,
        ...payload
      }

    case ERROR:
      return {
        ...state,
        ...payload
      }

    case DISMISS_ERROR:
      return defaultState

    default:
      return state
  }
}

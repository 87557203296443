import * as R from 'ramda'

export const selectedSetTokenUrlUpdated = partner => R.path([
  'partner', partner, 'simulator', 'setTokenUrlUpdated'])

export const simulatedTransactionsSelector = partner => R.path([
  'partner', partner, 'simulator', 'simulatedTransactions'])

export const selectedTransactionIdSelector = partner => R.path([
  'partner', partner, 'simulator', 'selectedTransactionId'])

/* eslint-disable space-before-function-paren */
/* eslint-disable generator-star-spacing */

import * as SAGA from 'redux-saga/effects'

import safe from '../safe'

import { success, failure } from '../../actions/async'
import {
  CLEAR_WHITELIST_LOOKUP,
  FETCH_WHITELIST_USER_BY_EMAIL
} from '../../actions/whitelist'

import { selectActivePartner } from '../../selectors'

import { getResponseData } from '../../services/api/helpers'
import { getUserByEmail } from '../../services/whitelist'

export const fetchWhitelistUserForEmail = function* ({ payload }) {
  try {
    const partnerId = yield selectActivePartner

    const { query: partialEmail } = payload

    if (partialEmail) {
      const response = yield SAGA.call(getUserByEmail, { partnerId, partialEmail })
      yield SAGA.put(success(FETCH_WHITELIST_USER_BY_EMAIL, { matchedUsers: getResponseData(response) }))
    } else {
      yield SAGA.put({ type: CLEAR_WHITELIST_LOOKUP })
      yield SAGA.put(failure(FETCH_WHITELIST_USER_BY_EMAIL))
    }
  } catch (error) {
    yield SAGA.put(failure(FETCH_WHITELIST_USER_BY_EMAIL, { error }))
  }
}

export default function* whitelist() {
  yield SAGA.takeLatest(FETCH_WHITELIST_USER_BY_EMAIL.default, safe(fetchWhitelistUserForEmail, 'fetchWhitelistUserForEmail'))
}

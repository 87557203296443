import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Tabs.scss'

export const Tab = (props) => {
  return (
    <li className={`w-tabs__nav__tab ${props.isActive ? 'w-tabs__nav__tab--active' : ''}`}>
      <a
        className={`w-tabs__nav__tab__link ${props.linkClassName || ''}`}
        href={'#'}
        onClick={(event) => {
          event.preventDefault()
          props.onClick(props.tabIndex)
        }}
      >{props.label}
      </a>
    </li>
  )
}

Tab.propTypes = {
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  linkClassName: PropTypes.string
}

export class Tabs extends React.Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      activeTabIndex: this.props.defaultActiveTabIndex
    }
    this.handleTabClick = this.handleTabClick.bind(this)
  }

  handleTabClick (tabIndex) {
    const newActiveTabIndex = tabIndex === this.state.activeTabIndex
      ? this.props.defaultActiveTabIndex
      : tabIndex
    this.setState({
      activeTabIndex: newActiveTabIndex
    })
    if (this.props.onChangeTab) {
      const tab = React.Children.toArray(this.props.children)[newActiveTabIndex]
      this.props.onChangeTab(tab, newActiveTabIndex)
    }
  }

  // Encapsulate <Tabs/> component API as props for <Tab/> children
  renderChildrenWithTabsApiAsProps () {
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        onClick: this.handleTabClick,
        tabIndex: index,
        isActive: index === this.state.activeTabIndex
      })
    })
  }

  // Render current active tab content
  renderActiveTabContent () {
    const { children } = this.props
    const { activeTabIndex } = this.state

    const selectedChild = Array.isArray(children)
      ? children[activeTabIndex]
      : children

    if (selectedChild) {
      return selectedChild.props.children
    }
  }

  render () {
    const {
      defaultActiveTabIndex,
      children,
      className,
      title,
      size,
      onChangeTab,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-tabs',
      (size ? 'w-tabs--' + size : null),
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        <ul className='w-tabs__nav'>
          {title ? <li className='w-tabs__nav__title'>{title}</li> : null}
          {this.renderChildrenWithTabsApiAsProps()}
        </ul>
        <div className='w-tabs__content'>
          {this.renderActiveTabContent()}
        </div>
      </div>
    )
  }
}

Tabs.propTypes = {
  defaultActiveTabIndex: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.string,
  onChangeTab: PropTypes.func
}

Tabs.defaultProps = {
  defaultActiveTabIndex: 0
}

export default Tabs

import { CREATIVES_RESET, SET_CREATIVE_IDS } from '../../actions/creatives'

const defaultState = {
  ids: []
}

const creativesReducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case CREATIVES_RESET: {
      return {
        ...state,
        ids: []
      }
    }

    case SET_CREATIVE_IDS: {
      const ids = payload.map(({ id }) => id)

      return {
        ...state,
        ids
      }
    }

    default:
      return state
  }
}

export default creativesReducer

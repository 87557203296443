import { curry } from 'ramda'

export const SUCCESS_SUFFIX = '::success'
export const FAILURE_SUFFIX = '::failure'

export const createAsyncActionType = type => ({
  success: `${type}${SUCCESS_SUFFIX}`,
  failure: `${type}${FAILURE_SUFFIX}`,
  default: type
})

const _success = (type, data) => {
  const t = type.default || type
  return {
    type: `${t}${SUCCESS_SUFFIX}`,
    payload: data
  }
}

const _failure = (type, { error, ...data }) => {
  const t = type.default || type
  return {
    type: `${t}${FAILURE_SUFFIX}`,
    payload: data,
    error: error
  }
}

export const success = curry(_success)
export const failure = curry(_failure)

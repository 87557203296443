import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  activePartnerGlobalSelector,
  swidPartnerSelector,
  userClaimSelector,
  userFeaturesSelector,
  userRolesSelector,
  whitelistSearchSelector
} from '../../selectors'

import { setWhitelistFormField } from '../../actions/form'

import { useDocumentTitle } from '../../lib/custom-hooks'

import { ViewContext } from '../../context'

import Header from '../../containers/header'
import Whitelist from '../../containers/whitelist'

import FilterBar from '../../components/filter-bar'
import DocLink from '../../containers/doc-link'

import './style.css'

const WhitelistView = ({
  claims,
  features,
  roles,
  partner,
  swid,
  search,
  dispatch
}) => {
  useDocumentTitle('Allow List')

  const context = {
    claims,
    features,
    roles,
    partner,
    swid
  }

  return (
    <ViewContext.Provider value={context}>
      <div className='whitelist-view'>
        <div className='whitelist-view__row'>
          <Header
            middle={
              <FilterBar
                value={search}
                onChange={e => {
                  dispatch(setWhitelistFormField('search', e.target.value.trim()))
                }}
              />
            }
          />
        </div>

        <div className='whitelist-view__row'>
          <div className='whitelist-view__col' >
            <div className='whitelist-view__content'>
              <Whitelist />
            </div>
          </div>
        </div>
      </div>

      <DocLink topic="debugging" />
    </ViewContext.Provider>
  )
}

WhitelistView.propTypes = {
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  partner: PropTypes.string.isRequired,
  swid: PropTypes.shape({
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  search: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const claims = userClaimSelector(state)
  const features = userFeaturesSelector(state)
  const roles = userRolesSelector(state)
  const partner = activePartnerGlobalSelector(state)

  const swid = swidPartnerSelector(partner)(state)

  const search = whitelistSearchSelector(partner)(state)

  return {
    claims,
    features,
    roles,
    partner,
    swid,
    search
  }
}

export default connect(mapStateToProps)(WhitelistView)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Tooltip.scss'
import '../../scss/wham.scss'

class Tooltip extends React.Component {
  static propTypes = {
    placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'top-right', 'top-left', 'bottom-right', 'bottom-left']),
    trigger: PropTypes.object,
    offset: PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number
    }),
    className: PropTypes.string
  }

  static defaultProps = {
    placement: 'bottom'
  }

  render () {
    const {
      className,
      placement,
      trigger,
      offset = {},
      ...otherProps
    } = this.props

    const position = {
      top: trigger.offsetTop + (offset.top || 0),
      left: trigger.offsetLeft + (offset.left || 0)
    }

    const placementStyles = {}

    if (trigger) {
      switch (placement) {
        case 'top':
          placementStyles.left = position.left + (trigger.offsetWidth / 2)
          placementStyles.top = position.top
          break
        case 'right':
          placementStyles.left = position.left + trigger.offsetWidth
          placementStyles.top = position.top + (trigger.offsetHeight / 2)
          break
        case 'left':
          placementStyles.left = position.left
          placementStyles.top = position.top + (trigger.offsetHeight / 2)
          break
        case 'top-right':
          placementStyles.left = position.left + trigger.offsetWidth
          placementStyles.top = position.top
          break
        case 'top-left':
          placementStyles.left = position.left
          placementStyles.top = position.top
          break
        case 'bottom-right':
          placementStyles.left = position.left + trigger.offsetWidth
          placementStyles.top = position.top + trigger.offsetHeight
          break
        case 'bottom-left':
          placementStyles.left = position.left
          placementStyles.top = position.top + trigger.offsetHeight
          break
        default:
          placementStyles.left = position.left + (trigger.offsetWidth / 2)
          placementStyles.top = position.top + trigger.offsetHeight
      }
    }

    return (
      <div
        className={classNames('w-tooltip w-overlay', 'w-tooltip--' + placement, trigger && 'w-tooltip--active', className)}
        style={placementStyles}
        {...otherProps}
      >
        {this.props.trigger.getAttribute('data-tooltip')
          ? <div className='w-tooltip__title'>
            {this.props.trigger.getAttribute('data-tooltip')}
          </div> : null}
        {this.props.trigger.getAttribute('data-tooltip') && this.props.trigger.getAttribute('data-tooltip-message')
          ? <div className='w-tooltip__message'>
            {this.props.trigger.getAttribute('data-tooltip-message')}
          </div> : null}
      </div>
    )
  }
}

export default Tooltip

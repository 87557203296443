import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import blacklist from 'blacklist'

import { MENU_SIZES, MENU_TYPES } from '../lib/wham'

class MenuList extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    /** The Size of the Menu Items */
    size: PropTypes.oneOf(MENU_SIZES),
    /** What sort of Menu Item is displayed */
    type: PropTypes.oneOf(MENU_TYPES),
    subtle: PropTypes.bool
  }

  render () {
    const {
      type,
      size,
      className,
      children,
      subtle,
      ...otherProps
    } = this.props
    // classes
    var componentClass = classNames(
      'w-menu-list',
      (type && `w-menu-list--${type}`),
      (size && `w-menu-list--${size}`),
      (subtle && 'w-menu-list--subtle'),
      className
    )

    // props
    var props = blacklist(this.props, 'subtle', 'type', 'size', 'className')
    props.className = componentClass

    return (
      <div className={componentClass}>
        <ul className='w-menu-list__items' {...otherProps}>
          {children}
        </ul>
      </div>
    )
  }
};

MenuList.defaultProps = {
  type: 'default'
}

export default MenuList

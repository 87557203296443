import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client'

import * as Wham from '../../../wham/components'

import { goToDebugResults } from '../../../actions/navigators'
import { setLogEntryFlag } from '../../../actions/transactions'
import HeaderBack from '../../../components/header/header-back'
import { useDocumentTitle } from '../../../lib/custom-hooks'
import DocLink from '../../../containers/doc-link'
import Header from '../../../containers/header'
import { ViewContext } from '../../../context'
import { paths } from '../../../route-paths'
import * as selectors from '../../../selectors'

import { TransactionDetails } from '../debug-item'
import { getTransactionDetails } from '../getTransactionDetails'

import { updateTransactionDetails } from './util'

import styles from './index.module.scss'

export const GQL_DEBUG_TRANSACTION = gql`
  query Debuglog($transactionId: String!) {
    debuglog(transactionId: $transactionId) {
      transactionId
      assetId
      ssess
      podId
      breakDurationRequested
      breakDurationSelected
      adDecisionServiceData {
        request {
          headers {
            key
            value
          }
          createdAt
          eventTime
          cookies {
            key
            value
          }
          url
        }
        response {
          createdAt
          data
          eventTime
          ruleId
        }
      }
      adEngineDecisions {
        traceId
        duration
        createdAt
        vastIdentifiers {
          id
          sequence
          adSystem
          wrapperUrl
        }
        amsId
        eventTime
        mezzFile
        status {
          _type
          failure {
            _type
          }
        }
      }
      mediaRequests {
        traceId
        request {
          headers {
            key
            value
          }
          createdAt
          eventTime
          cookies {
            key
            value
          }
          url
        }
        response {
          result {
            _type
          }
          createdAt
          redirectUrl
          amsId
          eventTime
        }
      }
      session {
        setToken {
          isPostRequest
          url
          headers {
            key
            value
          }
          cookies {
            key
            value
          }
          params {
            key
            value
          }
        }
        aps {
          request {
            url
            body
          }
          response {
            statusCode
            body
          }
          error
        }
      }
      allowlistEntry {
        idType
        label
        value
      }
      labelIds
      eventTime
      platform
      swid
      ruleId
      ruleName
      eventName
      saved
      source {
        _type
      }
      deviceId
      userId
      assetNameValues {
        name
        value
      }
      status
      statusMessage
    }
  }
`

export const GQL_WAITING_MEDIA_REQUESTS = gql`
  query MediaRequests($transactionId: String!) {
    mediaRequests(transactionId: $transactionId) {
      traceId
    }
  }
`

export const GQL_LATEST_MEDIA_REQUESTS = gql`
  query MediaRequests($transactionId: String!) {
    mediaRequests(transactionId: $transactionId) {
      traceId
      request {
        headers {
          key
          value
        }
        createdAt
        eventTime
        cookies {
          key
          value
        }
        url
      }
      response {
        result {
          _type
        }
        createdAt
        redirectUrl
        amsId
        eventTime
      }
    }
  }
`

const GQL_DEBUG_BOOKMARK = gql`
  mutation DebugBookmark($transactionId: ID!, $saved: Boolean!) {
    debugBookmark(transactionId: $transactionId, saved: $saved) {
      transactionId
      saved
    }
  }
`

const MEDIA_REQUESTS_INTERVAL = 10000

const DebugTransactionView = ({
  claims,
  features,
  roles,
  partner,
  transactionId,
  transactionDetails,
  mediaRequestsPollingEnabled,
  dispatch
}) => {
  const [localSavedFlag, setLocalSavedFlag] = useState(false)

  const transactionQueryResult = useQuery(GQL_DEBUG_TRANSACTION, {
    variables: {
      transactionId
    },
    onCompleted: data => {
      if (data.debuglog) {
        setLocalSavedFlag(data.debuglog.saved)
      }
    },
    onError: error => {
      console.log('GQL_DEBUG_TRANSACTION error', error)
    }
  })

  const waitingMediaRequestsQueryResult = useQuery(GQL_WAITING_MEDIA_REQUESTS, {
    variables: {
      transactionId
    }
  })

  useEffect(() => {
    if (mediaRequestsPollingEnabled) {
      waitingMediaRequestsQueryResult.startPolling(MEDIA_REQUESTS_INTERVAL)
    } else {
      waitingMediaRequestsQueryResult.stopPolling()
    }
  }, [mediaRequestsPollingEnabled])

  const [latestMediaRequestsLazyQuery, latestMediaRequestsLazyQueryResult] = useLazyQuery(GQL_LATEST_MEDIA_REQUESTS)

  const [bookmarkMutation, bookmarkMutationResult] = useMutation(GQL_DEBUG_BOOKMARK, {
    onCompleted: data => {
      // Keep "list" view in sync to reduce network requests...
      setLocalSavedFlag(data.debugBookmark.saved)
      dispatch(setLogEntryFlag(data.debugBookmark))
    }
  })

  useDocumentTitle('Transaction Details')

  const deepLink = typeof transactionDetails.focusedLog === 'undefined'

  const updatedTransactionDetails = updateTransactionDetails(
    partner,
    claims,
    transactionDetails,
    transactionQueryResult,
    waitingMediaRequestsQueryResult,
    latestMediaRequestsLazyQueryResult,
    bookmarkMutationResult,
    deepLink
  )

  if (updatedTransactionDetails.focusedLog) {
    updatedTransactionDetails.focusedLog.saved = localSavedFlag
  }

  const context = {
    claims,
    features,
    roles,
    partner
  }

  return (
    <ViewContext.Provider value={context}>
      <div className={styles.main}>
        <div className={styles.header}>
          <Header
            left={
              <HeaderBack
                text='Return to Debug List'
                path={paths.debug.replace(':partnerId', partner)}
                onClick={() => {
                  dispatch(goToDebugResults({ hash: transactionId, deep: deepLink, features }))
                }}
              />
            }
          />

          <div className={styles.pending}>
            <Wham.Progress type='indeterminate' active={transactionQueryResult.loading} />
          </div>
        </div>

        <div className={classNames('debug-result w-layout--spacing-default', styles.content)}>
          <div className={classNames('w-layout__row w-layout__row--fill', styles.container)}>
            <TransactionDetails
              transactionDetails={updatedTransactionDetails}
              partnerId={partner}
              claims={claims}
              features={features}
              dispatch={dispatch}
              onBookmark={({ transactionId, saved }) => {
                bookmarkMutation({ variables: { transactionId, saved } })
              }}
              onUpdate={() => {
                latestMediaRequestsLazyQuery({ variables: { transactionId } })
              }}
            />
          </div>

          <DocLink topic='debugging' />
        </div>
      </div>
    </ViewContext.Provider>
  )
}

DebugTransactionView.propTypes = {
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  partner: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  transactionDetails: PropTypes.object.isRequired,
  mediaRequestsPollingEnabled: PropTypes.bool.isRequired,
  rule: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const transactionId = ownProps.match.params.transactionId
  const partner = selectors.activePartnerGlobalSelector(state)
  const claims = selectors.userClaimSelector(state)
  const features = selectors.userFeaturesSelector(state)
  const roles = selectors.userRolesSelector(state)
  const mediaRequestsPollingEnabled = selectors.debuggingMediaRequestsPollingEnabledSelector(partner)(state)
  const transactionDetails = getTransactionDetails(state, partner, transactionId)

  return {
    claims,
    features,
    roles,
    partner,
    transactionId,
    transactionDetails,
    mediaRequestsPollingEnabled
  }
}

export default connect(mapStateToProps)(DebugTransactionView)

import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { WHAM_TYPES, WHAM_WIDTHS, WHAM_SIZES } from '../lib/wham'

import './FormField.scss'
import '../../scss/wham.scss'
export default class FormField extends React.Component {
  static displayName = 'FormField'
  static propTypes = {
    children: PropTypes.node,
    /** Additional class names to add */
    className: PropTypes.string,
    htmlFor: PropTypes.string,
    /** Prevent editing this field, and events */
    disabled: PropTypes.bool,
    /** Read-only view of element */
    readOnly: PropTypes.bool,
    /** Reduces visual impact */
    subtle: PropTypes.bool,
    /** Error style toggle */
    danger: PropTypes.bool,
    /** Primary style toggle */
    primary: PropTypes.bool,
    /** Manually specifiy the HTML ID */
    id: PropTypes.string,
    /** Label displayed before the control */
    label: PropTypes.string,
    /** Text below the control */
    helpText: PropTypes.string,
    offsetAbsentLabel: PropTypes.bool,
    /** The WHAM_SIZE property */
    size: PropTypes.oneOf(WHAM_SIZES),
    /** The WHAM_WIDTH property */
    width: PropTypes.oneOf(WHAM_WIDTHS),
    /** WARING DEPRECATION: The visual type, eg 'primary', 'danger', 'default' */
    type: PropTypes.oneOf(WHAM_TYPES),
    /** Passed-Through to Form Field Components ONLY */
    value: PropTypes.any,
    /** Passed-Through to Form Field Components ONLY */
    onChange: PropTypes.any,
    /** Visual indicator that field is optional */
    optional: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      type,
      primary,
      danger,
      disabled,
      readOnly,
      value,
      onChange,
      children,
      width,
      className,
      optional,
      offsetAbsentLabel,
      htmlFor,
      label,
      helpText,
      subtle,
      id,
      ...otherProps
    } = this.props

    var componentClass = classNames('w-field', {
      'w-field--disabled': disabled,
      'w-field--error': (type === 'danger' || danger),
      [`w-field--${size}`]: true,
      'w-field--primary': primary,
      'w-field--has-help-text': helpText,
      'w-field--default': !primary && !subtle && !danger && !type,
      'offset-absent-label': offsetAbsentLabel,
      [`w-field--${type}`]: !!type // TODO: deprecate
    }, width, className)

    var labelClass = classNames('w-field__label', {
      'w-field__label--optional': optional
    })

    // elements
    var componentLabel = label ? (
      <label className={labelClass} htmlFor={id || htmlFor}>
        {label}
      </label>
    ) : null

    var componentHelpText = helpText ? (
      <p className='w-field__help'>
        {helpText}
      </p>
    ) : null

    /* If we are passing a change handler, we can only pass it to a single child */
    let childrenWithWrapperDiv
    try {
      const child = React.Children.only(children)
      /* Inject Type and disabled extensions if passed to the parent component */
      const overrideProps = {
        disabled,
        value,
        onChange: onChange,
        readOnly,
        ...child.props,
        subtle: child.props.subtle || subtle,
        size: child.props.size || size,
        danger: child.props.danger || danger
      }
      const componentWithComposedProps = React.cloneElement(child, overrideProps)
      childrenWithWrapperDiv = (<div className='w-field__control w-field__control--expanded'>{componentWithComposedProps}</div>)
    } catch (e) {
      childrenWithWrapperDiv = React.Children.map(children, (child, i) => {
        const overrideProps = {
          disabled,
          readOnly,
          ...child.props,
          subtle: child.props.subtle || subtle,
          size: child.props.size || size,
          danger: child.props.danger || danger,
          key: `item-${i}`
        }
        const componentWithComposedProps = React.cloneElement(child, overrideProps)
        return (<div className='w-field__control w-field__control--expanded'>{componentWithComposedProps}</div>)
      })
    }

    return (
      <div className={componentClass} {...otherProps}>
        {componentLabel}
        <div className='w-field--has-addons'>
          {childrenWithWrapperDiv}
        </div>
        {componentHelpText}
      </div>
    )
  }
}

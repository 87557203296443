import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import blacklist from 'blacklist'

import './MenuItem.scss'
import '../../scss/wham.scss'

import { MENU_SIZES, MENU_TYPES } from '../lib/wham.js'

class MenuItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    /* is this item currently selected */
    selected: PropTypes.bool,
    /* leave blank for default, or 'small' for a smaller menu item */
    size: PropTypes.oneOf(MENU_SIZES),
    /* subtle, default, spaced or contextual */
    type: PropTypes.oneOf(MENU_TYPES),
    /** The main MenuItem text */
    label: PropTypes.string,
    /** Additional Text Information, only shown in contextual-mode */
    caption: PropTypes.string,
    href: PropTypes.string,
    children: PropTypes.any
  }

  render () {
    const {
      size,
      selected,
      className,
      type,
      label,
      caption,
      href,
      children,
      ...otherProps
    } = this.props
    // classes
    const componentClass = classNames(
      'w-menu-list__item',
      `w-menu-list__item--${type}`,
      (size && `w-menu-list__item--${size}`),
      (selected && 'w-menu-list__item--selected'),
      className
    )

    // props
    var props = blacklist(this.props, 'subtle', 'selected', 'type', 'size', 'component', 'className', 'label', 'caption', 'href')
    props.className = componentClass

    var tag = href ? 'a' : 'span'

    var menuContent = React.createElement(tag, { href: href, className: 'w-menu-list__item__link' }, <span><span className='w-menu-list__item__link__label'>{label}</span> {caption ? <span className='w-menu-list__item__link__caption'>{caption}</span> : null}</span>)
    menuContent = children ? <span className='w-menu-list__item__link'><span className='w-menu-list__item__link__label'>{children}</span></span> : menuContent

    return (
      <li className={componentClass} {...otherProps}>
        {menuContent}
      </li>
    )
  }
};

MenuItem.defaultProps = {
  type: 'default'
}

export default MenuItem

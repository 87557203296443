export default [
  {
    name: 'bookmarks',
    description: 'Bookmarks',
    control: 'bookmarks'
  },
  {
    name: 'platform',
    description: 'Platforms',
    control: 'platforms'
  },
  {
    name: 'pod',
    description: 'Pods',
    control: 'pods'
  },
  {
    name: 'rule',
    description: 'Rules',
    control: 'rules'
  },
  {
    name: 'duration',
    description: 'Break Durations',
    control: 'durations'
  },
  {
    name: 'source',
    description: 'Prefetching',
    control: 'sources'
  },
  {
    name: 'asset',
    description: 'Event Names',
    control: 'assets'
  },
  {
    name: 'fillPercent',
    description: 'Fill Percent',
    control: 'fills'
  },
  {
    name: 'status',
    description: 'Status',
    control: 'status'
  }
]

import React from 'react'
import PropTypes from 'prop-types'

import UriListItem from './uri-list-item'

import './style.css'

const UriList = ({
  uris,
  results,
  selected,
  selectResult,
  removeResult,
  clearAllResults
}) => {
  return (
    <div className='uri-list'>
      {uris.length ? (
        <div className='uri-list__header'>
          <p>Validated Assets
            <a href='#' onClick={e => {
              e.preventDefault()
              clearAllResults()
            }}
            >Clear all</a>
          </p>
        </div>
      ) : null}
      <ul className='uri-list__list'>
        {uris.map(uri => (
          <UriListItem key={uri} uri={uri} result={results[uri]} selected={selected} selectResult={selectResult} removeResult={removeResult}/>
        ))}
      </ul>
    </div>
  )
}

UriList.propTypes = {
  uris: PropTypes.array,
  results: PropTypes.object,
  selected: PropTypes.string,
  selectResult: PropTypes.func,
  removeResult: PropTypes.func,
  clearAllResults: PropTypes.func
}

export default UriList

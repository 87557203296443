import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment-timezone'

import { TZ } from '../../constants'
import { ViewContext } from '../../context'
import { expandPathTemplate } from '../../lib/path-matcher'
import { paths } from '../../route-paths'

import * as Wham from '../../wham/components'

import CreativeIcon from '../creative-icon'
import CreativeVastPath from '../creative-vast-path'
import CreativeSummaryRecordControls from './creative-summary-record-controls'

import './style.css'

const CreativeSummaryRecord = ({
  record,
  play,
  redo,
  pending,
  onClick,
  onCopy,
  isCopied,
  onPlay,
  onRedo,
  onValidate
}) => {
  const { partner } = useContext(ViewContext)

  const timeoutTooltip = useRef()

  const [tooltip, setTooltip] = useState(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeoutTooltip.current)
    }
  }, [])

  useEffect(() => {
    clearTimeout(timeoutTooltip.current)
  }, [record.status])

  const onMouseOver = e => {
    const target = e.target
    timeoutTooltip.current = setTimeout(() => {
      setTooltip(target)
    }, 1000)
  }

  const onMouseOut = () => {
    clearTimeout(timeoutTooltip.current)
    setTooltip(null)
  }

  const {
    id,
    status,
    mezzFile,
    errorMsg
  } = record

  const path = expandPathTemplate(paths.creative, { partnerId: partner, creativeId: id })
  const vastIds = record.vastIds.slice(0, 3)
  const timestamp = moment(record.epoch).tz(TZ).format('M/D/YYYY @ h:mm:ss a')
  const duration = record.duration ? `${record.duration} seconds` : ''

  return (
    <a className='creative-summary-record' href={path} onClick={e => {
      e.preventDefault()
      onClick(path)
    }}>
      <div className='creative-summary-record__tile'>
        <div className='creative-summary-record__head'>
          <CreativeIcon status={status} />

          <CopyToClipboard text={mezzFile} onCopy={() => onCopy('mezz')}>
            <span className={classNames('creative-summary-record__head-mezz creative-summary-record__copyable', { 'creative-summary-record__copied': isCopied('mezz') })} onClick={e => e.preventDefault() || e.stopPropagation()}>{mezzFile}</span>
          </CopyToClipboard>
        </div>

        <div className='creative-summary-record__vasts'>
          {vastIds.map((vast, offset) => (
            <div className='creative-summary-record__vast' key={offset}>
              <CopyToClipboard text={vast.id} onCopy={() => onCopy('vast', offset)}>
                <span className={classNames('creative-summary-record__vast-id creative-summary-record__copyable', { 'creative-summary-record__copied': isCopied('vast', offset) })} onClick={e => e.preventDefault() || e.stopPropagation()}>{vast.id}</span>
              </CopyToClipboard>
            </div>
          ))}
        </div>

        <div className='creative-summary-record__paths'>
          {vastIds.map((vast, offset) => {
            return (
              <div className='creative-summary-record__vast-paths' key={offset}>
                <CreativeVastPath className='creative-summary-record__vast-paths' path={vast.path} />
              </div>
            )
          })}
        </div>

        <div className='creative-summary-record__timestamp'>
          <CopyToClipboard text={timestamp} onCopy={() => onCopy('timestamp')}>
            <span className={classNames('creative-summary-record__timestamp-text creative-summary-record__copyable', { 'creative-summary-record__copied': isCopied('timestamp') })} onClick={e => e.preventDefault() || e.stopPropagation()}>{timestamp}</span>
          </CopyToClipboard>
        </div>

        <div className='creative-summary-record__duration'>
          <span className='creative-summary-record__duration-text'>{duration}</span>
        </div>

        <CreativeSummaryRecordControls
          record={record}
          play={play}
          redo={redo}
          pending={pending}
          onPlay={onPlay}
          onRedo={onRedo}
          onValidate={onValidate}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />

        <div className='creative-summary-record__error'>
          {errorMsg && status === 'Blocked' ? (
            <CopyToClipboard text={errorMsg} onCopy={() => onCopy('error')}>
              <span className={classNames('creative-summary-record__error-text creative-summary-record__copyable', { 'creative-summary-record__copied': isCopied('error') })} onClick={e => e.preventDefault() || e.stopPropagation()}>Error</span>
            </CopyToClipboard>
          ) : null}
        </div>
      </div>

      {tooltip ? (
        <Wham.Tooltip trigger={tooltip} />
      ) : null}
    </a>
  )
}

CreativeSummaryRecord.propTypes = {
  record: PropTypes.object.isRequired,
  play: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    playing: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  redo: PropTypes.shape({
    pending: PropTypes.bool.isRequired,
    complete: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
  }).isRequired,
  pending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  isCopied: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onRedo: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired
}

export default CreativeSummaryRecord

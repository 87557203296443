import * as SAGA from 'redux-saga/effects'
import moment from 'moment-timezone'

import safe from '../safe'

import { success, failure } from '../../actions/async'
import {
  FETCH_CURRENT_DEBUG_SUMMARIES,
  FETCH_FURTHER_DEBUG_SUMMARIES,
  FETCH_PENDING_DEBUG_SUMMARIES
} from '../../actions/debug'
import { logoutAction } from '../../actions/login'

import { TZ } from '../../constants'

import {
  selectActivePartner,
  selectDebugEpochNow,
  selectDebugEpochFrom,
  selectDebugEpochTo,
  selectDebugLogFacets,
  selectDebugSearchQuery
} from '../../selectors'

import { queryDebugSummaries } from '../../services/debugging/graphql'

import delay from '../../lib/delay'

import { LOCATION_CHANGED } from '../navigation-side-effects'

const createFacets = logFacets => {
  const facets = logFacets
    .map(logFacet => {
      const options = logFacet.options
        .filter(option => option.selected)
        .map(option => option.key)

      return {
        name: logFacet.name,
        options
      }
    })
    .filter(facet => facet.options.length > 0)

  return facets.length ? facets : undefined
}

function * fetchDebugSummariesTask (overrides) {
  const partner = yield selectActivePartner
  const epochFrom = yield selectDebugEpochFrom(partner)
  const epochTo = yield selectDebugEpochTo(partner)
  const logFacets = yield selectDebugLogFacets(partner)
  const searchQuery = yield selectDebugSearchQuery(partner)
  const from = overrides.from || epochFrom || undefined
  const to = overrides.to || epochTo || undefined
  const query = searchQuery || undefined
  const offset = overrides.offset || undefined
  const facets = createFacets(logFacets)

  return yield SAGA.call(queryDebugSummaries, {
    from,
    to,
    query,
    offset,
    facets
  })
}

export const fetchCurrentDebugSummariesTask = function * () {
  try {
    const data = yield * fetchDebugSummariesTask({})
    yield SAGA.put(success(FETCH_CURRENT_DEBUG_SUMMARIES, { data }))
  } catch (error) {
    yield SAGA.put(failure(FETCH_CURRENT_DEBUG_SUMMARIES, { error }))
  }
}

export const fetchFurtherDebugSummariesTask = function * ({ payload: { offset } }) {
  try {
    const data = yield * fetchDebugSummariesTask({ offset })

    yield SAGA.put(success(FETCH_FURTHER_DEBUG_SUMMARIES, { data }))
  } catch (error) {
    yield SAGA.put(failure(FETCH_FURTHER_DEBUG_SUMMARIES, { error }))
  }
}

export const fetchPendingDebugSummariesTask = function * () {
  while (true) {
    yield SAGA.call(delay, 10000)

    try {
      const partner = yield selectActivePartner
      const now = yield selectDebugEpochNow(partner)

      const data = yield * fetchDebugSummariesTask({ from: now, to: moment().tz(TZ).valueOf() })

      yield SAGA.put(success(FETCH_PENDING_DEBUG_SUMMARIES, { data }))
    } catch (error) {
      if (error.networkError && error.networkError.statusCode === 401) {
        yield SAGA.put(logoutAction) // User's token has expired so stop polling and force new login...
        break
      }

      yield SAGA.put(failure(FETCH_PENDING_DEBUG_SUMMARIES, { error }))
    }
  }
}

export default function * debugging () {
  yield SAGA.takeEvery([FETCH_CURRENT_DEBUG_SUMMARIES.default], safe(fetchCurrentDebugSummariesTask, 'fetchCurrentDebugSummariesTask'))
  yield SAGA.takeEvery([FETCH_FURTHER_DEBUG_SUMMARIES.default], safe(fetchFurtherDebugSummariesTask, 'fetchFurtherDebugSummariesTask'))

  while (true) {
    yield SAGA.take(FETCH_PENDING_DEBUG_SUMMARIES.default)

    yield SAGA.race({
      task: SAGA.call(safe(fetchPendingDebugSummariesTask, 'fetchPendingDebugSummariesTask')),
      cancel: SAGA.take(LOCATION_CHANGED)
    })
  }
}

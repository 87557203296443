import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Menu from '../../../Menu'
import Icon from '../../../Icon'

import './PartnerSelector.scss'

const noop = () => {}

class PartnerSelector extends React.Component {
  static propTypes = {
    partners: PropTypes.array,
    selectPartner: PropTypes.func,
    active: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    appName: PropTypes.string,
    screenName: PropTypes.string
  }

  constructor (props) {
    super(props)
    const { active = false } = props
    this.state = {
      active
    }
  }

  toggleMenu = () => {
    this.setState({
      ...this.state,
      active: !this.state.active
    })
  }

  collapseMenu = () => {
    this.setState({
      ...this.state,
      active: false
    })
  }

  changePartner = (partner, e) => {
    const { selectPartner = noop } = this.props

    this.setState({
      ...this.state,
      active: false
    })

    selectPartner(partner, e)
  }

  render () {
    const {
      partners = [],
      children,
      appName,
      screenName,
      className,
      selectPartner,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-partner-selector',
      (this.state.active ? 'w-partner-selector--active' : null),
      (!children ? 'w-partner-selector--empty' : null),
      className
    )

    const selectedPartner = partners.find(p => p.selected) || {}
    const { logo: SelectedLogo, title: SelectedTitle } = selectedPartner

    return (
      <div
        className={componentClass}
        tabIndex='0'
        onBlur={this.collapseMenu}
        {...otherProps}
      >
        <div className='w-partner-selector__selected' onClick={this.toggleMenu}>
          <div className='w-partner-selector__selected__content'>
            {this.state.active
              ? <Icon color='#fff' iconName='close' />
              : (SelectedLogo ? React.createElement(SelectedLogo, { size: 'small' }) : 'Partner')
            }
          </div>
        </div>
        {this.state.active
          ? <div className='w-partner-selector__menu'>
            <Menu>
              {partners.map(({ logo: Logo, ...partner }) => {
                return (
                  <li
                    key={partner.title}
                    onClick={e => this.changePartner(partner, e)}
                    className={
                      SelectedTitle !== partner.title
                        ? 'w-partner-selector__menu__item'
                        : 'w-partner-selector__menu__item w-partner-selector__menu__item--selected'
                    }
                  >
                    <Logo monochrome />
                    <span className='w-partner-selector__menu__item__text'>{partner.title}</span>
                  </li>
                )
              })}
            </Menu>
          </div>
          : null}
      </div>
    )
  }
}

export default PartnerSelector

import moment from 'moment-timezone'

import { TZ } from '../../../constants'

const adjust = (original, tz, revert = false) => {
  const offset = moment(original).tz(TZ).utcOffset() - moment(original).tz(tz).utcOffset()

  if (revert) {
    return moment(original).subtract(offset, 'minutes').valueOf()
  }

  return moment(original).add(offset, 'minutes').valueOf()
}

const toAdjustedEpoch = (originalDate, tz) => {
  const originalEpoch = originalDate.getTime()
  return tz === TZ ? originalEpoch : adjust(originalEpoch, tz, true)
}

const toAdjustedDate = (originalEpoch, tz) => {
  const epoch = tz === TZ ? originalEpoch : adjust(originalEpoch, tz)
  return moment(epoch).tz(TZ).toDate()
}

const roundUpToNearestTimeInterval = (date, timeInterval) => {
  const result = new Date(date)
  const minutes = result.getMinutes()
  const remainder = minutes % timeInterval
  result.setMinutes((minutes - remainder) + timeInterval, 0, 0)
  return result
}

const withoutMilliseconds = date => {
  const result = new Date(date)
  result.setMilliseconds(0)
  return result
}

export {
  roundUpToNearestTimeInterval,
  toAdjustedEpoch,
  toAdjustedDate,
  withoutMilliseconds
}

import * as R from 'ramda'
import { config } from '../../services/config'

const insightsBetaConfig = config.insightsBetaConfig || {}

export const getInsightsBetaWidgets = (partnerId, duration) => {
  const dict = insightsBetaConfig[partnerId] || {}
  const widgets = dict[duration] || []
  return widgets
}

export const isInsightsBetaConfigured = partnerId => {
  const dict = insightsBetaConfig[partnerId] || {}
  return R.toPairs(dict).length > 0
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Heading.scss'
import '../../scss/wham.scss'

const HEADING_LEVEL = [
  1,
  2,
  3,
  4,
  5
]

export default class Heading extends React.Component {
  static displayName = 'Heading'
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    level: PropTypes.oneOf(HEADING_LEVEL),
    inverse: PropTypes.bool
  }

  render () {
    const {
      className,
      children,
      level,
      inverse,
      ...otherProps
    } = this.props
    // classes
    var componentClass = classNames(
      'w-heading',
      (level >= 1 && `w-heading--level-${level}`),
      (inverse ? 'w-heading--inverse' : null),
      className
    )

    return (
      <span className={componentClass} {...otherProps}>{children}</span>
    )
  }
}

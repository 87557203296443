/* eslint-disable react/display-name */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as R from 'ramda'

import { ViewContext } from '../../context'
import { useDocumentTitle } from '../../lib/custom-hooks'

import Header from '../../containers/header'
import HtmlToReact from 'html-to-react'

import * as Wham from '../../wham/components'

import * as selectors from '../../selectors'

import { paths } from '../../route-paths'
import { expandPathTemplate } from '../../lib/path-matcher'
import { goTo } from '../../actions/navigators'

import './style.css'

// 'publicDocs' is a symbolic link to 'public/docs' because we can't import
// directly from the public folder due to it lying outside the src folder.
import overview from './publicDocs/overview.html'
import rules from './publicDocs/rules.html'
import debugging from './publicDocs/debugging.html'
import reporting from './publicDocs/reporting.html'
import assetValidator from './publicDocs/asset-validator.html'
import creativeAssets from './publicDocs/creative-assets.html'
import usingCharlesProxy from './publicDocs/using-charles-proxy.html'
import serverSideState from './publicDocs/server-side-state.html'
import prefetching from './publicDocs/prefetching.html'
import simulator from './publicDocs/simulator.html'
import insights from './publicDocs/insights.html'
import aps from './publicDocs/aps.html'

const topicDictionary = {
  overview,
  rules,
  debugging,
  reporting,
  'asset-validator': assetValidator,
  'creative-assets': creativeAssets,
  'using-charles-proxy': usingCharlesProxy,
  'server-side-state': serverSideState,
  prefetching,
  simulator,
  insights,
  aps
}

const makeHeading = (children, level) =>
  <Wham.Heading level={level}>{children}</Wham.Heading>

const styleObjectFromStyleString = styleString =>
  R.fromPairs(
    styleString
      .split(';')
      .filter(Boolean)
      .map(s => s.split(':').map(s => s.trim())))

const parseHtml = (dispatch, html) => {
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
  const processingInstructions = [
    {
      shouldProcessNode: node => node.name === 'h1',
      processNode: (_, children) => makeHeading(children, 1)
    },
    {
      shouldProcessNode: node => node.name === 'h2',
      processNode: (_, children) => makeHeading(children, 2)
    },
    {
      shouldProcessNode: node => node.name === 'h3',
      processNode: (_, children) => makeHeading(children, 3)
    },
    {
      shouldProcessNode: node => node.name === 'h4',
      processNode: (_, children) => makeHeading(children, 4)
    },
    {
      shouldProcessNode: node => node.name === 'h5',
      processNode: (_, children) => makeHeading(children, 5)
    },
    {
      shouldProcessNode: node => node.name === 'a',
      processNode: (node, children) => {
        const { href, ...otherAttribs } = node.attribs
        if (href.startsWith('http')) {
          return <a href={href} {...otherAttribs}>{children}</a>
        } else {
          const topic = href.replace(/\.html/, '')
          const path = expandPathTemplate(paths.documentation, { topic })
          return (
            <a
              href={path}
              {...otherAttribs}
              onClick={e => {
                e.preventDefault()
                dispatch(goTo({ to: path }))
              }}
            >
              {children}
            </a>
          )
        }
      }
    },
    {
      shouldProcessNode: node => node.name === 'img',
      processNode: node => {
        const { src, style: styleString, ...otherAttribs } = node.attribs
        if (styleString) {
          const style = styleObjectFromStyleString(styleString)
          return <img src={`/docs/${src}`} style={style} {...otherAttribs} />
        } else {
          return <img src={`/docs/${src}`} {...otherAttribs} />
        }
      }
    },
    {
      shouldProcessNode: node => node.name === 'ul',
      processNode: (_, children) =>
        <ul className='w-markdown__ul'>{children}</ul>
    },
    {
      shouldProcessNode: node => node.name === 'ol',
      processNode: (_, children) =>
        <ol className='w-markdown__ol'>{children}</ol>
    },
    {
      shouldProcessNode: node => node.name === 'blockquote',
      processNode: (_, children) =>
        <blockquote className='w-markdown__blockquote'>{children}</blockquote>
    },
    {
      shouldProcessNode: R.T,
      processNode: processNodeDefinitions.processDefaultNode
    }
  ]
  const htmlToReactParser = new HtmlToReact.Parser()
  const reactElements = htmlToReactParser.parseWithInstructions(html, R.T, processingInstructions)
  return reactElements
}

const DocumentationView = ({
  claims,
  features,
  roles,
  partner,
  swid,
  topic,
  dispatch
}) => {
  useDocumentTitle('Documentation')

  const context = {
    claims,
    features,
    roles,
    partner,
    swid
  }

  const html = topicDictionary[topic] || overview
  const reactElements = parseHtml(dispatch, html)

  window.scrollTo(0, 0)

  return (
    <ViewContext.Provider value={context}>
      <div className='documentation-view'>
        <div className='documentation-view__header-row'>
          <Header />
        </div>

        <div className='documentation-view__content-row'>
          {reactElements}
        </div>
      </div>
    </ViewContext.Provider>
  )
}

DocumentationView.propTypes = {
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  partner: PropTypes.string.isRequired,
  swid: PropTypes.shape({
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  topic: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const claims = selectors.userClaimSelector(state)
  const features = selectors.userFeaturesSelector(state)
  const roles = selectors.userRolesSelector(state)
  const partner = selectors.activePartnerGlobalSelector(state)
  const swid = selectors.swidPartnerSelector(partner)(state)
  const topic = ownProps.match.params.topic

  return {
    claims,
    features,
    roles,
    partner,
    swid,
    topic
  }
}

export default connect(mapStateToProps)(DocumentationView)

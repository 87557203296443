import * as R from 'ramda'
import { SELECTED_TO_PLAY } from '../../../constants'
import { buildSgmntToAdNumber, getBitrate, getChunk, getChunkDur, getEventTime, getSgmnt } from '../../../lib/playlist'
import { sortMediaRequests } from '../../../lib/debug'
import { getSummaryData, getGamNetworkId } from '../../debug/getTransactionDetails'

// Partly getTransactionDetails (src/views/debug/getTransactionDetails.js)
// Partly updateTransactionDetails (src/views/debug/debug-transaction/modern/util/index.js)
// The plan is to get rid of most of this when we remove the following code:
// - pre-facets Debugging list view
// - pre-GraphQL Debugging details view
// Here, we want to do the absolute minimum to take a debuglog from the GraphQL response
// and make it compatible with the existing Debugging components that expect all sorts of
// bits and pieces in all sorts of strange places.
export const makeTransactionDetails = (partner, claims, debuglog) => {
  const {
    adDecisionServiceData,
    adEngineDecisions
  } = debuglog

  const mediaRequests = sortMediaRequests(debuglog.mediaRequests)
  const adsRequestUrl = adDecisionServiceData?.request?.url ?? ''
  const adsRawResponseXML = adDecisionServiceData?.response?.data ?? ''
  const adsRequestCookies = adDecisionServiceData?.request?.cookies ?? []
  const adsRequestHeaders = adDecisionServiceData?.request?.headers ?? []

  const gamNetworkId = getGamNetworkId(adsRequestUrl)

  const augmentedMediaRequests = mediaRequests.map(mediaRequest => {
    return {
      ...mediaRequest,
      $bitrate: getBitrate(mediaRequest),
      $chunk: getChunk(mediaRequest),
      $chunkdur: getChunkDur(mediaRequest),
      $eventTime: getEventTime(mediaRequest),
      $sgmnt: getSgmnt(mediaRequest)
    }
  })

  const [selectedAds, skippedAds] = R.partition(({ status }) => status._type === SELECTED_TO_PLAY, adEngineDecisions ?? [])

  const viewedAds = selectedAds.filter(selectedAd => {
    return augmentedMediaRequests.some(({ response }) => {
      return response && response.amsId === selectedAd.amsId
    })
  })

  const sgmntToAdNumber = buildSgmntToAdNumber(selectedAds, augmentedMediaRequests)
  const firstSgmntNumber = augmentedMediaRequests.length ? Number(augmentedMediaRequests[0].$sgmnt) : 0

  const focusedLog = {
    ...debuglog,
    settoken: debuglog.session?.setToken,
    aps: debuglog.session?.aps
  }

  const summaryData = getSummaryData(partner, claims, focusedLog, debuglog.ruleId, debuglog.ruleName)

  return {
    transactionId: debuglog.transactionId,
    prevTransactionId: undefined,
    nextTransactionId: undefined,
    selectedAds,
    skippedAds,
    viewedAds,
    focusedLog,
    adEDecisions: adEngineDecisions,
    adsRawResponseXML,
    adsRequestHeaders,
    adsRequestCookies,
    adsRequestUrl,
    gamNetworkId,
    tsData: augmentedMediaRequests,
    sgmntToAdNumber,
    firstSgmntNumber,
    summaryData
  }
}

import * as R from 'ramda'

import {
  CLEAR_CREATE_RULE,
  CLONE_RULE,
  CLEAR_ALERT,
  SET_RULE_ORDER,
  FETCH_GLOBAL_RULE,
  FETCH_RULES,
  EDIT_RULE_MACRO,
  SAVE_RULE,
  TOGGLE_RULE_IS_ACTIVE,
  SAVE_GLOBAL_RULE,
  EDIT_RULE_NAME,
  UPDATING_CLEAR,
  UPDATING_DEFINE,
  UPDATING_EVENT,
  SET_EXPRESSION
} from '../../actions/rules'

import { CREATE_RULE_STRING } from '../../route-paths'

const BLANK_RULE = {
  id: CREATE_RULE_STRING,
  active: false,
  name: '',
  evaluationResult: '',
  expression: undefined
}

const defaultState = {
  pendingGlobal: false,
  pendingStandard: false,
  pending: false,
  updating: {
    active: null,
    count: 1 // Allow for initial load...
  },
  alert: false,
  error: false,
  partnerId: '',
  rules: [BLANK_RULE],
  revert: {
    lastFetch: {},
    lastState: {}
  }
}

const pendingFlags = (state, newFlags = {}) => {
  const pendingGlobal = newFlags.pendingGlobal ?? state.pendingGlobal
  const pendingStandard = newFlags.pendingStandard ?? state.pendingStandard
  return {
    pendingGlobal,
    pendingStandard,
    pending: pendingGlobal || pendingStandard
  }
}

const updateRule = (rules, predicate, fn) => {
  const index = R.findIndex(predicate, rules)

  if (index >= 0) {
    return R.adjust(index, fn, rules)
  }

  return rules
}

export default (state = defaultState, action) => {
  const { payload = { rules: [] } } = action

  switch (action.type) {
    case CLEAR_CREATE_RULE: {
      return {
        ...state,
        rules: R.map(rule => {
          if (rule.id === CREATE_RULE_STRING) {
            return BLANK_RULE
          }
          return rule
        }, state.rules)
      }
    }

    case CLONE_RULE: {
      const { ruleToClone } = payload
      return {
        ...state,
        rules: R.map(rule => {
          if (rule.id === CREATE_RULE_STRING) {
            return {
              ...R.pick(R.keys(BLANK_RULE), ruleToClone),
              id: CREATE_RULE_STRING,
              name: `[CLONE] ${ruleToClone.name}`,
              active: false,
              cloned: true
            }
          }
          return rule
        }, state.rules)
      }
    }

    case CLEAR_ALERT: {
      return {
        ...state,
        alert: false
      }
    }

    case EDIT_RULE_NAME: {
      const ruleToEdit = payload.rule
      const { value: name } = payload.target
      return ruleToEdit.isGlobal ? ({
        ...state,
        globalRule: { ...state.globalRule, name, isDirty: true },
        alert: false
      }) : ({
        ...state,
        rules: state.rules.map(rule =>
          ruleToEdit.id === rule.id
            ? { ...rule, name }
            : rule),
        alert: false
      })
    }

    case EDIT_RULE_MACRO: {
      const ruleToEdit = payload.rule

      return ruleToEdit.isGlobal ? ({
        ...state,
        globalRule: {
          ...state.globalRule,
          evaluationResult: payload.target.value,
          isDirty: true
        },
        alert: false
      }) : ({
        ...state,
        rules: state.rules.map(rule =>
          ruleToEdit.id === rule.id
            ? {
              ...rule,
              evaluationResult: payload.target.value,
              isDirty: true
            }
            : rule),
        alert: false
      })
    }

    case SET_RULE_ORDER: {
      const rules = Array.isArray(payload) ? payload : state.rules
      const isValidForPriority = rule => rule && rule.id !== CREATE_RULE_STRING

      return {
        ...state,
        rules: rules.map((rule, i) => {
          const previousRule = rules[i - 1]
          const nextRule = rules[i + 1]
          const maybeBefore = isValidForPriority(nextRule) ? { before: nextRule.id } : undefined
          const maybeAfter = isValidForPriority(previousRule) ? { after: previousRule.id } : undefined
          const priority = { ...maybeBefore, ...maybeAfter }

          return {
            ...rule,
            displayPriority: i + 1,
            priority
          }
        }),
        alert: false
      }
    }

    case UPDATING_CLEAR: {
      return {
        ...state,
        updating: {
          active: null,
          count: 0
        }
      }
    }

    case UPDATING_DEFINE: {
      return {
        ...state,
        updating: {
          active: payload.active,
          count: 2 // Wait for two FETCH_RULES events to complete...
        }
      }
    }

    case UPDATING_EVENT: {
      const count = state.updating.count - 1

      return {
        ...state,
        updating: {
          active: count ? state.updating.active : null,
          count: count
        }
      }
    }

    case FETCH_GLOBAL_RULE.default: {
      return {
        ...state,
        ...pendingFlags(state, { pendingGlobal: true }),
        error: false
      }
    }

    case FETCH_GLOBAL_RULE.success: {
      const globalRule = {
        ...payload.globalRule,
        isDirty: false,
        isGlobal: true,
        id: 'global'
      }

      const revert = {
        ...state.revert,
        lastFetch: {
          ...state.revert.lastFetch,
          globalRule: R.clone(globalRule)
        }
      }

      return {
        ...state,
        globalRule,
        revert,
        ...pendingFlags(state, { pendingGlobal: false }),
        error: false
      }
    }

    case FETCH_GLOBAL_RULE.failure: {
      return {
        ...state,
        ...pendingFlags(state, { pendingGlobal: false }),
        error: action.error.message
      }
    }

    case SAVE_GLOBAL_RULE.default: {
      return {
        ...state,
        ...pendingFlags(state, { pendingGlobal: true }),
        alert: false,
        error: false
      }
    }

    case SAVE_GLOBAL_RULE.success: {
      const revert = {
        ...state.revert,
        lastState: {
          ...state.revert.lastState,
          globalRule: R.clone(R.defaultTo({}, state.revert.lastFetch.globalRule))
        }
      }

      const globalRule = {
        ...payload.rule,
        isDirty: false,
        isGlobal: true,
        id: 'global'
      }

      revert.lastFetch = {
        ...revert.lastFetch,
        globalRule: R.clone(globalRule)
      }

      const alert = !payload.revert

      return {
        ...state,
        globalRule,
        revert,
        ...pendingFlags(state, { pendingGlobal: false }),
        alert: alert,
        error: false
      }
    }

    case SAVE_GLOBAL_RULE.failure: {
      return {
        ...state,
        ...pendingFlags(state, { pendingGlobal: false }),
        alert: false,
        error: action.error.message
      }
    }

    case SAVE_RULE.default: {
      return {
        ...state,
        ...pendingFlags(state, { pendingStandard: true }),
        alert: false,
        error: false
      }
    }

    case SAVE_RULE.success: {
      const revert = {
        ...state.revert,
        lastState: R.clone(state.revert.lastFetch)
      }

      const alert = !payload.revert

      return {
        ...state,
        revert,
        ...pendingFlags(state, { pendingStandard: false }),
        alert: alert,
        error: false
      }
    }

    case SAVE_RULE.failure: {
      return {
        ...state,
        ...pendingFlags(state, { pendingStandard: false }),
        alert: false,
        error: action.error.message
      }
    }

    case FETCH_RULES.default: {
      return {
        ...state,
        ...pendingFlags(state, { pendingStandard: true }),
        error: false
      }
    }

    case FETCH_RULES.success: {
      const transformedRules = payload.rules.map((rule, index) => {
        return {
          ...rule,
          displayPriority: index + 1,
          isDirty: false
        }
      })

      const createRule = R.find(({ id }) => id === CREATE_RULE_STRING, state.rules) || BLANK_RULE
      const rules = R.append(createRule, transformedRules)

      const revert = {
        ...state.revert,
        lastFetch: {
          ...state.revert.lastFetch,
          rules: R.clone(rules)
        }
      }

      return {
        ...state,
        rules,
        revert,
        ...pendingFlags(state, { pendingStandard: false }),
        error: false
      }
    }

    case FETCH_RULES.failure: {
      return {
        ...state,
        ...pendingFlags(state, { pendingStandard: false }),
        error: action.error.message
      }
    }

    case SET_EXPRESSION: {
      return {
        ...state,
        rules: state.rules.map(rule =>
          rule.id === payload.rule.id
            ? { ...rule, expression: payload.expression }
            : rule),
        alert: false
      }
    }

    case TOGGLE_RULE_IS_ACTIVE.default: {
      return {
        ...state,
        ...pendingFlags(state, { pendingStandard: true }),
        error: false
      }
    }

    case TOGGLE_RULE_IS_ACTIVE.success: {
      const revert = {
        ...state.revert,
        lastState: {
          ...state.revert.lastState,
          rules: R.clone(R.defaultTo([], state.revert.lastFetch.rules))
        }
      }

      const rules = updateRule(
        state.rules,
        R.propEq('id', payload.rule.id),
        rule => ({
          ...rule,
          ...payload.rule,
          isDirty: false
        })
      )

      revert.lastFetch = {
        ...revert.lastFetch,
        rules: R.clone(rules)
      }

      const alert = payload.rule.active

      return {
        ...state,
        rules,
        revert,
        ...pendingFlags(state, { pendingStandard: false }),
        alert: alert,
        error: false
      }
    }

    case TOGGLE_RULE_IS_ACTIVE.failure: {
      return {
        ...state,
        ...pendingFlags(state, { pendingStandard: false }),
        error: action.error.message
      }
    }

    default:
      return state
  }
}

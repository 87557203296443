import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'

import { gql, useQuery } from '@apollo/client'

import * as Wham from '../../wham/components'

import { TZ } from '../../constants'

import styles from './index.module.scss'

export const GQL_LINE_ITEM = gql`
  query LineItem($id: String!) {
    lineItem(id: $id) {
      id
      name
      creationDateTime
      startDateTime
      endDateTime
      deliveryRateType
      lineItemType
      priority
      status
      costType
      costPerUnit {
        currencyCode
        amount
      }
      videoMaxDuration
    }
  }
`

const DATETIME_FORMAT = 'M/D/YYYY @ h:mm:ss a z'

const CreativeDetailTrafficking = ({
  id
}) => {
  const { data: { lineItem = {} } = {}, loading, error, refetch } = useQuery(GQL_LINE_ITEM, {
    variables: {
      id
    }
  })

  const {
    name,
    creationDateTime,
    startDateTime,
    endDateTime,
    deliveryRateType,
    lineItemType,
    priority,
    status,
    costType,
    costPerUnit = {},
    videoMaxDuration
  } = lineItem || {}

  const {
    currencyCode,
    amount
  } = costPerUnit

  const createdDate = creationDateTime ? moment(creationDateTime).tz(TZ).format(DATETIME_FORMAT) : null
  const startDate = startDateTime ? moment(startDateTime).tz(TZ).format(DATETIME_FORMAT) : null
  const endDate = endDateTime ? moment(endDateTime).tz(TZ).format(DATETIME_FORMAT) : null

  return (
    <section className={styles.trafficking}>
      <header className={styles.header}>
        <h2 className={styles.header_title}>ADS Trafficking Details</h2>
      </header>

      <article className={styles.left}>
        {loading ? (
          <div className={styles.loader}>
            <Wham.ProgressCircular type='indeterminate' active={loading} sqSize={24} />
          </div>
        ) : error ? (
          <h3 className={styles.error}>
            <span className={styles.error_text}>Failed to retrieve data</span>
            <span className={styles.error_again}>
              <span>(click</span>
              <span className={styles.error_action} onClick={() => refetch()}>here</span>
              <span>to try again)</span>
            </span>
          </h3>
        ) : lineItem === null ? (
          <h3 className={styles.empty}>No Data to Display</h3>
        ) : (
          <table className={styles.table}>
            <tbody>
              <tr className={styles.row}>
                <td className={classNames(styles.cell, styles.key)}>Line Item Name</td>
                <td className={classNames(styles.cell, styles.value)}>
                  <div className={styles.truncate}>{name}</div>
                </td>
              </tr>

              <tr className={styles.row}>
                <td className={classNames(styles.cell, styles.key)}>Line Item ID</td>
                <td className={classNames(styles.cell, styles.value)}>{id}</td>
              </tr>

              {deliveryRateType ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Delivery Type</td>
                  <td className={classNames(styles.cell, styles.value)}>{deliveryRateType}</td>
                </tr>
              ) : null}

              {createdDate ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Created Date</td>
                  <td className={classNames(styles.cell, styles.value)}>{createdDate}</td>
                </tr>
              ) : null}

              {startDate ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Campaign Start Date</td>
                  <td className={classNames(styles.cell, styles.value)}>{startDate}</td>
                </tr>
              ) : null}

              {endDate ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Campaign End Date</td>
                  <td className={classNames(styles.cell, styles.value)}>{endDate}</td>
                </tr>
              ) : null}

              <tr className={styles.row}>
                <td className={classNames(styles.cell, styles.key)}>Ad Type</td>
                <td className={classNames(styles.cell, styles.value)}>{lineItemType}</td>
              </tr>

              {priority ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Priority</td>
                  <td className={classNames(styles.cell, styles.value)}>{priority}</td>
                </tr>
              ) : null}

              <tr className={styles.row}>
                <td className={classNames(styles.cell, styles.key)}>Status</td>
                <td className={classNames(styles.cell, styles.value)}>{status}</td>
              </tr>

              <tr className={styles.row}>
                <td className={classNames(styles.cell, styles.key)}>Booked Type</td>
                <td className={classNames(styles.cell, styles.value)}>{costType}</td>
              </tr>

              {amount !== undefined && currencyCode ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Booked Rate</td>
                  <td className={classNames(styles.cell, styles.value)}>{amount + ' ' + currencyCode}</td>
                </tr>
              ) : null}

              {videoMaxDuration ? (
                <tr className={styles.row}>
                  <td className={classNames(styles.cell, styles.key)}>Maximum Video Duration</td>
                  <td className={classNames(styles.cell, styles.value)}>{videoMaxDuration}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        )}
      </article>
    </section>
  )
}

CreativeDetailTrafficking.propTypes = {
  id: PropTypes.string.isRequired
}

export default CreativeDetailTrafficking

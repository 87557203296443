import { push } from 'connected-react-router'
import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

import safe from '../safe'

import { SWITCH_PARTNER_CONTEXT } from '../../actions/partnerContext'
import { selectActivePartner } from '../../selectors'
import { paths } from '../../route-paths'
import { matchPathAndParams, expandPathTemplate } from '../../lib/path-matcher'
import { reduxPeristenceFactory } from '../../lib/reduxPersistence'

const navigateOnPartnerSwitch = function * () {
  const partnerPersistence = reduxPeristenceFactory('PartnerContext')
  const partnerContextPersistence = partnerPersistence.get()
  const partnerId = yield selectActivePartner
  const pathTemplate = paths.dashboard
  const defaultPath = expandPathTemplate(pathTemplate, { partnerId })
  const goToPath = R.pathOr(defaultPath, [partnerId, 'location'], partnerContextPersistence)
  const matchingPathDetails = matchPathAndParams(Object.values(paths), goToPath)
  const path = matchingPathDetails ? goToPath : defaultPath
  return yield SAGA.put(push(path))
}

export default function * partner () {
  while (true) {
    yield SAGA.take([SWITCH_PARTNER_CONTEXT])
    yield safe(navigateOnPartnerSwitch, 'navigateOnPartnerSwitch')()
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Menu from '../Menu'

import './AppNavigation.scss'
import '../../scss/wham.scss'

class AppNavigation extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    appName: PropTypes.string,
    screenName: PropTypes.string
  }

  constructor (props) {
    super(props)
    this.state = {
      active: false
    }
  }

  toggleMenu = () => {
    this.setState({
      ...this.state,
      active: !this.state.active
    })
  }

  collapseMenu = () => {
    this.setState({
      ...this.state,
      active: false
    })
  }

  expandMenu = () => {
    this.setState({
      ...this.state,
      active: true
    })
  }

  render () {
    const {
      children,
      appName,
      screenName,
      className,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-app-navigation',
      (this.state.active ? 'w-app-navigation--active' : null),
      (!children ? 'w-app-navigation--empty' : null),
      className
    )

    return (

      <div
        className={componentClass}
        tabIndex='0'
        onBlur={this.collapseMenu}
        {...otherProps}
      >
        <div className='w-app-navigation__context' onClick={children ? this.toggleMenu : null}>
          <span className='w-app-navigation__menu-icon material-icons'>menu</span>
          <span className='w-app-navigation__app-name'>{appName}</span>
          <span className='w-app-navigation__app-section'>{screenName}</span>
        </div>
        <div className='w-app-navigation__menu__container'>
          <Menu className='w-app-navigation__menu' type='contextual'>
            {children}
          </Menu>
        </div>
      </div>

    )
  }
}

export default AppNavigation

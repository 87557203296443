import React from 'react'
import PropTypes from 'prop-types'
import NoData from '../no-data'
import DebugKeyValueTable from '../debug-key-value-table'
import * as Wham from '../../wham/components'

const SetTokenTab = ({ settoken = {} }) => {
  const paramsTabTitle = settoken.isPostRequest ? 'Params (POST)' : 'Params (GET)'
  return (
    <Wham.Tabs title='Request'>
      <Wham.Tab label={paramsTabTitle}>
        <DebugKeyValueTable items={settoken.params} dataType='params' />
      </Wham.Tab>

      <Wham.Tab label='URL'>
        <Wham.Copyable>
          <span style={{ wordBreak: 'break-word' }}>
            {settoken.url || <NoData dataType='URL' />}
          </span>
        </Wham.Copyable>
      </Wham.Tab>

      <Wham.Tab label='Cookies'>
        <DebugKeyValueTable items={settoken.cookies} dataType='cookies' />
      </Wham.Tab>

      <Wham.Tab label='Headers'>
        <DebugKeyValueTable items={settoken.headers} dataType='headers' />
      </Wham.Tab>

    </Wham.Tabs>
  )
}

SetTokenTab.propTypes = {
  settoken: PropTypes.shape({
    isPostRequest: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    headers: PropTypes.object.isRequired,
    cookies: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }).isRequired
}

export default SetTokenTab

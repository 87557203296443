const updateLogFacets = (originalFacets, latestFacets) => {
  return latestFacets ? latestFacets.map(latestFacet => {
    const matchedFacet = originalFacets.find(originalFacet => {
      return originalFacet.name === latestFacet.name
    })

    return {
      ...latestFacet,
      options: latestFacet.options.map(latestFacetOption => {
        const matchedFacetOption = matchedFacet ? matchedFacet.options.find(matchedFacetOption => {
          return matchedFacetOption.key === latestFacetOption.key
        }) : undefined

        return {
          ...latestFacetOption,
          selected: matchedFacetOption ? matchedFacetOption.selected : undefined
        }
      })
    }
  }) : originalFacets
}

export {
  updateLogFacets
}

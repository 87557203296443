import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import './style.css'

const ExpandCollapse = ({
  collapsed,
  onClick
}) => {
  const [tooltip, setTooltip] = useState(null)

  return (
    <span className='expand-collapse'>
      <Wham.Button
        className='expand-collapse__button'
        onClick={() => setTooltip(null) || onClick()}
        onMouseOver={e => setTooltip(e.target)}
        onMouseOut={() => setTooltip(null)}
        data-tooltip={`${collapsed ? 'Expand' : 'Collapse'} all`}
      >
        <Wham.Icon className='expand-collapse__button-icon' iconName={`${collapsed ? 'unfold_more' : 'unfold_less'}`} />
      </Wham.Button>

      {tooltip ? (
        <Wham.Tooltip trigger={tooltip} placement='bottom-right' />
      ) : (
        null
      )}
    </span>
  )
}

ExpandCollapse.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ExpandCollapse

import * as R from 'ramda'

import { expandPathTemplate } from '../../../lib/path-matcher'

import { hasRole } from '../../../components/authorized'

const groups = {
  'Rules Management': 'rules',
  Transactions: 'transactions',
  'Creative Management': 'creatives',
  Analytics: 'analytics'
}

const createTiles = (context, locations) => {
  const {
    claims,
    partner
  } = context

  const filteredLocations = R.compose(
    R.filter(({ controller, action }) => controller && action ? hasRole(partner, controller, action, claims) : true),
    R.filter(({ condition }) => condition ? condition(partner) : true),
    R.filter(({ label }) => label !== 'Dashboard')
  )(locations)

  return R.map(location => {
    const { group, label, icon, caption, route, routeParams, externalLink } = location
    const type = groups[group]
    const path = route
      ? expandPathTemplate(route, { partnerId: partner, ...routeParams })
      : undefined

    return {
      type,
      label,
      icon,
      caption,
      path,
      externalLink
    }
  }, filteredLocations)
}

export {
  createTiles
}

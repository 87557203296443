import * as R from 'ramda'
import * as SAGA from 'redux-saga/effects'
import * as qs from 'query-string'
import moment from 'moment-timezone'

import safe from '../safe'

import { APP_INIT } from '../../actions/appInit'
import {
  fetchCurrentDebugSummaries,
  setFinal,
  setPending,
  setGroupBy,
  setFacets,
  updateOpenFilters
} from '../../actions/debug'
import { setFeature } from '../../actions/features'
import { setDebugFormField, setWhitelistFormField } from '../../actions/form'
import { goToDebugResults } from '../../actions/navigators'
import { setActivePartner } from '../../actions/partnerContext'
import { getGlobalRule, getRulesForPartner } from '../../actions/rules'

import * as selectors from '../../selectors'

import { TZ } from '../../constants'
import * as Groups from '../../constants/debug/groups'

import { matchPathAndParams } from '../../lib/path-matcher'

import { paths } from '../../route-paths'

import { cancelOutstandingRequests } from '../../services/api/helpers'

import { changeGroupBy, makeFacets, makeOpenFilters, valid } from './util'

export const LOCATION_CHANGED = '@@router/LOCATION_CHANGE'

export const pathActionTriggers = {
  [paths.viewRules]: function * () {
    yield SAGA.put(getRulesForPartner())
    yield SAGA.put(getGlobalRule())
  },

  [paths.createEditRule]: function * () {
    yield SAGA.put(getRulesForPartner())
    yield SAGA.put(getGlobalRule())
  },

  [paths.debug]: function * ({ location, features }) {
    const search = qs.parse(location.search)

    if (valid(search)) {
      const partner = yield selectors.selectActivePartner
      const epochFrom = yield selectors.selectDebugEpochFrom(partner)
      const epochTo = yield selectors.selectDebugEpochTo(partner)
      const groupBy = yield selectors.selectDebugGroupBy(partner)
      const searchQuery = yield selectors.selectDebugSearchQuery(partner)
      const fetch = yield selectors.selectDebugFetch(partner)
      const from = Number(search.from)
      const to = Number(search.to)

      if (epochFrom !== from) {
        yield SAGA.put(setDebugFormField('epochFrom', from))
        yield SAGA.put(setDebugFormField('duration', 'custom'))
      }

      if (epochTo !== to) {
        yield SAGA.put(setDebugFormField('epochTo', to))
        yield SAGA.put(setDebugFormField('duration', 'custom'))
      }

      if (changeGroupBy(groupBy, search)) {
        yield SAGA.put(setGroupBy(groupBy === Groups.DEFAULT ? Groups.SESSION : Groups.DEFAULT))
      }

      if (!searchQuery && search.query) {
        yield SAGA.put(setDebugFormField('searchQuery', search.query))
      }

      const facets = makeFacets(search)
      const openFilters = makeOpenFilters(search, groupBy)

      yield SAGA.put(setFacets(facets))
      yield SAGA.put(updateOpenFilters(openFilters))

      if (fetch) {
        yield SAGA.put(setFinal(false))
        yield SAGA.put(setPending(true))
        yield SAGA.put(fetchCurrentDebugSummaries())
      }
    } else {
      yield SAGA.put(goToDebugResults({ features }))
    }
  },

  [paths.transaction]: function * ({ type, payload }) {
    if (type === LOCATION_CHANGED && payload.action === 'REPLACE') { // Is this a deep-link load?
      const now = payload.$now || moment().tz(TZ).valueOf()
      const from = moment(now).tz(TZ).startOf('day').valueOf()
      const to = moment(now).tz(TZ).endOf('day').valueOf()

      yield SAGA.put(setDebugFormField('epochNow', now))
      yield SAGA.put(setDebugFormField('epochFrom', from))
      yield SAGA.put(setDebugFormField('epochTo', to))
    }
  },

  [paths.whitelist]: function * ({ location: { state = {} } }) {
    yield SAGA.put(setWhitelistFormField('search', state.search || '')) // Pass through any filtering context Whitelist opened via WhitelistSidebar...
  }
}

const mapToValue = (value) => {
  if (value === 'true') {
    return true
  }

  if (value === 'false') {
    return false
  }

  if (value.includes(',')) {
    return value.split(',')
  }

  return value
}

export function * setFeatures (location) {
  const search = qs.parse(location.search)
  const keys = Object.keys(search)

  for (let i = 0; i < keys.length; i++) {
    const match = keys[i].match(/^feature\[(.*)]$/)

    if (match && match.length > 1) {
      yield SAGA.put(setFeature({ name: match[1], value: mapToValue(search[keys[i]]) }))
    }
  }
}

export function * handleNavigationActions ({ type, payload }) {
  const isLoggedIn = yield selectors.selectUserIsLoggedIn
  if (isLoggedIn) {
    const activePartner = yield selectors.selectActivePartner

    yield SAGA.call(cancelOutstandingRequests, activePartner)

    const location = R.pathOr(window.location, ['location'], payload)
    yield * setFeatures(location)
    const features = yield selectors.selectUserFeatures

    {
      const matchingPathDetails = matchPathAndParams(Object.values(paths), location.pathname)
      if (matchingPathDetails) {
        const [, params] = matchingPathDetails
        const { partnerId } = params
        if (partnerId) {
          const activePartner = yield selectors.selectActivePartner
          if (partnerId !== activePartner) {
            yield SAGA.put(setActivePartner(partnerId))
          }
        }
      }
    }

    const matchingPathDetails = matchPathAndParams(Object.keys(pathActionTriggers), location.pathname)
    if (matchingPathDetails) {
      const [path, params] = matchingPathDetails
      const saga = pathActionTriggers[path]
      yield saga && saga({ type, payload, location, params, features })
    }
  }
}

export default function * navigationSideEffects () {
  yield SAGA.takeEvery(['@@router/LOCATION_CHANGE', APP_INIT], safe(handleNavigationActions, 'handleNavigationActions'))
}

import React from 'react'
import PropTypes from 'prop-types'

import { human } from '../../../../lib/numbers'

import './style.css'

const DebugFiltersMeta = ({
  count,
  total
}) => (
  <div className='debug-filters-meta'>
    {typeof count === 'number' && typeof total === 'number' && count <= total ? (
      <>
        <span className='debug-filters-meta__label'>Showing:</span>
        <span className='debug-filters-meta__value'>{human(count)} of {human(total)}</span>
      </>
    ) : (
      <span className='debug-filters-meta__label'>Filters</span>
    )}
  </div>
)

DebugFiltersMeta.propTypes = {
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default DebugFiltersMeta

/* global localStorage */
export const reduxPeristenceFactory = reducer => {
  if (!reducer) throw new Error('Please specify a reducer')
  return {
    get: key => key && getPersistedReduxState(reducer)
      ? getPersistedReduxState(reducer)[key]
      : getPersistedReduxState(reducer),
    set: setPersistedReduxState(reducer),
    clear: () => clearPersistedReduxState(reducer)
  }
}

export const getPersistedReduxState = (reducer = '') => {
  const persistedState = localStorage.getItem(reducer)
  if (!persistedState) return {}
  return JSON.parse(persistedState)
}

export const clearPersistedReduxState = reducer => {
  if (!reducer) return false
  return localStorage.removeItem(reducer)
}

export const setPersistedReduxState = reducer => action => {
  if (!action) return false
  return localStorage.setItem(reducer, JSON.stringify(action))
}

import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import * as FEATURE from '../../../common/features'
import { ViewContext } from '../../../context'

import { paths } from '../../../route-paths'
import { expandPathTemplate } from '../../../lib/path-matcher'
import { goTo } from '../../../actions/navigators'
import { validateAsset } from '../../../actions/assets'

import './style.css'

const SendToValidator = ({ uri, dispatch }) => {
  const {
    partner: partnerId,
    claims
  } = useContext(ViewContext)

  const [tooltip, setTooltip] = useState(null)

  const onMouseOver = e => {
    setTooltip(e.target)
  }

  const onMouseOut = () => {
    setTooltip(null)
  }

  const onClick = () => {
    const to = expandPathTemplate(paths.assetValidator, { partnerId })
    dispatch(validateAsset({ uri }))
    dispatch(goTo({ to }))
  }

  return claims[partnerId] && claims[partnerId][FEATURE.ASSET_VALIDATOR] ? (
    <span className='send-to-validator'>
      <Wham.Button className='send-to-validator__button' onClick={onClick}>
        <div
          className="send-to-validator__button-icon-wrapper"
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          data-tooltip='Send to Validator'
        >
          <Wham.Icon className='send-to-validator__button-icon' iconName='launch' />
        </div>
        {tooltip && <Wham.Tooltip trigger={tooltip} />}
      </Wham.Button>
    </span>
  ) : null
}

SendToValidator.propTypes = {
  uri: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default SendToValidator

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as Wham from '../../../wham/components'

import './style.css'

const KeyValuePairTable = ({
  base,
  tuples,
  onDelete,
  onInsert,
  onUpdate
}) => {
  return (
    <div className='key-value-pair-table'>
      <Wham.LayoutColumn>
        <div className='key-value-pair-table__url-container'>
          <div className='key-value-pair-table__url-header'>
            <div>
              <h2 className='key-value-pair-table__url-title'>URL</h2>
            </div>
            <div>
              <p className='key-value-pair-table__url'>{base}</p>
            </div>
          </div>
        </div>

        <div className='key-value-pair-table__params-container'>
          <div className='key-value-pair-table__params-header'>
            <div>
              <h2 className='key-value-pair-table__params-title'>Parameters</h2>
            </div>

            {onInsert ? (
              <button className='key-value-pair-table__params-add w-btn' onClick={() => {
                onInsert({})
              }}>
                <span className='key-value-pair-table__params-add-icon w-icon'>add</span>
              </button>
            ) : null}
          </div>

          <table className='key-value-pair-table__table'>
            <tbody>
            {tuples.map((tuple, i) => tuple.key ? (
              <tr
                key={i}
                className='key-value-pair-table__row'
                data-cy='key-value-pair-table__row'
                onClick={() => {
                  onUpdate(tuple, i)
                }}
              >
                <td className='key-value-pair-table__cell key-value-pair-table__col-key'>
                  <span className='key-value-pair-table__key'>{tuple.key}</span>
                </td>
                <td className='key-value-pair-table__cell key-value-pair-table__col-value'>
                  <div className={classNames('key-value-pair-table__value', { 'key-value-pair-table__value--offset': onDelete })}>
                    {tuple.values.map((value, i) => (
                      <span key={i} className={classNames('key-value-pair-table__value-part', { 'key-value-pair-table__value-part--macro': value.type === 'macro' })}>{value.text}</span>
                    ))}
                  </div>
                </td>
                {onDelete ? (
                  <td className='key-value-pair-table__cell key-value-pair-table__col-delete'>
                    <button className='key-value-pair-table__delete w-btn' onClick={e => {
                      e.stopPropagation()
                      onDelete(tuple, i)
                    }}>
                      <span className='key-value-pair-table__delete-icon w-icon'>delete</span>
                    </button>
                  </td>
                ) : null}
              </tr>
            ) : null)}
            </tbody>
          </table>
        </div>

      </Wham.LayoutColumn>
    </div>
  )
}

KeyValuePairTable.propTypes = {
  base: PropTypes.string.isRequired,
  tuples: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onInsert: PropTypes.func,
  onUpdate: PropTypes.func.isRequired
}

export default KeyValuePairTable

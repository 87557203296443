import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ViewContext } from '../../context'
import { useDocumentTitle } from '../../lib/custom-hooks'

import Dashboard from '../../containers/dashboard'
import Header from '../../containers/header'

import * as selectors from '../../selectors'

import './style.css'

const DashboardView = ({
  claims,
  features,
  roles,
  partner,
  swid
}) => {
  useDocumentTitle('Dashboard')

  const context = {
    claims,
    features,
    roles,
    partner,
    swid
  }

  return (
    <ViewContext.Provider value={context}>
      <div className='dashboard-view'>
        <div className='dashboard-view__row'>
          <Header />
        </div>

        <div className='dashboard-view__row'>
          <Dashboard />
        </div>
      </div>
    </ViewContext.Provider>
  )
}

DashboardView.propTypes = {
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  partner: PropTypes.string.isRequired,
  swid: PropTypes.shape({
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  search: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const claims = selectors.userClaimSelector(state)
  const features = selectors.userFeaturesSelector(state)
  const roles = selectors.userRolesSelector(state)
  const partner = selectors.activePartnerGlobalSelector(state)

  const swid = selectors.swidPartnerSelector(partner)(state)

  return {
    claims,
    features,
    roles,
    partner,
    swid
  }
}

export default connect(mapStateToProps)(DashboardView)

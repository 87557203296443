import { push } from 'connected-react-router'
import { paths } from '../../route-paths'
import { expandPathTemplate } from '../../lib/path-matcher'

export const goToTransaction = (partnerId, transactionId) =>
  push(expandPathTemplate(paths.transaction, { partnerId, transactionId }))

export const goToStandardRule = (partnerId, ruleId) =>
  push(expandPathTemplate(paths.createEditRule, { partnerId, ruleId }))

export const goToGlobalRule = (partnerId) =>
  push(expandPathTemplate(paths.createEditGlobalRule, { partnerId }))

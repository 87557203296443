import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Mark.scss'
import '../../../scss/wham.scss'

const BAMTECH_MARK_SIZES = ['large', 'small', 'medium']

class ESPNMark extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(BAMTECH_MARK_SIZES),
    inverse: PropTypes.bool,
    monochrome: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      inverse = false,
      monochrome,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-mark',
      'w-mark--espn',
      (size ? 'w-mark--' + size : null),
      (inverse ? 'w-mark--inverse' : null),
      (!inverse && monochrome ? 'w-mark--monochrome' : null),
      className
    )

    return (
      <span className={componentClass} {...otherProps}>
        <svg xmlns='http://www.w3.org/2000/svg' width='138.349' height='136.583' viewBox='0 0 138.349 136.583'>
          <polygon className='w-mark__element' points='16.769 0 12.063 38.331 133.661 38.331 138.349 0 16.767 0 16.769 0' fill='#ef4236' />
          <polygon className='w-mark__element' points='10.289 52.937 0 136.583 121.59 136.583 126.07 100.296 49.933 100.288 51.859 84.64 127.966 84.64 131.862 52.939 10.296 52.939 10.289 52.937' fill='#ef4236' />
        </svg>
      </span>
    )
  }
};

export default ESPNMark

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as Wham from '../../wham/components'

import parseValidation, { createMessage, createDescription } from '../../lib/asset-validation'
import SpecTableView from '../asset-validator/validation-result/spec-table-view'

import styles from './index.module.scss'

const CreativeDetailValidation = ({
  validation
}) => {
  const message = createMessage(validation)
  const description = createDescription(validation)

  const { passed, error, closestProfile, additionalProfiles } = parseValidation(validation)

  return (
    <section className={styles.validation}>
      <header className={styles.header}>
        <h2 className={styles.header_title}>Validation</h2>

        {validation.complete ? (
          <h3 className={classNames(styles.header_result, { [styles.header_result__failed]: validation.failed })}>
            <span className={styles.header_result_message}>{message}</span>

            {validation.passed ? (
              <span className={styles.header_result_description}>{description}</span>
            ) : null}
          </h3>
        ) : null}
      </header>

      <article className={styles.content}>
        {validation.checking ? (
          <div className={styles.loader}>
            <Wham.ProgressCircular type='indeterminate' active={validation.checking} sqSize={24} />
          </div>
        ) : error ? null : (
          <div className={styles.spec}>
            <SpecTableView
              passed={passed}
              closestProfile={closestProfile}
              additionalProfiles={additionalProfiles}
            />
          </div>
        )}
      </article>
    </section>
  )
}

CreativeDetailValidation.propTypes = {
  validation: PropTypes.shape({
    checking: PropTypes.bool,
    complete: PropTypes.bool.isRequired,
    passed: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired,
    asset: PropTypes.object,
    spec: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
}

export default CreativeDetailValidation

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ProgressCircular from '../ProgressCircular'

import './Button.scss'
import '../../scss/wham.scss'

import {
  WHAM_TYPES as BUTTON_TYPES,
  WHAM_SIZES as BUTTON_SIZES
} from '../lib/wham'

class Button extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    /** Prevents clicking, displays faded */
    disabled: PropTypes.bool,
    className: PropTypes.string,
    component: PropTypes.element,
    /** Should render a link? */
    href: PropTypes.string,
    active: PropTypes.bool,
    /** Can be large, medium or small */
    size: PropTypes.oneOf(BUTTON_SIZES),
    submit: PropTypes.bool,
    /** Can be default, primary, danger or subtle */
    // DEPRECATED: Will be removed in favor of bools
    type: PropTypes.oneOf(BUTTON_TYPES),
    danger: PropTypes.bool,
    primary: PropTypes.bool,
    subtle: PropTypes.bool,
    progress: PropTypes.bool,
    inverse: PropTypes.bool,
    onClick: PropTypes.func,
    /** DEPRECATION NOTICE: This be removed in a future version */
    action: PropTypes.func
  }

  static defaultProps = {
    type: 'default'
  }

  render () {
    const {
      children,
      fullWidth,
      disabled,
      className,
      component,
      href,
      active,
      size,
      submit,
      type,
      danger,
      primary,
      subtle,
      progress,
      inverse,
      action,
      onClick,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-btn',
      (inverse && 'w-btn--inverse'),
      (subtle && 'w-btn--subtle'),
      (progress && 'w-btn--progress'),
      (size && `w-btn--${size}`),
      {
        'w-btn--fullwidth': fullWidth,
        'w-btn--active': active
      },
      ((!danger && !primary && !type) && 'w-btn--default'),
      (primary && 'w-btn--primary'),
      (danger && 'w-btn--danger'),
      `w-btn--${type}`,
      className
    )

    // props
    const props = { href, ...otherProps, onClick: action || onClick, disabled }
    props.className = componentClass

    if (component) {
      return React.cloneElement(component, props)
    }

    var tag = 'button'
    props.type = submit ? 'submit' : 'button'

    if (href) {
      tag = 'a'
      delete props.type
    }

    const content = (progress) ? <ProgressCircular inverse={((!inverse && (type === 'primary' || type === 'danger')) || (inverse && (type !== 'primary' && type !== 'danger')))} active type='indeterminate' className='w-button__progress' /> : children
    return React.createElement(tag, props, content)
  }
}

export default Button

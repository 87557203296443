import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../../wham/components'

import ProfileTable from '../profile-table'

const SpecTableView = ({
  passed,
  closestProfile,
  additionalProfiles
}) => {
  const [showAdditionalProfiles, setShowAdditionalProfiles] = useState(false)

  return (
    <div id='validator_spectable'>
      <div id='validator_spectable-detail_pane' className={`validator_spectable-detail_pane ${passed ? '' : 'validator_spectable-detail_pane--failure'}`}>
        {passed ? (
          <div>
            <div>
              <ProfileTable profile={closestProfile} passed={passed} closest />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <ProfileTable profile={closestProfile} passed={passed} closest />
            </div>

            {showAdditionalProfiles ? (
              <Wham.Rule label='Additional profiles'/>
            ) : null}

            {showAdditionalProfiles ? (
              <div>
                {additionalProfiles.map(additionalProfile => (
                  <ProfileTable key={additionalProfile.name} profile={additionalProfile} passed={passed} />
                ))}
              </div>
            ) : null}

            <Wham.Button
              className={`validator_spectable-profiles_button ${showAdditionalProfiles ? 'validator_spectable-profiles_button--hide-profiles' : 'validator_spectable-profiles_button--show-profiles'}`}
              onClick={() => setShowAdditionalProfiles(!showAdditionalProfiles)}
            >{showAdditionalProfiles ? 'Hide additional profiles' : 'View all profiles' }</Wham.Button>
          </div>
        )}
      </div>
    </div>
  )
}

SpecTableView.propTypes = {
  passed: PropTypes.bool,
  closestProfile: PropTypes.any,
  additionalProfiles: PropTypes.array
}

export default SpecTableView

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import CreativeDetailVast from './creative-detail-vast'

import styles from './index.module.scss'

const CreativeDetailSummary = ({
  record,
  onCopy,
  isCopied
}) => {
  const {
    vastIds,
    vastUniversalAdId,
    duration,
    amsId,
    errorMsg,
    status
  } = record

  const [expand, setExpand] = useState(false)

  const renderSummaryRow = (key, value) => {
    return (
      <tr className={styles.row}>
        <td className={classNames(styles.cell, styles.key)}>
          {key}
        </td>
        <td className={classNames(styles.cell, styles.value)}>
          <CopyToClipboard text={value} onCopy={() => onCopy(value)}>
            <div className={classNames(styles.copyable, { [styles.copied]: isCopied(value) })}>
              <span className={classNames(styles.text, styles.truncated)}>{value}</span>
            </div>
          </CopyToClipboard>
        </td>
        <td className={styles.controls}>
        </td>
      </tr>
    )
  }

  return (
    <section className={styles.summary}>
      <header className={styles.header}>
        <h2 className={styles.header_title}>Summary</h2>
      </header>

      <div className={styles.wrapper}>
        <article className={styles.details}>
          <table>
            <tbody>
            {amsId ? renderSummaryRow('adE ID', amsId) : null}
            {vastUniversalAdId ? renderSummaryRow('Universal Ad ID', vastUniversalAdId) : null}
            {Number.isInteger(duration) ? renderSummaryRow('Duration', `${duration} seconds`) : null}

            {errorMsg && status === 'Blocked' ? (
              <tr className={classNames(styles.row, { [styles.row__expanded]: expand })}>
                <td className={classNames(styles.cell, styles.key, { [styles.cell__expanded]: expand })}>Error</td>
                <td className={classNames(styles.cell, styles.value, { [styles.cell__expanded]: expand })}>
                  <CopyToClipboard text={errorMsg} onCopy={() => onCopy(errorMsg)}>
                    <div className={classNames(styles.copyable, { [styles.copied]: isCopied(errorMsg) })}>
                      <span className={classNames(styles.text, { [styles.truncated]: !expand })}>{errorMsg}</span>
                    </div>
                  </CopyToClipboard>
                </td>
                <td className={styles.controls}>
                  <button className={styles.button} onClick={() => setExpand(!expand)}>
                    <span className={styles.icon}>{!expand ? 'expand_more' : 'expand_less'}</span>
                  </button>
                </td>
              </tr>
            ) : null}
            </tbody>
          </table>
        </article>

        <article className={styles.vasts}>
          {vastIds.length ? (
            <div className={styles.vast}>
              <h3 className={styles.vast_title}>{`VAST Resolution${vastIds.length > 1 ? ' Chain' : ''}`}</h3>

              {vastIds.length === 1 ? (
                <CreativeDetailVast vast={vastIds[0]} copied={isCopied(vastIds[0].id)} onCopy={() => onCopy(vastIds[0].id)}/>
              ) : (
                <ol className={styles.vast_list}>
                  {vastIds.map((vast, offset) => (
                    <li className={styles.vast_item} key={offset}>
                      <CreativeDetailVast vast={vast} copied={isCopied(vast.id)} onCopy={() => onCopy(vast.id)}/>
                    </li>
                  ))}
                </ol>
              )}
            </div>
          ) : null}
        </article>
      </div>
    </section>
  )
}

CreativeDetailSummary.propTypes = {
  record: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired,
  isCopied: PropTypes.func.isRequired
}

export default CreativeDetailSummary

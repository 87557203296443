import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './index.module.scss'

const CreativeVastPath = ({
  className,
  path
}) => {
  return (
    <div className={classNames(className, styles.container)}>
      {path.map((path, index) => (
        <>
          {index ? (
            <span className='w-icon'>arrow_right_alt</span>
          ) : null}

          <span>{path}</span>
        </>
      ))}
    </div>
  )
}

CreativeVastPath.propTypes = {
  className: PropTypes.string,
  path: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default CreativeVastPath

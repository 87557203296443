import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import RuleCard from './rule-card'

export const GlobalRule = ({
  globalRule,
  partnerId,
  dispatch
}) => (
    <section data-cy='global-rule'>
      <span>
        <header>
          Global rule
        <i>?</i>
          <span>
            <h4>What is a global rule?</h4>
            <p>Your global rule is the default configuration
            that will be used if no other rules are selected
          </p>
          </span>
        </header>
      </span>
      <div className='rule-card-wrapper'>
        <RuleCard
          className='global-rule-card'
          {...globalRule}
          displayPriority={<Wham.Icon iconName='all_inclusive' />}
          active
          partnerId={partnerId}
          dispatch={dispatch}
        />
        <span className='w-icon' style={{ visibility: 'hidden' }}>drag_indicator</span>
      </div>
    </section>
  )

GlobalRule.propTypes = {
  globalRule: PropTypes.object,
  partnerId: PropTypes.string,
  dispatch: PropTypes.func
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import blacklist from 'blacklist'
import { WHAM_SIZES, WHAM_TYPES } from '../lib/wham'
import autosize from 'autosize'
import standardizeChangeHandler from '../lib/standardize-changehandlers'
import { splitFieldEventProps } from '../lib/eventProps'

import ReactResizeDetector from 'react-resize-detector'
import Icon from '../Icon'

import './FormInput.scss'
import '../../scss/wham.scss'

const createOptionTag = ({ id, value }, i) => (<option key={id || `${i}`} value={value} />) //eslint-disable-line

class FormInput extends React.Component {
  static displayName = 'FormInput'
  static propTypes = {
    type: PropTypes.oneOf(WHAM_TYPES),
    size: PropTypes.oneOf(WHAM_SIZES),
    href: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    danger: PropTypes.bool,
    subtle: PropTypes.bool,
    multiline: PropTypes.bool,
    descriptor: PropTypes.string,
    descriptorClickCallback: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.string,
    /**
     * Called upon changing the value.
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {string} data - The state after the change.
     */
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onKeypress: PropTypes.func,
    field: PropTypes.string,
    autoHeight: PropTypes.bool,
    maxLength: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    list: PropTypes.array
  }

  constructor (props) {
    super(props)

    this.state = {
      valueLength: String((this.props.value ? this.props.value : '')).length
    }
  }

  UNSAFE_componentDidMount () {
    if (this.props.autoFocus && this.inputRef) {
      this.inputRef.focus()
    }
    this.render()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { autoHeight, multiline } = this.props
    if ((autoHeight || multiline) && (!nextProps.multiline || !nextProps.autoHeight)) {
      autosize.destroy(this.inputRef)
    }
    this.render()
  }

  render () {
    const {
      field = 'text',
      onClear = null,
      disabled,
      danger,
      multiline,
      autoHeight,
      descriptor,
      descriptorClickCallback,
      type,
      size = 'medium',
      className,
      subtle = false,
      onChange,
      id,
      value = '',
      maxLength,
      list,
      ...otherProps
    } = this.props
    const [inputProps, remainingProps] = splitFieldEventProps(otherProps)
    /** If there's a maxlength, don't render values larger than it */
    const renderedValue = maxLength ? value.substr(0, parseInt(maxLength, 10)) : value
    /** Count the rendered value's length */
    const valueLength = maxLength ? renderedValue.length : undefined

    // classes
    const wrapperClassnames = classNames(
      'w-input',
      `w-input--${type}`,
      `w-input--${field}`,
      {
        'w-input--disabled': disabled,
        'w-input--subtle': subtle,
        'w-input--danger': danger,
        'w-input--textarea': multiline,
        'w-input--textarea--disabled': disabled && multiline,
        'w-input--textarea--autoheight': autoHeight && multiline
      },
      (size ? ('w-input--' + size) : null),
      'w-input--fullwidth',
      className
    )

    // classes
    const descriptorClassnames = classNames(
      'w-input__descriptor',
      (maxLength ? 'w-input__descriptor--maxlength' : null),
      (descriptorClickCallback ? 'w-input__descriptor--interactive' : null)
    )

    let props = blacklist({ ...otherProps, size, type, disabled, ref: 'input' }, 'subtle', 'onChange', 'value')
    let Element = 'input'

    if (this.props.href) {
      Element = 'a'
      props.type = null
      props.children = props.children || props.value
    } else if (multiline) {
      Element = 'textarea'
      props = {
        ...props,
        rows: 1,
        onChange,
        maxLength,
        value: renderedValue
      }
    } else {
      props = {
        ...props,
        disabled,
        onChange,
        maxLength,
        value: renderedValue
      }
    }
    if (multiline && autoHeight) {
      setTimeout(() => {
        autosize(this.inputRef)
        autosize.update(this.inputRef)
      }, 0)
    }

    if (maxLength || descriptor) {
      setTimeout(() => {
        const input = this.inputRef
        if (this.descriptorRef) {
          input.style.paddingRight = this.descriptorRef.offsetWidth + 20 + 'px'
        } else {
          input.style.paddingRight = '10px'
        }
      }, 0)
    }

    let DescriptorElement = 'span'
    if (descriptorClickCallback) {
      DescriptorElement = 'button'
    }

    const renderedOptions = list ? list.map(createOptionTag) : null

    const listID = 'list-' + Math.round(Math.random().toString() * 10000)
    const dataList = list ? <datalist id={listID}>{renderedOptions}</datalist> : null

    return (
      <span className={wrapperClassnames} {...remainingProps}>
        <div className='w-input--clear-wrapper'>
          <Element
            id={id}
            {...props}
            {...inputProps}
            type={field}
            ref={el => { this.inputRef = el }}
            disabled={disabled}
            list={listID}
          />
          {onClear && value.length > 0
            ? (
              <span onClick={onClear} className='w-input--clear-button'>
                <Icon iconName='close' />
              </span>
            )
            : null}
        </div>
        {
          maxLength
            ? (
              <DescriptorElement
                className={descriptorClassnames}
                ref={el => { this.descriptorRef = el }}
              >
                {valueLength + '/' + maxLength}
              </DescriptorElement>
            )
            : null
        }
        {
          this.props.descriptor && !this.props.maxLength
            ? (
              <DescriptorElement
                className={descriptorClassnames}
                onClick={descriptorClickCallback}
                ref={el => { this.descriptorRef = el }}
              >
                {this.props.descriptor}
              </DescriptorElement>
            )
            : null
        }
        {list ? dataList : null}
        {(multiline && autoHeight)
          ? <ReactResizeDetector
            handleWidth
            onResize={() => {
              this.render()
            }}
          />
          : null}
      </span>
    )
  }
}

const enhancedFormInput = standardizeChangeHandler(FormInput)
enhancedFormInput.displayName = FormInput.displayName
export default enhancedFormInput

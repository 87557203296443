import PropTypes from 'prop-types'
import React from 'react'
import Search from '../AppSearch'
import AppContext from '../AppContext'
import classNames from 'classnames'

import './AppBar.scss'
import '../../scss/wham.scss'

const createArea = location => {
  const elem = ({ children, className, ...otherProps }) => (
    <div className={(className ? className + ' ' : '') + `w-app-bar__nav-${location}`} {...otherProps}>
      <div className='w-app-bar__nav-item'>
        {children}
      </div>
    </div>
  )
  elem.propTypes = {
    /** React Components to Place in the AppBar */
    children: PropTypes.node,
    className: PropTypes.string
  }
  elem.displayName = `AppBar${location.charAt(0).toUpperCase() + location.slice(1)}`
  return elem
}

export const AppBarLeft = createArea('left')

export const AppBarRight = createArea('right')

export const AppBarCenter = ({ heading, subtitle, headingRelatesState, subtitleRelatesState, children }) => {
  return (<AppContext primary={heading} secondary={subtitle} primaryRelatesState={headingRelatesState} secondaryRelatesState={subtitleRelatesState}>{children}</AppContext>)
}
AppBarCenter.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  headingRelatesState: PropTypes.bool,
  subtitleRelatesState: PropTypes.bool,
  children: PropTypes.any
}

export class AppBar extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    danger: PropTypes.bool,
    warning: PropTypes.bool,
    className: PropTypes.string
  }

  getComponent (type) {
    return this.props.children.filter((comp) => {
      return comp.type === type
    }) || null
  }

  render () {
    const {
      className,
      children,
      danger,
      warning,
      ...otherProps
    } = this.props
    // classes
    var componentClass = classNames(
      'w-app-bar',
      (warning && !danger ? 'w-app-bar--warning' : null),
      (danger ? 'w-app-bar--danger' : null),
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        <nav className='w-app-bar__level w-app-bar__level--nav'>
          <div className='container is-fluid'>
            {this.getComponent(AppBarLeft)}
            {this.getComponent(AppBarCenter)}
            {this.getComponent(AppBarRight)}
          </div>
        </nav>
        {this.getComponent(Search)}
      </div>
    )
  }
}

export default AppBar

import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

export const ruleGlobalSelector = partner => R.pathOr([], [
  'partner', partner, 'ruleset', 'rules'])
export const lastStateSelector = partner => R.path([
  'partner', partner, 'ruleset', 'revert', 'lastState'])
export const selectRule = R.compose(SAGA.select, ruleGlobalSelector)

export const ruleSelector = (partner, ruleId) => state => {
  const safeFind = R.compose(
    R.defaultTo({}),
    R.find(({ id }) => R.equals(id, ruleId)))

  return safeFind(ruleGlobalSelector(partner)(state))
}

export const pendingRuleSelector = partner => R.pathOr(false, [
  'partner', partner, 'ruleset', 'pending'])

export const updatingRuleSelector = partner => R.pathOr(false, [
  'partner', partner, 'ruleset', 'updating'])

export const alertRuleSelector = partner => R.pathOr(false, [
  'partner', partner, 'ruleset', 'alert'])

export const ruleErrorSelector = partner => R.path([
  'partner', partner, 'ruleset', 'error'])

export const partnerGlobalRuleSelector = partner => R.pathOr(
  {}, ['partner', partner, 'ruleset', 'globalRule'])

import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'

import { gql, useQuery } from '@apollo/client'

import * as selectors from '../../selectors'

import { setWhitelistFormField } from '../../actions/form'

import WhitelistError from '../../components/whitelist/whitelist-error'
import WhitelistTable from '../../components/whitelist/whitelist-table'

import { types } from '../../constants/whitelist'
import { ViewContext, WhitelistContext } from '../../context'

import { matches } from '../../lib/whitelist'

import WhitelistAdd from '../whitelist-add'

import './style.css'

export const GQL_WHITELIST = gql`
  query Allowlist {
    allowlist {
      edges {
        node {
          id
          idType
          value
          label
        }
      }
    }
  }
`

export const Whitelist = ({
  search,
  dispatch
}) => {
  const { partner, swid } = useContext(ViewContext)

  const { data = {}, loading, error, refetch } = useQuery(GQL_WHITELIST)

  const edges = data.allowlist?.edges ?? []
  const items = edges.map(edge => {
    const { id, idType, value, label } = edge.node
    return {
      id: Number(id),
      idType: idType.toLowerCase(),
      value,
      label: label ?? ''
    }
  })

  const defaultType = swid.enabled ? 'swid' : 'uid'

  useEffect(() => {
    dispatch(setWhitelistFormField('type', defaultType))
    dispatch(setWhitelistFormField('label', ''))
    dispatch(setWhitelistFormField('value', ''))
  }, [])

  useEffect(() => {
    refetch()
  }, [partner])

  const options = R.compose(
    R.prepend({ label: 'All Types', value: '*' }),
    R.map(key => ({ label: types[key], value: key })),
    R.filter(key => key !== 'swid' || swid.enabled),
    R.keys
  )(types)

  const filteredItems = R.filter(item => !search || matches(search, item.value) || matches(search, item.label), items)

  const onCloseError = () => {
    refetch()
  }

  const context = {
    options,
    pending: loading
  }

  return (
    <WhitelistContext.Provider value={context}>
      <div className='whitelist'>
        <div className='whitelist__add'>
          <WhitelistAdd items={items} onAdd={() => refetch()} />
        </div>

        <div className='whitelist__table'>
          <WhitelistTable items={filteredItems} total={items.length} onDelete={() => refetch()} />
        </div>

        <div className='whitelist__error'>
          {error ? (
            <WhitelistError type={error} onClose={() => onCloseError()} />
          ) : null}
        </div>
      </div>
    </WhitelistContext.Provider>
  )
}

Whitelist.propTypes = {
  search: PropTypes.string.isRequired,
  dispatch: PropTypes.func
}

const mapStateToProps = state => {
  const partner = selectors.activePartnerGlobalSelector(state)

  const search = selectors.whitelistSearchSelector(partner)(state)

  return {
    search
  }
}

export default connect(mapStateToProps)(Whitelist)

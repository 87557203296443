import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'
import * as Wham from '../../../../wham/components'

import KeyValuePairModal from '../../../key-value-pair/key-value-pair-modal'
import KeyValuePairTable from '../../../key-value-pair/key-value-pair-table'
import { TextParts } from '../../../macro-splitter'

import { macroJoiner, macroSplitter } from '../../../../lib/macros'
import { isValidUrl } from '../../../../lib/util/urls'

import './style.css'

const validateUrl = urlParts => urlParts.url.length === 0 || isValidUrl(urlParts.url)

const checkForDups = urlParts => {
  const keys = R.compose(
    R.map(R.toLower),
    R.filter(key => !!key),
    R.pluck('key')
  )(urlParts.tuples)
  return new Set(keys).size < keys.length
}

const makeUrlState = currentUrl => {
  const urlParts = macroSplitter(currentUrl)
  return {
    currentUrl,
    urlParts,
    valid: validateUrl(urlParts),
    hasDups: checkForDups(urlParts)
  }
}

const UrlEditor = ({
  url: initialUrl,
  onChange,
  onValid,
  onInvalid
}) => {
  const [urlState, setUrlState] = useState(() => makeUrlState(initialUrl))
  const [errorMessage, setErrorMessage] = useState('')
  const [upsertTuple, setUpsertTuple] = useState(null)

  const timeout = useRef()

  useEffect(() => {
    clearTimeout(timeout.current)

    urlState.valid && !urlState.hasDups ? onValid() : onInvalid()

    if (!urlState.valid) {
      timeout.current = setTimeout(
        () => setErrorMessage('Invalid URL'),
        500)
      return
    }

    if (urlState.hasDups) {
      timeout.current = setTimeout(
        () => setErrorMessage('Duplicate query param names detected'),
        500)
      return
    }

    setErrorMessage('')
  }, [urlState.valid, urlState.hasDups])

  useEffect(() => {
    onChange(urlState.currentUrl)
  }, [urlState.currentUrl])

  return (
    <Wham.Layout className='macro-modal'>
      <Wham.LayoutRow>
        <KeyValuePairTable
          base={urlState.urlParts.base}
          tuples={urlState.urlParts.tuples}
          onUpdate={(tuple, index) => {
            setUpsertTuple({ tuple, index })
          }}
        />

        {upsertTuple ? (
          <KeyValuePairModal
            tuple={upsertTuple.tuple}
            onClose={() => {
              setUpsertTuple(null)
            }}
            onSave={editedTuple => {
              const updatedTuples = R.update(upsertTuple.index, editedTuple, urlState.urlParts.tuples)
              const mergedTuples = R.zipWith(R.mergeRight, urlState.urlParts.tuples, updatedTuples)
              const currentUrl = macroJoiner(urlState.urlParts.textParts[0].text, mergedTuples)
              setUrlState(makeUrlState(currentUrl))
              setUpsertTuple(null)
            }}
          />
        ) : null}

        <Wham.LayoutColumn>
          <Wham.Tabs className='macro-modal__tabs' title='adEngine Request URL' size='small'>
            <Wham.Tab label='Edit'>
              <Wham.FormInput
                className='edit-macro__url macro-modal__input-url'
                data-cy='modal-input-url'
                value={urlState.urlParts.url}
                onChange={e => setUrlState(makeUrlState(e.target.value))}
                field='text'
                size='medium'
                spellCheck='false'
                autoFocus
                autoHeight
                multiline
              />
              <span className={`edit-macro__warn ${errorMessage ? 'edit-macro__warn--show' : 'edit-macro__warn--hide'}`}>
                {errorMessage}
              </span>
            </Wham.Tab>

            <Wham.Tab label='View'>
              <div className='ad-field'>
                <TextParts textParts={urlState.urlParts.textParts} />
              </div>
            </Wham.Tab>
          </Wham.Tabs>
        </Wham.LayoutColumn>
      </Wham.LayoutRow>
    </Wham.Layout>
  )
}

UrlEditor.propTypes = {
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func.isRequired,
  onInvalid: PropTypes.func.isRequired
}

export default UrlEditor

import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import marksy from 'marksy'

import Heading from '../../../Heading'
import Image from '../../../Image'
import Table from '../../../Table'
import Code from '../../../Code'

import '../../Markdown.scss'
import '../../../../scss/wham.scss'

const MARKDOWN_SIZES = ['small', 'medium', 'large']

class MarkdownTemplateDocumentation extends React.Component {
  static propTypes = {
    markdown: PropTypes.string,
    className: PropTypes.string,
    elements: PropTypes.object,
    size: PropTypes.oneOf(MARKDOWN_SIZES)
  }

  constructor (props) {
    super(props)

    const makeHeading = (id, children, level) => {
      const strippedId = id.replace(/[^a-z-]/g, '')
      return <Heading id={strippedId} className={`w-markdown__h${level}`} level={level}>{children}</Heading>
    }

    this.markdownElements = {
      h1 ({
        id,
        children
      }) {
        return makeHeading(id, children, 1)
      },
      h2 ({
        id,
        children
      }) {
        return makeHeading(id, children, 2)
      },
      h3 ({
        id,
        children
      }) {
        return makeHeading(id, children, 3)
      },
      h4 ({
        id,
        children
      }) {
        return makeHeading(id, children, 4)
      },
      h5 ({
        id,
        children
      }) {
        return makeHeading(id, children, 5)
      },
      h6 ({
        id,
        children
      }) {
        return makeHeading(id, children, 6)
      },
      blockquote ({
        children
      }) {
        return <blockquote className='w-markdown__blockquote'>{children}</blockquote>
      },
      hr () {
        return <hr className='w-markdown__hr' />
      },
      ol ({
        children
      }) {
        return <ol className='w-markdown__ol'>{children}</ol>
      },
      ul ({
        children
      }) {
        return <ul className='w-markdown__ul'>{children}</ul>
      },
      // p ({children}) {},
      table ({
        children
      }) {
        return <Table className='w-markdown__table'>{children}</Table>
      },
      thead ({
        children
      }) {
        return <thead className='w-markdown__thead'>{children}</thead>
      },
      tbody ({
        children
      }) {
        return <tbody className='w-markdown__tbody'>{children}</tbody>
      },
      tr ({
        children
      }) {
        return <tr className='w-markdown__tr'>{children}</tr>
      },
      th ({
        children
      }) {
        return <th className='w-markdown__th'>{children}</th>
      },
      td ({
        children
      }) {
        return <td className='w-markdown__td'>{children}</td>
      },
      a ({
        href,
        title,
        target,
        children
      }) {
        const hrefModified = href.startsWith('http')
          ? href
          : `/documentation/${href.replace(/\.md/, '')}`
        return <a className='w-markdown__a' href={hrefModified} title={title} target={target}>{children}</a>
      },
      strong ({
        children
      }) {
        return <strong className='w-markdown__strong'>{children}</strong>
      },
      em ({
        children
      }) {
        return <em className='w-markdown__em'>{children}</em>
      },
      br () {
        return <br />
      },
      del ({
        children
      }) {
        return <del className='w-markdown__del'>{children}</del>
      },
      img ({
        src
      }) {
        return (
          <p align="center">
            <Image className='w-markdown__img' src={`/docs/${src}`} />
          </p>
        )
      },
      code ({
        language,
        code
      }) {
        return <Code className='w-markdown__code' code={code} lang={language} pretty />
      },
      codespan ({
        children
      }) {
        return <Code className='w-markdown__code' code={children[0]} pretty />
      }
    }

    this.state = {
      elements: this.markdownElements
    }

    this.compile = marksy({
      createElement,
      elements: this.state.elements
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.elements) {
      this.setState({
        elements: Object.assign(this.state.elements, nextProps.elements)
      })
    }
  }

  render () {
    const {
      markdown = '',
      elements,
      className,
      size,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-markdown--template--default',
      (size ? 'w-markdown--' + size : null),
      className
    )

    this.compile = marksy({
      createElement,
      elements: this.state.elements
    })

    const compiled = this.compile(markdown, {})

    return (
      <div className={componentClass} {...otherProps}>
        {compiled.tree}
      </div>
    )
  }
};

export default MarkdownTemplateDocumentation

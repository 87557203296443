import { performReq } from '../api/helpers'
import { config } from '../config'

const { WEBUI_API_BASE_URL } = config

export const createAssetValidationUri = ({ partnerId, body }) =>
  performReq('POST', `${WEBUI_API_BASE_URL}partner/${partnerId}/assets/upload`, body)

export const deleteAssetValidationUri = ({ partnerId, body }) =>
  performReq('DELETE', `${WEBUI_API_BASE_URL}partner/${partnerId}/assets/upload`, body)

export const getAssetValidationForUri = ({ partnerId, uri }) =>
  performReq('GET', `${WEBUI_API_BASE_URL}partner/${partnerId}/assets/validate?mediaUrl=${encodeURIComponent(uri)}`)

export const uploadFileToAssetValidationUri = ({ uri, file, type }) =>
  performReq('PUT', uri, file, type)

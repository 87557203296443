import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

import './style.css'

const WhatsNew = ({
  active,
  viewAllLink,
  notes = '',
  dismissHandler,
  onClick,
  ...otherProps
}) => (
  <span className='w-whats-new' style={{ position: 'relative' }} {...otherProps}>
    <Wham.Icon
      className='w-whats-new__icon'
      style={{ cursor: 'pointer' }}
      iconName='new_releases'
      onClick={onClick}
    />

    {active ? (
      <span
        className='w-whats-new__content-container'
        style={{
          position: 'absolute',
          top: 'calc(100% + 10px)',
          right: 'calc(100% - 30px)'
        }}
      >
        <Wham.Popover className='w-whats-new__content' type='spaced' active={active}>
          <div className='w-whats-new__content__release'>
            <Wham.Markdown markdown={notes} size='small' template='release-notes' />
          </div>

          <div className='w-whats-new__content__controls'>
            {dismissHandler ? <Wham.Button type='primary' onClick={dismissHandler}>Got it</Wham.Button> : null}
            {viewAllLink ? <a href={viewAllLink}>View all releases</a> : null}
          </div>
        </Wham.Popover>
      </span>
    ) : null}
  </span>
)

WhatsNew.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
  notes: PropTypes.string,
  viewAllLink: PropTypes.string,
  dismissHandler: PropTypes.func
}

export default WhatsNew

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Layout.scss'

const LAYOUT_COLUMN_SPACING = [
  'none',
  'default',
  'spaced'
]

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    fullHeight: PropTypes.bool,
    colSpacing: PropTypes.oneOf(LAYOUT_COLUMN_SPACING)
  }

  render () {
    const {
      colSpacing = 'default',
      fullWidth,
      fullHeight,
      children,
      className,
      ...otherProps
    } = this.props

    const componentClass = classNames(
      'w-layout',
      (fullWidth && 'w-layout--fullwidth'),
      (fullHeight && 'w-layout--fullheight'),
      `w-layout--spacing-${colSpacing}`,
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        {children}
      </div>
    )
  }
}

export default Layout

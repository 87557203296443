import React from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'
import * as Wham from '../../../../wham/components'

import MiddleTruncate from '../../../middle-truncate'

const status = {
  passed: {
    text: 'check',
    status: 'success',
    msg: 'Successfully validated'
  },
  error: {
    text: 'warning',
    status: 'warning',
    msg: 'Upload error'
  },
  failed: {
    text: 'warning',
    status: 'warning',
    msg: 'Failed parse'
  },
  default: {
    text: 'not_interested',
    status: 'danger',
    msg: 'Failed validation'
  }
}

const getStatus = (passed, error, failedParse) => {
  if (passed) {
    return status.passed
  }

  if (error) {
    return status.error
  }

  if (failedParse) {
    return status.failed
  }

  return status.default
}

const UriListItem = ({
  uri,
  result,
  selected,
  selectResult,
  removeResult
}) => {
  const {
    name,
    passed = false,
    pending = false,
    latest = false,
    repeat = false,
    error = false
  } = result

  const failedParse = R.isEmpty(R.path(['asset', 'GeneralFileExtension'], result))
  const isSelected = selected === uri
  const status = getStatus(passed, error, failedParse)

  return (
    <li
      className={`asset-validator__menu_item ${isSelected ? 'asset-validator__menu_item--selected' : ''} ${latest ? 'asset-validator__menu_item--latest' : ''} ${pending && repeat ? 'asset-validator__menu_item--repeat' : ''}`}
      key={uri}
      onClick={e => {
        if (!pending) selectResult({ uri })
        e.preventDefault()
      }}
    >
      <div
        style={{
          margin: 'auto 1.5rem auto 0.5rem',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {pending ? (
          <Wham.ProgressCircular
            active
            type='indeterminate'
          />
        ) : (
          <Wham.Icon
            className={`asset-validator__menu_item-icon asset-validator__menu_item-icon--${status.status}`}
            iconName={status.text}
          />
        )}
      </div>
      <span
        style={{
          marginRight: '2rem',
          color: 'black',
          [isSelected && 'color']: 'white'
        }}
      >
          <MiddleTruncate className='asset-validator__menu_item-link' maxLength={38}>{name}</MiddleTruncate>
        <span
          className='asset-validator__menu_item-text'
          style={{
            display: 'block',
            fontSize: 'var(--w-font-size-small)',
            color: 'var(--w-color-gray-light)',
            [isSelected && 'color']: 'white'
          }}
        >
          { pending ? (
            repeat ? (
              'Revalidating...'
            ) : (
              'Validating...'
            )
          ) : (
            status.msg
          )}
        </span>
      </span>
      <span
        onClick={e => {
          e.preventDefault()
          removeResult({uri})
        }}
        className="asset-validator__menu_item-delete"
      >
        <Wham.Icon
          className="delete-icon"
          iconName="delete"
        />
      </span>
    </li>
  )
}

UriListItem.propTypes = {
  uri: PropTypes.string,
  result: PropTypes.object,
  selected: PropTypes.string,
  selectResult: PropTypes.func,
  removeResult: PropTypes.func
}

export default UriListItem

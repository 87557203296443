import React, { Component } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'

import { ApolloProvider } from '@apollo/client'

import store from './store'
import client from './apollo/client'

import { appInit } from './actions/appInit'

import ErrorScreen, { PAGE_NOT_FOUND_ERROR_SCREEN } from './components/error-screen'
import SecureRoute from './containers/secure-route'

import { reportClientError } from './services/api'
import { history } from './services/history'

import { paths } from './route-paths'

import LoginView from './views/login'
import HomeView from './views/home'
import DashboardView from './views/dashboard'
import DocumentationView from './views/documentation'
import DebugView from './views/debug'
import DebugTransactionView from './views/debug/debug-transaction'
import WhitelistView from './views/whitelist'
import SimulatorView from './views/simulator'
import CreativesView from './views/creatives'
import CreativeView from './views/creative'
import AssetValidatorView from './views/asset-validator'
import ReportingView from './views/reporting'
import InsightsBetaView from './views/insights-beta'
import ReleaseNotesView from './views/release-notes'
import ViewRules from './views/rule-manager/view-rules'
import CreateRule from './views/rule-manager/create-rule'

import { ThemeProvider } from 'styled-components'
import { theme } from '@erso/common-ui-react'

const invertedTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    white: theme.colors.dark,
    // theme.colors.dark = #28283C = rgb(40, 40, 60)
    white90: 'rgba(40, 40, 60, 0.90)',
    white60: 'rgba(40, 40, 60, 0.60)',
    white30: 'rgba(40, 40, 60, 0.30)',
    white12: 'rgba(40, 40, 60, 0.12)',
    dark: theme.colors.white,
    dark2: theme.colors.white,
    dark2subtle: theme.colors.white,
    dark3: theme.colors.white
  }
}

store.dispatch(appInit())

class App extends Component {
  componentDidCatch (error, errorInfo) {
    reportClientError(error)
  }

  render () {
    return (
      <ApolloProvider client={client}>
        <ThemeProvider theme={invertedTheme}>
          <Provider store={store}>
            <div id='routes'>
              <ErrorScreen />
              <ConnectedRouter history={history}>
                <Switch>
                  <Route exact path={paths.login} component={LoginView} />
                  <SecureRoute exact path={paths.home} component={HomeView} />
                  <SecureRoute exact path={paths.dashboard} component={DashboardView} />
                  <SecureRoute exact path={paths.documentation} component={DocumentationView} />
                  <SecureRoute exact path={paths.viewRules} component={ViewRules} />
                  <SecureRoute exact path={paths.createEditRule} component={CreateRule} />
                  <SecureRoute exact path={paths.debug} component={DebugView} />
                  <SecureRoute exact path={paths.transaction} component={DebugTransactionView} />
                  <SecureRoute exact path={paths.whitelist} component={WhitelistView} />
                  <SecureRoute exact path={paths.simulator} component={SimulatorView} />
                  <SecureRoute exact path={paths.creative} component={CreativeView} />
                  <SecureRoute exact path={paths.creatives} component={CreativesView} />
                  <SecureRoute exact path={paths.assetValidator} component={AssetValidatorView} />
                  <SecureRoute exact path={paths.reporting} component={ReportingView} />
                  <SecureRoute exact path={paths.insightsBeta} component={InsightsBetaView} />
                  <SecureRoute exact path={paths.releaseNotes} component={ReleaseNotesView} />
                  <SecureRoute path='/:any'>{PAGE_NOT_FOUND_ERROR_SCREEN}</SecureRoute>
                </Switch>
              </ConnectedRouter>
            </div>
          </Provider>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

export default App

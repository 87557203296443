import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../Checkbox'
import { splitFieldEventProps } from '../lib/eventProps'

import './Switch.scss'
import '../../scss/wham.scss'

export default class Switch extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    /** Prevent change  */
    disabled: PropTypes.bool,
    /** Immutable, but not disabled **/
    readOnly: PropTypes.bool,
    /** Text to render on hover */
    title: PropTypes.string,
    /** On or off as true/false */
    value: PropTypes.bool,
    onChange: PropTypes.func,
    danger: PropTypes.bool,
    label: PropTypes.string
  }

  onChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e, !this.props.value)
      e.preventDefault()
    }
  }

  render () {
    const {
      disabled,
      readOnly,
      title,
      value,
      className,
      onChange,
      danger,
      label,
      ...otherProps
    } = this.props
    const [formEventProps, wrapperProps] = splitFieldEventProps(otherProps)
    const componentClass = classNames('w-switch', {
      'w-switch--disabled': disabled,
      'w-switch--readonly': readOnly,
      'w-switch--danger': danger
    }, className)

    return (
      <label className={componentClass} title={title} {...wrapperProps}>
        <span className='w-switch__container'>
          <Checkbox className='w-switch__input' value={value} disabled={disabled} onChange={this.onChange} {...formEventProps} />
          <span className='w-switch__input__label' data-on='On' data-off='Off' />
          <span className='w-switch__handle' />
        </span>
        {label ? <span className='w-switch__label'>{label}</span> : null}
      </label>
    )
  }
}

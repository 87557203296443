import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ExpandCollapse from '../../../../components/actions/expand-collapse'

import DebugLogRecord from './debug-log-record'

import './style.css'

const DebugLogGroup = ({
  groupBy,
  logGroup,
  onClick
}) => {
  const [collapsed, setCollapsed] = useState(true)

  const marginBottom = collapsed ? (logGroup.length < 3 ? logGroup.length * 5 : 15) + 5 : 10
  const overlapBottom = collapsed ? logGroup.length < 3 ? 5 : 10 : 0

  return (
    <div className='debug-log-group' data-cy='debug-log-group' style={{ marginBottom }}>
      <div className='debug-log-group__control'>
        {
          logGroup.length > 1 ? (
            <div className='debug-log-group__control-container'>
              <div className='debug-log-group__control-container-upper'>
                <span className='debug-log-group__control-count'>{logGroup.length}</span>
                <ExpandCollapse
                  collapsed={collapsed} onClick={() => {
                  setCollapsed(!collapsed)
                }}
                />
              </div>

              {
                !collapsed && logGroup.length > 3 ? (
                  <div className='debug-log-group__control-container-lower' style={{ top: `calc(52px + ${(logGroup.length - 1) * 130}px)`}}>
                    <ExpandCollapse
                      collapsed={collapsed} onClick={() => {
                      setCollapsed(!collapsed)
                    }}
                    />
                  </div>
                ) : null
              }
            </div>
          ) : null
        }
      </div>

      <div className='debug-log-group__records'>
        {
          logGroup.map((logRecord, index) => (
            <DebugLogRecord key={index} groupBy={groupBy} logRecord={logRecord} index={index} collapsed={collapsed} onClick={onClick} />
          ))
        }
      </div>

      <div className='debug-log-group__thread'>
        {
          logGroup.length > 1 ? (
            <React.Fragment>
              <div className='debug-log-group__thread-line' style={{ height: `calc(100% + ${overlapBottom}px)`}} />
              <div className='debug-log-group__thread-mask' style={{ height: `calc(100% + ${overlapBottom}px + 2px)`}} />
            </React.Fragment>
          ) : null
        }
      </div>
    </div>
  )
}

DebugLogGroup.propTypes = {
  groupBy: PropTypes.string.isRequired,
  logGroup: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DebugLogGroup

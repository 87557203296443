import React from 'react'
import PropTypes from 'prop-types'
import * as Wham from '../../wham/components'
import { objectToClassNames } from '../../lib/class-name-helpers'
import './style.css'

const isSafari = () =>
  navigator.vendor.toLowerCase().includes('apple')

const PlaybackButton = ({
  notOnSafari,
  onPlay,
  isPlaying,
  playerLoading,
  playerError,
  compact
}) => {
  const playButtonClassNames = objectToClassNames({
    'playback-button__play': true,
    'playback-button__play--playing': isPlaying
  })

  const playingTextClassName = compact
    ? 'playback-button__playing-text--compact'
    : 'playback-button__playing-text--normal'

  const renderErrorIcon = () => (
    <Wham.Icon
      className='playback-button__error'
      iconName='block'
      title={playerError}
    />
  )

  const renderPlayIcon = () => (
    <Wham.Icon
      className={playButtonClassNames}
      iconName='play_circle_filled'
      title='Play media asset'
      onClick={onPlay}
    />
  )

  const renderPlayingText = () => (
    isPlaying
      ? (
        <span className={playingTextClassName}>
          {playerLoading ? 'Loading' : 'Playing'}
        </span>
      )
      : null
  )

  const renderCompact = () => (
    isPlaying
      ? renderPlayingText()
      : renderPlayIcon()
  )

  const renderNormal = () => (
    <div className='playback-button__layout' data-cy='playback-button'>
      {renderPlayIcon()}
      {renderPlayingText()}
    </div>
  )

  if (notOnSafari && isSafari()) {
    return null
  }

  if (playerError) {
    return renderErrorIcon()
  }

  return compact
    ? renderCompact()
    : renderNormal()
}

PlaybackButton.propTypes = {
  notOnSafari: PropTypes.bool,
  onPlay: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  playerLoading: PropTypes.bool.isRequired,
  playerError: PropTypes.string,
  compact: PropTypes.bool
}

export default PlaybackButton

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classNames from 'classnames'

import { useCopied } from '../../../lib/custom-hooks'

import * as Wham from '../../../wham/components'
import Dialog from '../../../components/dialog'

import './style.css'

const ArrayValueDialog = ({ onClose, title, nameValues }) => {
  const footer = (
    <div>
      <Wham.Button type='default' onClick={onClose}>
        Close
      </Wham.Button>
    </div>
  )

  return (
    <Dialog
      header={title}
      footer={footer}
      showDialog={true}
      onClose={onClose}
    >
      <div style={{ width: '600px', marginBottom: '3rem' }}>
        <table>
          <tbody>
            {
              nameValues.map(({ name, value }, index) => (
                <tr key={index} className="debug-summary__row">
                  <td className='debug-summary__key-cell' style={{ width: '15rem' }}>
                    <span className='debug-summary__key'>{name}</span>
                  </td>
                  <td className='debug-summary__value-cell'>
                    <span className='debug-summary__value'>{value}</span>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Dialog>
  )
}

ArrayValueDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  nameValues: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired
}

const copyable = [
  'Asset ID',
  'Device ID',
  'UID',
  'SWID',
  'Session ID',
  'Transaction ID'
]

const searchable = [
  'Device ID',
  'UID',
  'SWID',
  'Session ID'
]

const DebugSummary = ({
  summaryData,
  onClick,
  onSearch
}) => {
  const [hover, setHover] = useState(false)
  const [dialogData, setDialogData] = useState(null)
  const [onCopy, isCopied] = useCopied()

  const renderValueAsLink = (_key, value, goToAction) => {
    const onClickLink = e => {
      e.preventDefault()
      onClick(goToAction)
    }
    return <a onClick={onClickLink}>{value}</a>
  }

  const renderCopyableValue = (key, value) => {
    const className = classNames(
      'debug-summary__value',
      'debug-summary__value-copyable',
      { 'debug-summary__value-copyable--copied': isCopied(key, value) }
    )
    return (
      <CopyToClipboard text={value} onCopy={() => onCopy(key, value)}>
        <span
          className={className}
          data-cy='value'
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          {value}
        </span>
      </CopyToClipboard>
    )
  }

  const renderScalarValue = (_key, value) => {
    return (
      <span className='debug-summary__value' data-cy='value'>{value}</span>
    )
  }

  const renderArrayValue = (_key, value) => {
    const onClick = e => {
      if (!dialogData) {
        setDialogData({
          title: 'Event Metadata',
          nameValues: value
        })
      }
    }
    return (
      <span className='debug-summary__value' data-cy='value' onClick={onClick} style={{ color: 'var(--w-color-primary', cursor: 'pointer' }}>View...</span>
    )
  }

  const renderValue = (key, value) => {
    return (Array.isArray(value))
      ? renderArrayValue(key, value)
      : renderScalarValue(key, value)
  }

  const renderSearchCell = (key, value) => {
    const className = classNames(
      'debug-summary__search',
      { 'debug-summary__search--hide': hover }
    )
    return searchable.includes(key) && value
      ? <Wham.Icon className={className} iconName='search' data-cy='search' />
      : <span className='debug-summary__empty' data-cy='search'>&nbsp;</span>
  }

  return (
    <>
      <table data-cy='summary'>
        <tbody>
          {summaryData.map(({ key, value, goToAction }, index) =>
            <tr
              key={index}
              className={`debug-summary__row ${searchable.includes(key) && value ? 'debug-summary__row-searchable' : ''}`}
              data-cy='row'
              onClick={e => {
                if (searchable.includes(key) && value) {
                  if (!e.target.className.includes('debug-summary__value-copyable')) {
                    onSearch(key, value)
                  }
                }
              }}
            >
              <td className='debug-summary__key-cell'>
                <span className='debug-summary__key' data-cy='key'>{key}</span>
              </td>

              <td className='debug-summary__value-cell'>
                {
                  goToAction
                    ? renderValueAsLink(key, value, goToAction)
                    : copyable.includes(key)
                      ? renderCopyableValue(key, value)
                      : renderValue(key, value)
                }
              </td>

              <td className='debug-summary__search-cell'>
                {renderSearchCell(key, value)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {
        dialogData && (
          <ArrayValueDialog
            onClose={() => setDialogData(null)}
            title={dialogData.title}
            nameValues={dialogData.nameValues}
          />
        )
      }
    </>
  )
}

DebugSummary.propTypes = {
  summaryData: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
}

export default DebugSummary

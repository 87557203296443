import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { human } from '../../../lib/numbers'

import './style.css'

const DebugFacet = ({
  name,
  description,
  opened,
  facets,
  pending,
  asLabel = option => option.key,
  onClick,
  onReset,
  onToggle
}) => {
  const [expanded, setExpanded] = useState(null)

  const timeoutExpanded = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutExpanded.current)
    }
  }, [])

  useEffect(() => {
    if (pending) {
      clearTimeout(timeoutExpanded.current)
      setExpanded(null)
    }
  }, [pending])

  const onMouseOver = i => {
    if (!pending) {
      timeoutExpanded.current = setTimeout(() => {
        setExpanded(i)
      }, 1000)
    }
  }

  const onMouseOut = () => {
    clearTimeout(timeoutExpanded.current)
    setExpanded(null)
  }

  const facet = facets.find(f => f.name === name)

  const somethingSelected = facet && facet.options.some(option => option.selected)

  return (
    <div className={classNames('debug-facet', { 'debug-facet--pending': pending })}>
      <div className='debug-facet__header' data-cy='debug-facet__header' onClick={onToggle}>
        <div className='debug-facet__title-wrapper'>
          <span className='debug-facet__title-icon w-icon'>{opened ? 'expand_more' : 'chevron_right'}</span>
          <h2 className='debug-facet__title' data-cy='debug-facet__title'>{description}</h2>
        </div>

        {somethingSelected ? (
          <button
            className='debug-facet__reset-button w-btn'
            disabled={pending}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              facet && onReset(facet)
            }}
          >
            <span className='w-icon'>clear</span>
          </button>
        ) : null}
      </div>

      {facet && opened ? (
        <div className='debug-facet__content'>
          {facet.options.length ? facet.options.map((option, i) => (
            <div className='debug-facet__option' data-cy='debug-facet__option' key={i}>
              <div className='debug-facet__option-wrapper'>
                <input
                  className='debug-facet__option-input'
                  id={`debug-facet__option-group--${name}--${i}`}
                  data-cy='debug-facet__option-input'
                  type='checkbox'
                  disabled={pending}
                  checked={option.selected === true}
                  onClick={() => onClick(facet, option)} />
                <label
                  className={classNames('debug-facet__option-label', { 'debug-facet__option-label--expanded': i === expanded })}
                  htmlFor={`debug-facet__option-group--${name}--${i}`}
                  data-cy='debug-facet__option-label'
                  onMouseOver={() => onMouseOver(i)}
                  onMouseOut={() => onMouseOut()}
                >{asLabel(option, i === expanded)}</label>
              </div>

              <span className='debug-facet__option-count' data-cy='debug-facet__option-count'>{human(option.count)}</span>
            </div>
          )) : (
              <span className='debug-facet__option-none'>[none]</span>
            )}

          {facet.sumOtherDocCount ? (
            <div className='debug-facet__others'>
              <span className='debug-facet__other-label'>[others]</span>
              <span className='debug-facet__others-count'>{human(facet.sumOtherDocCount)}</span>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}

DebugFacet.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  facets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    sumOtherDocCount: PropTypes.number.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      selected: PropTypes.bool.isRequired
    }))
  })),
  pending: PropTypes.bool.isRequired,
  asLabel: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default DebugFacet

export const SELECTED_TO_PLAY = 'SelectedToPlay'
export const BLOCKED = 'Blocked'
export const UNUSED = 'Unused'
export const NO_VALID_CREATIVE = 'NoValidCreative'
export const SENT_TO_TRANSCODE = 'SentToTranscode'
export const NO_ADVERT = 'NoAds'
export const MAX_DEPTH_REACHED = 'MaxDepthReached'
export const TIMEOUT = 'Timeout'
export const NON_LINEAR = 'NonLinear'
export const PARSE_FAILURE = 'ParseFailure'
export const CALL_FAILURE = 'CallFailure'
export const RESOLVER_FAILURE = 'ResolverFailure'

export const MACRO_HEADER_DEFAULT = '(User-Agent,DNT,X-Forwarded-For,Remote-Address,Referer,X-Device-User-Agent=[User-Agent])'

export const TZ = 'America/New_York'

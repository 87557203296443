import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Rule.scss'
import '../../scss/wham.scss'

class Rule extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string
  }

  render () {
    const {
      label,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-rule',
      (label ? 'w-rule--labeled' : null),
      this.props.className
    )

    const rule = (label)
      ? <span className={componentClass} {...otherProps}><span className='w-rule__label'>{label}</span></span>
      : <hr className={componentClass} {...otherProps} />

    // render popover element
    return (rule)
  }
}

export default Rule

import React, { useRef, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from 'moment-timezone'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { gql, useMutation } from '@apollo/client'

import * as Wham from '../../wham/components'

import { validateAsset } from '../../actions/assets'
import { TZ } from '../../constants'
import { ViewContext } from '../../context'
import { useCopied, useDocumentTitle } from '../../lib/custom-hooks'
import { makeProxyUrl } from '../../lib/playlist'
import * as selectors from '../../selectors'
import { hasPermissionToRetranscode } from '../../lib/creative-assets'

import CreativeDetailSummary from '../../components/creative-detail-summary'
import CreativeDetailTabs from '../../components/creative-detail-tabs'
import CreativeIcon from '../../components/creative-icon'
import VideoPlayer from '../../components/video-player/loadable-video-player'

import styles from './index.module.scss'

const GQL_CREATIVE_RETRANSCODE = gql`
  mutation CreativeRetranscode($id: ID!) {
    creativeRetranscode(id: $id) {
      id
    }
  }
`

const find = (uri, validations) => {
  const found = Object.keys(validations.media.results).find(key => key === uri)

  if (found) {
    const { pending, passed: passedInteger, asset, spec } = validations.media.results[found]

    const complete = pending === false
    const passed = passedInteger === 1
    const failed = passedInteger === 0

    return {
      pending,
      complete,
      passed,
      failed,
      asset,
      spec
    }
  }

  return {}
}

const CreativeDetailRecord = ({
  record,
  validations,
  onRefetch = () => {},
  dispatch
}) => {
  const { partner, claims } = useContext(ViewContext)

  const {
    id,
    epoch,
    mezzFile,
    status
  } = record

  const safari = navigator.vendor.toLowerCase().includes('apple')

  const copiedTimeout = useRef()
  const redoneTimeout = useRef()

  const [player, setPlayer] = useState({})
  const [redone, setRedone] = useState({})
  const [validation, setValidation] = useState({})

  const [creativeRetranscode, { loading: retranscodeCallInProgress }] = useMutation(GQL_CREATIVE_RETRANSCODE, {
    onError: error => {
      setRedone({ error })

      redoneTimeout.current = setTimeout(() => {
        setRedone({})
      }, 3000)
    },
    onCompleted: () => {
      setRedone({ complete: true })
      onRefetch()
    }
  })

  useDocumentTitle('Creative Asset Details')

  useEffect(() => {
    const { complete, passed, failed, asset, spec } = find(mezzFile, validations)

    if (complete) {
      setValidation({ complete, passed, failed, asset, spec })
    }
  }, [validations])

  useEffect(() => {
    clearTimeout(copiedTimeout.current)
    clearTimeout(redoneTimeout.current)

    const { pending, complete, passed, failed, asset, spec } = find(mezzFile, validations)

    if (pending) {
      setValidation({ checking: true })
    } else if (complete) {
      setValidation({ complete, passed, failed, asset, spec })
    } else {
      setValidation({})
    }
  }, [id])

  useEffect(() => {
    return () => {
      clearTimeout(copiedTimeout.current)
      clearTimeout(redoneTimeout.current)
    }
  }, [])

  useEffect(() => {
    const { complete, passed, failed, asset, spec } = find(mezzFile, validations)

    if (complete) {
      setValidation({ complete, passed, failed, asset, spec })
    }
  }, [validations])

  const [onCopy, isCopied] = useCopied()

  const onPlay = uri => {
    const url = makeProxyUrl(uri)
    setPlayer({ url, loading: true })
  }

  const onPlayerReady = () => {
    setPlayer({
      ...player,
      loading: undefined,
      playing: true
    })
  }

  const onPlayerEnded = () => {
    setPlayer({})
  }

  const onPlayerError = () => {
    setPlayer({
      ...player,
      error: 'Asset unavailable'
    })
  }

  const onPlayerReset = () => {
    setPlayer({})
  }

  const onRedo = () => {
    clearTimeout(redoneTimeout.current)

    creativeRetranscode({
      variables: {
        id
      }
    })
  }

  const onValidate = uri => {
    setValidation({ checking: true })
    dispatch(validateAsset({ uri }))
  }

  const timestamp = moment(epoch).tz(TZ).format('M/D/YYYY @ h:mm:ss a')

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <div className={styles.header_left}>
          <div className={styles.header_left_upper}>
            <CreativeIcon status={status} tooltips={true} />

            <CopyToClipboard text={mezzFile} onCopy={() => onCopy(mezzFile)}>
              <div className={classNames(styles.mezz_wrap, styles.copyable, { [styles.copied]: isCopied(mezzFile) })}>
                <span className={styles.mezz}>{mezzFile}</span>
              </div>
            </CopyToClipboard>
          </div>

          <div className={styles.header_left_lower}>
            <CopyToClipboard text={timestamp} onCopy={() => onCopy(timestamp)}>
              <span className={classNames(styles.copyable, { [styles.copied]: isCopied(timestamp) })}>{timestamp}</span>
            </CopyToClipboard>
          </div>
        </div>

        <div className={styles.header_right}>
          <button
            className={classNames(styles.button, styles.play, { [styles.button__safari]: safari })}
            disabled={player.loading || player.playing}
            onClick={() => {
              onPlay(mezzFile)
            }}
          >
            {player.loading ? (
              <Wham.ProgressCircular className={styles.play_loading} active inverse type='indeterminate' sqSize={16} />
            ) : player.playing ? (
              <Wham.Progress className={styles.play_playing} active inverse type='indeterminate' />
            ) : (
                  <span className={styles.play_icon}>play_arrow</span>
                )}
            <span>Play Asset</span>
          </button>

          <button
            className={classNames(styles.button, styles.validate, { [styles.validate_failed]: validation.failed, [styles.button__safari]: safari })}
            disabled={validation.checking || validation.complete}
            onClick={() => {
              onValidate(mezzFile)
            }}
          >
            {validation.checking ? (
              <Wham.ProgressCircular className={styles.validate_checking} active type='indeterminate' sqSize={16} />
            ) : validation.failed ? (
              <span className={classNames(styles.validate_icon, styles.validate_icon_failed)}>error</span>
            ) : (
                  <span className={styles.validate_icon}>check</span>
                )}
            <span>Validate</span>
          </button>

          {hasPermissionToRetranscode(partner, claims)
            ? (
              <button
                className={classNames(styles.button, styles.redo, { [styles.redo_error]: redone.error, [styles.button__safari]: safari })}
                disabled={retranscodeCallInProgress || status === 'Retranscode' || status === 'Pending'}
                onClick={onRedo}
              >
                {retranscodeCallInProgress ? (
                  <Wham.ProgressCircular className={styles.redo_redoing} active type='indeterminate' sqSize={16} />
                ) : redone.error ? (
                  <span className={classNames(styles.redo_icon, styles.redo_icon_error)}>warning</span>
                ) : (
                      <span className={styles.redo_icon}>redo</span>
                    )}
                <span>Retranscode</span>
              </button>
            ) : null}
        </div>
      </header>

      <article className={styles.summary}>
        <CreativeDetailSummary
          record={record}
          onCopy={onCopy}
          isCopied={isCopied}
        />
      </article>

      <article className={styles.tabs}>
        <CreativeDetailTabs
          record={record}
          validation={validation}
        />
      </article>

      {player.url ? (
        <div className={styles.player}>
          <VideoPlayer
            url={player.url}
            onReady={() => onPlayerReady()}
            onEnded={() => onPlayerEnded()}
            onError={() => onPlayerError()}
          />

          {player.loading ? null : (
            <button className={styles.player_cancel} onClick={onPlayerReset}>
              <span className={styles.player_cancel_icon}>close</span>
            </button>
          )}
        </div>
      ) : null}

    </section>
  )
}

CreativeDetailRecord.propTypes = {
  record: PropTypes.object.isRequired,
  validations: PropTypes.shape({
    media: PropTypes.shape({
      results: PropTypes.object.isRequired
    }).isRequired
  }).isRequired,
  onRefetch: PropTypes.func,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const partner = selectors.activePartnerGlobalSelector(state)
  const validations = selectors.assetValidatorSelector(partner)(state)

  return {
    validations
  }
}

export default connect(mapStateToProps)(CreativeDetailRecord)

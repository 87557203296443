import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './ProgressCircular.scss'
import '../../scss/wham.scss'

const PROGRESS_TYPES = [
  'default',
  'indeterminate'
]

class ProgressCircular extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    sqSize: PropTypes.number,
    strokeWidth: PropTypes.number,
    progress: PropTypes.number,
    inverse: PropTypes.bool,
    active: PropTypes.bool,
    type: PropTypes.oneOf(PROGRESS_TYPES)
  }

  render () {
    const {
      className,
      progress,
      strokeWidth,
      sqSize,
      inverse,
      type,
      active,
      ...otherProps
    } = this.props

    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (sqSize - strokeWidth) / 2
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - dashArray * progress

    // classes
    const wrapperClasses = classNames(
      'w-progress-circular',
      (inverse ? 'w-progress-circular--inverse' : null),
      (active ? 'w-progress-circular--active' : null),
      (type ? 'w-progress-circular--' + type : null),
      className
    )

    return (
      <div className={wrapperClasses} {...otherProps}>
        <svg className='w-progress-circular__container' width={sqSize} height={sqSize} viewBox={viewBox}>
          <circle className='w-progress-circular__background' cx={sqSize / 2} cy={sqSize / 2} r={radius} strokeWidth={`${strokeWidth}px`} />
          <circle className='w-progress-circular__progress' cx={sqSize / 2} cy={sqSize / 2} r={radius} strokeWidth={`${strokeWidth}px`} transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`} style={{ strokeDasharray: dashArray, strokeDashoffset: dashOffset }} />
        </svg>
      </div>
    )
  }
}

ProgressCircular.defaultProps = {
  sqSize: 20,
  progress: 0,
  strokeWidth: 2
}

export default ProgressCircular

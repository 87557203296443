import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

import safe from '../safe'

import { selectRule, selectActivePartner } from '../../selectors'

import { success, failure } from '../../actions/async'
import {
  setRuleOrder,
  FETCH_RULES,
  SAVE_GLOBAL_RULE,
  FETCH_GLOBAL_RULE,
  SAVE_RULE,
  TOGGLE_RULE_IS_ACTIVE,
  updatingClear,
  updatingDefine,
  updatingEvent
} from '../../actions/rules'

import { CREATE_RULE_STRING } from '../../route-paths'

import { getResponseData } from '../../services/api/helpers'
import {
  getGlobalRule,
  updateGlobalRule
} from '../../services/partner/global-rule'
import {
  getRules,
  createRule,
  toggleRuleIsActive,
  updateRule
} from '../../services/partner/rules'

import { goToEdit } from '../../views/rule-manager/view-rules/local-actions.js'

import handleErrs from '../global-error-handling'

export const saveRuleTask = function * ({ payload }) {
  try {
    const {
      rule,
      revert,
      editRuleAfterSave = true
    } = payload

    yield SAGA.put(updatingDefine({ active: rule.active }))
    yield SAGA.put(setRuleOrder())

    const partnerId = yield selectActivePartner
    const reduxRules = yield selectRule(partnerId)
    const reduxRule = R.defaultTo({}, reduxRules.find(({ id }) => id === rule.id))

    const normalisedRule = {
      ...rule,
      ...R.pick(['priority'], reduxRule), // Rules are returned with implicit priority, but POST requires "priority" object; we pick this from the redux store (payload only contains numerical display priority)
      name: R.trim(rule.name) ? rule.name : partnerId
    }

    let ruleCall
    if (normalisedRule.id === CREATE_RULE_STRING) {
      ruleCall = createRule
      delete normalisedRule.id
    } else {
      ruleCall = updateRule
      if (!revert) {
        if (!payload.priorityChanged) {
          delete normalisedRule.priority
        }
      }
    }

    if (revert) {
      if (rule.active !== reduxRule.active) {
        yield * toggleRuleIsActiveTask({ payload: { ...rule, active: !rule.active } })
      }
    }

    const saveCall = yield SAGA.call(ruleCall, { rule: normalisedRule, partnerId })
    const data = getResponseData(saveCall)

    yield SAGA.put(success(SAVE_RULE, { rule: data, revert }))

    if (editRuleAfterSave) {
      yield SAGA.put(goToEdit({ partnerId, ruleId: data.id }))
    } else {
      yield SAGA.call(fetchRulesForPartner)
    }
  } catch (error) {
    yield SAGA.put(failure(SAVE_RULE, { error }))
    yield SAGA.put(updatingClear())
  }
}

export const toggleRuleIsActiveTask = function * ({ payload }) {
  try {
    const partnerId = yield selectActivePartner
    const { id: ruleId, active } = payload
    const response = yield SAGA.call(toggleRuleIsActive, { partnerId, ruleId, active })
    yield SAGA.put(success(TOGGLE_RULE_IS_ACTIVE, { rule: getResponseData(response) }))
  } catch (error) {
    yield SAGA.put(failure(TOGGLE_RULE_IS_ACTIVE, { error }))
  }
}

export const updateGlobalRuleTask = function * ({ payload }) {
  try {
    const {
      rule,
      revert
    } = payload

    const partnerId = yield selectActivePartner
    const response = yield SAGA.call(updateGlobalRule, { partnerId, rule })

    const data = getResponseData(response)

    yield SAGA.put(success(SAVE_GLOBAL_RULE, { rule: data, revert }))
  } catch (err) {
    yield handleErrs(err, SAVE_GLOBAL_RULE)
  }
}

export const fetchGlobalRuleTask = function * () {
  try {
    const partnerId = yield selectActivePartner
    const globalRuleCall = yield SAGA.call(
      getGlobalRule,
      {
        partnerId
      }
    )

    yield SAGA.put(
      success(
        FETCH_GLOBAL_RULE,
        { globalRule: getResponseData(globalRuleCall) }
      )
    )

    return globalRuleCall
  } catch (err) {
    yield handleErrs(err, FETCH_GLOBAL_RULE)
  }
}

export const fetchRulesForPartner = function * () {
  try {
    const partnerId = yield selectActivePartner
    const rules = yield SAGA.call(
      getRules,
      { partnerId }
    )

    yield SAGA.put(success(FETCH_RULES, { rules: getResponseData(rules) }))
    yield SAGA.put(updatingEvent())
  } catch (error) {
    yield handleErrs(error, FETCH_RULES)
    yield SAGA.put(updatingClear())
  }
}

export default function * ruleManagement () {
  yield SAGA.takeEvery(FETCH_GLOBAL_RULE.default, safe(fetchGlobalRuleTask, 'fetchGlobalRuleTask'))
  yield SAGA.takeEvery(FETCH_RULES.default, safe(fetchRulesForPartner, 'fetchRulesForPartner'))
  yield SAGA.takeEvery(SAVE_GLOBAL_RULE.default, safe(updateGlobalRuleTask, 'updateGlobalRuleTask'))
  yield SAGA.takeEvery(SAVE_RULE.default, safe(saveRuleTask, 'saveRuleTask'))
  yield SAGA.takeEvery(TOGGLE_RULE_IS_ACTIVE.default, safe(toggleRuleIsActiveTask, 'toggleRuleIsActiveTask'))
}

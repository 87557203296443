import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../wham/components'

import { WhitelistContext } from '../../../context'

import './style.css'

const WhitelistAddLookup = ({
  item,
  looking,
  options,
  onLookup,
  onIgnore,
  onSelect
}) => {
  const { pending } = useContext(WhitelistContext)

  return (
    <div className='whitelist-add-lookup'>
      {looking ? (
        <Wham.ProgressCircular
          className='whitelist-add-lookup__spinner'
          active
          type='indeterminate'
          sqSize={17}
        />
      ) : (
        <Wham.Button
          className='whitelist__button whitelist__button-lookup'
          disabled={pending || !item.label}
          onClick={() => onLookup(item)}
        >
          <Wham.Icon className='whitelist__button-icon' iconName='find_in_page' />
        </Wham.Button>
      )}

      {options.length ? (
        <div>
          <div className='whitelist-add-lookup__background' onClick={() => onIgnore()} />
          <ul className='whitelist-add-lookup__list'>
            {options.map(option => (
              <li
                key={option.id}
                className='whitelist-add-lookup__item'
                onClick={e => onSelect(e, option)}
              >{option.value}</li>
            ))}
          </ul>
        </div>
      ) : null}
    </div>
  )
}

WhitelistAddLookup.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired,
  looking: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onIgnore: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default WhitelistAddLookup

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './index.module.scss'

const HeaderBack = ({
  text,
  path,
  onClick
}) => {
  return (
    <nav className={styles.nav}>
      <a
        className={styles.nav_back}
        href={path}
        onClick={e => {
          e.preventDefault()
          onClick(path)
        }}
      >
        <span className={classNames('w-icon', styles.nav_back_icon)}>navigate_before</span>
        <span>{text}</span>
      </a>
    </nav>
  )
}

HeaderBack.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default HeaderBack

export const getChildComponentByType = function (type) {
  const child = this.props.children
  if (Array.isArray(child)) {
    return child.filter(comp => {
      if (!comp) return false
      if (!comp.type) return false
      return comp.type === type
    })
  }
  return (child.type === type) ? child : null
}

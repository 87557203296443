import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../../wham/components'

import './style.css'

const KeyValuePairInteractive = ({
  title,
  hint,
  options,
  onClick
}) => {
  const timeout = useRef()

  const [tooltip, setTooltip] = useState(null)

  const onMouseOver = el => {
    timeout.current = setTimeout(() => {
      setTooltip(el)
    }, 500)
  }

  const onMouseOut = () => {
    clearTimeout(timeout.current)
    setTooltip(null)
  }

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  return (
    <div className='key-value-pair-interactive'>
      <h3 className='key-value-pair-interactive__title'>{title}</h3>

      <div className='key-value-pair-interactive__cloud'>
        {options.map(({ text, value, description }, i) => (
          <button
            key={i}
            className='key-value-pair-interaction w-btn'
            draggable='true'
            onClick={e => {
              e.preventDefault()
              onClick(value || text)
            }}
            onDragStart={e => {
              clearTimeout(timeout.current)
              setTooltip(null)
              e.dataTransfer.effectAllowed = 'copy'
              e.dataTransfer.setData('text/plain', value || text)
            }}
            onMouseOver={e => {
              onMouseOver(e.target)
            }}
            onMouseOut={() => {
              onMouseOut()
            }}
            data-tooltip={description}
          >{text}</button>
        ))}

        {tooltip ? (
          <Wham.Tooltip className='key-value-pair-interaction__tooltip' trigger={tooltip} placement='bottom' offset={{ top: 0, left: 0 }} />
        ) : null}
      </div>

      <div className='key-value-pair-interactive__hint'>
        <p className='key-value-pair-interactive__hint-text'>{hint}</p>
      </div>
    </div>

  )
}

KeyValuePairInteractive.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.string,
    description: PropTypes.string.isRequired
  })).isRequired,
  hint: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default KeyValuePairInteractive

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Mark.scss'
import '../../../scss/wham.scss'

const BAMTECH_MARK_SIZES = ['large', 'small', 'medium']

class MLBMark extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(BAMTECH_MARK_SIZES),
    inverse: PropTypes.bool,
    monochrome: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      inverse = false,
      monochrome,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-mark',
      'w-mark--mlb',
      'w-mark--long',
      (size ? 'w-mark--' + size : null),
      (inverse ? 'w-mark--inverse' : null),
      (!inverse && monochrome ? 'w-mark--monochrome' : null),
      className
    )

    return (
      <span className={componentClass} {...otherProps}>
        <svg xmlns='http://www.w3.org/2000/svg' width='119.102' height='60.837' viewBox='0 0 119.102 60.837'>
          <path className='w-mark__element' d='M117.093,2.008A6.812,6.812,0,0,0,112.245,0H82.78L99.732,28.283l1.55,0.192,0.9,1.291v1.226l1.1,0.194,0.969,1.354V33.7l1.1,0.191s1.1,1.162,1.1,1.228l0,2.646a18.285,18.285,0,0,0,4.521,3.03c1.485,0.58,1.679,2.971,2.52,4.262,1.163,1.806,2.711,2.58,2.39,3.613-0.772,2.775-3.611,7.426-6.258,7.687,0,0-6.584,0-10.459.006l0,4.469h13.078a6.864,6.864,0,0,0,6.855-6.856V6.855A6.82,6.82,0,0,0,117.093,2.008Z' transform='translate(0)' fill='#d50032' />
          <path className='w-mark__element' d='M72.736,0H6.856A6.857,6.857,0,0,0,0,6.855V53.98a6.856,6.856,0,0,0,6.855,6.857h42.8c-1.075-1.79-2.279-3.823-2.65-4.441H41.9c-0.007-12.651,4.314-19.691,9.414-21.05,0.709-.13.386-3.616-0.519-4.649a24.521,24.521,0,0,1-2.97-.063c-0.646-.192-0.195-0.838-0.195-0.838l2.386-5.167-0.324-1.421-8.844.007c-0.13,0,7.292-5.106,7.292-5.106C48.523,4.682,62.274,3.642,70.6,9.188c4.908,3.291,5.3,9.748,4.914,14.267-0.064.258-1.291,0.064-1.291,0.064s-0.835,4.906,1.357,4.906l9.685,0c3.938-.132,7.749,2.514,7.749,2.514l0.966-3.358ZM14.171,51.137a4.707,4.707,0,1,1-.14-9.413h0.137A4.707,4.707,0,0,1,14.171,51.137Z' transform='translate(0)' fill='#002d72' />
        </svg>
      </span>
    )
  }
};

export default MLBMark

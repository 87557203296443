import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Mark.scss'
import '../../../scss/wham.scss'

const BAMTECH_MARK_SIZES = ['large', 'small', 'medium']

class EurosportMark extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(BAMTECH_MARK_SIZES),
    inverse: PropTypes.bool,
    monochrome: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      inverse = false,
      monochrome,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-mark',
      'w-mark--eurosport',
      (size ? 'w-mark--' + size : null),
      (inverse ? 'w-mark--inverse' : null),
      (!inverse && monochrome ? 'w-mark--monochrome' : null),
      className
    )

    return (
      <span className={componentClass} {...otherProps}>
        <svg xmlns='http://www.w3.org/2000/svg' width='879.0894165px' height='1000px' viewBox='0 0 879.0894165 1000'>
          <path
            className='w-mark__element' fill='#1D0F44' d='M458.3199768,283.5844421H237.1092987V32.4694939C237.1092987,14.570776,251.6789551,0,269.5971069,0
          h576.9545288c17.899292,0,32.4700928,14.6612606,32.4700928,32.5605316l-0.0544434,140.791626 c0,19.318161-12.097168,33.2699738-31.3242798,33.2699738H458.3199768V283.5844421z'
          />
          <path
            className='w-mark__element' fill='#1D0F44' d='M847.7296143,1000h-618.520752l229.1055908-206.5671997h388.650238
          c18.663147,0,32.1247559,15.843689,32.1247559,32.2330322v141.8652344c0,17.9170532-12.9520264,32.4689331-30.8513184,32.4689331'
          />
          <polygon
            className='w-mark__element' fill='#1D0F44' points='458.3199768,607.2146606 751.9082031,482.1940002 458.3199768,482.1940002
          458.3199768,283.5939026 288.2235718,482.1940002 0,482.1940002 184.0121155,607.2146606 26.0306625,793.4278564 289.9511108,679.0842896 458.3199768,793.4278564'
          />
        </svg>
      </span>
    )
  }
};

export default EurosportMark

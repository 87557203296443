import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Container.scss'
import '../../scss/wham.scss'

const Container = ({
  children,
  className,
  title,
  collapsed = false,
  onClick,
  ...otherProps
}) => {
  const componentClass = classNames(
    'w-container',
    'w-container--collapsible',
    collapsed && 'w-container--collapsed',
    className
  )

  const wrappedChildren = collapsed ? null : (
    <div className='w-container__content'>
      <div className='w-container__content__box'>
        {children}
      </div>
    </div>
  )

  return (
    <div className={componentClass} {...otherProps}>
      {title
        ? <div className='w-container__header' onClick={onClick}>{title}</div>
        : null}
      {wrappedChildren}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  collapsed: PropTypes.bool,
  onClick: PropTypes.func
}

export default Container

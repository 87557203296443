import React from 'react'
import { withRouter } from 'react-router-dom'

import * as Wham from '../../wham/components'

import { useDocumentTitle } from '../../lib/custom-hooks'
import Header from '../../containers/header'

import notes from '../../release-notes/index.txt'

import './style.css'

const APP_VERSION = __VERSION__ // eslint-disable-line no-undef
const VERSION_DATE = __VERSION_DATE__ // eslint-disable-line no-undef

const GoBackButton = withRouter(({ history }) => (
  <div className='release-notes__back' onClick={() => history.goBack()}>
    <Wham.AppSubject backHandler={() => {}} primary='Back to AdEngine' />
  </div>
))

const ReleaseNotes = () => {
  useDocumentTitle('Release Notes')

  return (
    <div className='release-notes'>
      <Header
        left={
          <Wham.AppBar2Left>
            <GoBackButton />
          </Wham.AppBar2Left>
        }
        middle={
          <Wham.AppBar2Center heading={`Version: ${APP_VERSION}`} subtitle={VERSION_DATE} />
        }
      />
      <div className='release-notes__content'>
        <Wham.Markdown markdown={notes} template='release-notes' />
      </div>
    </div>
  )
}

export default ReleaseNotes

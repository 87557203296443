import * as R from 'ramda'

import { LOGIN, LOGOUT } from '../../actions/login'
import { SET_FEATURE } from '../../actions/features'

const defaultState = {
  isLoggedIn: false,
  developer: false,
  role: '',
  lastLoggedIn: '',
  firstName: '',
  lastName: '',
  partners: [],
  features: {},
  autoEnrollmentAlert: false
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN.success: {
      // const { roles } = action.payload
      // const { features = {} } = window.adEngineUI

      return {
        ...state,
        isLoggedIn: true,
        pending: false,
        error: '',
        features: {
        },
        ...action.payload
      }
    }

    case LOGIN.failure: {
      return {
        ...state,
        isLoggedIn: false,
        error: R.pathOr(
          action.payload,
          ['error', 'message'],
          action
        ),
        pending: false
      }
    }

    case LOGOUT: {
      return {
        ...defaultState,
        isLoggedIn: false,
        pending: false
      }
    }

    case SET_FEATURE: {
      const { name, value } = action.payload

      return {
        ...state,
        features: {
          ...state.features,
          [name]: value
        }
      }
    }

    default:
      return { ...state }
  }
}

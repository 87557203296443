import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import * as R from 'ramda'
import * as Wham from '../../wham/components'

import {
  clearResults,
  removeResult,
  selectResult,
  validateAsset
} from '../../actions/assets'

import {
  activePartnerGlobalSelector,
  assetValidatorSelector
} from '../../selectors'

import { useDocumentTitle } from '../../lib/custom-hooks'

import Header from '../../containers/header'

import MezzForm from '../../components/asset-validator/mezz-form'
import UriList from '../../components/asset-validator/url-list'
import { makeErrorBoundary } from '../../components/error-hocs'
import ValidationResult from '../../components/asset-validator/validation-result'
import DocLink from '../../containers/doc-link'

import './style.css'

const ELayoutColumn = makeErrorBoundary(Wham.LayoutColumn)

const getPending = (uris = [], results = {}) => R.reduce((p, uri) => +results[uri].pending + p, 0, uris)

const AssetValidatorView = ({
  dispatch,
  inputUri = '',
  uris,
  results,
  selected
}) => {
  useDocumentTitle('Asset Validator')

  useEffect(() => {
    if (getPending(uris, results)) {
      const el = document.querySelector('.asset-validator__menu_item--latest')

      if (el) {
        el.scrollIntoView({})
      }
    }
  }, [uris, results])

  const onDrop = useCallback(R.pipe(R.map(file => dispatch(validateAsset({ uri: 'urn:uuid:' + uuid(), file })))), [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: ['video/x-flv', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wm'],
    noClick: true,
    onDrop
  })

  const hasUris = !!R.length(uris)
  const first = R.prop(R.head(uris))
  const latest = R.compose(R.find(R.has('latest')), R.values)
  const current = R.defaultTo(first(results), latest(results))
  const validation = selected ? results[selected] : current || false

  selected = selected || validation.uri

  const pending = getPending(uris, results)
  const pendingSelected = !!R.path(['pending'], validation)

  return (
    <div className='asset-validator w-layout w-layout--fullwidth w-layout--fullheight w-layout--spacing-none'>
      <div className='w-layout__row w-layout__row--fit'>
        <ELayoutColumn className={'asset-validator__header_row'} >
          <Header />
        </ELayoutColumn>
      </div>

      <div className={`creative_drop-area w-drop-area w-drop-area--overlay ${isDragActive ? 'w-drop-area--active' : ''} ${isDragAccept ? 'w-drop-area--accepted' : ''} ${isDragReject ? 'w-drop-area--rejected' : ''}`} {...getRootProps()}>
        <div className='w-layout__row w-layout__row--fill'
          style={!hasUris
            ? {
              alignItems: 'center',
              justifyContent: 'center'
            } : {}}
        >
          <ELayoutColumn
            className='asset-validator__column creative_uri-list results-container'
            type='minor'
            style={{
              maxWidth: '720px',
              flex: hasUris
                ? '.33 1 0'
                : '.66 1 0'
              }}
          >
            <div className='add-uri-list-wrapper'>
              <UriList
                clearAllResults={R.compose(dispatch, clearResults)}
                results={results}
                selected={selected}
                removeResult={R.compose(dispatch, removeResult)}
                selectResult={R.compose(dispatch, selectResult)}
                uris={uris}
              />
              <MezzForm
                inputUri={inputUri}
                hasUris={hasUris}
                dispatch={dispatch}
              />
            </div>
          </ELayoutColumn>
          {hasUris && (
            <ELayoutColumn className='asset-validator__column results-container' type='major'>
              <div style={{
                maxWidth: '1100px',
                margin: '0 auto',
                height: '100%'
                }}
                >
                {pendingSelected ? (
                  <div style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  >
                    <span style={{
                      color: '#949497',
                      fontSize: '1.5rem',
                      textAlign: 'center'
                    }}
                    >
                      {pending > 1 ? (
                        'Validating assets...'
                      ) : (
                        'Validating asset...'
                      )}
                    </span>
                  </div>
                ) : (
                  <ValidationResult validation={validation} selected={selected} />
                )}
              </div>
            </ELayoutColumn>
          )}
        </div>

        <div className='w-drop-area__active-message'>
          <div className='w-drop-area__active-message--accepted'>
            <Wham.Icon className='w-drop-area__icon' iconName='check' />
            <span className='w-drop-area__title'>Drop to upload</span>
          </div>
          <div className='w-drop-area__active-message--rejected'>
            <Wham.Icon className='w-drop-area__icon' iconName='close' />
            <span className='w-drop-area__title'>This file type isn&rsquo;t allowed</span>
          </div>
        </div>

        <input {...getInputProps()} />
      </div>
      <DocLink topic="asset-validator" />
    </div>
  )
}

AssetValidatorView.propTypes = {
  dispatch: PropTypes.func,
  inputUri: PropTypes.string,
  uris: PropTypes.arrayOf(PropTypes.string),
  results: PropTypes.object,
  selected: PropTypes.string,
  pending: PropTypes.bool,
  error: PropTypes.object
}

const mapStateToProps = state => {
  const partnerId = activePartnerGlobalSelector(state)
  const inputUri = R.pathOr(
    '',
    [
      'partner',
      partnerId,
      'forms',
      'assetValidator',
      'fields',
      'uri',
      'value'
    ],
    state
  ).trim()

  const {
    pending,
    selected,
    media
  } = assetValidatorSelector(partnerId)(state)

  const {
    uris,
    results
  } = media

  return {
    inputUri,
    pending,
    selected,
    results,
    uris
  }
}

export default connect(mapStateToProps)(AssetValidatorView)

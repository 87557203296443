import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Badge.scss'
import '../../scss/wham.scss'

const BADGE_SIZES = ['large', 'small']

const BADGE_TYPES = [
  'default',
  'primary',
  'success',
  'danger',
  'warning'
]

class Badge extends React.Component {
  static propTypes = {
    size: PropTypes.oneOf(BADGE_SIZES),
    type: PropTypes.oneOf(BADGE_TYPES),
    className: PropTypes.string,
    label: PropTypes.string,
    inverse: PropTypes.bool
  }

  render () {
    const {
      size,
      type,
      className,
      label,
      inverse,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-badge',
      (type ? 'w-badge--' + type : null),
      (size ? 'w-badge--' + size : null),
      (inverse ? 'w-badge--inverse' : null),
      this.props.className
    )

    // render popover element
    return (
      <span {...otherProps} className={componentClass}>{label}</span>
    )
  }
}

export default Badge

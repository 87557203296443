import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import * as R from 'ramda'
import * as Wham from '../../../wham/components'

import { CREATE_RULE_STRING, GLOBAL_RULE_STRING, paths } from '../../../route-paths'

import {
  editRuleMacro,
  getGlobalRule,
  getRulesForPartner,
  setRuleOrder,
  setExpression
} from '../../../actions/rules'

import * as Selectors from '../../../selectors'

import InPageModal from '../../../components/in-page-modal'
import KeyValuePairModal from '../../../components/key-value-pair/key-value-pair-modal'
import MacroModal from '../../../components/macro/macro-modal'
import MacroSplitter, { HeadPart, TextParts } from '../../../components/macro-splitter'

import DocLink from '../../../containers/doc-link'

import { macroSplitter, macroJoiner } from '../../../lib/macros'
import { isValidUrl } from '../../../lib/util/urls'

import Header from './header'
import BasicHeader from '../../../containers/header'
import HeaderBack from '../../../components/header/header-back'
import ContentFailed from '../../../components/content/content-failed'
import { expandPathTemplate } from '../../../lib/path-matcher'

import Priority from './priority'

import {
  PredicateRuleBuilder,
  BasicRuleBuilder,
  AdvancedRuleBuilder
} from '@adtech/rule-builder-ui'

import './style.css'

export const CreateRule = ({
  rules,
  rule,
  ruleId,
  pending,
  updating,
  partnerId,
  partnerName,
  editMacro,
  setRuleOrder,
  dispatch,
  setExpression
}) => {
  const [macro, setMacro] = useState({})
  const [modal, setModal] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [optionals, setOptionals] = useState(false)
  const [upsertTuple, setUpsertTuple] = useState(null)
  const [priorityChanged, setPriorityChanged] = useState(false)

  useEffect(() => {
    if (!pending) {
      const title = ruleId === CREATE_RULE_STRING ? 'Create Rule' : 'Edit Rule'
      document.title = `adEngine - ${title}`
    }
  }, [pending, ruleId])

  const {
    isGlobal = false,
    evaluationResult = ''
  } = rule

  const {
    header,
    textParts
  } = macroSplitter(evaluationResult)

  useEffect(() => {
    setMacro(macroSplitter(evaluationResult))
  }, [evaluationResult, modal])

  useEffect(() => {
    if (macro.tuples) {
      setOptionals(macro.tuples.some(tuple => tuple?.flags?.optional))
    }
  }, [macro])

  const controls = {
    expanded,
    onClickHeader: () => setExpanded(!expanded),
    onClickField: () => setModal(true)
  }

  const appendToUrl = editedTuple => {
    if (macro.tuples.length) {
      macro.tuples[macro.tuples.length - 1].delimiter = macro.tuples.length > 1 ? macro.tuples[macro.tuples.length - 2].delimiter : '&'
    }

    const updatedTuples = R.append(editedTuple, macro.tuples)
    return macroJoiner(macro.header + macro.textParts[0].text, updatedTuples)
  }

  const deleteFromUrl = index => {
    if (macro.tuples.length > 1) {
      delete macro.tuples[macro.tuples.length - 2].delimiter
    }

    const updatedTuples = R.remove(index, 1, macro.tuples)
    return macroJoiner(macro.header + macro.textParts[0].text, updatedTuples)
  }

  const updateToUrl = editedTuple => {
    const updatedTuples = R.insert(upsertTuple.index, editedTuple, [...R.remove(upsertTuple.index, 1, macro.tuples)])
    return macroJoiner(macro.header + macro.textParts[0].text, R.zipWith(R.mergeRight, macro.tuples, updatedTuples))
  }

  const contentClassName = [
    'create-edit-rule--content',
    rule.name ? '' : 'create-edit-rule--content--hidden'
  ].join(' ')

  const onChangeExpression = expression => {
    setExpression({ rule, expression })
  }

  const getRuleBuilder = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const builderFromQueryString = searchParams.get('builder')
    const builderFromConfig = window.adEngineUI.RULE_BUILDER

    switch (builderFromQueryString || builderFromConfig) {
      case 'predicate':
        return {
          name: 'predicate',
          component: PredicateRuleBuilder
        }
      case 'basic':
      default:
        return {
          name: 'basic',
          component: BasicRuleBuilder
        }
      case 'advanced':
        return {
          name: 'advanced',
          component: AdvancedRuleBuilder
        }
    }
  }

  const renderRuleBuilder = () => {
    const ruleBuilder = getRuleBuilder()

    const ruleBuilderParams = window.adEngineUI.ruleParamsConfig || []

    if (ruleBuilderParams.length === 0) {
      return null
    }

    if (rule && rule.expression) {
      if (!ruleBuilder.component.canEditExpression(rule.expression)) {
        return (
          <div className="rule-builder__cannot-edit">
            The {ruleBuilder.name} rule builder cannot edit this expression
          </div>
        )
      }
    } else {
      // Are we editing an existing rule ?
      if (ruleId !== CREATE_RULE_STRING) {
        // Wait for the fetching of the rule to complete
        return null
      }
    }

    const ruleBuilderProps = {
      expression: rule.expression,
      params: ruleBuilderParams,
      onChange: onChangeExpression
    }

    return React.createElement(ruleBuilder.component, ruleBuilderProps)
  }

  if (!pending && ruleId !== CREATE_RULE_STRING && ruleId !== GLOBAL_RULE_STRING && R.isEmpty(rule)) {
    const path = expandPathTemplate(paths.viewRules, { partnerId })
    return (
      <Wham.Layout className='create-edit-rule' fullWidth colSpacing='none'>
        <BasicHeader
          left={
            <HeaderBack text='Return to Rules' path={path} onClick={() => dispatch(push(path))} />
          }
        />
        <ContentFailed
          id={ruleId}
          label='rule'
          className='rule-content-failed'
          onReturn={() => dispatch(push(path))}
        />
      </Wham.Layout>
    )
  }

  return (
    <Wham.Layout className={isGlobal ? 'create-edit-rule is-global' : 'create-edit-rule'} fullWidth colSpacing='none'>
      <Header
        rule={rule}
        isGlobal={isGlobal}
        partnerId={partnerId}
        partnerName={partnerName}
        pending={pending}
        updating={updating}
        priorityChanged={priorityChanged}
      />

      <Wham.Layout className={contentClassName}>
        <Wham.LayoutRow className='split-view-wrapper'>
          {R.not(isGlobal) ? (
            <Wham.LayoutColumn>
              <Wham.LayoutRow>
                <div className="rule-builder-wrapper">
                  <Wham.Heading level={5}>Rule Criteria</Wham.Heading>
                  {renderRuleBuilder()}
                </div>
              </Wham.LayoutRow>
              <Wham.LayoutRow>
                <Priority
                  pending={pending}
                  rule={rule}
                  setRuleOrder={reorderedRules => {
                    setPriorityChanged(true)
                    setRuleOrder(reorderedRules)
                  }}
                  rules={rules}
                />
              </Wham.LayoutRow>
            </Wham.LayoutColumn>
          ) : null}

          <Wham.LayoutColumn>
            <div className='uri-field'>
              <Wham.Heading className='uri-field__heading' level={4}>
                {isGlobal ? 'Global ad request URL' : '...make this ad request'}
              </Wham.Heading>
              <div className='ad-field'>
                {evaluationResult ? (
                  <MacroSplitter expanded={expanded} onClickHeader={() => setExpanded(!expanded)} onClickField={() => setModal(true)}>
                    <HeadPart headPart={header} />
                    <TextParts textParts={textParts} />
                  </MacroSplitter>
                ) : (
                  <span className='create-edit-rule__enter-url' onClick={() => setModal(true)}>{'Enter a URL here'}</span>
                )}
              </div>
            </div>
          </Wham.LayoutColumn>
        </Wham.LayoutRow>
      </Wham.Layout>

      <DocLink topic="rules" />

      <InPageModal
        showModal={modal}
        header={`${evaluationResult ? 'Edit' : 'Enter'} your ad request:`}
        footer={
          <>
            <Wham.Button
              className='in-page-modal--footer-download'
              type='primary'
              href='/rules/adengine-macro-documentation.pdf'
              download='adengine-macro-documentation.pdf'
            >Download adE Macro Documentation
            </Wham.Button>

            <Wham.Button
              className='in-page-modal--confirm-done'
              data-cy='modal-confirm-done'
              type='primary'
              disabled={!isValidUrl(macro.url)}
              onClick={() => {
                setModal(false)
                editMacro({ target: { value: macro.header + macro.url }, rule })
              }}
            >Save Request URL
            </Wham.Button>
          </>
        }
        onClose={() => {
          setModal(false)
        }}
      >
        <MacroModal
          macro={macro}
          controls={controls}
          onChangeHeader={e => {
            setMacro(macroSplitter(e.target.value + macro.url))
          }}
          onChangeUrl={e => {
            setMacro(macroSplitter(macro.header + e.target.value))
          }}
          onInsert={(tuple, index) => {
            setUpsertTuple({ tuple, index })
          }}
          onDelete={(tuple, index) => {
            setMacro(macroSplitter(deleteFromUrl(index)))
          }}
          onUpdate={(tuple, index) => {
            setUpsertTuple({ tuple, index })
          }}
        />
      </InPageModal>

      {upsertTuple ? (
        <KeyValuePairModal
          tuple={upsertTuple.tuple}
          macros={true}
          encodings={true}
          optionals={optionals}
          onClose={() => {
            setUpsertTuple(null)
          }}
          onSave={editedTuple => {
            if (upsertTuple.index === undefined) {
              setMacro(macroSplitter(appendToUrl(editedTuple)))
            } else {
              setMacro(macroSplitter(updateToUrl(editedTuple)))
            }

            setUpsertTuple(null)
          }}
        />
      ) : null}

      {isGlobal ? (
        <Wham.Message className='global-rule-warning'>This is your team&apos;s global rule</Wham.Message>
      ) : null}
    </Wham.Layout>
  )
}

CreateRule.propTypes = {
  setRuleOrder: PropTypes.func,
  rules: PropTypes.array,
  rule: PropTypes.object,
  lastRule: PropTypes.object,
  ruleId: PropTypes.string,
  getGlobalRule: PropTypes.func,
  getRulesForPartner: PropTypes.func,
  setExpression: PropTypes.func,
  editMacro: PropTypes.func,
  editName: PropTypes.func,
  partnerId: PropTypes.string,
  partnerName: PropTypes.string,
  pending: PropTypes.bool,
  updating: PropTypes.object,
  alert: PropTypes.bool,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.arrayOf(PropTypes.string).isRequired
}

const getRule = (ruleId, globalRule, rules) => {
  const safeFind = type => R.compose(R.defaultTo(type), R.find)

  if (ruleId === GLOBAL_RULE_STRING) {
    return globalRule
  }

  return safeFind({})(
    ({ id }) =>
      R.equals(
        id.toString(),
        ruleId
      ),
    rules)
}

const mapStateToProps = (state, ownProps) => {
  const ruleId = R.path(['params', 'ruleId'], ownProps.match)
  const partnerId = Selectors.activePartnerGlobalSelector(state)
  const { name: partnerName } = Selectors.activePartnerDetailsSelector(partnerId)(state)
  const pending = Selectors.pendingRuleSelector(partnerId)(state)
  const updating = Selectors.updatingRuleSelector(partnerId)(state)
  const alert = Selectors.alertRuleSelector(partnerId)(state)
  const error = Selectors.ruleErrorSelector(partnerId)(state)

  let rules = Selectors.ruleGlobalSelector(partnerId)(state)
  const isNotCreate = ruleId => ruleId !== CREATE_RULE_STRING
  if (isNotCreate(ruleId)) {
    rules = R.filter(({ id }) => isNotCreate(id), rules)
  }

  const globalRule = Selectors.partnerGlobalRuleSelector(partnerId)(state)

  const rule = getRule(ruleId, globalRule, rules)

  return {
    rules,
    rule,
    ruleId,
    pending,
    updating,
    alert,
    error,
    partnerId,
    partnerName
  }
}

const mapDispatchToProps = dispatch => {
  const editMacro = R.compose(dispatch, editRuleMacro)

  return {
    editMacro,
    setRuleOrder: R.compose(dispatch, setRuleOrder),
    getGlobalRule: R.compose(dispatch, getGlobalRule),
    getRulesForPartner: R.compose(dispatch, getRulesForPartner),
    setExpression: R.compose(dispatch, setExpression),
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateRule)

import React from 'react'
import PropTypes from 'prop-types'

import ErrorModal from '../../error-modal'

const SimulatorModalError = ({
  error,
  onClose
}) => {
  return error
    ? <ErrorModal
      message={error}
      onClose={() => onClose()}
    />
    : null
}

SimulatorModalError.propTypes = {
  error: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

export default SimulatorModalError

import moment from 'moment-timezone'

import { TZ } from '../../../constants'

/**
 * The inspector only keeps 5 days history of transactions, apart from any "bookmarked" transactions (which are kept
 * indefinitely). We need to let users select date-ranges that are older than the 5-day limit, so they can search for
 * older "bookmarked" transactions. However, when an entire date-range lies outside the 5-day limit we need to warn
 * the user that their search may return zero results if no "bookmarked" transactions exist for that period...
 */

const MAXIMUM_DAYS = 30
const DETAILED_DAYS = 5

const bookmarkedOnlyDays = [...Array(MAXIMUM_DAYS).keys()]
  .map(key => ++key)
  .reverse()
  .splice(0, MAXIMUM_DAYS - DETAILED_DAYS) // [30, 29, 28 ... 8, 7, 6]

const createDebugDateLimits = now => {
  const minDate = moment(now).tz(TZ).subtract(MAXIMUM_DAYS, 'days').startOf('day').toDate()

  const warnDates = bookmarkedOnlyDays.map(bookmarkedOnly => {
    return moment(now).tz(TZ).subtract(bookmarkedOnly, 'days').startOf('day').toDate()
  })

  return {
    minDate,
    warnDates
  }
}

const beforeDebugDateLimits = ({ now, from, to }) => {
  const sparseDate = moment(now).tz(TZ).subtract(DETAILED_DAYS, 'days').startOf('day')
  return moment(from).tz(TZ).isBefore(sparseDate) && moment(to).tz(TZ).isBefore(sparseDate)
}

export {
  createDebugDateLimits,
  beforeDebugDateLimits
}

const suffixesOrderedByScale = ['b', 'Kb', 'Mb', 'Gb']

function prettyRate (val, { decimals = 3, timeUnitSuffix = '/s' } = {}) {
  const bitvalue = parseFloat(val, 10)
  const sf = `${Math.trunc(bitvalue)}`.length
  const targetIdx = Math.floor((sf - 1) / 3)
  const divisor = Math.pow(1000, targetIdx)
  const inFormat = parseFloat(
    parseFloat(bitvalue / divisor, 10).toFixed(decimals)
  )
  return `${inFormat} ${suffixesOrderedByScale[targetIdx]}${timeUnitSuffix}`
}

export default prettyRate

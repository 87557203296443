import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import blacklist from 'blacklist'
import MenuList from '../MenuList'
import Overlay from '../Overlay'

import './Menu.scss'
import '../../scss/wham.scss'

const MENU_SIZES = ['small']

const MENU_TYPES = [
  'default',
  'spaced',
  'contextual',
  'subtle'
]

class Menu extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    block: PropTypes.bool,
    className: PropTypes.string,
    active: PropTypes.bool,
    size: PropTypes.oneOf(MENU_SIZES),
    type: PropTypes.oneOf(MENU_TYPES)
  }

  render () {
    const {
      type,
      size,
      className,
      children
    } = this.props
    // classes
    const componentClass = classNames(
      'w-menu',
      (type && `w-menu--${type}`),
      (size && `w-menu--${size}`),
      className
    )

    // props
    var props = blacklist(this.props, 'subtle', 'active', 'type', 'size', 'className')
    props.className = componentClass

    return (
      <Overlay className={componentClass}>
        <MenuList type={type} size={size}>
          {children}
        </MenuList>
      </Overlay>
    )
  }
}

Menu.defaultProps = {
  type: 'default'
}

export default Menu

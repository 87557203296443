import * as R from 'ramda'

const messageHandlers = table => {
  const keys = R.keys(table)
  const conds = R.reduce(
    (cond, type) => ([[R.equals(type), () => R.prop(type, table)], ...cond]),
    [[R.T, () => R.identity]],
    keys
  )
  return R.cond(conds)
}
export default messageHandlers

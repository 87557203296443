import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import CreativeVastPath from '../../creative-vast-path'

import styles from './index.module.scss'

const CreativeDetailVast = ({
  className,
  vast,
  copied,
  onCopy
}) => {
  return (
    <table className={className}>
      <tbody>
        <tr>
          <td className={classNames(styles.key, styles.upper)}>VAST ID</td>
          <td className={classNames(styles.value, styles.upper)}>
            <CopyToClipboard text={vast.id} onCopy={() => onCopy(vast.id)}>
              <div className={classNames(styles.copyable, { [styles.copied]: copied })}>
                <span className={styles.text}>{vast.id}</span>
              </div>
            </CopyToClipboard>
          </td>
        </tr>

        <tr>
          <td className={classNames(styles.key, styles.lower)}>Path</td>
          <td className={classNames(styles.value, styles.lower)}>
            <CreativeVastPath path={vast.path}/>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

CreativeDetailVast.propTypes = {
  className: PropTypes.string,
  vast: PropTypes.shape({
    id: PropTypes.string.isRequired,
    path: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  copied: PropTypes.bool.isRequired,
  onCopy: PropTypes.func.isRequired
}

export default CreativeDetailVast

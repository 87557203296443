import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { paths } from '../../route-paths'
import * as selectors from '../../selectors'

import { isAllowed } from './util'

import { PAGE_NOT_FOUND_ERROR_SCREEN } from '../../components/error-screen'

const SecureRoute = ({
  ownProps,
  isLoggedIn,
  claims,
  partner
}) => {
  const {
    component,
    children,
    location,
    path
  } = ownProps

  if (isLoggedIn) {
    const partnerId = ownProps.computedMatch?.params?.partnerId

    if ((!partnerId || partnerId === partner) && isAllowed(path, claims[partner])) {
      return (
        <Route {...ownProps} component={children || component}/>
      )
    }

    return PAGE_NOT_FOUND_ERROR_SCREEN
  }

  return (
    <Redirect
      to={{
        pathname: paths.login,
        state: {
          location
        }
      }}
    />
  )
}

SecureRoute.propTypes = {
  ownProps: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  claims: PropTypes.object,
  partner: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const isLoggedIn = selectors.userIsLoggedInSelector(state)
  const claims = selectors.userClaimSelector(state)
  const partner = selectors.activePartnerGlobalSelector(state)

  return {
    ownProps,
    isLoggedIn,
    claims,
    partner
  }
}

export default connect(mapStateToProps)(SecureRoute)

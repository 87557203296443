import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

export const userPartnersSelector = R.path(['user', 'partners'])

const kvpHasActivePartner = ([, v]) => v.active

export const activePartnerGlobalSelector =
  R.compose(
    R.prop(0), // [k, v]
    R.find(kvpHasActivePartner),
    Object.entries,
    R.prop('partner'))

export const userIsLoggedInSelector = R.path(['user', 'isLoggedIn'])
export const userAutoEnrollmentAlertSelector = R.path(['user', 'autoEnrollmentAlert'])

export const userClaimSelector = R.path(['user', 'claims'])
export const userFeaturesSelector = R.path(['user', 'features'])
export const userRolesSelector = R.path(['user', 'roles'])

export const selectActivePartner = SAGA.select(activePartnerGlobalSelector)
export const selectUserIsLoggedIn = SAGA.select(userIsLoggedInSelector)
export const selectUserFeatures = SAGA.select(userFeaturesSelector)

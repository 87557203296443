import set from 'lodash/fp/set'
import moment from 'moment-timezone'

import { CLEAR_VALIDATED } from '../../actions/assets'
import { SET_FIELD, SET_MODERN } from '../../actions/form'
import { LOGOUT } from '../../actions/login'
import { CLOSE_WHITELIST_SIDEBAR, OPEN_WHITELIST_SIDEBAR } from '../../actions/debug'
import { GO_TO_DEBUG_RESULTS } from '../../actions/navigators'
import { SAVE_RULE } from '../../actions/rules'

import { TZ } from '../../constants'

import { TS_REQUEST_DEFAULT_PARAMS } from '../../lib/simulator'
import { convertDurationToEpochs } from '../../lib/durations'

const DEFAULT_BRKDUR = ''
const DEFAULT_POD_ID = TS_REQUEST_DEFAULT_PARAMS.podId

const TRANSACTION_ID_REGEX = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/

const createDefaultStateCreatives = now => {
  const epochs = convertDurationToEpochs('this-week', moment(now).tz(TZ))

  return {
    duration: 'this-week',
    epochs: {
      now: epochs.now,
      from: epochs.from,
      until: epochs.to
    }
  }
}

const createDefaultStateDebug = now => {
  const epochs = convertDurationToEpochs('today', moment(now).tz(TZ))

  return {
    duration: 'today',
    epochNow: epochs.now,
    epochFrom: epochs.from,
    epochTo: epochs.to,
    searchQuery: '',
    fetch: true
  }
}

export const createDefaultState = now => ({
  login: {
    pending: false,
    error: null,
    fields: {
      email: {
        value: '',
        valid: null,
        validationText: ''
      },
      password: {
        value: '',
        valid: null,
        validationText: ''
      }
    }
  },
  assetValidator: {
    fields: {
      uri: { value: '' }
    }
  },
  creatives: createDefaultStateCreatives(now),
  debug: createDefaultStateDebug(now),
  simulator: {
    fields: {
      setTokenUrl: {
        value: ''
      },
      brkdur: {
        value: DEFAULT_BRKDUR
      },
      implicitPodId: {
        value: DEFAULT_POD_ID
      },
      explicitPodId: {
        value: ''
      },
      assetId: {
        value: ''
      }
    }
  },
  whitelist: {
    open: false,
    type: 'uid',
    label: '',
    value: '',
    search: '',
    error: null
  }
})

const defaultState = createDefaultState(moment().tz(TZ).valueOf())

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_VALIDATED: {
      return {
        ...state,
        assetValidator: {
          fields: {
            uri: {
              value: ''
            }
          }
        }
      }
    }

    case SAVE_RULE.success: {
      return {
        ...state,
        rules: defaultState.rules
      }
    }

    case OPEN_WHITELIST_SIDEBAR: {
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          open: true
        }
      }
    }

    case CLOSE_WHITELIST_SIDEBAR: {
      return {
        ...state,
        whitelist: {
          ...state.whitelist,
          open: false
        }
      }
    }

    case SET_FIELD: {
      const { formName, fieldName, value } = action.payload
      return set(`${formName}.fields.${fieldName}.value`, value, state)
    }

    case SET_MODERN: {
      const { formName, fieldName, fieldValue } = action.payload

      return {
        ...state,
        [formName]: {
          ...state[formName],
          [fieldName]: fieldValue
        }
      }
    }

    case GO_TO_DEBUG_RESULTS: {
      const { query, hash, deep } = action.payload

      const fetchNeeded = () => {
        if (deep) {
          return true
        }

        if (query.groupBy) {
          return false // Only changing existing filtered results
        }

        if (TRANSACTION_ID_REGEX.test(hash)) {
          return false // Return from Detail view so transactions already loaded (unless deep-linked)
        }

        return true
      }

      if (fetchNeeded()) {
        const generateEpochs = () => {
          const now = action.payload.$now || moment().tz(TZ).valueOf()
          return {
            now,
            from: state.debug.epochFrom,
            to: state.debug.epochTo
          }
        }

        const { now, from, to } = query.epochs || generateEpochs()

        return {
          ...state,
          debug: {
            ...state.debug,
            epochNow: now,
            epochFrom: from,
            epochTo: to,
            fetch: true
          }
        }
      } else {
        return {
          ...state,
          debug: {
            ...state.debug,
            fetch: false // Avoid unnecessary network requests...
          }
        }
      }
    }

    case LOGOUT: {
      return defaultState
    }

    default:
      return state
  }
}

module.exports = {
  ALLOWLIST: 'Allowlist',
  GLOBAL_RULE: 'Global Rule',
  CREATIVE_ASSETS: 'CIS Table',
  ASSET_VALIDATOR: 'Media Validator',
  STANDARD_RULES: 'Standard Rules',
  STANDARD_RULES_ACTIVATION: 'Standard Rules Activation',
  REPORTING: 'Reporting',
  INSIGHTS_BETA: 'Insights Beta',
  SIMULATOR: 'Simulator',
  DEBUGGING_FLAGGING: 'Debugging Flagging'
}

const creativesPolicy = () => {
  return {
    keyArgs (args) {
      return Object.keys(args).filter(key => key !== 'page')
    }
  }
}

const creativeAssetRecordsPolicy = () => {
  return {
    merge (existing = [], incoming, { readField }) {
      const merged = existing.slice(0)
      const existingIds = existing.map(record => readField('id', record))

      for (let i = 0; i < incoming.length; i++) {
        const incomingId = readField('id', incoming[i])

        if (!existingIds.includes(incomingId)) {
          merged.push(incoming[i])
        }
      }

      return merged
    }
  }
}

export {
  creativesPolicy,
  creativeAssetRecordsPolicy
}

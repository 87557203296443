import React, { createElement } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import marksy from 'marksy'

import Heading from '../../../Heading'
import Image from '../../../Image'
import Table from '../../../Table'
import Code from '../../../Code'
import Tag from '../../../Tag'

import '../../Markdown.scss'
import '../../../../scss/wham.scss'

const MARKDOWN_SIZES = ['small', 'medium', 'large']

class MarkdownTemplateReleaseNotes extends React.Component {
  static propTypes = {
    markdown: PropTypes.string,
    className: PropTypes.string,
    elements: PropTypes.object,
    size: PropTypes.oneOf(MARKDOWN_SIZES)
  }

  constructor (props) {
    super(props)

    this.markdownElements = {
      h1 ({
        id,         // eslint-disable-line
        children    // eslint-disable-line
      }) {
        return <Heading className='w-markdown__h1' level={1}>{children}</Heading>
      },
      h2 ({
        id,         // eslint-disable-line
        children    // eslint-disable-line
      }) {
        return <Heading className='w-markdown__h2' level={2}>{children}</Heading>
      },
      h3 ({
        id,         // eslint-disable-line
        children    // eslint-disable-line
      }) {
        var markup
        switch (children[0]) {
          case 'New features':
            markup = <Tag className='w-markdown__h3' type='primary' label='New features' />
            break
          case 'Bug fixes':
            markup = <Tag className='w-markdown__h3' type='danger' label='Bug fixes' />
            break
          default :
            markup = <Tag label={children[0]} />
        }

        return markup
      },
      h4 ({
        id,         // eslint-disable-line
        children    // eslint-disable-line
      }) {
        return <Heading className='w-markdown__h4' level={4}>{children}</Heading>
      },
      h5 ({
        id,         // eslint-disable-line
        children    // eslint-disable-line
      }) {
        return <Heading className='w-markdown__h5' level={5}>{children}</Heading>
      },
      h6 ({
        id,         // eslint-disable-line
        children    // eslint-disable-line
      }) {
        return <Heading className='w-markdown__h6' level={6}>{children}</Heading>
      },
      blockquote ({
        children  // eslint-disable-line
      }) {
        return <blockquote className='w-markdown__blockquote'>{children}</blockquote>
      },
      hr () {
        return <hr className='w-markdown__hr' />
      },
      ol ({
        children    // eslint-disable-line
      }) {
        return <ol className='w-markdown__ol'>{children}</ol>
      },
      ul ({
        children    // eslint-disable-line
      }) {
        return <ul className='w-markdown__ul'>{children}</ul>
      },
      // p ({children}) {},
      table ({
        children    // eslint-disable-line
      }) {
        return <Table className='w-markdown__table'>{children}</Table>
      },
      thead ({
        children     // eslint-disable-line
      }) {
        return <thead className='w-markdown__thead'>{children}</thead>
      },
      tbody ({
        children     // eslint-disable-line
      }) {
        return <tbody className='w-markdown__tbody'>{children}</tbody>
      },
      tr ({
        children     // eslint-disable-line
      }) {
        return <tr className='w-markdown__tr'>{children}</tr>
      },
      th ({
        children     // eslint-disable-line
      }) {
        return <th className='w-markdown__th'>{children}</th>
      },
      td ({
        children     // eslint-disable-line
      }) {
        return <td className='w-markdown__td'>{children}</td>
      },
      a ({
        href,     // eslint-disable-line
        title,     // eslint-disable-line
        target,     // eslint-disable-line
        children    // eslint-disable-line
      }) {
        return <a className='w-markdown__a' href={href} title={title} target={target}>{children}</a>
      },
      strong ({
        children    // eslint-disable-line
      }) {
        return <strong className='w-markdown__strong'>{children}</strong>
      },
      em ({
        children    // eslint-disable-line
      }) {
        return <em className='w-markdown__em'>{children}</em>
      },
      br () {
        return <br />
      },
      del ({
        children    // eslint-disable-line
      }) {
        return <del className='w-markdown__del'>{children}</del>
      },
      img ({
        src,    // eslint-disable-line
        alt     // eslint-disable-line
      }) {
        return <Image className='w-markdown__img' src={src} />
      },
      code ({
        language,     // eslint-disable-line
        code    // eslint-disable-line
      }) {
        return <Code className='w-markdown__code' code={code} lang={language} pretty />
      },
      codespan ({
        children  // eslint-disable-line
      }) {
        return <Code className='w-markdown__code' code={children[0]} pretty />
      }
    }

    this.state = {
      elements: this.markdownElements
    }

    this.compile = marksy({
      createElement,
      elements: this.state.elements
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.elements) {
      this.setState({
        elements: Object.assign(this.state.elements, nextProps.elements)
      })
    }
  }

  render () {
    const {
      markdown = '',
      elements,
      className,
      size,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-markdown--template--release-notes',
      (size ? 'w-markdown--' + size : null),
      className
    )

    this.compile = marksy({
      createElement,
      elements: this.state.elements
    })

    const compiled = this.compile(markdown, {})

    return (
      <div className={componentClass} {...otherProps}>
        {compiled.tree}
      </div>
    )
  }
};

export default MarkdownTemplateReleaseNotes

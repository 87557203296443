/* global __VERSION__, __VERSION_DATE__ */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { detect } from 'detect-browser'
import classNames from 'classnames'

import { gql, useLazyQuery } from '@apollo/client'

import * as R from 'ramda'
import * as Wham from '../../wham/components'

import { LOGIN } from '../../actions/login'
import { success, failure } from '../../actions/async'

import DisneyLogo from '../../components/logo/disney'
import { useDocumentTitle } from '../../lib/custom-hooks'
import parseQueryString from '../../lib/parseQueryString'
import { paths } from '../../route-paths'
import * as selectors from '../../selectors'

import styles from './index.module.scss'

const APP_VERSION = __VERSION__
const VERSION_DATE = __VERSION_DATE__

const GQL_USER = gql`
  query User {
    user {
      appVersion
      name
      email
      claims {
        partner
        features {
          name
          permissions {
            key
            value
          }
        }
      }
      partners {
        id
      }
      roles
      iat
      autoEnrollmentResult {
        espnAccess
        email
        emailLookupCount
        swid
        allowListEntryId
        created
      }
    }
  }
`

const detected = detect()

const mapUserClaims = claims => {
  return claims.reduce((acc, claim) => {
    const features = claim.features.reduce((acc2, feature) => {
      const permissions = feature.permissions.reduce((acc3, permission) => {
        return {
          ...acc3,
          [permission.key]: permission.value
        }
      }, {})

      return {
        ...acc2,
        [feature.name]: permissions
      }
    }, {})

    return {
      ...acc,
      [claim.partner]: features
    }
  }, {})
}

const LoginView = ({
  location,
  isLoggedIn,
  dispatch
}) => {
  useDocumentTitle('Login')

  const [userQuery, userQueryResult] = useLazyQuery(GQL_USER, {
    onCompleted: ({ user }) => {
      const claims = mapUserClaims(user.claims)
      const autoEnrollmentAlert = (
        user.autoEnrollmentResult &&
        user.autoEnrollmentResult.espnAccess &&
        !user.autoEnrollmentResult.allowListEntryId
      )
      dispatch(success(LOGIN, { ...user, claims, autoEnrollmentAlert }))
    },
    onError: error => {
      console.log('GQL_USER error', error)
      dispatch(failure(LOGIN, { error }))
    }
  })

  useEffect(() => {
    if (!isLoggedIn) {
      userQuery()
    }
  }, [])

  const error = R.path(['e'], parseQueryString(location.search))
  const to = R.pathOr(paths.home, ['state', 'location'], location)

  if (isLoggedIn) {
    return <Redirect to={to} />
  }

  return (
    <div>
      {error ? (
        <div className={styles.error}>
          <div className={styles.error_text}>
            {error}
          </div>
        </div>
      ) : null}

      <a className={styles.logo} href='https://www.disneystreaming.com/' target='_blank' rel='noopener noreferrer'>
        <DisneyLogo />
      </a>

      {APP_VERSION ? (
        <small className={styles.version}>
          v{APP_VERSION} {VERSION_DATE && `(${VERSION_DATE})`}
        </small>
      ) : null}

      <div className={styles.layout}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.content}>
              <div className={styles.wrapper}>
                <header className={styles.header}>
                  <span className={styles.header_text}>
                    adEngine
                  </span>
                </header>

                {userQueryResult.pending ? (
                  <Wham.Progress inverse active type='indeterminate' />
                ) : (
                  <div>
                    <button
                      className={classNames('w-btn w-btn--inverse w-btn--default', styles.submit)}
                      onClick={() => window.location.assign('/api/login/federated')}
                    >
                      Sign in via SSO
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {detected && detected.name !== 'chrome' ? (
          <div className={styles.message}>
            <div className={styles.message_warn}>
              <div className={styles.message_content}>
                <div className={styles.message_icon}>
                  <span className='w-icon'>warning</span>
                </div>
                You are using an unsupported browser.
                Your experience may be degraded.
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

LoginView.propTypes = {
  location: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const isLoggedIn = selectors.userIsLoggedInSelector(state)

  return {
    isLoggedIn
  }
}

export default connect(mapStateToProps)(LoginView)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {
  ALL,
  ACTIVE,
  INACTIVE
} from '../../../reducers/ruleset/view-rules'

export const RuleFilters = ({
  selectedFilter,
  changeSelectedFilter
}) => (
  <div className='active-filter-toggle'>
    <a
      onClick={() => changeSelectedFilter(ALL)}
      className={classNames({ active: selectedFilter === ALL })}
    >
      All Rules
    </a>
    <a
      onClick={() => changeSelectedFilter(ACTIVE)}
      className={classNames({ active: selectedFilter === ACTIVE })}
    >
      Active Rules
    </a>
    <a
      onClick={() => changeSelectedFilter(INACTIVE)}
      className={classNames({ active: selectedFilter === INACTIVE })}
    >
      Deactivated Rules
    </a>
  </div>
)

RuleFilters.propTypes = {
  selectedFilter: PropTypes.oneOf([ALL, ACTIVE, INACTIVE]).isRequired,
  changeSelectedFilter: PropTypes.func.isRequired
}

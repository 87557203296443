import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { gql, useLazyQuery, useQuery } from '@apollo/client'

import log from 'loglevel'
import * as R from 'ramda'
import * as Wham from '../../wham/components'

import {
  activePartnerGlobalSelector,
  debuggingLogEntriesGlobalSelector,
  selectedAssetId,
  selectedUserAgent,
  selectedBrkdur,
  selectedExplicitPodId,
  selectedImplicitPodId,
  selectedSetTokenUrl,
  selectedSetTokenMethod,
  selectedSetTokenUrlUpdated,
  selectedTransactionIdSelector,
  simulatedTransactionsSelector,
  userClaimSelector,
  userFeaturesSelector,
  userRolesSelector
} from '../../selectors'

import {
  getDefaultSetTokenUrl,
  simulateTransaction,
  TS_REQUEST_DEFAULT_PARAMS
} from '../../lib/simulator'

import {
  addSimulatedTransaction,
  removeSimulatedTransaction,
  markSimulatedTransactionViewed,
  removeAllSimulatedTransactions,
  selectSimulatedTransaction,
  settokenUpdated
} from '../../actions/simulator'
import { editField } from '../../actions/form'

import Header from '../../containers/header'

import { useDocumentTitle } from '../../lib/custom-hooks'

import { ViewContext } from '../../context'

import { makeTransactionDetails } from './util'

import SimulatorModal from '../../components/simulator/simulator-modal'
import SimulatorModalError from '../../components/simulator/simulator-modal-error'
import SimulatorContentUpper from '../../components/simulator/simulator-content-upper'
import SimulatorContentLower from '../../components/simulator/simulator-content-lower'
import DocLink from '../../containers/doc-link'

import { GQL_DEBUG_TRANSACTION } from '../debug/debug-transaction'

import './style.css'

const GQL_EVENT_NAMES = gql`
  query EventNames($n: Int) {
    eventNames(n: $n) {
      assetId
      eventName
    }
  }
`

const GQL_ALLOW_LIST = gql`
  query Allowlist {
    allowlist {
      edges {
        node {
          id
          idType
          value
          label
        }
      }
    }
  }
`

const SimulatorView = ({
  claims,
  features,
  roles,
  partnerId,
  setTokenUrl,
  setTokenMethod,
  setTokenUrlUpdated,
  brkdur,
  implicitPodId,
  explicitPodId,
  assetId,
  userAgent,
  assetIds,
  simulatedTransactions,
  selectedTransactionId,
  selectedSimulatedTransaction,
  simulatorFieldEdit,
  simulatorSettokenEdit,
  addSimulatedTransaction,
  removeSimulatedTransaction,
  markSimulatedTransactionViewed,
  removeAllSimulatedTransactions,
  dispatch
}) => {
  useDocumentTitle('Simulator')

  const [pending, setPending] = useState(false)
  const [error, setError] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [eventNames, setEventNames] = useState([])
  const [allowList, setAllowList] = useState([])
  const [transactionDetails, setTransactionDetails] = useState(null)

  const eventNamesQueryResult = useQuery(GQL_EVENT_NAMES)
  const allowListQueryResult = useQuery(GQL_ALLOW_LIST)

  const [transactionLazyQuery, transactionLazyQueryResult] = useLazyQuery(GQL_DEBUG_TRANSACTION, { fetchPolicy: 'no-cache' })

  const fetchTransaction = transactionId => {
    if (!transactionLazyQueryResult.loading) {
      setTransactionDetails(null)
      transactionLazyQuery({
        variables: {
          transactionId
        }
      })
    }
  }

  useEffect(() => {
    if (transactionLazyQueryResult.data) {
      const debuglog = transactionLazyQueryResult.data.debuglog
      if (debuglog) {
        setTransactionDetails(makeTransactionDetails(partnerId, claims, debuglog))
      }
    }
  }, [transactionLazyQueryResult])

  useEffect(() => {
    if (eventNamesQueryResult.data) {
      setEventNames(R.sort(
        R.ascend(R.compose(R.toLower, R.prop('eventName'))),
        eventNamesQueryResult.data.eventNames))
    }
  }, [eventNamesQueryResult])

  useEffect(() => {
    if (allowListQueryResult.data) {
      const edges = allowListQueryResult.data.allowlist.edges
      const nodes = edges.map(edge => edge.node)
      setAllowList(nodes.map(node => ({
        ...node,
        id: Number(node.id),
        idType: node.idType.toLowerCase(),
        label: node.label || ''
      })))
    }
  }, [allowListQueryResult])

  const context = {
    partner: partnerId,
    claims,
    features,
    roles
  }

  const progressActive = transactionLazyQueryResult.loading

  const onChangeField = (fieldName, e) => {
    simulatorFieldEdit(fieldName)(e)
  }

  const onSubmit = async (e) => {
    try {
      e.preventDefault()

      setPending(true)
      setError(null)

      const thisPodId = explicitPodId.value.trim() ? explicitPodId : implicitPodId

      simulatorFieldEdit('implicitPodId')({}, String(Number(thisPodId.value) + 1))
      simulatorFieldEdit('explicitPodId')({}, '')

      const tsRequestParams = {
        brkdur: brkdur.value || TS_REQUEST_DEFAULT_PARAMS.brkdur,
        podId: thisPodId.value,
        assetId: assetId.value
      }

      const responseData = await simulateTransaction(
        partnerId,
        setTokenUrl.value,
        setTokenMethod.value,
        tsRequestParams,
        userAgent.value)
      const { transactionId, slateDebug } = responseData

      if (transactionId) {
        const timestamp = new Date().getTime()
        const brkdurNumber = Number(tsRequestParams.brkdur)
        addSimulatedTransaction(transactionId, timestamp, brkdurNumber)
        return
      }

      if (slateDebug) {
        throw new Error(`Failed to create transaction (${slateDebug})`)
      }

      throw new Error('Failed to create transaction')
    } catch (error) {
      log.error(`[SimulatorView#onSubmit] ${error.message}`)
      setError(error.message)
    } finally {
      setPending(false)
    }
  }

  const onSelectSimulatedTransaction = transactionId => {
    dispatch(selectSimulatedTransaction({ transactionId }))
    fetchTransaction(transactionId)
  }

  return (
    <ViewContext.Provider value={context}>
      <div className='w-layout w-layout--fullwidth w-layout--fullheight w-layout--spacing-default'>
        <div className='w-layout__row'>
          <Header />
        </div>

        {setTokenUrlUpdated ? (
          <div className='w-layout__row'>
            <Wham.Message className='simulator__message simulator__message--saved'>
              <span data-cy='simulator-message-saved'>adEngine Request Updated.</span>
              <a
                className='simulator__message-action'
                data-cy='revert-back'
                href='#'
                onClick={e => {
                  e.preventDefault()
                  simulatorSettokenEdit(partnerId, getDefaultSetTokenUrl(partnerId))
                }}
              >Revert to Default</a>
            </Wham.Message>
          </div>
        ) : null}

        <div className='w-layout__row'>
          <Wham.Progress type='indeterminate' active={progressActive} />
        </div>

        <div className='w-layout__row simulator'>
          <SimulatorContentUpper
            defaultSetTokenUrl={getDefaultSetTokenUrl(partnerId)}
            whitelist={allowList}
            setTokenUrl={setTokenUrl}
            setTokenMethod={setTokenMethod}
            brkdur={brkdur}
            implicitPodId={implicitPodId}
            explicitPodId={explicitPodId}
            assetId={assetId}
            userAgent={userAgent}
            assetIds={assetIds}
            eventNames={eventNames}
            pending={pending}
            simulatedTransactions={simulatedTransactions}
            selectedTransactionId={selectedTransactionId}
            onClick={() => setShowModal(true)}
            onChangeField={onChangeField}
            onChangeSettokenUrl={updatedSettokenUrl => simulatorSettokenEdit(partnerId, updatedSettokenUrl)}
            onRemove={removeSimulatedTransaction}
            onRemoveAll={removeAllSimulatedTransactions}
            onSelect={onSelectSimulatedTransaction}
            onSubmit={onSubmit}
          />
        </div>

        <div className='w-layout__row w-layout__row--fill debug-result'>
          <SimulatorContentLower
            transactionId={selectedTransactionId}
            transactionPending={transactionLazyQueryResult.loading}
            transactionDetails={transactionDetails}
            viewed={R.pathOr(false, ['viewed'], selectedSimulatedTransaction)}
            onRefresh={fetchTransaction}
            onViewed={markSimulatedTransactionViewed}
            dispatch={dispatch}
          />
        </div>

        <SimulatorModal
          showModal={showModal}
          setTokenUrl={setTokenUrl}
          onSave={updatedSettokenUrl => {
            simulatorSettokenEdit(partnerId, updatedSettokenUrl)
            setShowModal(false)
          }}
          onClose={() => setShowModal(false)}
        />

        <DocLink topic="simulator" />

        <SimulatorModalError
          error={error}
          onClose={() => setError(null)}
        />
      </div>
    </ViewContext.Provider>
  )
}

SimulatorView.propTypes = {
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  partnerId: PropTypes.string.isRequired,
  setTokenUrl: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  setTokenMethod: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  setTokenUrlUpdated: PropTypes.bool.isRequired,
  brkdur: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  implicitPodId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  explicitPodId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  userAgent: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  simulatedTransactions: PropTypes.array.isRequired,
  selectedTransactionId: PropTypes.string.isRequired,
  selectedSimulatedTransaction: PropTypes.string.isRequired,
  simulatorFieldEdit: PropTypes.func.isRequired,
  simulatorSettokenEdit: PropTypes.func.isRequired,
  goToDeepLink: PropTypes.func.isRequired,
  addSimulatedTransaction: PropTypes.func.isRequired,
  removeSimulatedTransaction: PropTypes.func.isRequired,
  markSimulatedTransactionViewed: PropTypes.func.isRequired,
  removeAllSimulatedTransactions: PropTypes.func.isRequired,
  logEntry: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  const resolveSettokenUrl = partnerId => state => {
    const setTokenUrl = selectedSetTokenUrl(partnerId)(state)
    return setTokenUrl.value ? setTokenUrl : { value: getDefaultSetTokenUrl(partnerId) }
  }

  const claims = userClaimSelector(state)
  const features = userFeaturesSelector(state)
  const roles = userRolesSelector(state)
  const partnerId = activePartnerGlobalSelector(state)
  const setTokenUrl = resolveSettokenUrl(partnerId)(state)
  const setTokenMethod = selectedSetTokenMethod(partnerId)(state) || { value: 'GET' }
  const setTokenUrlUpdated = selectedSetTokenUrlUpdated(partnerId)(state)
  const brkdur = selectedBrkdur(partnerId)(state)
  const implicitPodId = selectedImplicitPodId(partnerId)(state)
  const explicitPodId = selectedExplicitPodId(partnerId)(state)
  const assetId = selectedAssetId(partnerId)(state)
  const userAgent = selectedUserAgent(partnerId)(state)
  const logEntries = debuggingLogEntriesGlobalSelector(partnerId)(state)
  const assetIds = R.compose(
    R.sort(R.ascend(R.identity)),
    R.uniq,
    R.filter(assetId => !!assetId),
    R.pluck('assetId')
  )(logEntries)
  const simulatedTransactions = simulatedTransactionsSelector(partnerId)(state)
  const selectedTransactionId = selectedTransactionIdSelector(partnerId)(state)
  const selectedSimulatedTransaction = selectedTransactionId
    ? simulatedTransactions.find(R.propEq('transactionId', selectedTransactionId))
    : undefined
  return {
    claims,
    features,
    roles,
    partnerId,
    setTokenUrl,
    setTokenMethod,
    setTokenUrlUpdated,
    brkdur,
    implicitPodId,
    explicitPodId,
    assetId,
    userAgent,
    assetIds,
    simulatedTransactions,
    selectedTransactionId,
    selectedSimulatedTransaction
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    simulatorFieldEdit: (fieldName) => (event, value) => {
      editField(dispatch, 'simulator')(fieldName)(event, value)
    },
    simulatorSettokenEdit: (partnerId, setTokenUrl) => {
      const defaultSetTokenUrl = getDefaultSetTokenUrl(partnerId)

      editField(dispatch, 'simulator')('setTokenUrl')({ target: { value: setTokenUrl } })

      if (setTokenUrl !== defaultSetTokenUrl) {
        dispatch(settokenUpdated())
      }
    },
    addSimulatedTransaction: (transactionId, timestamp, brkdur) => {
      dispatch(addSimulatedTransaction({ transactionId, timestamp, brkdur }))
    },
    removeSimulatedTransaction: transactionId => {
      dispatch(removeSimulatedTransaction({ transactionId }))
    },
    markSimulatedTransactionViewed: transactionId => {
      dispatch(markSimulatedTransactionViewed({ transactionId }))
    },
    removeAllSimulatedTransactions: () => {
      dispatch(removeAllSimulatedTransactions())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SimulatorView)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as Wham from '../../wham/components'

import DateRangePicker from '../date-range-picker'

import { convertDurationToEpochs } from '../../lib/durations'

import './style.css'

const options = [
  {
    label: 'Today',
    value: 'today'
  },
  {
    label: 'Yesterday',
    value: 'yesterday'
  },
  {
    label: 'Last 15 minutes',
    value: 'last-15-minutes'
  },
  {
    label: 'Last 4 hours',
    value: 'last-4-hours'
  },
  {
    label: 'This Week',
    value: 'this-week'
  },
  {
    label: 'This Month',
    value: 'this-month'
  },
  {
    label: 'Previous Week',
    value: 'previous-1-week'
  },
  {
    label: 'Previous Month',
    value: 'previous-1-month'
  },
  {
    label: 'Custom',
    value: 'custom'
  }
]

const CreativeDates = ({
  duration,
  epochs,
  pending,
  onChange,
  onSelect,
  $state = {}
}) => {
  const [picker, setPicker] = useState($state.picker || null)

  const value = options.reduce((acc, option) => {
    return option.value === duration ? duration : acc
  }, options[4].value)

  const onOption = e => {
    onSelect(e.target.value)

    if (e.target.value === 'custom') {
      setPicker(duration)
    } else {
      const { now, from, to: until } = convertDurationToEpochs(e.target.value)
      onChange({ now, from, until })
    }
  }

  const onClose = () => {
    onSelect(picker)
    setPicker(null)
  }

  const onCustom = ({ now, from, to: until }) => {
    onChange({ now, from, until })
    setPicker(null)
  }

  const custom = value === 'custom'

  return (
    <div className='creative-dates' data-cy='creative-dates'>
      <div className='creative-dates__control'>
        <Wham.Select
          className={classNames('creative-dates__select', { 'creative-dates__select--custom': custom })}
          value={value}
          options={options}
          disabled={pending}
          onChange={onOption}
        />

        {custom ? (
          <span className='creative-dates__calendar'>
            <button
              className='creative-dates__calendar-btn w-btn'
              disabled={pending}
              onClick={() => {
                setPicker('custom')
              }}
            >
              <span className='creative-dates__calendar-icon w-icon'>date_range</span>
            </button>
          </span>
        ) : null}
      </div>

      {picker ? (
        <div className='creative-dates__picker'>
          <DateRangePicker
            now={epochs.now}
            from={epochs.from}
            to={epochs.until}
            pending={pending}
            onCustom={onCustom}
            onClose={onClose}
          />
        </div>
      ) : null}
    </div>
  )
}

CreativeDates.propTypes = {
  duration: PropTypes.string.isRequired,
  epochs: PropTypes.shape({
    now: PropTypes.number.isRequired,
    from: PropTypes.number.isRequired,
    until: PropTypes.number.isRequired
  }).isRequired,
  pending: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  $state: PropTypes.object
}

export default CreativeDates

import { performReq } from './helpers'

import { config } from '../config'

const { WEBUI_API_BASE_URL } = config

const partnerUrl = partnerId => {
  return WEBUI_API_BASE_URL + 'partner/' + encodeURIComponent(partnerId)
}

export {
  partnerUrl,
  performReq
}

export const reportClientError = (error) => {
  console.error(error)
  const { name, message, stack, origin } = error
  performReq('POST', WEBUI_API_BASE_URL + '/error', { name, message, stack, origin })
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './AppContext.scss'
import '../../scss/wham.scss'
export default class AppContext extends React.Component {
  static displayName = 'AppContext'
  static propTypes = {
    primary: PropTypes.string,
    primaryRelatesState: PropTypes.bool,
    secondary: PropTypes.string,
    secondaryRelatesState: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.any
  }

  render () {
    const {
      className,
      primary,
      secondary,
      primaryRelatesState,
      secondaryRelatesState,
      children,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-app-bar__context',
      this.props.className
    )

    var primaryClass = classNames(
      'w-app-bar__context__primary',
      (primaryRelatesState ? 'w-app-bar__context__primary--relates-state' : null)
    )

    var secondaryClass = classNames(
      'w-app-bar__context__secondary',
      (secondaryRelatesState ? 'w-app-bar__context__secondary--relates-state' : null)
    )

    return (
      <div className={componentClass} {...otherProps}>
        {primary ? <div className={primaryClass}>{primary}</div> : null}
        {secondary ? <div className={secondaryClass}>{secondary}</div> : null}
        {children ? <div>{children}</div> : null}
      </div>
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import Moment from 'react-moment'
import 'moment-timezone'

import * as Wham from '../../../wham/components'

import { TZ } from '../../../constants'
import MiddleTruncate from '../../../components/middle-truncate'

import {
  goToEdit
} from './local-actions'

import { prettyPrint } from '@adtech/rule-builder-ui'

const formatExpression = expression => {
  try {
    return prettyPrint(expression)
  } catch {
    return null
  }
}

const RuleCard = ({
  className = '',
  dispatch,
  id = '',
  active = false,
  partnerId = '',
  name = '',
  updatedAt = '',
  displayPriority,
  isGlobal = false,
  updatedByEmail = '',
  expression
}) => (
    <div className={`rule-card ${active ? 'isActive' : ''}`} data-cy='rule-card'>
      <Wham.Slab
        className={className}
        key={id}
        onClick={() =>
          dispatch(goToEdit({ partnerId, ruleId: id }))}
      >
        <Wham.SlabMarker background='white'>
          <div style={{ textAlign: 'center' }}>
            <div className='priority-header'>
              Priority
            </div>
            <div className={`priority-number ${active ? '' : 'priority-number--deactivated'}`}>
              {displayPriority}
            </div>
          </div>
        </Wham.SlabMarker>
        <Wham.SlabHeader>
          <MiddleTruncate maxLength={60} data-cy='rule-name'>{name}</MiddleTruncate>
        </Wham.SlabHeader>
        <Wham.SlabCaption>
          <div className='rule-card__expression'>
            {!isGlobal && formatExpression(expression)}
          </div>
          {updatedAt ? (
            <div className='rule-card__updated-at'>
              <span>
                Updated <Moment fromNow tz={TZ}>{updatedAt}</Moment>
              </span>
              {updatedByEmail && !isGlobal && (
                <span> by <span className='rule-username'>{updatedByEmail}</span></span>
              )}
            </div>
          ) : <div>&nbsp;</div>}
        </Wham.SlabCaption>

        <Wham.SlabInfo>
          {active
            ? (<span>Active</span>)
            : (<span>Deactivated</span>)}
        </Wham.SlabInfo>
      </Wham.Slab>
    </div>
  )

RuleCard.propTypes = {
  dispatch: PropTypes.func,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string /* "global" */
  ]),
  active: PropTypes.bool,
  partnerId: PropTypes.string,
  name: PropTypes.string,
  updatedAt: PropTypes.number,
  updatedByEmail: PropTypes.string,
  displayPriority: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.node /* "global" */
  ]),
  isGlobal: PropTypes.bool,
  className: PropTypes.string,
  expression: PropTypes.object
}

export default RuleCard

import { push } from 'connected-react-router'
import { paths } from '../../../route-paths'
import { expandPathTemplate } from '../../../lib/path-matcher'

export const goToRules = partnerId =>
  push(expandPathTemplate(paths.viewRules, { partnerId }))

export const goToCreate = partnerId =>
  push(expandPathTemplate(paths.createRule, { partnerId }))

export const goToEdit = ({ partnerId, ruleId }) =>
  push(expandPathTemplate(paths.createEditRule, { partnerId, ruleId }))

export const SWITCH_PARTNER_CONTEXT = 'SWITCH_PARTNER_CONTEXT'
export const SET_ACTIVE_PARTNER = 'SET_ACTIVE_PARTNER'

export const switchPartnerContext = partnerId => ({
  type: SWITCH_PARTNER_CONTEXT,
  payload: partnerId
})

export const setActivePartner = partnerId => ({
  type: SET_ACTIVE_PARTNER,
  payload: partnerId
})

import React from 'react'
import PropTypes from 'prop-types'

import { paths } from '../../../route-paths'
import { expandPathTemplate } from '../../../lib/path-matcher'

import './style.css'

const HeaderProductLogo = ({
  partner,
  onClick
}) => {
  const path = expandPathTemplate(paths.dashboard, { partnerId: partner })

  return (
    <a className='header-product-logo' href={path} onClick={e => {
      e.preventDefault()
      onClick(path)
    }}>
      <img src="/assets/disney-streaming-logo.png" alt="Disney Streaming Logo" />
    </a>
  )
}

HeaderProductLogo.propTypes = {
  partner: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default HeaderProductLogo

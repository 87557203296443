import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classNames from 'classnames'

import { gql, useQuery } from '@apollo/client'

import ContentFailed from '../../components/content/content-failed'
import CreativeDetailRecord from '../../containers/creative-detail-record'
import HeaderBack from '../../components/header/header-back'
import { useDocumentTitle } from '../../lib/custom-hooks'
import DocLink from '../../containers/doc-link'
import Header from '../../containers/header'
import { ViewContext } from '../../context'
import { expandPathTemplate } from '../../lib/path-matcher'
import { paths } from '../../route-paths'
import * as selectors from '../../selectors'
import * as Wham from '../../wham/components'

import styles from './index.module.scss'

const GQL_CREATIVE_RECORD = gql`
  query Creative($id: String!) {
    creative(id: $id) {
      id
      epoch
      mezzFile
      vastIds {
        id
        path
      }
      vastUniversalAdId
      duration
      status
      amsId
      errorMsg
    }
  }
`

const resolvePaths = (creativeId, creativeIds, partner) => {
  const path = expandPathTemplate(paths.creatives, { partnerId: partner })

  const index = creativeIds.indexOf(creativeId)

  const prevId = index !== -1 && index > 0 ? creativeIds[index - 1] : null
  const nextId = index !== -1 && index < (creativeIds.length - 1) ? creativeIds[index + 1] : null

  const prev = prevId ? expandPathTemplate(paths.creative, { partnerId: partner, creativeId: prevId }) : undefined
  const next = nextId ? expandPathTemplate(paths.creative, { partnerId: partner, creativeId: nextId }) : undefined

  return {
    path,
    prev,
    next
  }
}

const CreativeView = ({
  creativeId,
  creativeIds,
  roles,
  claims,
  partner,
  dispatch
}) => {
  const context = {
    roles,
    claims,
    partner
  }

  useDocumentTitle('Creative')

  const { data = {}, loading, error, refetch } = useQuery(GQL_CREATIVE_RECORD, {
    variables: {
      id: creativeId
    }
  })

  const { creative: record } = data
  const { path, prev, next } = resolvePaths(creativeId, creativeIds, partner)

  const onKeyDown = ({ key }) => {
    if (key === 'ArrowRight' && next) {
      dispatch(push(next))
    }

    if (key === 'ArrowLeft' && prev) {
      dispatch(push(prev))
    }
  }

  const timeout = useRef()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    clearTimeout(timeout.current)

    if (loading) {
      timeout.current = setTimeout(() => {
        setLoader(true)
      }, 500)
    }
  }, [loading])

  useEffect(() => {
    setLoader(false)
    document.addEventListener('keydown', onKeyDown)

    return () => {
      clearTimeout(timeout.current)
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [creativeId])

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <ViewContext.Provider value={context}>
      <section className={styles.section}>
        <header className={styles.header}>
          <Header
            left={
              <HeaderBack text='Return to Creatives' path={path} onClick={() => dispatch(push(path))} />
            }
          />

          <div className={styles.pending}>
            <Wham.Progress type='indeterminate' active={loading} />
          </div>

          {prev || next ? (
            <nav className={styles.nav}>
              {prev ? (
                <a
                  className={classNames(styles.nav_link, styles.nav_prev)}
                  href={prev}
                  onClick={e => {
                    e.preventDefault()
                    dispatch(push(prev))
                  }}
                >
                  <span className={styles.nav_icon}>chevron_left</span>
                  <span className={styles.nav_text}>Previous</span>
                </a>
              ) : null}
              {next ? (
                <a
                  className={classNames(styles.nav_link, styles.nav_next)}
                  href={next}
                  onClick={e => {
                    e.preventDefault()
                    dispatch(push(next))
                  }}
                >
                  <span className={styles.nav_text}>Next</span>
                  <span className={styles.nav_icon}>chevron_right</span>
                </a>
              ) : null}
            </nav>
          ) : null}
        </header>

        <article className={styles.article}>
          {loading ? (
            <div className={styles.loader}>
              {loader ? (
                <Wham.ProgressCircular type='indeterminate' active={loading} sqSize={48} />
              ) : null}
            </div>
          ) : record ? (
            <CreativeDetailRecord record={record} onRefetch={refetch} />
          ) : (
            <ContentFailed
              id={creativeId}
              label='creative'
              error={error}
              className={styles.failed}
              onRetry={refetch}
              onReturn={() => dispatch(push(path))}
            />
          )}
        </article>

        <DocLink topic='creative-assets' />
      </section>
    </ViewContext.Provider>
  )
}

CreativeView.propTypes = {
  creativeId: PropTypes.string.isRequired,
  creativeIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  roles: PropTypes.array.isRequired,
  claims: PropTypes.array.isRequired,
  partner: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { creativeId } } } = ownProps

  const roles = selectors.userRolesSelector(state)
  const claims = selectors.userClaimSelector(state)
  const partner = selectors.activePartnerGlobalSelector(state)
  const creativeIds = selectors.creativeIdsSelector(partner)(state)

  return {
    creativeId,
    creativeIds,
    roles,
    claims,
    partner
  }
}

export default connect(mapStateToProps)(CreativeView)

import * as R from 'ramda'

export const whitelistAddingSelector = partner => state => R.path([
  'partner', partner, 'whitelist', 'adding'], state)

export const whitelistDeletingSelector = partner => state => R.path([
  'partner', partner, 'whitelist', 'deleting'], state)

export const whitelistErrorSelector = partner => state => R.path([
  'partner', partner, 'whitelist', 'error'], state)

export const whitelistPendingSelector = partner => state => R.path([
  'partner', partner, 'whitelist', 'pending'], state)

export const whitelistEmailLoading = partner => R.path([
  'partner', partner, 'whitelist', 'pendingEmailLookup'])

export const whitelistMatchedUsersSelector = partner => R.path([
  'partner', partner, 'whitelist', 'matchedUsers'])

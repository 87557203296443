import React from 'react'
import PropTypes from 'prop-types'
import * as Wham from '../../wham/components'
import './style.css'

const ErrorModal = ({
  message,
  onClose
}) => (
  <div className='error-modal'>
    <div className='error-modal__background' />
    <Wham.Message
      active
      mode='floating'
      type='danger'
      dismissCallback={() => onClose()}
    >
      <div className='error-modal__message'>
        <p className='error-modal__text'>{message}</p>
      </div>
    </Wham.Message>
  </div>
)

ErrorModal.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ErrorModal

import PropTypes from 'prop-types'
import React from 'react'
import AppContext from '../AppContext'
import classNames from 'classnames'

import './AppBar.scss'
import '../../scss/wham.scss'

const createArea = location => {
  const elem = ({ children, className, ...otherProps }) => (
    <div className={(className ? className + ' ' : '') + `w-app-bar__nav-${location}`} {...otherProps}>
      <div className='w-app-bar__nav-item'>
        {children}
      </div>
    </div>
  )
  elem.propTypes = {
    /** React Components to Place in the AppBar */
    children: PropTypes.node,
    className: PropTypes.string
  }
  elem.displayName = `AppBar${location.charAt(0).toUpperCase() + location.slice(1)}`
  return elem
}

export const AppBar2Left = createArea('left')

export const AppBar2Right = createArea('right')

export const AppBar2Center = ({
  heading,
  subtitle,
  headingRelatesState,
  subtitleRelatesState,
  children
}) => {
  return (
    <AppContext
      primary={heading}
      secondary={subtitle}
      primaryRelatesState={headingRelatesState}
      secondaryRelatesState={subtitleRelatesState}
    >
      {children}
    </AppContext>
  )
}
AppBar2Center.propTypes = {
  heading: PropTypes.string,
  subtitle: PropTypes.string,
  headingRelatesState: PropTypes.bool,
  subtitleRelatesState: PropTypes.bool,
  children: PropTypes.any
}

export const AppBar2 = ({
  className,
  children,
  danger,
  warning,
  renderLeft,
  renderCenter,
  renderRight,
  ...otherProps
}) => {
  const componentClass = classNames(
    'w-app-bar-2',
    (warning && !danger ? 'w-app-bar--warning' : null),
    (danger ? 'w-app-bar--danger' : null),
    className
  )

  return (
    <div className={componentClass} {...otherProps}>
      <nav className='w-app-bar__level w-app-bar__level--nav'>
        <div className='container is-fluid'>
          {!!renderLeft && renderLeft()}
          {!!renderCenter && renderCenter()}
          {!!renderRight && renderRight()}
        </div>
      </nav>
    </div>
  )
}

AppBar2.propTypes = {
  renderLeft: PropTypes.func,
  renderCenter: PropTypes.func,
  renderRight: PropTypes.func,
  children: PropTypes.node,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  className: PropTypes.string
}

export default AppBar2

class ClientError extends Error {
  constructor (params, origin) {
    super(params)
    this.origin = origin
  }
}

export {
  ClientError
}

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import blacklist from 'blacklist'

import './Avatar.scss'
import '../../scss/wham.scss'
export default class Avatar extends React.Component {
  static displayName = 'Avatar'
  static propTypes = {
    activeUser: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
    component: PropTypes.node
  }

  render () {
    const {
      activeUser,
      className,
      children,
      component,
      ...otherProps
    } = this.props
    // classes
    var componentClass = classNames(
      'w-avatar',
      { 'w-avatar--you': activeUser },
      className
    )

    // props
    var props = blacklist(this.props, 'subtle', 'block', 'activeUser', 'type', 'size', 'component', 'className', 'submit')
    props.className = componentClass

    if (component) {
      return React.cloneElement(component, { ...props, ...otherProps })
    }

    var tag = 'span'
    return React.createElement(tag, { ...props, ...otherProps }, children)
  }
}

import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

import safe from '../safe'

import { VALIDATE_ASSET } from '../../actions/assets'
import { success, failure } from '../../actions/async'

import {
  createAssetValidationUri,
  deleteAssetValidationUri,
  getAssetValidationForUri,
  uploadFileToAssetValidationUri
} from '../../services/assets'

import { getResponseData } from '../../services/api/helpers'
import { selectActivePartner } from '../../selectors'

const performAssetValidationForFile = function * ({ partnerId, file }) {
  const { name, type } = file

  const response = yield SAGA.call(createAssetValidationUri, { partnerId, body: { name, type } })

  const {
    id,
    get,
    put
  } = getResponseData(response)

  try {
    yield SAGA.call(uploadFileToAssetValidationUri, { uri: put, file, type })
    return yield SAGA.call(getAssetValidationForUri, { partnerId, uri: get })
  } finally {
    yield SAGA.call(deleteAssetValidationUri, { partnerId, body: { id, name } })
  }
}

const performValidationResultForUri = function * ({ partnerId, uri }) {
  return yield SAGA.call(getAssetValidationForUri, { partnerId, uri })
}

const performAssetValidation = function * ({ partnerId, uri, file }) {
  if (R.isEmpty(file)) {
    return yield * performValidationResultForUri({ partnerId, uri })
  }

  return yield * performAssetValidationForFile({ partnerId, uri, file })
}

const countFailedRules = (rule) => R.length(R.filter(R.propEq('passed', false), R.values(rule)))

export const getAssetValidationInfoTask = function * ({ payload }) {
  const { uri, file = {} } = payload
  const { name } = file

  try {
    const partnerId = yield selectActivePartner
    const res = yield * performAssetValidation({ partnerId, uri, file })

    const {
      spec: specifications,
      asset
    } = getResponseData(res)

    const spec = specifications
      .map(specification => {
        const failed = R.compose(R.sum, R.values, R.map(countFailedRules))(specification)

        return {
          ...specification,
          failed
        }
      })
      .sort(({ failed: a }, { failed: b }) => (a - b))

    const passed = spec.filter(({ failed }) => (failed === 0)).length

    yield SAGA.put(success(VALIDATE_ASSET, {
      spec,
      asset,
      uri,
      name,
      passed
    }))
  } catch (error) {
    yield SAGA.put(failure(VALIDATE_ASSET, { error, uri, name }))
  }
}

export default function * assetValidatorSaga () {
  yield SAGA.takeEvery(VALIDATE_ASSET.default, safe(getAssetValidationInfoTask, 'getAssetValidationInfoTask'))
}

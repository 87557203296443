import { singleParamActionCreator } from '../../lib/singleParamActionCreator'
import { createAsyncActionType } from '../async'

export const EDIT_RULE_MACRO = 'EDIT_RULE_MACRO'

export const FETCH_GLOBAL_RULE = createAsyncActionType('FETCH_GLOBAL_RULE')
export const FETCH_RULES = createAsyncActionType('FETCH_RULES')

export const SAVE_RULE = createAsyncActionType('SAVE_RULE')
export const SAVE_GLOBAL_RULE = createAsyncActionType('SAVE_GLOBAL_RULE')

export const EDIT_RULE_NAME = 'EDIT_RULE_NAME'
export const TOGGLE_RULE_IS_ACTIVE = createAsyncActionType('TOGGLE_RULE_IS_ACTIVE')

export const CLEAR_ALERT = 'CLEAR_ALERT'
export const SET_RULE_ORDER = 'SET_RULE_ORDER'
export const SET_RULE_FILTER = 'SET_RULE_FILTER'
export const SORT_RULE_LIST = 'SORT_RULE_LIST'
export const CLONE_RULE = 'CLONE_RULE'
export const CLEAR_CREATE_RULE = 'CLEAR_CREATE_RULE'
export const UPDATING_CLEAR = 'UPDATING_CLEAR'
export const UPDATING_DEFINE = 'UPDATING_DEFINE'
export const UPDATING_EVENT = 'UPDATING_EVENT'
export const SET_EXPRESSION = 'SET_EXPRESSION'

export const setRuleFilter = singleParamActionCreator(SET_RULE_FILTER)
export const sortRuleList = singleParamActionCreator(SORT_RULE_LIST)
export const clearCreateRule = singleParamActionCreator(CLEAR_CREATE_RULE)
export const cloneRule = singleParamActionCreator(CLONE_RULE)
export const clearAlert = singleParamActionCreator(CLEAR_ALERT)
export const getGlobalRule = singleParamActionCreator(FETCH_GLOBAL_RULE)
export const getRulesForPartner = singleParamActionCreator(FETCH_RULES)
export const editRuleMacro = singleParamActionCreator(EDIT_RULE_MACRO)
export const saveRule = singleParamActionCreator(SAVE_RULE)
export const saveGlobalRule = singleParamActionCreator(SAVE_GLOBAL_RULE)
export const toggleRuleIsActive = singleParamActionCreator(TOGGLE_RULE_IS_ACTIVE)
export const editRuleName = singleParamActionCreator(EDIT_RULE_NAME)
export const setRuleOrder = singleParamActionCreator(SET_RULE_ORDER)
export const updatingClear = singleParamActionCreator(UPDATING_CLEAR)
export const updatingDefine = singleParamActionCreator(UPDATING_DEFINE)
export const updatingEvent = singleParamActionCreator(UPDATING_EVENT)
export const setExpression = singleParamActionCreator(SET_EXPRESSION)

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SearchInput from '../SearchInput'
import { splitFieldEventProps } from '../lib/eventProps'

import './AppSearch.scss'
import '../../scss/wham.scss'

class AppSearch extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onOptionChange: PropTypes.func,
    searchOptions: PropTypes.array,
    onClear: PropTypes.func,
    activeSearchOption: PropTypes.any
  }

  render () {
    const {
      className,
      children,
      placeholder,
      value,
      onChange,
      onOptionChange,
      searchOptions,
      activeSearchOption,
      onClear = null,
      ...otherProps
    } = this.props
    const [formEventProps, wrapperProps] = splitFieldEventProps(otherProps)

    var componentClass = classNames(
      'w-app-bar__level w-app-bar__level--search',
      className
    )

    return (
      <div className={componentClass} {...wrapperProps}>
        <div className='container is-fluid'>
          <SearchInput
            options={searchOptions}
            activeOption={activeSearchOption}
            className='w-app-bar__search__field'
            placeholder={placeholder}
            onChange={onChange}
            onOptionChange={onOptionChange}
            onClear={onClear}
            value={value}
            {...formEventProps}
          />
          <div className='w-app-bar__search__filters'>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default AppSearch

export const GO_TO = 'GO_TO'
export const GO_TO_DEBUG_RESULTS = 'GO_TO_DEBUG_RESULTS'

export const goTo = ({ to, from, state }) => ({
  type: GO_TO,
  payload: {
    to,
    from: from || window.location.pathname,
    state
  }
})

export const goToDebugResults = (payload = {}) => {
  const { hash, deep, ...query } = payload

  return {
    type: GO_TO_DEBUG_RESULTS,
    payload: {
      query,
      hash,
      deep
    }
  }
}

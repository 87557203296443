import flatten from 'lodash/flatten'

/* Configuration: sets up lists of categories for events we wish to capture */
const formElementEventTypes = ['keyboard', 'focus', 'change', 'clipboard']
const mediaElementEventTypes = ['media', 'image']

/* React's list of SyntheticEvents by Category */
const events = {
  keyboard: 'onKeyDown onKeyPress onKeyUp',
  focus: 'onFocus onBlur',
  change: 'onInput onInvalid onSubmit',
  mouse: 'onClick onContextMenu onDoubleClick onDrag onDragEnd onDragEnter onDragExit onDragLeave onDragOver onDragStart onDrop onMouseDown onMouseEnter onMouseLeave onMouseMove onMouseOut onMouseOver onMouseUp',
  select: 'onSelect',
  touch: 'onTouchCancel onTouchEnd onTouchMove onTouchStart',
  scroll: 'onScroll',
  mouseWheel: 'onWheel',
  media: 'onAbort onCanPlay onCanPlayThrough onDurationChange onEmptied onEncrypted onEnded onError onLoadedData onLoadedMetadata onLoadStart onPause onPlay onPlaying onProgress onRateChange onSeeked onSeeking onStalled onSuspend onTimeUpdate onVolumeChange onWaiting',
  image: 'onLoad',
  toggle: 'onToggle',
  transition: 'onTransitionEnd',
  animation: 'onAnimationStart onAnimationEnd onAnimationIteration',
  composition: 'onCompositionEnd onCompositionStart onCompositionUpdate',
  clipboard: 'onCopy onCut onPaste'
}

/* Reducing function to convert [['key', 'value'], ['key', 'value']] into an object hash */
const kvArrayToObjectHash = (prev = {}, cur) => {
  const [key, value] = cur
  return (key && value)
    ? {
      ...prev,
      [key]: value
    }
    : prev
}

/* Convenience - concat lists into arrays of ['key', ['val1', 'val2', 'valn']] */
const eventsLists = Object.entries(events)
  .map(([k, value]) => [k, value.split(' ')])
  .reduce((prev, cur) => {
    return cur[1]
      ? {
        ...prev,
        [cur[0]]: cur[1]
      }
      : {
        ...prev
      }
  }, {})

const createFlatEventList = (eventList, elemEventTypeList) => {
  const replacedVals = elemEventTypeList.map(key => eventList[key])
  return flatten(replacedVals)
}

export const FORM_EVENT_PROP_LIST = createFlatEventList(eventsLists, formElementEventTypes)
export const MEDIA_EVENT_PROP_LIST = createFlatEventList(eventsLists, mediaElementEventTypes)

export const getEventPropTypesForComponent = propList => props => {
  const isolated = Object.entries(props)
    .map(elem => {
      return elem
    })
    .filter(([key, value]) => propList.includes(key))
    .reduce(kvArrayToObjectHash, {})

  const otherProps = Object.entries(props)
    .filter(([key, value]) => (!propList.includes(key)))
    .reduce(kvArrayToObjectHash, {})

  return [isolated, otherProps]
}

export const splitFieldEventProps = getEventPropTypesForComponent(FORM_EVENT_PROP_LIST)

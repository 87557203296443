import {
  CLOSE_WHITELIST_SIDEBAR,
  OPEN_WHITELIST_SIDEBAR
} from '../../actions/debug'
import { LOGOUT } from '../../actions/login'
import {
  CLEAR_WHITELIST_LOOKUP,
  FETCH_WHITELIST_USER_BY_EMAIL
} from '../../actions/whitelist'

const defaultState = {
  added: false,
  adding: false,
  deleting: null,
  error: null,
  pending: false,
  pendingEmailLookup: false,
  matchedUsers: []
}

const whitelistReducer = (state = defaultState, action) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_WHITELIST_USER_BY_EMAIL.default: {
      return {
        ...state,
        pending: true,
        pendingEmailLookup: true
      }
    }

    case FETCH_WHITELIST_USER_BY_EMAIL.failure: {
      return {
        ...state,
        pending: false,
        pendingEmailLookup: false
      }
    }

    case FETCH_WHITELIST_USER_BY_EMAIL.success: {
      const { matchedUsers } = payload
      return {
        ...state,
        matchedUsers,
        pending: false,
        pendingEmailLookup: false
      }
    }

    case CLOSE_WHITELIST_SIDEBAR:
    case OPEN_WHITELIST_SIDEBAR:
    case CLEAR_WHITELIST_LOOKUP: {
      return {
        ...state,
        matchedUsers: defaultState.matchedUsers,
        pendingEmailLookup: false
      }
    }

    case LOGOUT:
      return defaultState

    default:
      return state
  }
}

export default whitelistReducer

import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const DashboardTile = ({
  tile,
  onClick
}) => {
  const maybeTarget = tile.externalLink
    ? { target: '_blank' }
    : undefined

  return (
    <div className='dashboard-tile'>
      <a
        className='dashboard-tile__link'
        href={tile.externalLink ?? tile.path}
        {...maybeTarget}
        onClick={e => {
          if (tile.path) {
            e.preventDefault()
            onClick(tile.path)
          }
        }}
      >
        <div className='dashboard-tile__container'>
          <div className='dashboard-tile__upper'>
            <div className='dashboard-tile__icon-wrap'>
              <span className={`dashboard-tile__icon ${tile.type ? `dashboard-tile__icon--${tile.type}` : ''} w-icon`}>{tile.icon}</span>
            </div>
            <div className='dashboard-tile__title-wrap'>
              <h2 className='dashboard-tile__title'>{tile.label}</h2>
            </div>
          </div>

          <div className='dashboard-tile__lower'>
            <div className='dashboard-tile__caption-wrap'>
              <p className='dashboard-tile__caption'>{tile.caption}</p>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

DashboardTile.propTypes = {
  tile: PropTypes.shape({
    type: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    path: PropTypes.string,
    externalLink: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired
}

export default DashboardTile

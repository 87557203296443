import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'
import axios from 'axios'

import { panicAction, errorAction } from '../../actions/panic'

export default function * handleErrs (error, action) {
  if (axios.isCancel(error)) {
    return
  }
  const status = R.pathOr(500, ['response', 'status'], error)
  switch (status) {
    case 500:
      console.dir(error)
      yield SAGA.put(panicAction)
      break
    case 403:
      yield SAGA.put(errorAction())
  }
}

import * as SAGA from 'redux-saga/effects'

import { LOGOUT } from '../../actions/login'

export default function * user () {
  while (true) {
    yield SAGA.take(LOGOUT)
    window.location.pathname = '/api/login/federated/logout'
  }
}

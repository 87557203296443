import * as R from 'ramda'
import { parseUrl } from 'query-string'

import { calcFilledPercent } from '../fill'

export const enrichSummaries = logEntries => {
  return logEntries.map(logEntry => ({
    ...logEntry,
    filled: calcFilledPercent(logEntry)
  }))
}

export const sortMediaRequests = tss => {
  const extractSgmnt = ts => {
    const url = R.pathOr('', ['request', 'url'], ts)
    const { query } = parseUrl(url)
    return Number(query.sgmnt)
  }
  const extractEventTime = ts => R.pathOr(0, ['request', 'eventTime'], ts)
  const hasSgmnt = ts => !!extractSgmnt(ts)
  const extractor = R.all(hasSgmnt, tss) ? extractSgmnt : extractEventTime
  return R.sortBy(extractor, tss)
}

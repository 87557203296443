import { push } from 'connected-react-router'
import * as SAGA from 'redux-saga/effects'
import * as qs from 'query-string'

import safe from '../safe'

import { GO_TO, GO_TO_DEBUG_RESULTS } from '../../actions/navigators'

import {
  selectActivePartner,
  selectDebugEpochFrom,
  selectDebugEpochTo,
  selectDebugLogFacets,
  selectDebugGroupBy,
  selectDebugSearchQuery
} from '../../selectors'

import { Groups } from '../../constants/debug'

import { paths } from '../../route-paths'

import { expandPathTemplate } from '../../lib/path-matcher'

export const goToRoute = function * ({ type, payload }) {
  switch (type) {
    case GO_TO: {
      const { to, state } = payload

      const location = {
        pathname: to
      }

      if (state) {
        location.state = state
      }

      return yield SAGA.put(push(location))
    }

    case GO_TO_DEBUG_RESULTS: {
      const { query, hash } = payload

      try {
        const partner = yield selectActivePartner
        const pathname = expandPathTemplate(paths.debug, { partnerId: partner })
        const epochFrom = yield selectDebugEpochFrom(partner)
        const epochTo = yield selectDebugEpochTo(partner)
        const facets = yield selectDebugLogFacets(partner)
        const groupBy = yield selectDebugGroupBy(partner)
        const searchQuery = yield selectDebugSearchQuery(partner)

        const maybeFacets = facets.reduce((acc, facet) => {
          const name = facet.name

          acc[name] = facet.options
            .filter(option => {
              return option.selected
            })
            .map(option => {
              return option.key
            })

          return acc
        }, {})

        const maybeGroup =
          (query.groupBy === Groups.SESSION) ||
            (groupBy === Groups.SESSION && query.groupBy !== Groups.DEFAULT)
            ? { group: true }
            : undefined

        const maybeQuery = searchQuery ? { query: searchQuery } : undefined

        const search = {
          from: epochFrom,
          to: epochTo,
          ...maybeFacets,
          ...maybeGroup,
          ...maybeQuery
        }

        return yield SAGA.put(push({
          pathname,
          search: qs.stringify(search),
          hash: hash
        }))
      } catch (err) {
        console.error(err)
      }
      break
    }
  }
}

export default function * navigators () {
  yield SAGA.takeLatest([GO_TO, GO_TO_DEBUG_RESULTS], safe(goToRoute, 'goToRoute'))
}

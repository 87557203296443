import { singleParamActionCreator } from '../../lib/singleParamActionCreator'

export const ADD_SIMULATED_TRANSACTION = 'ADD_SIMULATED_TRANSACTION'
export const REMOVE_SIMULATED_TRANSACTION = 'REMOVE_SIMULATED_TRANSACTION'
export const MARK_SIMULATED_TRANSACTION_VIEWED = 'MARK_SIMULATED_TRANSACTION_VIEWED'
export const REMOVE_ALL_SIMULATED_TRANSACTIONS = 'REMOVE_ALL_SIMULATED_TRANSACTIONS'
export const SELECT_SIMULATED_TRANSACTION = 'SELECT_SIMULATED_TRANSACTION'
export const SETTOKEN_UPDATED = 'SETTOKEN_UPDATED'

export const addSimulatedTransaction = singleParamActionCreator(ADD_SIMULATED_TRANSACTION)
export const removeSimulatedTransaction = singleParamActionCreator(REMOVE_SIMULATED_TRANSACTION)
export const markSimulatedTransactionViewed = singleParamActionCreator(MARK_SIMULATED_TRANSACTION_VIEWED)
export const removeAllSimulatedTransactions = singleParamActionCreator(REMOVE_ALL_SIMULATED_TRANSACTIONS)
export const selectSimulatedTransaction = singleParamActionCreator(SELECT_SIMULATED_TRANSACTION)
export const settokenUpdated = singleParamActionCreator(SETTOKEN_UPDATED)

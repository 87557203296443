import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '../Icon'
import Popover from '../Popover'
import Button from '../Button'
import Markdown from '../Markdown'

import './WhatsNew.scss'
import '../../scss/wham.scss'

class WhatsNew extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    notes: PropTypes.string,
    viewAllLink: PropTypes.string,
    dismissHandler: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      active: props.active
    }
  }

  togglePopover = () => {
    this.setState({ active: !this.state.active })
  }

  render () {
    const {
      active,
      className,
      viewAllLink,
      notes = '',
      dismissHandler,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-whats-new',
      (this.state.active ? 'w-whats-new--active' : null),
      className
    )

    setTimeout(() => {
      const popover = this.popoverRef
      if (popover) {
        popover.style.right = '-10px'
      }
    }, 0)

    return (
      <span ref={el => { this.componentRef = el }} className={componentClass} {...otherProps}>
        <Icon className='w-whats-new__icon' iconName='new_releases' onClick={this.togglePopover} />
        <span className='w-whats-new__content-container' ref={el => { this.popoverRef = el }}>
          <Popover type='spaced' className='w-whats-new__content' active={this.state.active}>
            <div className='w-whats-new__content__release'>
              <Markdown markdown={notes} elements={this.elements} size='small' template='release-notes' />
            </div>
            <div className='w-whats-new__content__controls'>
              {dismissHandler ? <Button type='primary' onClick={dismissHandler}>Got it</Button> : null}
              {viewAllLink ? <a href={viewAllLink}>View all releases</a> : null}
            </div>
          </Popover>
        </span>
      </span>
    )
  }
};

export default WhatsNew

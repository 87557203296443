import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Box.scss'
import '../../scss/wham.scss'

const BOX_PADDING = ['none', 'small', 'normal', 'large', 'xlarge']
const ALIGN_VERTICAL = ['top', 'bottom', 'center', 'fill']
const ALIGN_HORIZONTAL = ['left', 'right', 'center', 'fill']

class Box extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    padding: PropTypes.oneOf(BOX_PADDING),
    alignVertical: PropTypes.oneOf(ALIGN_VERTICAL),
    alignHorizontal: PropTypes.oneOf(ALIGN_HORIZONTAL),
    children: PropTypes.any
  }

  render () {
    const {
      padding = 'normal',
      alignVertical = 'top',
      alignHorizontal = 'left',
      children,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-box',
      `w-box--padding-${padding}`,
      `w-box--align-vert-${alignVertical}`,
      `w-box--align-horiz-${alignHorizontal}`,
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        <div className='w-box__content'>
          {children}
        </div>
      </div>
    )
  }
};

export default Box

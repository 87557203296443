const asBreakDurationLabel = (option = {}, expanded) => {
  const key = option.key || ''

  if (typeof key !== 'string') {
    return ''
  }

  const range = key.split('-')

  if (range.length !== 2) {
    return key
  }

  if (range[0] === 'unspecified') {
    return `up to ${range[1]} seconds`
  }

  if (range[1] === 'unspecified') {
    return `from ${range[0]} seconds`
  }

  return expanded ? `from ${range[0]} seconds up to ${range[1]} seconds` : `from ${range[0]} up to ${range[1]}`
}

const asAssetLabel = (option = {}) => {
  return option.displayName || option.key || ''
}

const asSourceLabel = (option = {}) => {
  const key = option.key || ''
  switch (key) {
    case 'ts': return 'Not Prefetched'
    case 'prefetch': return 'Prefetched'
    default: return key
  }
}

const asFillPercentLabel = (option = {}) => {
  const key = option.key || ''

  if (typeof key !== 'string') {
    return ''
  }

  const range = key.split('-')

  if (range.length !== 2) {
    return key
  }

  const [from, to] = range

  if (from === 'unspecified') {
    return `to ${to}%`
  }

  if (to === 'unspecified') {
    return `> ${from}%`
  }

  return `${from}% - ${to}%`
}

const asRuleLabel = (option = {}) => {
  return option.displayName || option.key || ''
}

const asBookmarksLabel = (option = {}) => {
  const key = option.key || ''
  switch (key) {
    case 'bookmarked': return 'Bookmarked'
    case 'not_bookmarked': return 'Not Bookmarked'
    default: return key
  }
}

const asStatusLabel = (option = {}) => {
  const key = option.key || ''
  switch (key) {
    case 'decided': return 'Success'
    case 'cancelled': return 'Timed Out'
    case 'race_lost': return 'Race Lost'
    case 'errored': return 'Error'
    default: return key
  }
}

export {
  asBreakDurationLabel,
  asAssetLabel,
  asSourceLabel,
  asFillPercentLabel,
  asRuleLabel,
  asBookmarksLabel,
  asStatusLabel
}

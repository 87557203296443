import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Overlay from '../Overlay'

import './Popover.scss'
import '../../scss/wham.scss'

const POPOVER_TYPES = [
  'default',
  'spaced'
]

class Popover extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    active: PropTypes.bool,
    alignment: PropTypes.oneOf(['left', 'right']),
    type: PropTypes.oneOf(POPOVER_TYPES)
  }

  constructor (props) {
    super(props)
    this.state = {
      active: this.props.active
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      ...this.state,
      active: nextProps.active
    })
  }

  render () {
    const {
      type,
      active,
      className,
      children,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-popover',
      (type ? 'w-popover--' + type : null),
      (active ? 'w-popover--active' : null),
      className
    )

    return (
      <Overlay className={componentClass} {...otherProps}>
        {children}
      </Overlay>
    )
  }
}

export default Popover

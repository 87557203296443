import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Screen.scss'
import '../../scss/wham.scss'

class Screen extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    fitToScreen: PropTypes.bool,
    inverse: PropTypes.bool,
    waiting: PropTypes.bool
  }

  render () {
    const {
      className,
      children,
      inverse,
      fitToScreen,
      waiting,
      ...otherProps
    } = this.props
    // classes
    const componentClassName = classNames(
      'w-screen',
      (fitToScreen ? 'w-screen--fit-to-screen' : null),
      (inverse ? 'w-screen--inverse' : null),
      (waiting ? 'w-screen--waiting' : null),
      className
    )

    // render popover element
    return (
      <div {...otherProps} className={componentClassName}>
        <div className='w-screen__content'>
          {children}
        </div>
      </div>
    )
  }
}

export default Screen

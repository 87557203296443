import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import locations from '../../constants/locations'
import { ViewContext } from '../../context'

import { createTiles } from './util'

import DashboardTile from '../../components/dashboard/tile'

import './style.css'

export const Dashboard = ({
  dispatch
}) => {
  const context = useContext(ViewContext)

  const tiles = createTiles(context, locations)

  return (
    <div className='dashboard'>
      <ul className='dashboard__tiles'>
        {tiles.map((tile, i) => (
          <li key={i} className='dashboard__tile'>
            <DashboardTile tile={tile} onClick={path => {
              dispatch(push(path))
            }}/>
          </li>
        ))}
      </ul>
    </div>
  )
}

Dashboard.propTypes = {
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    ...state
  }
}

export default connect(mapStateToProps)(Dashboard)

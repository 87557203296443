import * as R from 'ramda'

import {
  FETCH_CURRENT_DEBUG_SUMMARIES,
  FETCH_FURTHER_DEBUG_SUMMARIES,
  FETCH_PENDING_DEBUG_SUMMARIES,
  LOG_SUMMARY_FETCHED,
  RESET_FACET,
  RESET_FACETS,
  SET_FINAL,
  SET_PENDING,
  SET_GROUP_BY,
  SET_FACETS,
  UPDATE_FACET_OPTION,
  UPDATE_OPEN_FILTERS,
  START_MEDIA_REQUEST_POLLING,
  STOP_MEDIA_REQUEST_POLLING
} from '../../actions/debug'
import { LOGOUT } from '../../actions/login'
import { SET_LOG_ENTRY_FLAG } from '../../actions/transactions'

import * as Groups from '../../constants/debug/groups'

import { updateLogFacets } from './facets'

const combineLogEntries = (logEntries1, logEntries2) =>
  R.compose(
    R.sort(R.descend(R.prop('eventTime'))),
    R.uniqWith(R.eqBy(R.prop('transactionId'))),
    R.concat
  )(logEntries1, logEntries2)

const setLogEntryFlag = (logEntries, transactionId, flag) => {
  return logEntries.map(logEntry => {
    return logEntry.transactionId === transactionId ? { ...logEntry, saved: flag } : logEntry
  })
}

const defaultState = {
  groupBy: Groups.DEFAULT,
  logEntries: [],
  logEntriesTotal: null,
  logFacets: [],
  openFilters: new Set(),
  mediaRequestsPollingEnabled: false,
  newLogs: [],
  final: false,
  error: false,
  pending: false,
  updated: false, // ???
  transactionPending: false, // remove ?
  summaryPending: false,
  flagChangePending: false // remove ?
}

const resetLogFacetOptions = logFacet => ({
  ...logFacet,
  options: logFacet.options.map(option => ({
    ...option,
    selected: undefined
  }))
})

const debugReducer = (state = defaultState, action) => {
  const { type, payload, error } = action

  state.updated = false

  switch (type) {
    case LOG_SUMMARY_FETCHED:
      return {
        ...state,
        summaryPending: false
      }

    case SET_GROUP_BY:
      return {
        ...state,
        groupBy: payload
      }

    case RESET_FACET:
      return {
        ...state,
        logFacets: state.logFacets.map(logFacet =>
          logFacet.name === action.payload.facet.name
            ? resetLogFacetOptions(logFacet)
            : logFacet)
      }

    case RESET_FACETS:
      return {
        ...state,
        groupBy: defaultState.groupBy,
        logFacets: state.logFacets.map(resetLogFacetOptions)
      }

    case SET_FINAL:
      return {
        ...state,
        final: payload
      }

    case SET_PENDING:
      return {
        ...state,
        pending: payload
      }

    case FETCH_CURRENT_DEBUG_SUMMARIES.default: {
      return {
        ...state,
        logEntries: [],
        logEntriesTotal: null,
        newLogs: [], // Reset new logs whenever a current load is initiated to ensure that "pending" transactions are cleared...
        pending: true,
        error: false
      }
    }

    case FETCH_CURRENT_DEBUG_SUMMARIES.success: {
      const { data: { debuglogSummaries: { meta: { total, facets = [] }, data = [] } } } = payload

      return {
        ...state,
        logEntries: data, // Avoid combining with earlier results...
        logEntriesTotal: total,
        logFacets: updateLogFacets(state.logFacets, facets),
        pending: false
      }
    }

    case FETCH_CURRENT_DEBUG_SUMMARIES.failure:
      return {
        ...state,
        logEntries: [],
        logEntriesTotal: null,
        logFacets: [],
        pending: false,
        error
      }

    case FETCH_FURTHER_DEBUG_SUMMARIES.default: {
      return {
        ...state,
        pending: true,
        final: false,
        error: false
      }
    }

    case FETCH_FURTHER_DEBUG_SUMMARIES.success: {
      const { data: { debuglogSummaries: { meta: { total, facets = [] }, data = [] } } } = payload

      const logEntries = combineLogEntries(data, state.logEntries)

      return {
        ...state,
        logEntries: logEntries,
        logEntriesTotal: total,
        logFacets: updateLogFacets(state.logFacets, facets),
        pending: false,
        final: logEntries.length === state.logEntries.length
      }
    }

    case FETCH_FURTHER_DEBUG_SUMMARIES.failure: {
      return {
        ...state,
        pending: false,
        error
      }
    }

    case FETCH_PENDING_DEBUG_SUMMARIES.success: {
      const { data: { debuglogSummaries: { meta: { total }, data = [] } } } = payload

      const newTransactions = data.filter(({ transactionId }) =>
        !state.newLogs.find(newLog => newLog.transactionId === transactionId))

      return {
        ...state,
        logEntriesTotal: total === null ? null : state.logEntriesTotal + newTransactions.length,
        newLogs: combineLogEntries(newTransactions, state.newLogs)
      }
    }

    case FETCH_PENDING_DEBUG_SUMMARIES.failure: {
      return {
        ...state,
        error
      }
    }

    case SET_LOG_ENTRY_FLAG: {
      return {
        ...state,
        logEntries: setLogEntryFlag(state.logEntries, payload.transactionId, payload.saved)
      }
    }

    case START_MEDIA_REQUEST_POLLING: {
      return {
        ...state,
        mediaRequestsPollingEnabled: true
      }
    }

    case STOP_MEDIA_REQUEST_POLLING: {
      return {
        ...state,
        mediaRequestsPollingEnabled: false
      }
    }

    case SET_FACETS: {
      const existingLogFacets = state.logFacets.map(logFacet => {
        if (Object.keys(payload).includes(logFacet.name)) {
          const options = logFacet.options.map(option => {
            const value = payload[logFacet.name]
            option.selected = (Array.isArray(value) ? value.includes(option.key) : value === option.key) || undefined
            return option
          })

          return {
            ...logFacet,
            options
          }
        }

        return logFacet
      })

      const additionalLogFacets = Object.entries(payload).reduce((acc, [name, value]) => {
        if (!existingLogFacets.find(existingLogFacet => existingLogFacet.name === name)) {
          const options = Array.isArray(value) ? value.map(key => ({ key, selected: true })) : [{ key: value, selected: true }]

          acc.push({
            name,
            options
          })
        }

        return acc
      }, [])

      const logFacets = existingLogFacets.concat(additionalLogFacets)

      return {
        ...state,
        logFacets
      }
    }

    case UPDATE_FACET_OPTION: {
      const { facet, option } = payload

      const logFacets = state.logFacets.map(logFacet => {
        if (logFacet.name === facet.name) {
          if (facet.type === 'single') {
            const options = logFacet.options.map(existingOption => {
              const selected = existingOption.key === option.key ? option.selected ? undefined : true : undefined

              return {
                ...existingOption,
                selected
              }
            })

            return {
              ...logFacet,
              options
            }
          }

          if (facet.type === 'multi') {
            const options = logFacet.options.map(existingOption => {
              const selected = existingOption.key === option.key ? option.selected ? undefined : true : existingOption.selected

              return {
                ...existingOption,
                selected
              }
            })

            return {
              ...logFacet,
              options
            }
          }
        }

        return logFacet
      })

      return {
        ...state,
        logFacets
      }
    }

    case UPDATE_OPEN_FILTERS: {
      const [payloadOpenFacets, payloadClosedFacets] = R.partition(({ opened }) => opened, payload)
      const openFiltersNew = new Set(state.openFilters)
      Object.keys(payloadOpenFacets).forEach(control => openFiltersNew.add(control))
      Object.keys(payloadClosedFacets).forEach(control => openFiltersNew.delete(control))
      return {
        ...state,
        openFilters: openFiltersNew
      }
    }

    case LOGOUT:
      return defaultState

    default:
      return state
  }
}

export default debugReducer

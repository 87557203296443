import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Table.scss'
import '../../scss/wham.scss'

class Table extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string
  }

  render () {
    const {
      className,
      children,
      ...otherProps
    } = this.props
    // classes
    var wrapperClasses = classNames(
      'w-table',
      this.props.className
    )

    // render table element
    return (
      <table className={wrapperClasses} {...otherProps}>
        {children}
      </table>
    )
  }
}

export default Table

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { dismissErrorAction } from '../../actions/panic'
import * as Wham from '../../wham/components'
import { paths } from '../../route-paths'
import Header from '../../containers/header'
import './style.css'

const ErrorScreen = ({
  errorTitle,
  errorDescription,
  actionText,
  action
}) => {
  const clickHandler = e => {
    if (action) {
      e.preventDefault()
      action(e)
    }
  }

  return errorTitle && errorDescription ? (
    <div className='error-screen'>
      <Header />
      <div className='error-screen__body'>
        <Wham.Heading level={2} className='error-screen__title'>
          {errorTitle}
        </Wham.Heading>
        <p className='error-screen__description'>
          {errorDescription}
        </p>
        <Wham.Button primary onClick={clickHandler} href={paths.home}>
          {actionText}
        </Wham.Button>
      </div>
    </div>
  ) : null
}

ErrorScreen.propTypes = {
  errorTitle: PropTypes.string,
  errorDescription: PropTypes.string,
  actionText: PropTypes.string,
  action: PropTypes.func
}

const mapStateToProps = ({ system }) => {
  const { error } = system
  if (!error) return {}
  return {
    ...error,
    action: error.dismissable ? dismissErrorAction : undefined
  }
}

export default connect(mapStateToProps)(ErrorScreen)

export const PAGE_NOT_FOUND_ERROR_SCREEN =
  <ErrorScreen
    errorTitle='We cannot display this content'
    errorDescription={'We cannot find what you\'re looking for. If this issue persists, please contact your friendly admin for help.'}
    actionText='Home'
  />

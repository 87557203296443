import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as Wham from '../../wham/components'

import styles from './index.module.scss'

const ICONS = {
  Ready: 'check',
  Blocked: 'error',
  Pending: 'sync',
  Retranscode: 'sync'
}

const CreativeIcon = ({
  status,
  tooltips = false
}) => {
  const icon = ICONS[status] || 'warning'

  const timeout = useRef()

  const [tooltip, setTooltip] = useState(null)

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  const onMouseOver = e => {
    const target = e.target
    timeout.current = setTimeout(() => {
      setTooltip(target)
    }, 500)
  }

  const onMouseOut = () => {
    clearTimeout(timeout.current)
    setTooltip(null)
  }

  return (
    <>
      <span
        className={classNames('w-icon', styles.default, styles[status.toLowerCase()])}
        data-tooltip={status}
        onMouseOver={e => tooltips && onMouseOver(e)}
        onMouseOut={() => tooltips && onMouseOut()}
      >{icon}</span>

      {tooltips && tooltip ? (
        <Wham.Tooltip trigger={tooltip} placement='top'/>
      ) : null}
    </>
  )
}

CreativeIcon.propTypes = {
  status: PropTypes.string.isRequired,
  tooltips: PropTypes.bool
}

export default CreativeIcon

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CloseButton from '../CloseButton'

import './Modal.scss'
import '../../scss/wham.scss'

const MODAL_CONTENT_SIZE = [
  'large',
  'medium',
  'small'
]

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    active: PropTypes.bool,
    contentSize: PropTypes.oneOf(MODAL_CONTENT_SIZE),
    closeHandler: PropTypes.func,
    backgroundClosesModal: PropTypes.bool,
    inverse: PropTypes.bool
  }

  render () {
    const {
      active,
      className,
      contentSize,
      children,
      closeHandler,
      backgroundClosesModal,
      inverse,
      ...otherProps
    } = this.props
    const componentClass = classNames(
      'w-modal',
      (inverse && 'w-modal--inverse'),
      (active && 'w-modal--active'),
      className
    )

    const contentClass = classNames(
      'w-modal__content',
      (contentSize && `w-modal__content--${contentSize}`)
    )

    return (
      <div className={componentClass} {...otherProps}>
        <div className='w-modal__background' onClick={backgroundClosesModal ? closeHandler : null} />
        <div className={contentClass}>
          {children}
        </div>
        {closeHandler ? <CloseButton inverse={inverse} className='w-modal__close-btn' size='large' onClick={closeHandler} /> : null}
      </div>
    )
  }
}

export default Modal

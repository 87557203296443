import * as R from 'ramda'

import {
  VALIDATE_ASSET,
  CLEAR_VALIDATED,
  REMOVE_VALIDATED,
  SELECT_VALIDATED
} from '../../actions/assets'

const defaultState = {
  media: {
    uris: [],
    results: {}
  },
  selected: undefined
}

const prependNew = (uri, uris) => {
  return R.not(R.includes(uri, uris)) ? [uri, ...uris] : [...uris]
}

const addLatest = ({ uri }) => {
  return R.assocPath(['media', 'results', uri, 'latest'], true)
}

const delLatest = () => state => {
  const original = R.path(['media', 'results'], state)
  const updated = {
    ...R.map(originalResult => R.dissocPath(['latest'], originalResult), original)
  }
  return R.assocPath(['media', 'results'], updated, state)
}

const addRepeat = ({ uri }) => state => {
  const uris = R.path(['media', 'uris'], state)
  const isRepeat = R.includes(uri, uris, state)
  return R.assocPath(['media', 'results', uri, 'repeat'], isRepeat, state)
}

const addName = ({ uri, file = {}, name }) => {
  return R.assocPath(['media', 'results', uri, 'name'], file.name || name || uri)
}

const addIndex = ({ uri }) => state => {
  const original = R.path(['media', 'uris'], state)
  const updated = prependNew(uri, original)
  return R.assocPath(['media', 'uris'], updated, state)
}

const addResult = ({ uri, ...other }) => state => {
  const original = R.path(['media', 'results', uri], state)
  const updated = {
    ...original,
    ...other,
    uri
  }
  return R.assocPath(['media', 'results', uri], updated, state)
}

const addError = (error, { uri, ...other }) => state => {
  const original = R.path(['media', 'results', uri], state)
  const updated = {
    error,
    ...original,
    ...other
  }
  return R.assocPath(['media', 'results', uri], updated, state)
}

const getFirstUriFromIndices = R.compose(
  R.head,
  R.path(['media', 'uris'])
)

const removeResult = ({ uri }) => state => {
  return R.dissocPath(['media', 'results', uri], state)
}

const removeIndex = ({ uri }) => state => {
  const original = R.path(['media', 'uris'], state)
  const updated = R.without([uri], original)
  return R.assocPath(['media', 'uris'], updated, state)
}

const changeSelectedIfNeeded = ({ uri }) => state => {
  const selected = (R.prop('selected', state) === uri) ? getFirstUriFromIndices(state) : undefined
  return {
    ...state,
    selected
  }
}

const pending = ({ uri }) => {
  return R.assocPath(['media', 'results', uri, 'pending'], true)
}

const complete = ({ uri }) => {
  return R.assocPath(['media', 'results', uri, 'pending'], false)
}

export default (state = defaultState, { type, payload, error }) => {
  switch (type) {
    case VALIDATE_ASSET.default: {
      return R.compose(
        addLatest(payload),
        delLatest(payload),
        addName(payload),
        pending(payload),
        addIndex(payload),
        addRepeat(payload)
      )(state)
    }

    case VALIDATE_ASSET.success: {
      return R.compose(
        addName(payload),
        complete(payload),
        addResult(payload)
      )(state)
    }

    case VALIDATE_ASSET.failure: {
      return R.compose(
        addName(payload),
        complete(payload),
        addError(error, payload)
      )(state)
    }

    case CLEAR_VALIDATED: {
      return defaultState
    }

    case REMOVE_VALIDATED: {
      return R.compose(
        changeSelectedIfNeeded(payload),
        removeResult(payload),
        removeIndex(payload)
      )(state)
    }

    case SELECT_VALIDATED: {
      return R.path(['media', 'results', payload.uri], state)
        ? R.assoc('selected', payload.uri, state)
        : state
    }

    default: {
      return state
    }
  }
}

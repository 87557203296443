import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { inspect } from 'util'

import * as Wham from '../../wham/components'
import { paths } from '../../route-paths'

export const Panic = ({ err, ...otherProps }) => {
  return (
    <div>
      <Wham.Message type='danger'>There has been a problem rendering this content</Wham.Message>
      <div style={{
        width: '100%',
        maxWidth: '320px',
        margin: '0 auto'
      }}
      >
        <p> <br /> Try reloading the page, if the issue persists please contact us with the detailed error below </p>
        <p>
          <CopyToClipboard
            fullWidth
            text={inspect({ err, info: { ...otherProps } })}
          >
            <Wham.Button type='default'>
              Click Here to Copy The Error Message in Full
            </Wham.Button>
          </CopyToClipboard>
          <Wham.Button fullWidth type='primary' href={paths.home}>
            Reload AdEngine
          </Wham.Button>
        </p>
      </div>
    </div>
  )
}

Panic.propTypes = {
  err: PropTypes.any,
  state: PropTypes.any
}

export const ConnectedPanic = connect((state, ownProps) => ({ ...ownProps, state }))(Panic)
export const makeErrorBoundary = Component =>
  (class ViewErrorContainer extends React.Component {
    static propTypes = {
      ErrorComponent: PropTypes.any
    }

    constructor (props) {
      super(props)
      this.state = {
        err: {}
      }
    }

    componentDidCatch (error, info) {
      this.setState(
        {
          ...this.state,
          err: {
            error,
            info
          }
        }
      )
    }

    render () {
      const { ErrorComponent, ...otherProps } = this.props
      const { err } = this.state
      const DisplayedErrorComponent = ErrorComponent || ConnectedPanic
      return err.error
        ? <DisplayedErrorComponent err={err} {...otherProps} />
        : <Component {...otherProps} />
    }
  })

/* eslint-disable */
import React from 'react'

const transformChangeHandlerSignature = Component => ({onChange, ...props}) => {
  return (onChange) ? (
    <Component
      {...props}
      onChange={e => {
        onChange(e, e.target.value)
      }} />
  ) : (<Component {...props} />)
}

export default transformChangeHandlerSignature

/* eslint-enable */

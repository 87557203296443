import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import * as Wham from '../../wham/components'

import {
  SELECTED_TO_PLAY,
  NO_VALID_CREATIVE,
  NO_ADVERT,
  MAX_DEPTH_REACHED,
  TIMEOUT,
  CALL_FAILURE,
  PARSE_FAILURE,
  NON_LINEAR,
  UNUSED,
  BLOCKED,
  RESOLVER_FAILURE,
  SENT_TO_TRANSCODE
} from '../../constants'

import BadgeUrl from './badge-url'
import PlaybackButton from '../playback-button'
import SendToValidator from '../actions/send-to-validator'
import NavigateToCreative from '../actions/navigate-to-creative'

import './style.css'

export const SKIPPED_AD_STATUS_TEXT = {
  [NO_ADVERT]: 'No Valid Ads',
  [NO_VALID_CREATIVE]: 'Invalid Creative',
  [TIMEOUT]: 'Timeout',
  [MAX_DEPTH_REACHED]: 'Wrapper Limit Reached',
  [CALL_FAILURE]: 'Request Failure',
  [PARSE_FAILURE]: 'Parse Error',
  [NON_LINEAR]: 'Nonlinear',
  [UNUSED]: 'Surplus',
  [SENT_TO_TRANSCODE]: 'Sent to transcode',
  [BLOCKED]: 'Rejected By Transcode',
  [RESOLVER_FAILURE]: 'Resolver Failure'
}

const makeStatusBadgePair = (status, type) => {
  const label = SKIPPED_AD_STATUS_TEXT[status]
  const key = status
  const value = <Wham.Badge label={label} type={type} />
  return [key, value]
}

const STATUS_BADGES = R.fromPairs([
  makeStatusBadgePair(NO_ADVERT, 'danger'),
  makeStatusBadgePair(NO_VALID_CREATIVE, 'danger'),
  makeStatusBadgePair(TIMEOUT, 'danger'),
  makeStatusBadgePair(MAX_DEPTH_REACHED, 'danger'),
  makeStatusBadgePair(CALL_FAILURE, 'danger'),
  makeStatusBadgePair(PARSE_FAILURE, 'danger'),
  makeStatusBadgePair(NON_LINEAR, 'danger'),
  makeStatusBadgePair(UNUSED, 'success'),
  makeStatusBadgePair(SENT_TO_TRANSCODE, 'warning'),
  makeStatusBadgePair(BLOCKED, 'danger'),
  makeStatusBadgePair(RESOLVER_FAILURE, 'danger')
])

const STATUS_ICONS = {
  [NO_ADVERT]: <Wham.Icon iconName='help_outline' color='var(--w-color-danger)' />,
  [NO_VALID_CREATIVE]: <Wham.Icon iconName='videocam_off' color='var(--w-color-danger)' />,
  [UNUSED]: <Wham.Icon iconName='queue' color='var(--w-color-success)' />,
  [SENT_TO_TRANSCODE]: <Wham.Icon iconName='loop' color='var(--w-color-warning)' />,
  [TIMEOUT]: <Wham.Icon iconName='av_timer' color='var(--w-color-danger)' />,
  [MAX_DEPTH_REACHED]: <Wham.Icon iconName='warning' color='var(--w-color-danger)' />,
  [NON_LINEAR]: <Wham.Icon iconName='warning' color='var(--w-color-danger)' />,
  [CALL_FAILURE]: <Wham.Icon iconName='warning' color='var(--w-color-danger)' />,
  [PARSE_FAILURE]: <Wham.Icon iconName='warning' color='var(--w-color-danger)' />,
  [BLOCKED]: <Wham.Icon iconName='block' color='var(--w-color-danger)' />,
  [RESOLVER_FAILURE]: <Wham.Icon iconName='warning' color='var(--w-color-danger)' />
}

const RESOLVER_FAILURE_SUBTYPES = {
  [NO_ADVERT]: {
    tooltip: 'The top line or the vast wrapper did not contain a valid ad'
  },
  [MAX_DEPTH_REACHED]: {
    tooltip: 'The Ad Server returned a deeply nested response that exceeded the depth limits that Ad Engine allows'
  },
  [TIMEOUT]: {
    tooltip: 'There was a timeout in the request to the Ad Server'
  },
  [CALL_FAILURE]: {
    tooltip: 'HTTP request had a failure'
  },
  [PARSE_FAILURE]: {
    tooltip: 'Ad Engine was unable to parse elements of the response from the Ad Server'
  },
  [NON_LINEAR]: {
    tooltip: 'There was a nonlinear ad returned, no valid video creatives'
  }
}

const SEND_TO_VALIDATOR_STATUSES = [
  BLOCKED,
  UNUSED,
  SENT_TO_TRANSCODE
]

const NAVIGATE_TO_CREATIVE_STATUSES = [
  BLOCKED,
  SENT_TO_TRANSCODE
]

class AdDecision extends Component {
  constructor (props) {
    super(props)
    this.state = {
      resolverFailureTooltipTrigger: null
    }
  }

  millisToMinutesAndSeconds (millis) {
    if (!millis) return 0
    const minutes = Math.floor(millis / 60000)
    const seconds = ((millis % 60000) / 1000).toFixed(0)
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }

  onMouseOverSkipReason (e, tooltip) {
    this.setState({
      resolverFailureTooltipTrigger: tooltip ? e.target : null
    })
  }

  onMouseOutSkipReason () {
    this.setState({
      resolverFailureTooltipTrigger: null
    })
  }

  makeGamDeepLink (gamNetworkId, vastIdentifiers) {
    const adId = R.path([0, 'id'], vastIdentifiers)
    if (gamNetworkId && adId) {
      return `https://admanager.google.com/${gamNetworkId}#delivery/line_item/detail/line_item_id=${adId}`
    }
    return undefined
  }

  render () {
    const {
      mezzFile = '',
      vastIdentifiers = [],
      amsId: adEngineId = null,
      duration,
      status
    } = this.props.decision

    const {
      gamNetworkId,
      sequence,
      playMezzFile,
      isPlaying,
      playerLoading,
      playerError,
      dispatch,
      mode = 'normal'
    } = this.props

    const type = R.path(['_type'], status)
    const subtype = R.path(['failure', '_type'], status)
    const subtypeIsValid = !!RESOLVER_FAILURE_SUBTYPES[subtype]
    const gamDeepLink = this.makeGamDeepLink(gamNetworkId, vastIdentifiers)

    const getStatusType = () => {
      const candidateType = type === RESOLVER_FAILURE && subtypeIsValid
        ? subtype
        : type
      if (mode !== 'other') {
        return candidateType
      }
      return candidateType === SELECTED_TO_PLAY
        ? UNUSED
        : candidateType
    }
    const statusType = getStatusType()
    const skipped = statusType !== SELECTED_TO_PLAY

    const contentDuration = duration * 1000
    const adId = R.pathOr(null, [0, 'id'], vastIdentifiers)
    const wrapperUrl = R.pathOr(null, ['wrapperUrl'], R.findLast(R.has('wrapperUrl'), vastIdentifiers))

    const renderSkippedReason = () => {
      const tooltip = R.path([subtype, 'tooltip'], RESOLVER_FAILURE_SUBTYPES)
      return (
        <span className='debug__ad-decision__skip-reason' data-cy='skip-reason'>
          {STATUS_BADGES[statusType]}

          {SEND_TO_VALIDATOR_STATUSES.includes(statusType)
            ? <SendToValidator uri={mezzFile} dispatch={dispatch} />
            : null}

          {NAVIGATE_TO_CREATIVE_STATUSES.includes(statusType)
            ? <NavigateToCreative mezzFile={mezzFile} dispatch={dispatch} />
            : null}

          {tooltip ? <i
            onMouseOver={e => this.onMouseOverSkipReason(e, tooltip)}
            onMouseOut={() => this.onMouseOutSkipReason()}
            data-tooltip={tooltip}
          >?
          </i>
            : null}
        </span>
      )
    }

    return (
      <div className='debug__ad-decision' data-cy='ad-decision'>

        <div className='debug__ad-decision__context'>
          {
            mode === 'pod'
              ? sequence + 1
              : skipped ? STATUS_ICONS[statusType] : sequence + 1
          }
        </div>

        <div className='debug__ad-decision__content'>

          <div className='debug__ad-decision__content__primary'>
            <span className='debug__ad-decision__content__primary__ad-id'>
              {adId && (
                <span>
                  <Wham.Badge label='Ad ID' />
                  {
                    gamDeepLink
                      ? (
                        <>
                          <a href={gamDeepLink} rel='noopener noreferrer' target='_blank'>{adId}</a>
                          <Wham.Icon iconName='open_in_new' />
                        </>
                      )
                      : <span>{adId}</span>
                  }
                </span>
              )}
            </span>

            <span className='debug__ad-decision__url'>
              {mezzFile ? (
                <BadgeUrl url={mezzFile} label='Mezz File' source='AdDecision Mezzfile Link' />
              ) : (
                wrapperUrl && type === RESOLVER_FAILURE ? (
                  <BadgeUrl url={wrapperUrl} label='VAST' source='AdDecision VAST Link' />
                ) : null
              )}
            </span>
          </div>

          <div className='debug__ad-decision__content__secondary'>
            <span className='debug__ad-decision__details'>
              <span className='debug__ad-decision__duration'>
                <Wham.Icon iconName='timer' />
                <span className='debug__ad-decision__duration__label'>
                  {this.millisToMinutesAndSeconds(contentDuration)}
                </span>
              </span>
              <span className='debug__ad-decision__trace'>
                {vastIdentifiers.map((id, i) =>
                  <span key={i}>
                    {i !== 0 && <Wham.Icon iconName='arrow_forward' />}
                    <span className='debug__ad-decision__trace__label'>{id.adSystem}</span>
                  </span>
                )}
                <span className='debug__ad-decision__trace__label'>
                  {!adId && ('No VAST identifiers found')}
                </span>
              </span>
            </span>

            {
              this.state.resolverFailureTooltipTrigger
                ? <Wham.Tooltip trigger={this.state.resolverFailureTooltipTrigger} />
                : null
            }

            {skipped
              ? renderSkippedReason()
              : adEngineId && <span className='debug__ad-decision__content__primary__ade-id'>
                <Wham.Badge className='debug__ad-decision__badge' label='adE ID' />
                <span>{adEngineId}</span>
              </span>}
          </div>
        </div>

        <div className='debug__ad-decision__playback'>
          {
            mezzFile
              ? (
                <PlaybackButton
                  notOnSafari
                  onPlay={() => playMezzFile(this.props.decision)}
                  isPlaying={isPlaying}
                  playerLoading={playerLoading}
                  playerError={playerError}
                />
              )
              : null
          }
        </div>
      </div>
    )
  }
}

AdDecision.propTypes = {
  gamNetworkId: PropTypes.string,
  sequence: PropTypes.number,
  decision: PropTypes.object,
  playMezzFile: PropTypes.func,
  isPlaying: PropTypes.bool,
  playerLoading: PropTypes.bool,
  playerError: PropTypes.string,
  mode: PropTypes.oneOf(['normal', 'pod', 'other']),
  dispatch: PropTypes.func.isRequired
}

export default AdDecision

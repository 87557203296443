import React from 'react'

const DebugContext = React.createContext({})
const HeaderMainMenuContext = React.createContext({})
const ViewContext = React.createContext({})
const WhitelistContext = React.createContext({})

export {
  DebugContext,
  HeaderMainMenuContext,
  ViewContext,
  WhitelistContext
}

import * as R from 'ramda'

import { SELECTED_TO_PLAY } from '../../../../constants'
import { buildSgmntToAdNumber, getBitrate, getChunk, getChunkDur, getEventTime, getSgmnt } from '../../../../lib/playlist'
import { sortMediaRequests } from '../../../../lib/debug'

import { getSummaryData, getGamNetworkId } from '../../getTransactionDetails'

const updateTransactionDetails = (
  partner,
  claims,
  transactionDetails,
  transaction,
  waitingMediaRequests,
  latestMediaRequests,
  bookmark,
  deepLink
) => {
  const { data: { debuglog } = {}, loading, error } = transaction

  const transactionPending = loading
  const summaryPending = loading ? deepLink : false
  const mediaRequestsPending = loading || latestMediaRequests.loading
  const adDecisionServiceDataPending = loading
  const adEngineDecisionsPending = loading
  const flagChangePending = bookmark.loading

  if (loading || error || debuglog === null) {
    const summaryData = () => {
      if (!deepLink) {
        const ruleId = transactionDetails.focusedLog?.ruleId
        const ruleName = transactionDetails.focusedLog?.ruleName
        return getSummaryData(partner, claims, transactionDetails.focusedLog, ruleId, ruleName)
      }
    }

    return {
      ...transactionDetails,
      transactionPending,
      summaryPending,
      mediaRequestsPending,
      adDecisionServiceDataPending,
      adEngineDecisionsPending,
      flagChangePending,
      focusedLog: debuglog === null ? null : transactionDetails.focusedLog,
      summaryData: summaryData(),
      error
    }
  }

  const {
    adDecisionServiceData,
    adEngineDecisions
  } = debuglog

  const mediaRequests = sortMediaRequests(latestMediaRequests.data?.mediaRequests || debuglog.mediaRequests)
  const newTs = waitingMediaRequests.data?.mediaRequests || []

  const adsRawResponseXML = adDecisionServiceData?.response?.data || ''
  const adsRequestCookies = adDecisionServiceData?.request?.cookies || []
  const adsRequestHeaders = adDecisionServiceData?.request?.headers || []
  const adsRequestUrl = adDecisionServiceData?.request?.url || ''

  const gamNetworkId = getGamNetworkId(adsRequestUrl)

  const augmentedMediaRequests = mediaRequests.map(mediaRequest => {
    return {
      ...mediaRequest,
      $bitrate: getBitrate(mediaRequest),
      $chunk: getChunk(mediaRequest),
      $chunkdur: getChunkDur(mediaRequest),
      $eventTime: getEventTime(mediaRequest),
      $sgmnt: getSgmnt(mediaRequest)
    }
  })

  const [selectedAds, skippedAds] = R.partition(({ status }) => status._type === SELECTED_TO_PLAY, adEngineDecisions || [])

  const viewedAds = selectedAds.filter(selectedAd => {
    return augmentedMediaRequests.some(({ response }) => {
      return response && response.amsId === selectedAd.amsId
    })
  })

  const sgmntToAdNumber = buildSgmntToAdNumber(selectedAds, augmentedMediaRequests)
  const firstSgmntNumber = augmentedMediaRequests.length ? Number(augmentedMediaRequests[0].$sgmnt) : 0

  const focusedLog = {
    ...debuglog,
    settoken: debuglog.session?.setToken,
    aps: debuglog.session?.aps
  }

  const summaryData = getSummaryData(partner, claims, focusedLog, debuglog.ruleId, debuglog.ruleName)

  const {
    transactionId,
    prevTransactionId,
    nextTransactionId
  } = transactionDetails

  return {
    transactionId,
    selectedAds,
    skippedAds,
    viewedAds,
    newTs,
    transactionPending,
    summaryPending,
    mediaRequestsPending,
    adDecisionServiceDataPending,
    adEngineDecisionsPending,
    flagChangePending,
    focusedLog,
    adEDecisions: adEngineDecisions,
    adsRawResponseXML,
    adsRequestHeaders,
    adsRequestCookies,
    adsRequestUrl,
    gamNetworkId,
    tsData: augmentedMediaRequests,
    prevTransactionId,
    nextTransactionId,
    sgmntToAdNumber,
    firstSgmntNumber,
    summaryData
  }
}

export {
  updateTransactionDetails
}

import React from 'react'
import PropTypes from 'prop-types'

import HeaderMainMenu from './header-main-menu'
import HeaderUser from '../../components/header/header-user'

import './style.css'

const Header = ({
  left,
  middle,
  right
}) => (
  <div className='header'>
    <div className='header__left'>
      {left || <HeaderMainMenu />}
    </div>
    <div className='header__middle'>
      {middle}
    </div>
    <div className='header__right'>
      {right || <HeaderUser />}
    </div>
  </div>
)

Header.propTypes = {
  left: PropTypes.node,
  middle: PropTypes.node,
  right: PropTypes.node
}

export default Header

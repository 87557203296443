import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../../../wham/components'

const ProfileTable = ({
  profile,
  passed,
  closest = false
}) => (
  <div className={`validator_spectable-detail_wrapper ${closest ? 'validator_spectable-detail_wrapper--closest' : 'validator_spectable-detail_wrapper--additional'}`}>
    <div className='validator_spectable-detail_padding'>
      <table id={profile.name} className='validator_spectable-detail_table'>
        <tbody>
          {passed ? null : (
            <tr className='validator_spectable-detail_row'>
              <td className='validator_cell-closest' colSpan='4'>
                <span
                  className='validator_spectable-match'>{closest ? 'Closest matching profile: ' : ''}{profile.name}</span>
                <span
                  className='validator_spectable-error_count'>{profile.failures} error{profile.failures > 1 && 's'}</span>
              </td>
            </tr>
          )}

          {profile.specs.map(({ id, name, value, error, passed }, i) => (
            <tr key={i} id={id} className={`validator_spectable-detail_row ${(passed ? '' : 'validator_spectable-detail_row--failure')}`}>
              <td className={'validator_cell-icon'}>
                {passed ? null : (<Wham.Icon iconName='not_interested' color='white'/>)}
              </td>
              <td className='validator_cell-label'>
                {name}
              </td>
              <td className='validator_cell-value'>
                {value}
              </td>
              <td className='validator_cell-condition'>
                {error}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
)

ProfileTable.propTypes = {
  profile: PropTypes.any,
  passed: PropTypes.bool,
  closest: PropTypes.bool
}

export default ProfileTable

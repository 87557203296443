import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Image.scss'
import '../../scss/wham.scss'

const IMAGE_SIZINGS = ['fit', 'fill', 'full']

class Image extends React.Component {
  static propTypes = {
    src: PropTypes.string,
    caption: PropTypes.string,
    expandable: PropTypes.bool,
    className: PropTypes.string,
    sizing: PropTypes.oneOf(IMAGE_SIZINGS)
  }

  constructor () {
    super()
    this.state = {
      expanded: false
    }

    this.toggleExpand = this.toggleExpand.bind(this)
  }

  toggleExpand ({ target }) {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render () {
    const {
      src,
      expandable,
      caption,
      className,
      sizing,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-image',
      (expandable ? 'w-image--expandable' : null),
      (expandable && this.state.expanded ? 'w-image--expanded' : null),
      (sizing ? 'w-image--sizing-' + sizing : null),
      this.props.className
    )

    let Element = 'span'

    if (caption) {
      Element = 'figure'
    }

    // render popover element
    return (
      <Element {...otherProps} className={componentClass} onClick={this.toggleExpand}>
        <img src={src} alt='' />
        {(caption ? <figcaption className='w-image__caption'>{caption}</figcaption> : null)}
      </Element>
    )
  }
}

export default Image

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as Wham from '../../../wham/components'

import {
  activePartnerGlobalSelector,
  debuggingPendingSelector
} from '../../../selectors'

import Header from '../'
import HeaderDebugControls from '../header-debug-controls'

import './style.css'

const HeaderDebug = ({
  whitelist,
  pending = true
}) => {
  return (
    <div className='header-debug'>
      <div className='header-debug__row'>
        <Header
          middle={
            <HeaderDebugControls whitelist={whitelist} />
          }
        />
      </div>

      <div className='header-debug__row'>
        <Wham.Progress type='indeterminate' active={pending} />
      </div>
    </div>
  )
}

HeaderDebug.propTypes = {
  whitelist: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  }).isRequired,
  searchType: PropTypes.string,
  searchValue: PropTypes.string,
  swid: PropTypes.shape({
    enabled: PropTypes.bool.isRequired
  }).isRequired,
  pending: PropTypes.bool.isRequired,
  dispatch: PropTypes.func
}

const headerDebugStateSelector = (state, ownProps) => {
  const partner = activePartnerGlobalSelector(state)

  const pending = debuggingPendingSelector(partner)(state)

  return {
    ...ownProps,
    pending
  }
}

export default connect(headerDebugStateSelector)(HeaderDebug)

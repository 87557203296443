import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

import './style.css'

const TEXTPART_CLASSNAMES = {
  normal: 'textpart-normal',
  delimiter: 'textpart-normal',
  separator: 'textpart-normal',
  optional: 'textpart-optional',
  key: 'textpart-key',
  macro: 'textpart-macro',
  value: 'textpart-value'
}

export const HeadPart = ({ headPart }) =>
  <span>{headPart}</span>

HeadPart.propTypes = {
  headPart: PropTypes.string.isRequired
}

const TextPart = ({ textPart: { type, text } }) =>
  <span className={TEXTPART_CLASSNAMES[type]}>{text}</span>

TextPart.propTypes = {
  textPart: PropTypes.object.isRequired
}

export const TextParts = ({ textParts }) =>
  textParts.map((textPart, index) =>
    <TextPart textPart={textPart} key={index} />)

TextParts.propTypes = {
  textParts: PropTypes.array.isRequired
}

const MacroSplitter = ({
  children,
  expanded,
  onClickHeader,
  onClickField
}) => {
  return (
    <div className='macro-splitter'>

      <div className='macro-splitter__header'>
        <div className='macro-splitter__header-control'>
          <Wham.Heading className='macro-splitter__header-label' level={5}>Headers</Wham.Heading>
          <Wham.Icon className='macro-splitter__header-toggle' iconName={expanded ? 'expand_less' : 'expand_more'} onClick={onClickHeader} />
        </div>

        <div className='macro-splitter__header-line'>&nbsp;</div>
        <div className={`macro-splitter__header-content ${expanded ? 'macro-splitter__header-content--expanded' : 'macro-splitter__header-content--collapsed'}`} onClick={onClickField}>
          {children && children[0]}
        </div>
      </div>

      <div className={`macro-splitter__url ${expanded ? 'macro-splitter__url--expanded' : 'macro-splitter__url--collapsed'}`}>
        <Wham.Heading className='macro-splitter__url-label' level={5}>Request URL</Wham.Heading>

        <div className='macro-splitter__url-content' data-cy='macro-splitter' onClick={onClickField}>
          {children && children[1]}
        </div>
      </div>
    </div>
  )
}

MacroSplitter.propTypes = {
  children: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClickHeader: PropTypes.func.isRequired,
  onClickField: PropTypes.func.isRequired
}

export default MacroSplitter

import { GO_TO } from '../../actions/navigators'

const defaultState = {
  to: null,
  from: null
}

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case GO_TO: {
      return {
        ...state,
        ...payload
      }
    }

    default: {
      return state
    }
  }
}

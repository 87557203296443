import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const DebugFiltersRefresh = ({
  pending,
  onClick
}) => {
  return (
    <div className='debug-filters-refresh'>
      <button
        className='debug-filters-refresh__button w-btn'
        disabled={pending}
        onClick={onClick}
      >
        <span className='w-icon'>refresh</span>
      </button>
    </div>
  )
}

DebugFiltersRefresh.propTypes = {
  pending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DebugFiltersRefresh

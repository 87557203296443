import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

import './style.css'

const DocLink = ({ topic }) => {
  const [tooltip, setTooltip] = useState(null)
  const path = `/docs2/${topic}`

  return (
    <a href={path} className='doc-link' target='_blank' rel='noreferrer'>
      <Wham.Icon
        iconName='help_outline'
        onMouseOver={e => setTooltip(e.target)}
        onMouseOut={() => setTooltip(null)}
        data-tooltip='Navigate to user guide in new tab'
      />
      {tooltip && <Wham.Tooltip trigger={tooltip} placement='top-left' />}
    </a>
  )
}

DocLink.propTypes = {
  topic: PropTypes.string.isRequired
}

export default DocLink

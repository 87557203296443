export const BASIC_PROPS = [
  'id',
  'style',
  'onClick',
  'onHover',
  'onDoubleClick',
  'onContextMenu',
  'onMouseEnter',
  'onMouseLeave',
  'onMouseUp',
  'onMouseDown',
  'onMouseOver',
  'onTransitionEnd',
  'onKeyDown',
  'onKeyUp',
  'onFocus',
  'onBlur'
]

export const FORM_PROPS = [
  ...BASIC_PROPS,
  'onChange',
  'name'
]

export const TEXT_PROPS = [
  ...FORM_PROPS,
  'placeholder'
]

export const filterAcceptableProps = validPropList => props => {
  const filteredProps = {}
  for (const key in props) {
    if (Object.prototype.hasOwnProperty.call(props, key) && validPropList.indexOf(key) !== -1) {
      filteredProps[key] = props[key]
    }
  }
  return filteredProps
}

export default {
  BASIC_PROPS,
  FORM_PROPS,
  TEXT_PROPS,
  filterAcceptableProps
}

import * as R from 'ramda'

const isParam = pathBit => pathBit.startsWith(':')
const paramName = pathBit => pathBit.substring(1)

const pathMatcher = pathname => {
  const pathnameBits = pathname.split('/')

  return pathTemplate => {
    const pathTemplateBits = pathTemplate.split('/')

    if (pathTemplateBits.length !== pathnameBits.length) {
      return null
    }

    if (!pathTemplateBits.some(isParam)) {
      return pathname === pathTemplate ? [pathTemplate, {}] : null
    }

    const initialState = [true, {}]
    const finalState = pathTemplateBits.reduce(
      (acc, pathBit, index) => {
        const [matchResult, params] = acc
        if (!matchResult) return acc
        const pathnameBit = pathnameBits[index]
        if (isParam(pathBit)) {
          return [true, { ...params, [paramName(pathBit)]: pathnameBit }]
        } else {
          return [pathnameBit === pathBit, params]
        }
      },
      initialState)

    const [matchResult, params] = finalState
    return matchResult ? [pathTemplate, params] : null
  }
}

const sortFn = (pathTemplateA, pathTemplateB) => {
  const bitsA = pathTemplateA.split('/')
  const bitsB = pathTemplateB.split('/')
  const paramCountA = bitsA.filter(isParam).length
  const paramCountB = bitsB.filter(isParam).length
  if (paramCountA !== paramCountB) {
    return paramCountA - paramCountB
  }
  const patternA = bitsA.map(bit => isParam(bit) ? 'z' : 'a').join('')
  const patternB = bitsB.map(bit => isParam(bit) ? 'z' : 'a').join('')
  return patternA.localeCompare(patternB)
}

export const matchPathAndParams = (pathTemplates, pathname) => {
  const sortedPathTemplates = pathTemplates.sort(sortFn)
  const fn = pathMatcher(pathname)
  return R.reduceWhile(
    R.not,
    (_, pathTemplate) => fn(pathTemplate),
    null,
    sortedPathTemplates)
}

export const expandPathTemplate = (pathTemplate, values) => {
  const bits = pathTemplate.split('/')

  const valid = bits.every(bit => isParam(bit) ? R.has(paramName(bit), values) : true)

  if (!valid) {
    return '/'
  }

  const transformedBits = bits.map(bit => isParam(bit) ? values[paramName(bit)] : bit)
  return transformedBits.join('/')
}

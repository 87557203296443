import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import { HeaderMainMenuContext } from '../../../context'

import { createMenu } from './util'

import './style.css'

const HEIGHT_ALLOWANCE = 28
const HEIGHT_ADJUSTMENT = 140

const HEIGHT_OPTION_ITEM = 49
const HEIGHT_SECTION_MARGINS = 14
const HEIGHT_SECTION_TITLE = 23

const WIDTH_ADJUSTMENT = 210

const WIDTH_OPTION_ITEM = 200
const WIDTH_SECTION_MARGINS = 14

const calcHeight = section => {
  return HEIGHT_SECTION_MARGINS + HEIGHT_SECTION_TITLE + section.options.reduce(sum => sum + HEIGHT_OPTION_ITEM, 0)
}

const calcWidth = columns => {
  return ((WIDTH_OPTION_ITEM + WIDTH_SECTION_MARGINS) * columns)
}

const calcDimensions = (menu, windowHeight) => {
  const { height, columns } = menu.reduce(({ columns = 1, height = 0, result = 0 }, section) => {
    const sectionHeight = calcHeight(section)
    const columnHeight = sectionHeight + result

    const nextColumns = columnHeight > windowHeight ? columns + 1 : columns
    const nextResult = columnHeight > windowHeight ? sectionHeight : columnHeight
    const nextHeight = nextResult > height ? nextResult : height

    return {
      columns: nextColumns,
      height: nextHeight,
      result: nextResult
    }
  }, {})

  const width = calcWidth(columns)

  return {
    height,
    width
  }
}

const HeaderMegaMenu = ({
  locations,
  onClickOption,
  $state = {}
}) => {
  const context = useContext(HeaderMainMenuContext)

  const menu = createMenu(context, locations)

  const text = menu.reduce((acc, section) => {
    const selected = section.options.find(option => option.selected)
    return selected ? selected.text : acc
  }, '') || '?'

  const [open, setOpen] = useState($state.open || false)
  const [style, setStyle] = useState({})
  const [scroll, setScroll] = useState({})

  const updateScroll = (e) => {
    const element = e ? e.target : document.getElementById('header-mega-menu__sections')

    if (element && (element.scrollHeight > element.clientHeight)) {
      setScroll({
        upper: element.scrollTop > 0,
        lower: (element.scrollHeight - element.scrollTop) > element.clientHeight
      })
    } else {
      setScroll({})
    }
  }

  const updateStyle = () => {
    const windowHeight = window.innerHeight - HEIGHT_ADJUSTMENT
    const windowWidth = window.innerWidth - WIDTH_ADJUSTMENT

    const { height, width } = calcDimensions(menu, windowHeight - HEIGHT_ALLOWANCE)

    if ((height + HEIGHT_ALLOWANCE) > windowHeight || width > windowWidth) {
      setStyle({})
    } else {
      setStyle({ height: `${height}px`, width: `${width}px` })
    }

    updateScroll()
  }

  useEffect(() => {
    updateStyle()
    window.addEventListener('resize', updateStyle)
    return () => window.removeEventListener('resize', updateStyle)
  }, [])

  useEffect(() => {
    if (open) {
      updateScroll()
    }
  }, [open])

  return (
    <div className='header-mega-menu'>
      <div className='header-mega-menu__control'>
        <button className={classNames('header-mega-menu__button', {'header-mega-menu__button--open': open})} onClick={() => setOpen(!open)}>
          {open ? (
            <span className='header-mega-menu__icon w-icon'>close</span>
          ) : (
            <span className='header-mega-menu__text'>{text}</span>
          )}

          <span className={classNames('w-icon header-mega-menu__more', { 'header-mega-menu__more--open': open })}>expand_more</span>
        </button>
      </div>

      {open ? (
        <>
          <div className={classNames('header-mega-menu__content', {'header-mega-menu__content--styled': Object.keys(style).length})}>
            <div className='header-mega-menu__scroll'>
              <span className={classNames('header-mega-menu__scroll-icon w-icon', {'header-mega-menu__scroll-icon--show': scroll.upper})}>expand_less</span>
            </div>

            <div className='header-mega-menu__menu'>
              <ul id='header-mega-menu__sections' className='header-mega-menu__sections' style={style} onScroll={e => updateScroll(e)}>
                {menu.map((section, i) => (
                  <li key={i} className='header-mega-menu__section'>
                    <h2 className='header-mega-menu__section-title'>{section.title}</h2>

                    <ul className='header-mega-menu__options'>
                      {section.options.map((option, j) => (
                        <li key={j} className={classNames('header-mega-menu__option', { 'header-mega-menu__option--selected': option.selected })}>
                          <a className='header-mega-menu__option-link' href={option.path} onClick={e => {
                            e.preventDefault()
                            setOpen(false)
                            onClickOption(option.path, option.externalLink)
                          }}
                          >{option.text}</a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>

            <div className='header-mega-menu__scroll'>
              <span className={classNames('header-mega-menu__scroll-icon w-icon', {'header-mega-menu__scroll-icon--show': scroll.lower})}>expand_more</span>
            </div>
          </div>

          <div className='header-mega-menu__background' onClick={() => setOpen(false)}/>
        </>
      ) : null}
    </div>
  )
}

HeaderMegaMenu.propTypes = {
  locations: PropTypes.array.isRequired,
  onClickOption: PropTypes.func.isRequired,
  $state: PropTypes.object
}

export default HeaderMegaMenu

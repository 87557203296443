import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import {
  activePartnerGlobalSelector,
  selectLocationPathname,
  userClaimSelector,
  userPartnersSelector
} from '../../../selectors'

import { dismissErrorAction } from '../../../actions/panic'
import { switchPartnerContext } from '../../../actions/partnerContext'

import HeaderMegaMenu from '../../../components/header/header-mega-menu'
import HeaderProductLogo from '../../../components/header/header-product-logo'
import HeaderPartnerMenu from '../../../components/header/header-partner-menu'

import locations from '../../../constants/locations'
import { HeaderMainMenuContext } from '../../../context'

import { matchPathAndParams } from '../../../lib/path-matcher'

import './style.css'

const HeaderMainMenu = ({
  dispatch,
  claims,
  partner,
  partners,
  route
}) => {
  const context = {
    claims,
    partner,
    route
  }

  return (
    <HeaderMainMenuContext.Provider value={context}>
      <div className='header-main-menu'>
        <div className='header-main-menu__logo'>
          <HeaderProductLogo partner={partner} onClick={path => {
            dispatch(push(path))
          }} />
        </div>

        <div className='header-main-menu__partner'>
          <HeaderPartnerMenu
            partners={partners}
            onChangePartner={({ id: partnerId }) => {
              dispatch(dismissErrorAction())
              dispatch(switchPartnerContext(partnerId))
            }}
          />
        </div>

        <div className='header-main-menu__mega'>
          <HeaderMegaMenu
            locations={locations}
            onClickOption={(path, externalLink) => {
              if (externalLink) {
                window.open(externalLink, '_blank')
              } else {
                dispatch(push(path))
              }
            }}
          />
        </div>
      </div>
    </HeaderMainMenuContext.Provider>
  )
}

HeaderMainMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  claims: PropTypes.object.isRequired,
  partner: PropTypes.string.isRequired,
  partners: PropTypes.array.isRequired,
  route: PropTypes.string.isRequired
}

const mapStateToProps = state => {
  const claims = userClaimSelector(state)

  const partner = activePartnerGlobalSelector(state)
  const partners = userPartnersSelector(state)

  const pathTemplates = locations.map(location => location.route).filter(Boolean)
  const pathname = selectLocationPathname(state)
  const matchingPathDetails = matchPathAndParams(pathTemplates, pathname)
  const route = matchingPathDetails ? matchingPathDetails[0] : ''

  return {
    claims,
    partner,
    partners,
    route
  }
}

export default connect(mapStateToProps)(HeaderMainMenu)

import * as R from 'ramda'

export const podDecisionOrder = (adEngineDecisions, initialVAST) => {
  const hasSequence = Ad => Ad.hasAttribute('sequence')
  const getId = Ad => Ad.getAttribute('id')
  const getSequence = Ad => Number(Ad.getAttribute('sequence'))

  const initialAds = initialVAST.querySelectorAll('Ad')
  const [podAds, otherAds] = R.partition(hasSequence, initialAds)

  const comparator = (Ad1, Ad2) => getSequence(Ad1) - getSequence(Ad2)
  const sortedPodAds = R.sort(comparator, podAds)

  const podAdIds = podAds.map(getId)

  const firstVastIdentifier = R.path(['vastIdentifiers', 0, 'id'])

  const [podDecisions, otherDecisions] = R.partition(
    decision => podAdIds.includes(firstVastIdentifier(decision)),
    adEngineDecisions)

  const sortedPodDecisions = podDecisions.sort((a, b) => {
    const aIndex = sortedPodAds.findIndex(Ad => getId(Ad) === firstVastIdentifier(a))
    const bIndex = sortedPodAds.findIndex(Ad => getId(Ad) === firstVastIdentifier(b))
    return aIndex - bIndex
  })

  const sortedOtherDecisions = otherDecisions.sort((a, b) => {
    const aIndex = otherAds.findIndex(Ad => getId(Ad) === firstVastIdentifier(a))
    const bIndex = otherAds.findIndex(Ad => getId(Ad) === firstVastIdentifier(b))
    return aIndex - bIndex
  })

  return {
    podDecisions: sortedPodDecisions,
    otherDecisions: sortedOtherDecisions
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import * as Wham from '../../wham/components'

import './style.css'

export class PageControls extends React.Component {
  constructor (props) {
    super(props)
    this.handleArrows = this.handleArrows.bind(this)
  }

  static propTypes = {
    nextAction: PropTypes.func,
    prevAction: PropTypes.func,
    showNext: PropTypes.bool,
    showPrev: PropTypes.bool,
    keyBinds: PropTypes.bool
  }

  componentDidMount () {
    if (this.props.keyBinds) {
      window.addEventListener('keydown', this.handleArrows)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleArrows)
  }

  handleArrows ({ key }) {
    if (!['ArrowLeft', 'ArrowRight'].includes(key)) return false
    const {
      nextAction,
      prevAction,
      showNext,
      showPrev
    } = this.props
    if (key === 'ArrowLeft' && showPrev && prevAction) prevAction()
    if (key === 'ArrowRight' && showNext && nextAction) nextAction()
  }

  renderPrevArrow () {
    const onClick = e => {
      e.preventDefault()
      this.props.prevAction && this.props.prevAction()
    }
    return (
      <a className='anchor-control-left' onClick={onClick}>
        <Wham.Icon iconName='chevron_left' />
        Previous
      </a>
    )
  }

  renderNextArrow () {
    const onClick = e => {
      e.preventDefault()
      this.props.nextAction && this.props.nextAction()
    }
    return (
      <a className='anchor-control-right' onClick={onClick}>Next
        <Wham.Icon iconName='chevron_right' />
      </a>
    )
  }

  render () {
    return (
      <div className='transaction-navigation-tab'>
        {this.props.showPrev && this.renderPrevArrow()}
        {this.props.showNext && this.renderNextArrow()}
      </div>
    )
  }
}

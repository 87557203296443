import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CloseButton from '../CloseButton'

import './Tag.scss'
import '../../scss/wham.scss'

const TAG_SIZES = ['large', 'small']

const TAG_TYPES = [
  'default',
  'primary',
  'danger',
  'warning'
]

export default class Tag extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    size: PropTypes.oneOf(TAG_SIZES),
    primary: PropTypes.bool,
    danger: PropTypes.bool,
    type: PropTypes.oneOf(TAG_TYPES),
    component: PropTypes.node,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    inverse: PropTypes.bool,
    removeHandler: PropTypes.func
  }

  closeClickHandler = (e) => {
    e.stopPropagation()
    e.target = e.currentTarget = e.currentTarget.parentNode
    this.props.removeHandler(e)
  }

  render () {
    // classes
    const {
      className,
      type,
      primary,
      danger,
      size,
      label,
      component,
      selectable,
      selected,
      removeHandler,
      inverse,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-tag',
      (danger ? 'w-tag--danger' : null),
      (primary ? 'w-tag--primary' : null),
      ((!primary && !danger && !type) ? 'w-tag--default' : null),
      (type ? 'w-tag--' + type : null),
      (size ? 'w-tag--' + size : null),
      (selectable || selected ? 'w-tag--selectable' : null),
      (selected ? 'w-tag--selected' : null),
      (inverse ? 'w-tag--inverse' : null),
      className
    )

    if (component) {
      return React.cloneElement(component, otherProps)
    }

    return (
      <span className={componentClass} {...otherProps}>
        {label}
        {removeHandler ? <CloseButton className='w-tag__close' onClick={this.closeClickHandler} inverse={(type === 'primary' || type === 'warning' || type === 'danger')} size={size} /> : null}
      </span>
    )
  }
};

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import moment from 'moment-timezone'

import * as Wham from '../../wham/components'

import NoData from '../../components/no-data'
import DebugKeyValueTable from '../../components/debug-key-value-table'

import { TZ } from '../../constants'

import PlaybackButton from '../playback-button'

import kvReducer from '../../lib/kv-reducer'
import { SGMNT_DURATION_SECONDS } from '../../lib/playlist'

import './style.css'

const TsRequest = ({
  ts = {},
  className,
  collapse,
  traceId,
  firstSgmntNumber,
  sgmntToAdNumber,
  playSegment,
  isPlaying,
  playerLoading,
  playerError
}) => {
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    setCollapsed(collapse)
  }, [collapse])

  const trace = ts.traceId || traceId
  const adEngineId = R.path(['response', 'amsId'], ts)
  const tsRequestUrl = R.path(['request', 'url'], ts)
  const tsRequestHeaders = Array.isArray(ts.request.headers) ? ts.request.headers : kvReducer(ts.request.headers)
  const tsRequestCookies = Array.isArray(ts.request.cookies) ? ts.request.cookies : kvReducer(ts.request.cookies)
  const tsRedirectUrl = R.path(['response', 'redirectUrl'], ts)
  const tsRequestTime = ts.$eventTime
  const titleLeft = moment(tsRequestTime).tz(TZ).format('@ h:mm:ss.SSS a z')
  const sgmnt = ts.$sgmnt
  const chunkdur = ts.$chunkdur
  const bitrate = ts.$bitrate
  const sgmntNumber = Number(sgmnt)
  const adNumber = sgmntToAdNumber[sgmntNumber]
  const formattedAdNumber = adNumber
    ? `AD#${adNumber}`
    : <img className='debug-ts-request__title__right__ad-img' src='/assets/shutter-board.png' alt='Slate served' title='Slate served' />
  const formattedSgmnt = `sgmnt: ${sgmnt}`
  const formattedChunkDur = `chunkdur: ${chunkdur}`
  const formattedBitrate = `bitrate: ${bitrate}`
  const sgmntNumberRelativeToFirst = sgmntNumber - firstSgmntNumber + 1
  const seconds = sgmntNumberRelativeToFirst * SGMNT_DURATION_SECONDS
  const formattedTime = `${seconds}`

  const onPlay = e => {
    e.stopPropagation()
    playSegment(ts)
  }

  const title = (
    <span className='debug-ts-request__title'>
      <span>{titleLeft}</span>
      <span className='debug-ts-request__title__right'>
        <span className='debug-ts-request__title__right__ad'>{formattedAdNumber}</span>
        <span className='debug-ts-request__title__right__sgmnt'>{formattedSgmnt}</span>
        <span className='debug-ts-request__title__right__chunkdur'>{formattedChunkDur}</span>
        <span className='debug-ts-request__title__right__bitrate'>{formattedBitrate}</span>
        <span className='debug-ts-request__title__right__time__icon w-icon'>timer</span>
        <span className='debug-ts-request__title__right__time'>{formattedTime}</span>
        <span className='debug-ts-request__title__right__playback'>
          <PlaybackButton
            onPlay={onPlay}
            isPlaying={isPlaying}
            playerLoading={playerLoading}
            playerError={playerError}
            compact
          />
        </span>
      </span>
    </span>
  )

  return (
    <Wham.Container
      className={className}
      title={title}
      collapsed={collapsed}
      onClick={() => setCollapsed(!collapsed)}
    >
      <div style={{ margin: 'auto 5px 20px', display: 'inline-block' }}>
        <Wham.Badge label='Trace ID' />
        <span>{trace || 'No Trace ID Found'}</span>
      </div>

      <div style={{ margin: 'auto 5px 20px', display: 'inline-block' }}>
        <Wham.Badge label='AdE ID' />
        <span>{adEngineId || 'Slate Served'}</span>
      </div>

      <Wham.Tabs title='Request'>
        <Wham.Tab label='URL'>
          <Wham.Copyable>
            <span style={{ wordBreak: 'break-word' }}>
              {tsRequestUrl || <NoData dataType='request URL' />}
            </span>
          </Wham.Copyable>
        </Wham.Tab>

        <Wham.Tab label='Cookies'>
          <DebugKeyValueTable items={tsRequestCookies} dataType='cookies' />
        </Wham.Tab>

        <Wham.Tab label='Headers'>
          <DebugKeyValueTable items={tsRequestHeaders} dataType='headers' />
        </Wham.Tab>
      </Wham.Tabs>

      <Wham.Heading level={5} style={{ marginTop: 50, marginBottom: 5 }}>Redirect URL</Wham.Heading>
      <Wham.Rule style={{ marginTop: 0, marginBottom: 'auto 20' }} />
      <Wham.Copyable>
        <span style={{ wordBreak: 'break-word' }}>
          {tsRedirectUrl || <NoData dataType='redirect URL' />}
        </span>
      </Wham.Copyable>
    </Wham.Container>
  )
}

TsRequest.propTypes = {
  className: PropTypes.string,
  collapse: PropTypes.bool,
  traceId: PropTypes.string,
  ts: PropTypes.object,
  firstSgmntNumber: PropTypes.number,
  sgmntToAdNumber: PropTypes.object,
  playSegment: PropTypes.func,
  isPlaying: PropTypes.bool,
  playerLoading: PropTypes.bool,
  playerError: PropTypes.string
}

export default TsRequest

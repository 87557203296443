import { MACRO_HEADER_DEFAULT } from '../../../../constants'

const parseTemplate = evaluationResult => {
  const match = evaluationResult.match(/^\((.*)\)/)

  if (match) {
    const header = match[0]
    return {
      header,
      url: evaluationResult.substring(header.length)
    }
  }

  return {
    header: MACRO_HEADER_DEFAULT,
    url: evaluationResult
  }
}

export {
  parseTemplate
}

import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const SearchBar = ({
  value,
  pending,
  onChange,
  onClear,
  onSubmit
}) => (
  <div className='search-bar'>
    <form
      className='search-bar__form' onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <div className='search-bar__value'>
        <span className='search-bar__icon w-icon'>search</span>

        <input
          className='search-bar__input'
          data-cy='search-input'
          type='search'
          spellCheck='false'
          placeholder='Search...'
          value={value}
          disabled={pending}
          onChange={e => {
            const value = e.target.value.trim()
            value ? onChange(value) : onClear()
          }}
        />
      </div>

      <input
        className='search-bar__submit'
        type='submit'
        value=''
        disabled={pending}
      />
    </form>
  </div>
)

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SearchBar

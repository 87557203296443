const OPTIONAL = '*'

// https://support.google.com/admanager/answer/1080597
const encodeSpecialCharacters = value =>
  value
    .replace(/[=]/g, '%3D')
    .replace(/[&]/g, '%26')
    .replace(/[,]/g, '%2C')

export default (base, tuples) => {
  if (tuples.length) {
    return tuples.reduce((acc, { key, value = '', delimiter = '', flags = {} }) => {
      if (key || value) {
        const encodedValue = encodeSpecialCharacters(value || '')

        if (flags.optional && flags.alternative) {
          return `${acc.substring(0, acc.length - 1)}${OPTIONAL}${acc.substring(acc.length - 1)}${key}=${encodedValue}${OPTIONAL}${delimiter}`
        }

        if (flags.optional) {
          return `${acc}${OPTIONAL}${key}=${encodedValue}${OPTIONAL}${delimiter}`
        }

        return `${acc}${key}=${encodedValue}${delimiter}`
      }

      return `${acc}${delimiter}`
    }, base.replace(/([^?])$/, '$1?'))
  }

  return base
}

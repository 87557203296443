import * as R from 'ramda'

import prettyRate from './prettyRate'
import { camelCaseToSpaced } from './util/strings'

export const createMessage = (validation) => {
  if (R.path(['pending'], validation)) {
    return 'Validating...'
  }

  if (validation.passed) {
    return 'Matches'
  }

  if (validation.error) {
    return 'Failed to upload'
  }

  if (!R.path(['asset', 'GeneralFormat'], validation)) {
    return 'Failed to parse'
  }

  return 'No matching profile'
}

export const createDescription = (validation) => {
  if (validation && validation.spec && validation.spec.length) {
    const closest = validation.spec[0]

    const tokens = []

    Object.entries(closest).map(([name, rules]) => {
      if (Array.isArray(rules)) {
        tokens.push(R.reduce((acc, { field, value }) => field === 'FileExtension' ? value : acc, '', rules))
        tokens.push(R.reduce((acc, { field, value }) => field === 'Format' ? value : acc, '', rules))
      }
    })

    return R.join('; ', R.filter(R.compose(R.not, R.isEmpty), tokens))
  }

  return ''
}

const createSpecName = spec => {
  return spec.reduce((acc, { field, value }) => {
    switch (field) {
      case 'File Extension':
      case 'Format':
        return acc ? `${acc}; ${value}` : value
      default:
        return acc
    }
  }, '')
}

const renderValueForType = (predicate, value) => {
  const withUnits = value.type ? `${value.quantity} ${value.unit}` : value

  const predicateTransforms = {
    eq: val => (`= ${val}`),
    gte: val => (`>= ${val}`),
    lte: val => (`<= ${val}`),
    oneOf: val => (`One of: ${val.join(', ')}`),
    dividesWithinTolerance: val =>
      (`${val.denominator}sec +${val.tolerateOverrun}/-${val.tolerateUnderrun}`)
  }
  return `expected ${predicateTransforms[predicate](withUnits)}`
}

const transformFns = {
  'Bit Rate': prettyRate
}

const transformValueForField = (field, value) => {
  return transformFns[field]
    ? transformFns[field](value)
    : value
}

const parseSpecs = ({ spec: specs, asset }) => {
  const specsWithAssetData = specs ? specs.map(spec => {
    const {failed, ...categories} = spec

    const transformedSpec = Object.entries(categories).map(([name, rules]) => {
      return rules.map(({field, ...otherData}) => {
        const id = `${name}${field}`

        return {
          id,
          field: camelCaseToSpaced(field),
          ...otherData,
          assetValue: asset[id]
        }
      })
    })
    return [].concat(...transformedSpec) // flatten 1 deep
  }) : []

  const specsWithMetaData = specsWithAssetData.map(specWithAssetData => ({
    name: createSpecName(specWithAssetData),
    specs: specWithAssetData.map(({id, field, predicate, passed, value, assetValue }) => ({
      id,
      name: field,
      value: transformValueForField(field, assetValue),
      error: !passed && renderValueForType(predicate, value),
      passed
    })),
    failures: specWithAssetData.reduce((acc, rule) => (rule.passed ? acc : acc + 1), 0)
  }))

  const closestProfile = R.head(specsWithMetaData)
  const additionalProfiles = R.tail(specsWithMetaData)

  return {
    closestProfile,
    additionalProfiles
  }
}

export default validation => {
  const { passed } = validation

  const name = createDescription(validation)

  const error = !(passed || R.path(['asset', 'GeneralFormat'], validation))

  const { closestProfile, additionalProfiles } = parseSpecs(validation)

  return {
    passed,
    name,
    error,
    closestProfile,
    additionalProfiles
  }
}

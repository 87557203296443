import * as R from 'ramda'

export * from './asset-validator'
export * from './auth'
export * from './creatives'
export * from './debugging'
export * from './forms'
export * from './router'
export * from './rules'
export * from './simulator'
export * from './swid'
export * from './whitelist'

export const activePartnerDetailsSelector = partnerId =>
  R.compose(
    R.find(({id}) => id === partnerId),
    R.pathOr([], ['user', 'partners'])
  )

import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { gql, useMutation } from '@apollo/client'

import * as Wham from '../../wham/components'

import * as selectors from '../../selectors'

import { setWhitelistFormField } from '../../actions/form'
import {
  clearWhitelistLookup,
  fetchWhitelistUserByEmail
} from '../../actions/whitelist'

import { WhitelistContext, ViewContext } from '../../context'

import WhitelistAddLookup from '../../components/whitelist/whitelist-add-lookup'

import './style.css'

export const GQL_WHITELIST_CREATE = gql`
  mutation AllowlistCreate($input: AllowlistCreateInput!) {
    allowlistCreate(input: $input) {
      allowlistEntry {
        id
      }
      error {
        code
      }
    }
  }
`

const valid = string => !!(string && string.trim())

const WhitelistAdd = ({
  item,
  items,
  lookup,
  onAdd,
  dispatch
}) => {
  const { swid } = useContext(ViewContext)
  const { options, pending } = useContext(WhitelistContext)

  const timeout = useRef()

  const [email, setEmail] = useState('')
  const [failed, setFailed] = useState(null)
  const [duplicate, setDuplicate] = useState(false)

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    const found = items.find(({ idType, value }) => {
      return item.idType === idType && item.value === value
    })

    setDuplicate(!!found)
  }, [item])

  const [allowlistCreate, { loading }] = useMutation(GQL_WHITELIST_CREATE, {
    onError: error => {
      setFailed(error)

      timeout.current = setTimeout(() => {
        setFailed(null)
      }, 3000)
    },
    onCompleted: data => {
      if (data.allowlistCreate.error) {
        setFailed(data.allowlistCreate.error)

        timeout.current = setTimeout(() => {
          setFailed(null)
        }, 3000)
      } else {
        dispatch(setWhitelistFormField('label', ''))
        dispatch(setWhitelistFormField('value', ''))
        onAdd()
      }
    }
  })

  const onChange = (e, key) => {
    e.preventDefault()

    if (key === 'type') {
      dispatch(setWhitelistFormField('label', ''))
      dispatch(setWhitelistFormField('value', ''))
    }

    dispatch(clearWhitelistLookup())

    dispatch(setWhitelistFormField(key, e.target.value))
  }

  const onSubmit = () => {
    setEmail('')

    allowlistCreate({
      variables: {
        input: {
          idType: item.idType.toUpperCase(),
          value: item.value,
          label: item.label
        }
      }
    })
  }

  const onIgnore = () => {
    dispatch(clearWhitelistLookup())
  }

  const onLookup = item => {
    const query = item.label

    dispatch(clearWhitelistLookup())

    if (query) {
      dispatch(fetchWhitelistUserByEmail({ query }))
    }
  }

  const onSelect = (e, option) => {
    setEmail(option.value)

    dispatch(setWhitelistFormField('label', option.value))
    dispatch(setWhitelistFormField('value', option.id))
    dispatch(clearWhitelistLookup())
  }

  const type = options.find(option => option.value === item.idType).label

  return (
    <form className='whitelist-add' onSubmit={e => e.preventDefault()}>
      <h2 className='whitelist-add__header'>Add to Allow List</h2>

      <div className='whitelist-add__upper'>
        <div className='whitelist-add__field whitelist-add__type'>
          <Wham.Select
            className='whitelist-add__input'
            id='whitelist-add__type'
            disabled={pending || loading}
            options={options.filter(option => option.value !== '*')}
            value={item.idType}
            onChange={e => onChange(e, 'type')}
          />
        </div>

        {swid.enabled && item.idType === 'swid' ? (
          <div className='whitelist-add__field whitelist-add__lookup'>
            <Wham.FormInput
              className='whitelist-add__input'
              id='whitelist-add__lookup'
              autoFocus
              spellCheck='false'
              disabled={pending || loading}
              value={email}
              onChange={e => setEmail(e.target.value.trim())}
              onKeyDown={e => {
                if (e.key === 'Enter' && email) {
                  onLookup({ label: email })
                }
                if (e.key === 'Escape') {
                  onIgnore()
                }
              }}
              placeholder='Search by email...'
            />

            <WhitelistAddLookup
              item={{ label: email }}
              looking={lookup.looking}
              options={lookup.options}
              onIgnore={onIgnore}
              onLookup={onLookup}
              onSelect={onSelect}
            />
          </div>
        ) : null}
      </div>

      <div className='whitelist-add__lower'>
        <div className='whitelist-add__field whitelist-add__value'>
          <div className='whitelist-add__field-group'>
            <label className='whitelist-add__field-label' htmlFor='whitelist-add__value'>{type}:</label>
            <Wham.FormInput
              className={classNames('whitelist-add__input', { 'whitelist-add__input--duplicate': duplicate })}
              id='whitelist-add__value'
              spellCheck='false'
              disabled={pending || loading}
              value={item.value}
              onChange={e => onChange(e, 'value')}
              onFocus={() => onIgnore()}
              placeholder={`Enter a ${type} value...`}
            />
          </div>
        </div>

        <div className='whitelist-add__field whitelist-add__label'>
          <div className='whitelist-add__field-group'>
            <label className='whitelist-add__field-label' htmlFor='whitelist-add__label'>Label:</label>
            <Wham.FormInput
              className='whitelist-add__input'
              id='whitelist-add__label'
              spellCheck='false'
              disabled={pending || loading}
              value={item.label}
              onChange={e => onChange(e, 'label')}
              onFocus={() => onIgnore()}
              placeholder={`Give your ${type} a name...`}
            />
          </div>
        </div>

        <div className='whitelist-add__field whitelist-add__actions'>
          <Wham.Button
            className={classNames('whitelist__button whitelist-add__save', { 'whitelist-add__save--error': failed })}
            id='whitelist__button-save'
            submit
            type='primary'
            disabled={pending || loading || failed || duplicate || !valid(item.label) || !valid(item.value)}
            onClick={() => onSubmit()}
          >
            {loading ? (
              <Wham.ProgressCircular
                active
                inverse
                type='indeterminate'
                sqSize={17}
              />
            ) : failed ? (
              <span className='whitelist-add__save--error-icon'>Error</span>
            ) : (
              <span>Save</span>
            )}
          </Wham.Button>
        </div>
      </div>
    </form>
  )
}

WhitelistAdd.propTypes = {
  item: PropTypes.shape({
    idType: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  items: PropTypes.array.isRequired,
  lookup: PropTypes.shape({
    looking: PropTypes.bool.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
      }).isRequired
    ).isRequired
  }).isRequired,
  onAdd: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const partner = selectors.activePartnerGlobalSelector(state)

  const idType = selectors.whitelistTypeSelector(partner)(state)
  const label = selectors.whitelistLabelSelector(partner)(state)
  const value = selectors.whitelistValueSelector(partner)(state)

  const looking = selectors.whitelistEmailLoading(partner)(state)
  const lookups = selectors.whitelistMatchedUsersSelector(partner)(state)

  return {
    ...ownProps,
    item: {
      idType,
      label,
      value
    },
    lookup: {
      looking,
      options: lookups.map(({ uid: id, email: value }) => ({ id, value }))
    }
  }
}

export default connect(mapStateToProps)(WhitelistAdd)

import * as SAGA from 'redux-saga/effects'
import * as R from 'ramda'

export const debuggingNewLogsSelector = partner => R.path([
  'partner', partner, 'debugging', 'newLogs'])

export const debuggingLogEntriesGlobalSelector = partner => R.pathOr([], [
  'partner', partner, 'debugging', 'logEntries'])
export const selectDebugLogEntries = R.compose(SAGA.select, debuggingLogEntriesGlobalSelector)

export const debuggingLogEntriesTotalGlobalSelector = partner => R.path([
  'partner', partner, 'debugging', 'logEntriesTotal'])

export const debuggingFinalSelector = partner => R.path([
  'partner', partner, 'debugging', 'final'])

export const debuggingErrorSelector = partner => R.path([
  'partner', partner, 'debugging', 'error'])

export const debuggingGroupBySelector = partner => R.path([
  'partner', partner, 'debugging', 'groupBy'])
export const selectDebugGroupBy = R.compose(SAGA.select, debuggingGroupBySelector)

export const debuggingLogFacetsSelector = partner => R.path([
  'partner', partner, 'debugging', 'logFacets'])
export const selectDebugLogFacets = R.compose(SAGA.select, debuggingLogFacetsSelector)

export const debuggingOpenFiltersSelector = partner => R.path([
  'partner', partner, 'debugging', 'openFilters'])

export const debuggingUpdatedSelector = partner => R.path([
  'partner', partner, 'debugging', 'updated'])

export const debuggingMediaRequestsPollingEnabledSelector = partner => R.path([
  'partner', partner, 'debugging', 'mediaRequestsPollingEnabled'])

export const debuggingPendingSelector = partner => R.path([
  'partner', partner, 'debugging', 'pending'])

export const debuggingTransactionPendingSelector = partner => state => R.path([
  'partner', partner, 'debugging', 'transactionPending'], state)

export const debuggingSummaryPendingSelector = partner => state => R.path([
  'partner', partner, 'debugging', 'summaryPending'], state)

export const debuggingFlagChangePendingSelector = partner => R.path([
  'partner', partner, 'debugging', 'flagChangePending'])

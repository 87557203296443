import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Icon.scss'
import '../../scss/wham.scss'

class Icon extends React.Component {
  static propTypes = {
    iconName: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string
  }

  render () {
    const {
      iconName,
      className,
      color,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-icon',
      this.props.className
    )

    var style = color ? { color: color } : null

    // render popover element
    return (
      <span {...otherProps} className={componentClass} style={style}>{this.props.iconName}</span>
    )
  }
}

export default Icon

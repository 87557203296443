import HRNumbers from 'human-readable-numbers'

const human = number => {
  const value = Number(number)

  if (isNaN(value)) {
    return ''
  }

  return HRNumbers.toHumanString(value)
}

export {
  human
}

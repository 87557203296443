import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { expandPathTemplate } from '../../lib/path-matcher'
import { paths } from '../../route-paths'
import * as selectors from '../../selectors'

const HomeView = ({
  partners
}) => {
  if (partners.length) {
    const partnerId = partners[0].id
    const pathTemplate = paths.dashboard
    const path = expandPathTemplate(pathTemplate, { partnerId })

    return (
      <Redirect to={{ pathname: path }} />
    )
  }

  return (
    <p>Sorry - it looks like you don&apos;t have any configured partners!</p>
  )
}

HomeView.propTypes = {
  partners: PropTypes.array
}

const mapStateToProps = state => {
  const features = selectors.userFeaturesSelector(state)
  const partners = selectors.userPartnersSelector(state)

  return {
    features,
    partners
  }
}

export default connect(mapStateToProps)(HomeView)

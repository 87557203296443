import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CloseButton from '../CloseButton'

import './Message.scss'
import '../../scss/wham.scss'

const MESSAGE_TYPE = [
  'default',
  'primary',
  'danger',
  'warning',
  'success'
]

const MESSAGE_MODE = [
  'default',
  'floating'
]

class Message extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    active: PropTypes.bool,
    type: PropTypes.oneOf(MESSAGE_TYPE),
    mode: PropTypes.oneOf(MESSAGE_MODE),
    dismissCallback: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      active: props.active
    }
  }

  dismissMessage = () => {
    if (this.state.active && this.props.dismissCallback) {
      this.props.dismissCallback()
    }

    this.setState({
      ...this.state,
      active: false
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState(
      {
        ...this.state,
        active: nextProps.active

      }
    )
  }

  render () {
    var componentClass = classNames(
      'w-message',
      (this.props.type ? 'w-message--' + this.props.type : null),
      (this.props.mode ? 'w-message--' + this.props.mode : null),
      (this.state.active ? 'w-message--floating--active' : null),
      this.props.className
    )

    return (
      <div className={componentClass}>
        <div className='w-message__content'>{this.props.children}</div>
        <CloseButton inverse onClick={this.dismissMessage} />
      </div>
    )
  }
}

export default Message

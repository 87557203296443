import axios from 'axios'
import * as R from 'ramda'
import { parseUrl } from 'query-string'
import { config } from '../../services/config'

export const TS_REQUEST_DEFAULT_PARAMS = {
  brkdur: '60',
  podId: '1'
}

const simulatorConfig = config.simulatorConfig || {}

const cleanParams = R.pipe(
  R.map(v => v.toString().trim()),
  R.reject(R.isEmpty)
)

export const getDefaultSetTokenUrl = partnerId => {
  const partnerConfig = simulatorConfig[partnerId] || {}
  return partnerConfig.setTokenUrl
}

export const getSetTokenParams = partnerId => {
  const partnerConfig = simulatorConfig[partnerId] || {}
  return partnerConfig.setTokenParams || []
}

export const makeTsRequestUrl = (partnerId, params = {}) => {
  const partnerConfig = simulatorConfig[partnerId] || {}
  const url = partnerConfig.tsRequestUrl || ''
  const defaultPartnerSpecificParams = R.pick(['assetId'], partnerConfig)
  const replacements = {
    ...TS_REQUEST_DEFAULT_PARAMS,
    ...defaultPartnerSpecificParams,
    ...cleanParams(params)
  }
  return Object.entries(replacements).reduce((acc, [key, value]) => {
    const regex = new RegExp(`{${key}}`)
    return acc.replace(regex, value)
  }, url)
}

export const isSimulatorConfigured = partnerId => {
  const setTokenUrl = getDefaultSetTokenUrl(partnerId)
  const tsRequestUrl = makeTsRequestUrl(partnerId)
  return setTokenUrl && tsRequestUrl
}

export const simulateTransaction = async (partnerId, setTokenUrl, setTokenMethod, tsRequestParams, userAgent) => {
  const tsRequestUrl = makeTsRequestUrl(partnerId, tsRequestParams)
  const url = `/api/partner/${partnerId}/simulator/simulateTransaction`
  const config = {
    params: {
      setTokenUrl,
      setTokenMethod,
      tsRequestUrl,
      userAgent
    }
  }
  const response = await axios.get(url, config)
  return response.data
}

const extractWhitelistIds = setTokenUrl => {
  if (!setTokenUrl) return {}
  const { query } = parseUrl(setTokenUrl)
  return R.pick(['devid', 'ssess', 'uid', 'swid'], query)
}

export const isWhitelistable = (whitelist, setTokenUrl) => {
  if (R.isEmpty(whitelist)) return false
  const ids = extractWhitelistIds(setTokenUrl)
  return Object.entries(ids).some(([idType, value]) =>
    whitelist.find(entry =>
      entry.idType === idType && value && entry.value.toLowerCase() === value.toLowerCase()))
}

export const emptyOrIntWithConditions = (s, ...conditions) => {
  if (!s.trim()) return true
  const n = Number(s)
  return R.allPass([Number.isInteger, ...conditions])(n)
}

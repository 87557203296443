import React from 'react'
import ContentLoader from 'react-content-loader'

// http://danilowoz.com/create-content-loader/

export const ParamLoader = props => (
  <ContentLoader
    height='240'
    width='620'
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='6' y='8' rx='0' ry='0' width='612' height='64' />
    <rect x='6' y='80' rx='0' ry='0' width='612' height='64' />
    <rect x='6' y='152' rx='0' ry='0' width='612' height='64' />
  </ContentLoader>
)

export const PriorityLoader = props => (
  <ContentLoader
    height='270'
    width='620'
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='6' y='0' rx='0' ry='0' width='612' height='260' />
  </ContentLoader>
)

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import WhatsNew from '../../whats-new'

const HeaderReleaseNotes = ({
  notes,
  onClick,
  onAcknowledge
}) => {
  const [active, setActive] = useState(false)

  return (
    <WhatsNew
      notes={notes}
      active={active}
      onClick={() => {
        setActive(!active)
        onClick(!active)
      }}
      dismissHandler={() => {
        setActive(false)
        onAcknowledge()
      }}
    />
  )
}

HeaderReleaseNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onAcknowledge: PropTypes.func.isRequired
}

export default HeaderReleaseNotes

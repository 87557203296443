import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Moment from 'react-moment'
import 'moment-timezone'

import * as Wham from '../../../wham/components'

import { TZ } from '../../../constants'

const SimulatedTransaction = ({
  simulatedTransaction,
  isSelected,
  onRemove,
  onSelect
}) => {
  const className = classNames({
    simulator__transaction: true,
    'simulator__transaction--selected': isSelected
  })
  const { transactionId, brkdur, timestamp, viewed } = simulatedTransaction
  return (
    <tr
      className={className}
      key={transactionId}
      onClick={() => onSelect(transactionId)}
    >
      <td>
        <Moment format='M/D/YYYY @ h:mm a' tz={TZ}>{timestamp}</Moment>
      </td>
      <td>
        <div className='simulator__transaction__brkdur' data-cy='transaction-brkdur'>
          <span>{brkdur}s</span>
          <span className='w-icon'>timer</span>
        </div>
      </td>
      <td>
        {
          viewed && (
            <Wham.Button
              className='simulator__transaction__remove'
              onClick={e => {
                e.stopPropagation()
                onRemove(transactionId)
              }}
            >
              <Wham.Icon iconName='close' />
            </Wham.Button>
          )
        }
      </td>
    </tr>
  )
}

SimulatedTransaction.propTypes = {
  simulatedTransaction: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func
}

export default SimulatedTransaction

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import select from 'select'

import './Copyable.scss'
import '../../scss/wham.scss'

class Copyable extends React.Component {
  static propTypes = {
    children: PropTypes.any
  }

  constructor (props) {
    super(props)
    this.state = {
      active: false
    }
  }

  clickHandler = e => {
    e.preventDefault()
    select(this.copyableRef)
    copy(window.getSelection().toString())

    this.setState({
      ...this.state,
      active: true
    })

    var ths = this
    setTimeout(function () {
      ths.setState({
        ...ths.state,
        active: false
      })
    }, 750)
  }

  render () {
    const {
      children
    } = this.props

    // classes
    var componentClass = classNames(
      'w-copyable',
      (this.state.active ? 'w-copyable--active' : null)
    )

    return (
      <span ref={el => { this.copyableRef = el }} className={componentClass} onMouseDown={this.clickHandler}>
        {children}
      </span>
    )
  }
};

export default Copyable

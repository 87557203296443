// https://www.apollographql.com/docs/react/api/react-hoc/#graphql-options-for-queries

export const loading = 1
export const setVariables = 2
export const fetchMore = 3
export const refetch = 4
export const unused = 5
export const poll = 6
export const ready = 7
export const error = 8

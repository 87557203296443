import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'

import {
  SortableContainer,
  SortableHandle,
  SortableElement
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

import * as R from 'ramda'
import * as Wham from '../../../wham/components'

import MiddleTruncate from '../../../components/middle-truncate'

import { PriorityLoader } from './content-loaders'

const NEW_RULE_LABEL = 'New Rule'
const DragHandle = SortableHandle(() =>
  <span className='sortable-handle' />
)

class DraggableRule extends Component {
  render () {
    const {
      value,
      isActive,
      idx
    } = this.props

    const priority = idx + 1

    return (isActive ? (
      <div
        className='sortable-item active'
      >
        <DragHandle />
        <span className='sortable-item--label'>
          #{priority} <MiddleTruncate maxLength={47}>{value}</MiddleTruncate>
        </span>
        <span className='sortable-item--dragging'>Move here</span>
      </div>
    ) : (
        <div className='sortable-item'>
          #{priority} <MiddleTruncate maxLength={47}>{value}</MiddleTruncate>
        </div>
      ))
  }
}

DraggableRule.propTypes = {
  value: PropTypes.string,
  isActive: PropTypes.bool,
  idx: PropTypes.number
}

const SortableRule = SortableElement(DraggableRule)

class PriorityContainer extends Component {
  constructor (props) {
    super(props)
    this.priorityRef = {}
  }

  componentDidUpdate () {
    const activeRuleElem = document.querySelector('.sortable-item.active')

    if (activeRuleElem && R.not(this.props.hasMoved)) {
      const topPosition = activeRuleElem.offsetTop
      const scrollContainerTop = activeRuleElem.parentNode.offsetTop
      this.priorityRef.scrollTop = topPosition - scrollContainerTop
    }
  }

  render () {
    const { rules, currentRule } = this.props
    return (
      <div
        ref={wrapper => { this.priorityRef = wrapper }}
        className='priority-sorter'
      >
        {rules.map(({ name, id }, index) => {
          const isActive = id === currentRule.id
          const value = name || NEW_RULE_LABEL
          const key = `item-${index}`
          return (
            <SortableRule
              isActive={isActive}
              key={key}
              index={index}
              idx={index}
              value={value}
            />
          )
        })}
      </div>
    )
  }
}

PriorityContainer.propTypes = {
  hasMoved: PropTypes.bool,
  rules: PropTypes.array,
  currentRule: PropTypes.object
}

const SortableRules = SortableContainer(PriorityContainer)

const DnD = ({ rules, rule: currentRule, setRuleOrder, pending }) => {
  const [hasMoved, setHasMoved] = useState(false)

  const moveRuleTo = newIndex => {
    const oldIndex = rules.findIndex(rule => rule.id === currentRule.id)
    if (oldIndex >= 0) {
      const reorderedRules = arrayMove(rules, oldIndex, newIndex)
      setRuleOrder(reorderedRules)
    }
  }

  const onSortStart = (_item, e) => {
    e.preventDefault()
    setHasMoved(true)
  }

  const onSortEnd = ({ newIndex }) => {
    moveRuleTo(newIndex)
  }

  return (
    <div className='priority-sorter-wrapper'>
      <Wham.Heading level={5}>Priority</Wham.Heading>
      {pending ? <PriorityLoader /> : (
        <div>
          <div className='auto-move'>
            <span data-cy='move-to-top' onClick={() => moveRuleTo(0)}>Move to top</span>
            <span data-cy='move-to-bottom' onClick={() => moveRuleTo(-1)}>Move to bottom</span>
          </div>
          <div className='priority-helper-wrapper'>
            <span className='higher-helper'>Higher priority</span>
          </div>
          <SortableRules
            useDragHandle
            hasMoved={hasMoved}
            transitionDuration={100}
            helperClass='dragging-helper-class'
            lockToContainerEdges
            lockAxis='y'
            currentRule={currentRule}
            rules={rules}
            onSortStart={onSortStart}
            onSortEnd={onSortEnd}
          />
          <div className='priority-helper-wrapper'>
            <span className='lower-helper'>Lower priority</span>
          </div>
        </div>
      )}
    </div>
  )
}

DnD.propTypes = {
  pending: PropTypes.bool,
  setRuleOrder: PropTypes.func,
  rule: PropTypes.object,
  rules: PropTypes.array
}

export default DnD

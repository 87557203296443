import React, { useContext, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import classNames from 'classnames'

import { gql, useMutation } from '@apollo/client'

import * as Wham from '../../../wham/components'

import { icons, types } from '../../../constants/whitelist'
import { WhitelistContext } from '../../../context'

import { useCopied } from '../../../lib/custom-hooks'

import './style.css'

export const GQL_WHITELIST_DELETE = gql`
  mutation AllowlistDelete($input: AllowlistDeleteInput!) {
    allowlistDelete(input: $input) {
      error {
        code
      }
    }
  }
`

const WhitelistTable = ({
  items,
  total,
  onDelete
}) => {
  const { pending } = useContext(WhitelistContext)

  const timeoutLoader = useRef()
  const timeoutFailed = useRef()

  const [tooltip, setTooltip] = useState(null)
  const [deleting, setDeleting] = useState(null)
  const [failed, setFailed] = useState(null)
  const [loader, setLoader] = useState(null)
  const [onCopy, isCopied] = useCopied()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutLoader.current)
      clearTimeout(timeoutFailed.current)
    }
  }, [])

  useEffect(() => {
    clearTimeout(timeoutLoader.current)

    if (pending) {
      timeoutLoader.current = setTimeout(() => {
        setLoader(true)
      }, 500)
    }
  }, [pending])

  const [allowlistDelete, { loading }] = useMutation(GQL_WHITELIST_DELETE, {
    onError: () => {
      setFailed(deleting)
      setDeleting(null)

      timeoutFailed.current = setTimeout(() => {
        setFailed(null)
      }, 3000)
    },
    onCompleted: data => {
      if (data.allowlistDelete.error) {
        setFailed(deleting)

        timeoutFailed.current = setTimeout(() => {
          setFailed(null)
        }, 3000)
      } else {
        onDelete()
      }

      setDeleting(null)
    }
  })

  return (
    <div className={classNames('whitelist-table__wrapper', { 'whitelist-table__wrapper--pending': pending })} >
      <Wham.Table className='whitelist-table'>
        <thead>
          <tr>
            <th className='whitelist-table__head whitelist-table__head-type'>Type</th>
            <th className='whitelist-table__head whitelist-table__head-label'>Label</th>
            <th className='whitelist-table__head whitelist-table__head-value'>Allow List ID</th>
            <th className='whitelist-table__head whitelist-table__head-actions' />
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.id} className='whitelist-table__row' data-cy='whitelist-row'>
              <td
                className='whitelist-table__cell whitelist-table__icon'
                onMouseOver={e => setTooltip(e.target)}
                onMouseOut={() => setTooltip(null)}
                data-tooltip={types[item.idType]}
              >
                <Wham.Icon iconName={icons[item.idType]} />
              </td>
              <td className='whitelist-table__cell whitelist-table__label whitelist__ellipsis'>
                <CopyToClipboard text={item.label} onCopy={() => onCopy(item.id, item.label)}>
                  <span className={`whitelist-table__cell-copyable ${isCopied(item.id, item.label) ? 'whitelist-table__cell-copyable--copied' : ''}`} data-cy='whitelist-row-label'>{item.label}</span>
                </CopyToClipboard>
              </td>
              <td className='whitelist-table__cell whitelist-table__value whitelist__ellipsis'>
                <CopyToClipboard text={item.value} onCopy={() => onCopy(item.id, item.value)}>
                  <span className={`whitelist-table__cell-copyable ${isCopied(item.id, item.value) ? 'whitelist-table__cell-copyable--copied' : ''}`} data-cy='whitelist-row-value'>{item.value}</span>
                </CopyToClipboard>
              </td>
              <td className='whitelist-table__cell whitelist-table__actions'>
                <Wham.Button
                  className={classNames('whitelist__button whitelist__button-delete', { 'whitelist__button--spinner': loading && deleting === item.id })}
                  data-cy='whitelist-delete'
                  disabled={pending || loading || failed === item.id}
                  onClick={() => {
                    setDeleting(item.id)
                    allowlistDelete({ variables: { input: { id: item.id } } })
                  }}
                >
                  {loading && deleting === item.id ? (
                    <Wham.ProgressCircular
                      active
                      type='indeterminate'
                      sqSize={17}
                    />
                  ) : failed === item.id ? (
                    <Wham.Icon className='whitelist__button-icon whitelist__button-icon--error' iconName='error' />
                  ) : (
                    <Wham.Icon className='whitelist__button-icon' iconName='delete' />
                  )}
                </Wham.Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Wham.Table>

      <div className='whitelist-table__count'>
        <div>
          <span className='whitelist-table__count-label'>Showing:</span>
          <span className='whitelist-table__count-value'>{items.length} of {total}</span>
        </div>
      </div>

      {pending && loader ? (
        <div className='whitelist-table__pending'>
          <Wham.ProgressCircular type='indeterminate' active={true} sqSize={24} />
        </div>
      ) : null}

      {tooltip ? (
        <Wham.Tooltip trigger={tooltip} placement='bottom-right' offset={{ top: 190, left: -15 }} />
      ) : null}
    </div>
  )
}

WhitelistTable.propTypes = {
  items: PropTypes.array.isRequired,
  total: PropTypes.number,
  onDelete: PropTypes.func.isRequired
}

export default WhitelistTable

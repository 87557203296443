import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.css'

const options = [
  'Ready',
  'Blocked',
  // ADENGWEB-1391
  // 'Pending',
  'Retranscode'
]

const CreativesFilterByStatus = ({
  selected,
  pending,
  onClick
}) => {
  return (
    <div className={classNames('creatives-filter-by-status', { 'creatives-filter-by-status--pending': pending })} data-cy='creative-filters'>
      <div className='creatives-filter-by-status__header'>
        <div className='creatives-filter-by-status__title-wrapper'>
          <h2 className='creatives-filter-by-status__title'>Status</h2>
        </div>
      </div>

      <div className='creatives-filter-by-status__content'>
        {options.map((option, i) => (
          <div className='creatives-filter-by-status__option' key={i}>
            <div className='creatives-filter-by-status__option-wrapper'>
              <input
                className='creatives-filter-by-status__option-input'
                type='checkbox'
                id={`creatives-filter-by-status__option-group--${i}`}
                checked={option === selected}
                disabled={pending}
                onClick={() => onClick(option)}
              />
              <label
                className={'creatives-filter-by-status__option-label'}
                htmlFor={`creatives-filter-by-status__option-group--${i}`}
              >{option}</label>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

CreativesFilterByStatus.propTypes = {
  selected: PropTypes.string,
  pending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default CreativesFilterByStatus

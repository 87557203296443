import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const FilterBar = ({
  value,
  onChange
}) => (
  <div className='filter-bar'>
    <form className='filter-bar__form' onSubmit={e => e.preventDefault()}>
      <div className='filter-bar__value'>
        <span className='filter-bar__icon w-icon'>search</span>

        <input
          className='filter-bar__input'
          type='search'
          spellCheck='false'
          placeholder='Filter...'
          value={value}
          onChange={onChange}
        />
      </div>

      <input className='filter-bar__submit' type='submit' value='' />
    </form>
  </div>
)

FilterBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default FilterBar

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'

import {
  roundUpToNearestTimeInterval,
  toAdjustedEpoch,
  toAdjustedDate,
  withoutMilliseconds
} from './util'

import 'react-datepicker/dist/react-datepicker.css'
import './react-datepicker.css'
import './style.css'

const dateFormat = 'MM/dd/yy HH:mm'
const timeFormat = 'HH:mm'

const isValid = (startDate, endDate) => {
  return moment(withoutMilliseconds(startDate)).isBefore(moment(withoutMilliseconds(endDate)), 'minutes')
}

const DateRangePicker = ({
  now,
  from,
  to,
  timeInterval = 15, // minutes
  tzOverride,
  limits = {},
  pending,
  onChange = () => { },
  onClose,
  onCustom,
  $state = {}
}) => {
  // Used to manually adjust dates (if necessary) as react-datepicker no longer supports timezones...
  const tz = tzOverride || moment.tz.guess()

  const calcMinTime = (startDate, endDate) => {
    if (moment(startDate).isSame(endDate, 'day')) {
      return roundUpToNearestTimeInterval(startDate, timeInterval)
    }
  }

  const [startDate, setStartDate] = useState(() => toAdjustedDate(from, tz))
  const [endDate, setEndDate] = useState(() => toAdjustedDate(to, tz))

  const [minTime, setMinTime] = useState(() => calcMinTime(startDate, endDate))
  const [valid, setValid] = useState(() => isValid(startDate, endDate))
  const [next, setNext] = useState(typeof $state.next !== 'undefined' ? $state.next : true)

  useEffect(() => {
    setMinTime(calcMinTime(startDate, endDate))
  }, [startDate, endDate])

  useEffect(() => {
    setValid(isValid(startDate, endDate))
  }, [startDate, endDate])

  useEffect(() => {
    document.getElementById(`date-range-picker__input--${next ? 'start' : 'end'}`).focus()
  }, [next])

  const minDate = limits.minDate ? toAdjustedDate(limits.minDate, tz) : null
  const maxDate = moment(toAdjustedDate(now, tz)).endOf('day').toDate()

  const warnDates = limits.warnDates ? limits.warnDates.map(warnDate => toAdjustedDate(warnDate, tz)) : []
  const highlightDates = [{ 'date-range-picker__input--warn': warnDates }]

  return (
    <div className='date-range-picker'>
      <div className='date-range-picker__background' onClick={onClose} />

      <div className='date-range-picker__content'>
        <div className='date-range-picker__fields'>
          <div className='date-range-picker__field-wrapper'>
            <div className='date-range-picker__field'>
              <label className='date-range-picker__label' htmlFor='date-range-picker__input--start'>From:</label>
              <DatePicker
                className={classNames('date-range-picker__input', { 'date-range-picker__input--invalid': !valid })}
                id='date-range-picker__input--start'
                autoFocus
                selected={startDate}
                minDate={minDate}
                maxDate={maxDate}
                highlightDates={highlightDates}
                showTimeSelect
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                timeIntervals={timeInterval}
                timeCaption='Time'
                shouldCloseOnSelect={false}
                popperPlacement='bottom-start'
                onChange={date => {
                  const dateWithoutMs = withoutMilliseconds(date)

                  setStartDate(dateWithoutMs)

                  const from = toAdjustedEpoch(dateWithoutMs, tz)
                  const to = toAdjustedEpoch(endDate, tz)

                  onChange({ now, from, to })
                }}
                onFocus={() => setNext(true)}
              />
            </div>
          </div>

          <div className='date-range-picker__field-wrapper date-range-picker__field-wrapper--end'>
            <div className='date-range-picker__field'>
              <label className='date-range-picker__label' htmlFor='date-range-picker__input--end'>To:</label>
              <DatePicker
                className={classNames('date-range-picker__input', { 'date-range-picker__input--invalid': !valid })}
                id='date-range-picker__input--end'
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={maxDate}
                minTime={minTime}
                maxTime={minTime ? maxDate : null}
                highlightDates={highlightDates}
                showTimeSelect
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                timeIntervals={timeInterval}
                timeCaption='Time'
                shouldCloseOnSelect={false}
                popperPlacement='bottom-end'
                onChange={date => {
                  const dateWithoutMs = withoutMilliseconds(date)

                  setEndDate(dateWithoutMs)

                  const from = toAdjustedEpoch(startDate, tz)
                  const to = toAdjustedEpoch(dateWithoutMs, tz)

                  onChange({ now, from, to })
                }}
                onFocus={() => setNext(false)}
              />
            </div>
          </div>
        </div>

        <div className='date-range-picker__footer'>
          <div className='date-range-picker__controls'>
            {next ? (
              <button
                className='date-range-picker__button w-btn'
                disabled={pending}
                onClick={() => setNext(false)}
              >Next</button>
            ) : (
              <>
                <button
                  className='date-range-picker__button w-btn'
                  disabled={pending}
                  onClick={() => setNext(true)}
                >Previous</button>
                <button
                  className='date-range-picker__button w-btn w-btn--primary'
                  type='submit'
                  disabled={pending || !valid}
                  onClick={() => {
                    const from = toAdjustedEpoch(startDate, tz)
                    const to = toAdjustedEpoch(endDate, tz)

                    onCustom({ now, from, to })
                  }}
                >Apply</button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

DateRangePicker.propTypes = {
  now: PropTypes.number.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  timeInterval: PropTypes.number,
  tzOverride: PropTypes.string,
  limits: PropTypes.shape({
    minDate: PropTypes.number,
    warnDates: PropTypes.arrayOf(
      PropTypes.number.isRequired
    )
  }),
  pending: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onCustom: PropTypes.func.isRequired,
  $state: PropTypes.object
}

export default DateRangePicker

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'
import * as Wham from '../../../../wham/components'

import { ViewContext } from '../../../../context'

import {
  emptyOrIntWithConditions,
  isSimulatorConfigured,
  isWhitelistable,
  getSetTokenParams,
  TS_REQUEST_DEFAULT_PARAMS
} from '../../../../lib/simulator'
import { isValidUrl } from '../../../../lib/util/urls'

import SetTokenParams from './settoken-params'
import FormFieldError from './form-field-error'

import { ellipsisAfter } from '../../../../lib/string-utils'

import userAgentOptions from './user-agents.json'

import './style.css'

const DEFAULT_BRKDUR = TS_REQUEST_DEFAULT_PARAMS.brkdur

const INVALID_URL_ERROR = 'Invalid URL'
const WHITELISTABLE_ERROR = 'The adEngine request does not contain any allow list entries'
const CUSTOM_PARAMS_ERROR = 'There are empty or duplicate custom parameter names'
const BRKDUR_ERROR = 'Break duration must be a positive integer and a multiple of 5'
const PODID_ERROR = 'Pod number must be a positive integer'
const ASSETID_ERROR = 'Asset ID cannot be blank'

const AssetIdField = ({
  assetId,
  assetIdOptions = [],
  assetIdError,
  onChange
}) => {
  const [customValueMode, setCustomValueMode] = useState(false)

  useEffect(() => {
    if (customValueMode) return
    if (!assetId) return
    if (assetIdOptions.length === 0) return
    const selectedOption = assetIdOptions.find(option => option.value === assetId.value)
    if (!selectedOption) {
      onChange({ target: assetIdOptions[0] })
    }
  })

  const makeRadioButton = (label, id, value) => (
    <>
      <input
        type='radio'
        name='customValueMode'
        id={id}
        value={id}
        checked={customValueMode === value}
        onChange={() => setCustomValueMode(!customValueMode)}
      />
      <label htmlFor={id}>{label}</label>
    </>
  )

  return (
    <div className='simulator__form-field' data-cy='asset-id-field'>
      <div className='simulator__label'>Asset ID</div>
      <div className='simulator__sublabel'>(video content)</div>

      <div className='simulator__radio-buttons'>
        {makeRadioButton('Recent events', 'recentEvents', false)}
        {makeRadioButton('Custom value', 'customValue', true)}
      </div>

      {
        customValueMode
          ? (
            <Wham.FormInput
              style={{ width: '100%' }}
              value={assetId.value}
              onChange={onChange}
              data-cy='asset-id'
            />
          )
          : (
            <Wham.Select
              style={{ width: '100%' }}
              value={assetId.value}
              options={assetIdOptions}
              onChange={onChange}
              data-cy='asset-id'
            />
          )
      }
      <FormFieldError message={assetIdError} />
    </div>
  )
}

AssetIdField.propTypes = {
  assetId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetIdOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })),
  assetIdError: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

const UserAgentField = ({ userAgent, onChange }) => {
  const value = R.path(['value'], userAgent)
  useEffect(() => {
    if (!value) {
      onChange({ target: userAgentOptions[0] })
    }
  }, [])

  return (
    <div className='simulator__form-field'>
      <div className='simulator__label'>User Agent</div>
      <Wham.Select
        style={{ width: '100%' }}
        value={value}
        options={userAgentOptions}
        onChange={onChange}
      />
      <FormFieldError />
    </div>
  )
}

UserAgentField.propTypes = {
  userAgent: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired
}

const SetTokenMethod = ({ setTokenMethod, onChange }) => {
  const makeRadioButton = (label, id) => {
    const checked = label === setTokenMethod.value
    return (
      <>
        <input
          type='radio'
          name='setTokenMethod'
          id={id}
          key={`${id}-${checked}`} // without this, we seem to need to click the radio button twice to make it work
          value={label}
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={id}>{label}</label>
      </>
    )
  }

  return (
    <div className='simulator__form-field'>
      <div className='simulator__label'>SetToken Method</div>
      <div className='simulator__radio-buttons'>
        <form>
          {makeRadioButton('GET', 'settoken-method-get')}
          {makeRadioButton('POST', 'settoken-method-post')}
        </form>
      </div>
      <FormFieldError />
    </div>
  )
}

SetTokenMethod.propTypes = {
  setTokenMethod: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  onChange: PropTypes.func.isRequired
}

const SimulatorForm = ({
  whitelist,
  defaultSetTokenUrl,
  setTokenUrl,
  setTokenMethod,
  brkdur,
  implicitPodId,
  explicitPodId,
  assetId,
  userAgent,
  assetIds,
  eventNames,
  pending,
  onClick,
  onChangeField,
  onChangeSettokenUrl,
  onSubmit
}) => {
  const { partner } = useContext(ViewContext)
  const setTokenParams = getSetTokenParams(partner)
  const [customParamsErrorFlag, setCustomParamsErrorFlag] = useState(false)

  const validate = () => {
    const setTokenUrlValid = isValidUrl(setTokenUrl.value)
    const setTokenUrlWhitelistable = isWhitelistable(whitelist, setTokenUrl.value)
    const getSetTokenUrlError = () => {
      if (!setTokenUrlValid) return INVALID_URL_ERROR
      if (!setTokenUrlWhitelistable) return WHITELISTABLE_ERROR
      if (customParamsErrorFlag) return CUSTOM_PARAMS_ERROR
      return ''
    }
    const brkdurValid = emptyOrIntWithConditions(brkdur.value, n => n > 0, n => n % 5 === 0)
    const podIdValid = emptyOrIntWithConditions(explicitPodId.value, n => n > 0)
    const assetIdValid = R.compose(R.not, R.isEmpty, R.trim)(assetId.value)
    const setTokenUrlError = ['setTokenUrlError', getSetTokenUrlError()]
    const brkdurError = ['brkdurError', brkdurValid ? '' : BRKDUR_ERROR]
    const podIdError = ['podIdError', podIdValid ? '' : PODID_ERROR]
    const assetIdError = ['assetIdError', assetIdValid ? '' : ASSETID_ERROR]
    const kvps = [
      setTokenUrlError,
      brkdurError,
      podIdError,
      assetIdError
    ]
    const errorKvps = kvps.filter(([, error]) => Boolean(error))
    const errorsDict = R.fromPairs(errorKvps)
    return { valid: errorKvps.length === 0, ...errorsDict }
  }

  const form = validate()

  const assetIdOptions = eventNames.length
    ? eventNames.map(item => ({
      label: ellipsisAfter(70, item.eventName),
      value: item.assetId
    }))
    : assetIds.map(assetId => ({ label: assetId, value: assetId }))

  return (
    <form className='simulator__form'>
      <div className='simulator__form-field' data-cy='settokenurl-field'>
        <div className='simulator__settokenurl-field__wrapper'>
          <div className='simulator__label'>adEngine Request</div>
          <Wham.Icon
            className={`simulator__settokenurl-edit ${form.valid ? '' : 'simulator__settokenurl-edit--disabled'}`}
            iconName='edit'
            data-cy='edit-icon'
            onClick={() => form.valid && onClick()}
          />
        </div>
        <FormFieldError message={form.setTokenUrlError} />
      </div>

      <SetTokenParams
        defaultSetTokenUrl={defaultSetTokenUrl}
        setTokenUrl={setTokenUrl.value}
        setTokenParams={setTokenParams}
        whitelist={whitelist}
        onChangeSettokenUrl={onChangeSettokenUrl}
        setCustomParamsErrorFlag={setCustomParamsErrorFlag}
      />

      <div className='simulator__form-columns'>
        <div className='simulator__form-field' data-cy='break-duration-field'>
          <div className='simulator__label'>Break Duration</div>
          <div className='simulator__sublabel'>(multiples of 5 seconds)</div>
          <Wham.FormInput
            autoFocus
            spellCheck='false'
            placeholder={`Default ${DEFAULT_BRKDUR} sec`}
            value={brkdur.value}
            onChange={e => onChangeField('brkdur', e)}
            data-cy='break-duration'
          />
          <FormFieldError message={form.brkdurError} data-cy='break-duration-error' />
        </div>

        <div className='simulator__form-field' data-cy='pod-number-field'>
          <div className='simulator__label'>Pod Number</div>
          <div className='simulator__sublabel'>(advertising break of content)</div>
          <Wham.FormInput
            spellCheck='false'
            placeholder={`Default Pod ${implicitPodId.value}`}
            value={explicitPodId.value}
            onChange={e => onChangeField('explicitPodId', e)}
            data-cy='pod-number'
          />
          <FormFieldError message={form.podIdError} />
        </div>
      </div>

      <div>
        <AssetIdField
          assetId={assetId}
          assetIdOptions={assetIdOptions}
          assetIdError={form.assetIdError}
          onChange={e => onChangeField('assetId', e)}
        />
        <UserAgentField
          userAgent={userAgent}
          onChange={e => onChangeField('userAgent', e)}
        />
        <SetTokenMethod
          setTokenMethod={setTokenMethod}
          onChange={e => onChangeField('setTokenMethod', e)}
        />
      </div>

      <div className='simulator__button-wrapper'>
        <Wham.Button
          submit
          primary
          disabled={
            !isSimulatorConfigured(partner) ||
            !form.valid ||
            pending
          }
          onClick={e => onSubmit(e)}
          data-cy='simulate-transaction'
        >
          Simulate Transaction
        </Wham.Button>
      </div>
    </form>
  )
}

SimulatorForm.propTypes = {
  whitelist: PropTypes.array,
  defaultSetTokenUrl: PropTypes.string.isRequired,
  setTokenUrl: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  setTokenMethod: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  brkdur: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  implicitPodId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  explicitPodId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  userAgent: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventNames: PropTypes.arrayOf(PropTypes.shape({
    assetId: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired
  })).isRequired,
  pending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onChangeSettokenUrl: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SimulatorForm

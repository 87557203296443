export const icons = {
  uid: 'person',
  ssess: 'history',
  devid: 'devices',
  swid: 'person_outline'
}

export const types = {
  uid: 'User ID',
  ssess: 'Session ID',
  devid: 'Device ID',
  swid: 'SWID'
}

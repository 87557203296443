import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Layout.scss'

const LAYOUT_ROW_TYPE = [
  'fit',
  'fill'
]

class LayoutRow extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    type: PropTypes.oneOf(LAYOUT_ROW_TYPE)
  }

  render () {
    const {
      type,
      className,
      children,
      ...otherProps
    } = this.props

    const componentClass = classNames(
      'w-layout__row',
      (type && `w-layout__row--${type}`),
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        {children}
      </div>
    )
  }
}

export default LayoutRow

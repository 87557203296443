import * as F from './common/features'

export const CREATE_RULE_STRING = 'create'
export const GLOBAL_RULE_STRING = 'global'

export const paths = {
  home: '/',
  login: '/login',
  documentation: '/documentation/:topic',
  dashboard: '/:partnerId',
  debug: '/:partnerId/debug',
  transaction: '/:partnerId/debug/transaction/:transactionId',
  creative: '/:partnerId/creatives/:creativeId',
  creatives: '/:partnerId/creatives',
  assetValidator: '/:partnerId/asset-validator',
  createEditRule: '/:partnerId/rules/:ruleId',
  createEditGlobalRule: '/:partnerId/rules/global',
  createRule: `/:partnerId/rules/${CREATE_RULE_STRING}`,
  viewRules: '/:partnerId/rules',
  releaseNotes: '/:partnerId/release-notes',
  reporting: '/:partnerId/reporting',
  insightsBeta: '/:partnerId/insights-beta',
  simulator: '/:partnerId/simulator',
  whitelist: '/:partnerId/allowlist'
}

export const pathClaimsRequired = {
  [paths.creatives]: [F.CREATIVE_ASSETS],
  [paths.assetValidator]: [F.ASSET_VALIDATOR],
  [paths.createRule]: [F.STANDARD_RULES],
  [paths.createEditRule]: [F.STANDARD_RULES],
  [paths.createEditGlobalRule]: [F.GLOBAL_RULE],
  [paths.viewRules]: [F.STANDARD_RULES],
  [paths.reporting]: [F.REPORTING],
  [paths.insightsBeta]: [F.INSIGHTS_BETA],
  [paths.simulator]: [F.SIMULATOR],
  [paths.whitelist]: [F.ALLOWLIST]
}

import { paths } from '../../route-paths'
import { isSimulatorConfigured } from '../../lib/simulator'
import { isInsightsBetaConfigured } from '../../lib/insightsBeta'
import * as F from '../../common/features'
import * as P from '../../common/permissions'

export default [
  {
    group: 'Home',
    label: 'Dashboard',
    icon: 'home',
    caption: 'Dashboard',
    route: paths.dashboard
  },
  {
    group: 'Rules Management',
    label: 'Rules',
    icon: 'playlist_add_check',
    caption: 'Rule management',
    controller: F.STANDARD_RULES,
    action: P.READ,
    route: paths.viewRules
  },
  {
    group: 'Transactions',
    label: 'Debugging',
    icon: 'bug_report',
    caption: 'View allow listed transactions',
    route: paths.debug
  },
  {
    group: 'Transactions',
    label: 'Allow List',
    icon: 'filter_list_alt',
    caption: 'Manage allow list entries',
    controller: F.ALLOWLIST,
    action: P.CREATE,
    route: paths.whitelist
  },
  {
    group: 'Transactions',
    label: 'Simulator',
    icon: 'question_answer',
    caption: 'Simulate adEngine requests',
    controller: F.SIMULATOR,
    action: P.READ,
    route: paths.simulator,
    condition: isSimulatorConfigured
  },
  {
    group: 'Creative Management',
    label: 'Creative Assets',
    icon: 'list',
    caption: 'View creative asset logs',
    controller: F.CREATIVE_ASSETS,
    action: P.READ,
    route: paths.creatives
  },
  {
    group: 'Creative Management',
    label: 'Asset Validator',
    icon: 'check',
    caption: 'Validate media files',
    controller: F.ASSET_VALIDATOR,
    action: P.READ,
    route: paths.assetValidator
  },
  {
    group: 'Analytics',
    label: 'Reporting',
    icon: 'assignment',
    caption: 'View adEngine delivery data',
    controller: F.REPORTING,
    action: P.READ,
    route: paths.reporting
  },
  {
    group: 'Analytics',
    label: 'Insights',
    icon: 'bar_chart',
    caption: 'View performance dashboards',
    controller: F.INSIGHTS_BETA,
    action: P.READ,
    route: paths.insightsBeta,
    condition: isInsightsBetaConfigured
  },
  {
    group: 'Documentation',
    label: 'Documentation',
    icon: 'menu_book',
    caption: 'Documentation topics',
    externalLink: '/docs2'
  }
]

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FormFieldError = ({ message }) => {
  const className = classNames({
    'simulator__form-error': true,
    'simulator__form-error--hidden': !message
  })
  return (
    <div className={className}>
      {message || '&nbsp;'}
    </div>
  )
}

FormFieldError.propTypes = {
  message: PropTypes.string.isRequired
}

export default FormFieldError

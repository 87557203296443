import React from 'react'
import ContentLoader from 'react-content-loader'

// http://danilowoz.com/create-content-loader/

export const HeaderLoader = props =>
  <ContentLoader
    height={75}
    width={910}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='455' height='51' />
    <rect x='790' y='0' rx='5' ry='5' width='120' height='34' />
  </ContentLoader>

export const SummaryLoader = props =>
  <ContentLoader
    height={500}
    width={400}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='0' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='50' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='50' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='100' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='100' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='150' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='150' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='200' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='200' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='250' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='250' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='300' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='300' rx='5' ry='5' width='220' height='16' />

    <rect x='0' y='350' rx='5' ry='5' width='60' height='16' />
    <rect x='110' y='350' rx='5' ry='5' width='220' height='16' />
  </ContentLoader>

export const BubblesSlotsLoader = props =>
  <ContentLoader
    height={160}
    width={400}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='200' height='16' />
    <rect x='0' y='32' rx='5' ry='5' width='400' height='16' />
    <rect x='0' y='64' rx='5' ry='5' width='200' height='16' />
    <circle cx='10' cy='106' r='10' />
    <circle cx='40' cy='106' r='10' />
    <circle cx='70' cy='106' r='10' />
    <circle cx='100' cy='106' r='10' />
    <circle cx='130' cy='106' r='10' />
    <circle cx='160' cy='106' r='10' />
  </ContentLoader>

export const AdsRequestLoader = props =>
  <ContentLoader
    height={140}
    width={800}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='10' rx='3' ry='3' width='40' height='16' />
    <rect x='60' y='10' rx='3' ry='3' width='40' height='16' />
    <rect x='120' y='10' rx='3' ry='3' width='40' height='16' />
    <rect x='180' y='10' rx='3' ry='3' width='40' height='16' />

    <rect x='0' y='40' rx='3' ry='3' width='740' height='10' />
    <rect x='0' y='54' rx='3' ry='3' width='800' height='10' />
    <rect x='0' y='68' rx='3' ry='3' width='800' height='10' />
    <rect x='0' y='82' rx='3' ry='3' width='760' height='10' />
    <rect x='0' y='96' rx='3' ry='3' width='440' height='10' />
  </ContentLoader>

export const AdsResponseLoader = props =>
  <ContentLoader
    height={160}
    width={800}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='10' rx='3' ry='3' width='50' height='16' />
    <rect x='70' y='10' rx='3' ry='3' width='100' height='16' />
    <rect x='190' y='10' rx='3' ry='3' width='80' height='16' />
  </ContentLoader>

export const TsListLoader = props =>
  <ContentLoader
    height={160}
    width={800}
    speed={2}
    primaryColor='#f3f3f3'
    secondaryColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='5' ry='5' width='10' height='16' />
    <rect x='20' y='0' rx='5' ry='5' width='250' height='16' />
    <rect x='0' y='36' rx='5' ry='5' width='10' height='16' />
    <rect x='20' y='36' rx='5' ry='5' width='250' height='16' />
    <rect x='0' y='72' rx='5' ry='5' width='10' height='16' />
    <rect x='20' y='72' rx='5' ry='5' width='250' height='16' />
    <rect x='0' y='108' rx='5' ry='5' width='10' height='16' />
    <rect x='20' y='108' rx='5' ry='5' width='250' height='16' />
  </ContentLoader>

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Mark.scss'
import '../../../scss/wham.scss'

const BAMTECH_MARK_SIZES = ['large', 'small', 'medium']

class DisneyMark extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(BAMTECH_MARK_SIZES),
    inverse: PropTypes.bool,
    monochrome: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      inverse = false,
      monochrome,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-mark',
      'w-mark--disney',
      (size ? 'w-mark--' + size : null),
      (inverse ? 'w-mark--inverse' : null),
      (!inverse && monochrome ? 'w-mark--monochrome' : null),
      className
    )

    return (
      <span className={componentClass} {...otherProps}>
        <svg xmlns='http://www.w3.org/2000/svg' width='411.181' height='332.125' viewBox='0 0 411.181 332.125'>
          <path className='w-mark__element' d='M266.545,41.955S132.472-26.671,22.671,11.615c0,0-6.935-.289-13,9.1-6.5,9.1-11.991,15.314-8.669,22.1,3.323,6.935,20.227,9.969,35.4,4.768,0,0,6.935-4.334-1.734-7.224a90.749,90.749,0,0,0-13.436-3.612s-4.334-.867.433-3.178c4.334-2.167,54.612-18.637,143.319,4.623,88.852,22.538,209.489,94.2,205.877,169.036-3.323,74.694-120.348,78.017-120.348,78.017s-23.983,1.3-56.056-4.768c-0.722-24.416-1.734-66.314-1.734-91.6,45.8,0,91.886,7.657,95.643,19.215,0,0,2.312,5.057-8.091,10.113-9.391,4.045-16.759,7.513-8.668,11.269,0,0,11.558,5.346,18.782,4.768s25.717-8.957,27.45-29.184c1.878-20.516-21.816-38.719-57.357-44.787-27.017-5.2-41.753-6.935-66.025-5.779-0.289-22.249-3.323-50.133-8.235-56.634-6.068-8.669-12.858-20.227-18.2-7.224-4.479,10.836-7.946,35.4-9.391,67.181-37.275,6.212-78.883,21.96-91.453,37.564-13.436,17.337,0,31.351,1.445,32.8,0.867,1.445,28.317,41.031,87.552,67.9,1.156,10.691,3.178,22.394,7.224,28.9,9.246,13.581,26.005,4.045,28.028,1.734,1.734-1.734,2.889-6.212,3.612-16.9,26.294,6.935,74.549,14.159,125.693-1.445v0.144c77.294-23.4,88.274-71.515,90.3-99.977C413.332,179.64,391.95,105.235,266.545,41.955Zm-111.39,228.7c-27.45-9.824-56.056-25.572-79.461-50.566,0,0-8.668-9.246,3.756-15.025h0c11.558-5.2,36.119-12.136,78.594-15.025C158.044,211.858,158.333,227.316,155.155,270.659Z' fill='#587fc0' />
        </svg>

      </span>
    )
  }
};

export default DisneyMark

import { singleParamActionCreator } from '../../lib/singleParamActionCreator'
import { createAsyncActionType } from '../async'

export const LOG_SUMMARY_FETCHED = 'LOG_SUMMARY_FETCHED'

export const FETCH_CURRENT_DEBUG_SUMMARIES = createAsyncActionType('FETCH_CURRENT_DEBUG_SUMMARIES')
export const FETCH_FURTHER_DEBUG_SUMMARIES = createAsyncActionType('FETCH_FURTHER_DEBUG_SUMMARIES')
export const FETCH_PENDING_DEBUG_SUMMARIES = createAsyncActionType('FETCH_PENDING_DEBUG_SUMMARIES')

export const RESET_FACET = 'RESET_FACET'
export const RESET_FACETS = 'RESET_FACETS'

export const SET_FINAL = 'SET_FINAL'
export const SET_PENDING = 'SET_PENDING'
export const SET_GROUP_BY = 'SET_GROUP_BY'
export const SET_FACETS = 'SET_FACETS'

export const UPDATE_FACET_OPTION = 'UPDATE_FACET_OPTION'
export const UPDATE_OPEN_FILTERS = 'UPDATE_OPEN_FILTERS'

export const OPEN_WHITELIST_SIDEBAR = 'OPEN_WHITELIST_SIDEBAR'
export const CLOSE_WHITELIST_SIDEBAR = 'CLOSE_WHITELIST_SIDEBAR'

export const START_MEDIA_REQUEST_POLLING = 'START_MEDIA_REQUEST_POLLING'
export const STOP_MEDIA_REQUEST_POLLING = 'STOP_MEDIA_REQUEST_POLLING'

export const startMediaRequestPolling = singleParamActionCreator(START_MEDIA_REQUEST_POLLING)
export const stopMediaRequestPolling = singleParamActionCreator(STOP_MEDIA_REQUEST_POLLING)

export const fetchCurrentDebugSummaries = singleParamActionCreator(FETCH_CURRENT_DEBUG_SUMMARIES)
export const fetchFurtherDebugSummaries = singleParamActionCreator(FETCH_FURTHER_DEBUG_SUMMARIES)
export const fetchPendingDebugSummaries = singleParamActionCreator(FETCH_PENDING_DEBUG_SUMMARIES)
export const logSummaryFetched = singleParamActionCreator(LOG_SUMMARY_FETCHED)

export const resetFacet = singleParamActionCreator(RESET_FACET)
export const resetFacets = singleParamActionCreator(RESET_FACETS)

export const setFinal = singleParamActionCreator(SET_FINAL)
export const setPending = singleParamActionCreator(SET_PENDING)
export const setGroupBy = singleParamActionCreator(SET_GROUP_BY)
export const setFacets = singleParamActionCreator(SET_FACETS)

export const updateFacetOption = singleParamActionCreator(UPDATE_FACET_OPTION)
export const updateOpenFilters = singleParamActionCreator(UPDATE_OPEN_FILTERS)

export const openWhitelistSidebar = singleParamActionCreator(OPEN_WHITELIST_SIDEBAR)
export const closeWhitelistSidebar = singleParamActionCreator(CLOSE_WHITELIST_SIDEBAR)

import * as R from 'ramda'

import { expandPathTemplate } from '../../../../lib/path-matcher'

import { hasRole } from '../../../authorized'

const createMenu = (context, locations) => {
  const {
    claims,
    partner
  } = context

  const groups = R.compose(
    R.groupBy(({ group }) => group),
    R.filter(({ controller, action }) => controller && action ? hasRole(partner, controller, action, claims) : true),
    R.filter(({ condition }) => condition ? condition(partner) : true)
  )(locations)

  const createOptions = R.map(location => {
    const { label: text, route, routeParams, externalLink } = location

    const path = route
      ? expandPathTemplate(route, { partnerId: partner, ...routeParams })
      : undefined

    const selected = route === context.route ? true : undefined

    return {
      text,
      path,
      externalLink,
      selected
    }
  })

  return R.map(key => ({ title: key, options: createOptions(groups[key]) }), R.keys(groups))
}

export {
  createMenu
}

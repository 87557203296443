import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { getChildComponentByType } from '../lib/mixins'

import './Slab.scss'
import '../../scss/wham.scss'

export const SlabMarker = ({ background, context, children, className, ...otherProps }) => {
  const componentClass = classNames(
    'w-slab__marker',
    className
  )
  var style = background ? { background: background } : null

  return (
    <div className={componentClass} key='slab-marker' style={style} {...otherProps}>
      {children}
    </div>
  )
}

SlabMarker.propTypes = {
  className: PropTypes.string,
  /* Context information to render in the header */
  context: PropTypes.string,
  background: PropTypes.string,
  /** Additional components to render in the header */
  children: PropTypes.node
}

export const SlabHeader = ({ children, ...otherProps }) => {
  return (
    <div className='w-slab__header' {...otherProps}>
      {children}
    </div>
  )
}
SlabHeader.propTypes = {
  /** Content to render */
  children: PropTypes.node
}

export const SlabCaption = ({ children, ...otherProps }) => {
  return (
    <div className='w-slab__caption' {...otherProps}>
      {children}
    </div>
  )
}
SlabCaption.propTypes = {
  /** Content to render */
  children: PropTypes.node
}

export const SlabInfo = ({ children, ...otherProps }) => {
  return (
    <div className='w-slab__info' {...otherProps}>
      {children}
    </div>
  )
}
SlabInfo.propTypes = {
  /** Content to render */
  children: PropTypes.node
}

export class Slab extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  }

  getChildByType (type) {
    return getChildComponentByType.call(this, type)
  }

  render () {
    // classes
    const {
      children,
      className,
      ...otherProps
    } = this.props

    var componentClass = classNames(
      'w-slab',
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        {this.getChildByType(SlabMarker)}
        <div className='w-slab__content'>
          {this.getChildByType(SlabHeader)}
          {this.getChildByType(SlabCaption)}
        </div>
        {this.getChildByType(SlabInfo)}
      </div>
    )
  }
}

export default Slab

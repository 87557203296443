import { pathClaimsRequired } from '../../../route-paths'

const isAllowed = (path, claims = {}, $pathClaimsRequired = {}) => {
  const claimsRequired = $pathClaimsRequired[path] || pathClaimsRequired[path]

  if (claimsRequired) {
    const claimsHeld = claimsRequired
      .map(claimRequired => {
        return claims[claimRequired]
      })
      .filter(claim => {
        return Boolean(claim)
      })

    if (claimsHeld.length < claimsRequired.length) {
      return false // Missing a required claim
    }

    const claimsAllowed = claimsHeld.map(claimHeld => {
      return claimHeld.read === '*'
    })

    return Object.values(claimsAllowed).every(Boolean) // Does every claim held have "read" access?
  }

  return true
}

export {
  isAllowed
}

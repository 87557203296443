import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './CloseButton.scss'
import '../../scss/wham.scss'

const CLOSE_BUTTON_SIZES = ['large', 'small', 'medium']

class CloseButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(CLOSE_BUTTON_SIZES),
    inverse: PropTypes.bool
  }

  render () {
    const {
      size = 'medium',
      inverse = false,
      className,
      ...otherProps
    } = this.props

    // classes
    var componentClass = classNames(
      'w-close-btn',
      (inverse ? 'w-close-btn--inverse' : null),
      (size ? 'w-close-btn--' + size : null),
      className
    )

    return (<span className={componentClass} {...otherProps} />)
  }
};

export default CloseButton

import React from 'react'
import PropTypes from 'prop-types'

import SimulatorForm from './simulator-form'
import SimulatedTransactions from './simulated-transactions'

import './style.css'

const SimulatorContentUpper = ({
  defaultSetTokenUrl,
  whitelist,
  setTokenUrl,
  setTokenMethod,
  brkdur,
  implicitPodId,
  explicitPodId,
  assetId,
  userAgent,
  assetIds,
  eventNames,
  pending,
  simulatedTransactions,
  selectedTransactionId,
  onClick,
  onChangeField,
  onChangeSettokenUrl,
  onRemove,
  onRemoveAll,
  onSelect,
  onSubmit
}) => {
  return (
    <div className='simulator__content-upper'>
      <SimulatorForm
        defaultSetTokenUrl={defaultSetTokenUrl}
        whitelist={whitelist}
        setTokenUrl={setTokenUrl}
        setTokenMethod={setTokenMethod}
        brkdur={brkdur}
        implicitPodId={implicitPodId}
        explicitPodId={explicitPodId}
        assetId={assetId}
        userAgent={userAgent}
        assetIds={assetIds}
        eventNames={eventNames}
        pending={pending}
        onClick={onClick}
        onChangeField={onChangeField}
        onChangeSettokenUrl={onChangeSettokenUrl}
        onSubmit={onSubmit}
      />

      <SimulatedTransactions
        simulatedTransactions={simulatedTransactions}
        selectedTransactionId={selectedTransactionId}
        onRemove={onRemove}
        onRemoveAll={onRemoveAll}
        onSelect={onSelect}
      />
    </div>
  )
}

SimulatorContentUpper.propTypes = {
  whitelist: PropTypes.array,
  defaultSetTokenUrl: PropTypes.string.isRequired,
  setTokenUrl: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  setTokenMethod: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  brkdur: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  implicitPodId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  explicitPodId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetId: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  userAgent: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  assetIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventNames: PropTypes.arrayOf(PropTypes.shape({
    assetId: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired
  })).isRequired,
  pending: PropTypes.bool.isRequired,
  simulatedTransactions: PropTypes.array.isRequired,
  selectedTransactionId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onChangeSettokenUrl: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default SimulatorContentUpper

/* eslint-disable space-before-function-paren */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'

import classNames from 'classnames'
import * as R from 'ramda'
import Moment from 'react-moment'
import 'moment-timezone'
import * as Wham from '../../../wham/components'

import MonacoEditor from '../../../components/loadable-monaco-editor'
import prettify from 'prettify-xml'
import beautify from 'xml-beautifier'
import { podDecisionOrder } from '../../../lib/pod-decision-order'
import log from 'loglevel'

import {
  BLOCKED,
  NO_VALID_CREATIVE,
  RESOLVER_FAILURE,
  SENT_TO_TRANSCODE,
  UNUSED,
  TZ
} from '../../../constants'

import { setDebugFormField } from '../../../actions/form'
import { goToDebugResults } from '../../../actions/navigators'

import * as F from '../../../common/features'
import * as P from '../../../common/permissions'

import { hasRole } from '../../../components/authorized'
import AdDecision, { SKIPPED_AD_STATUS_TEXT } from '../../../components/debug-decision'
import NoData from '../../../components/no-data'
import DebugKeyValueTable from '../../../components/debug-key-value-table'
import { PageControls } from '../../../components/page-controls'
import TSTab from '../../../components/ts-tab'
import VideoPlayer from '../../../components/video-player/loadable-video-player'
import APSTab from '../../../components/aps-tab'
import SetTokenTab from '../../../components/settoken-tab'
import ContentFailed from '../../../components/content/content-failed'

import { paths } from '../../../route-paths'

import {
  makeProxyUrl,
  buildPlaylist,
  SGMNT_DURATION_SECONDS
} from '../../../lib/playlist'
import { expandPathTemplate } from '../../../lib/path-matcher'

import { goToTransaction } from '../local-actions'
import * as Loaders from '../transaction-loaders'

import { icons } from '../debug-logs/debug-log-group/debug-log-record'
import DebugSummary from '../debug-summary'

import './style.css'

const pluralise = (word, length) => word + (length === 1 ? '' : 's')

const SelectedAdBubble = ({
  ad,
  adNumber,
  viewedAds,
  setTooltip
}) => {
  const viewed = viewedAds.includes(ad)
  const tooltip = `Ad# ${adNumber} - ${viewed ? 'Viewed' : 'Not viewed'}`
  return (
    <span
      className={`ad ad-selected ${!viewed ? 'ad-selected--not-viewed' : ''}`}
      data-cy='bubble'
      onMouseEnter={e => setTooltip(e.target)}
      onMouseLeave={() => setTooltip(null)}
      data-tooltip={tooltip}
    />
  )
}

SelectedAdBubble.propTypes = {
  ad: PropTypes.object.isRequired,
  adNumber: PropTypes.number.isRequired,
  viewedAds: PropTypes.array.isRequired,
  setTooltip: PropTypes.func.isRequired
}

const STATUS_CLASS = {
  [BLOCKED]: 'ad-error',
  [NO_VALID_CREATIVE]: 'ad-error',
  [RESOLVER_FAILURE]: 'ad-error',
  [UNUSED]: 'ad-ok',
  [SENT_TO_TRANSCODE]: 'ad-warn'
}

const SkippedAdBubble = ({
  ad,
  setTooltip
}) => {
  const statusType = R.path(['status', '_type'], ad)
  const failureTypeOrStatusType =
    R.path(['status', 'failure', '_type'], ad) ||
    R.path(['status', '_type'], ad)
  const skippedReason =
    failureTypeOrStatusType &&
    SKIPPED_AD_STATUS_TEXT[failureTypeOrStatusType]
  const tooltip = skippedReason
    ? `Skipped Ad - ${skippedReason}`
    : 'Skipped Ad'
  return (
    <span
      className={`ad ${statusType ? STATUS_CLASS[statusType] : ''}`}
      data-cy='bubble'
      onMouseEnter={e => setTooltip(e.target)}
      onMouseLeave={() => setTooltip(null)}
      data-tooltip={tooltip}
    />
  )
}

SkippedAdBubble.propTypes = {
  ad: PropTypes.object.isRequired,
  setTooltip: PropTypes.func.isRequired
}

export class TransactionDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bubblesTooltip: null,
      bookmarkIconTooltip: null,
      xmlFakeRoot: '',
      vastResponses: [],
      currentVastResponseIndex: -1,
      podDecisions: [],
      otherDecisions: [],
      currentPlayingAdNumber: false,
      playerUrl: null,
      playerLoading: false,
      playingPOD: false,
      playingSegment: null,
      playingAdDecision: null,
      podPlaybackError: false,
      segmentPlaybackErrors: new Map(),
      adDecisionPlaybackErrors: new Map()
    }
  }

  componentWillUnmount() {
    this.resetPlayer()
  }

  downloadXml(e, xml, fileName) {
    e.preventDefault()
    const blob = new Blob([xml], { type: 'application/xml' })
    const url = URL.createObjectURL(blob)
    try {
      const { transactionId } = this.props.transactionDetails
      // Use the DOM to trigger a download
      const a = document.createElement('a')
      a.href = url
      a.download = `${transactionId}-${fileName}.xml`
      a.click()
    } finally {
      URL.revokeObjectURL(url)
    }
  }

  currentlyPlaying() {
    return (
      this.state.playingPOD ||
      this.state.playingSegment ||
      this.state.playingAdDecision
    )
  }

  buildHlsUrl(ts) {
    const playlist = buildPlaylist(ts, makeProxyUrl)
    const blob = new Blob([playlist])
    return URL.createObjectURL(blob)
  }

  playPod() {
    this.resetPlayer()
    setTimeout(() => {
      this.setState({
        playerUrl: this.buildHlsUrl(this.props.transactionDetails.tsData),
        playerLoading: true,
        playingPOD: true
      })
    }, 0)
  }

  playSegment(ts) {
    this.resetPlayer()
    setTimeout(() => {
      this.setState({
        playerUrl: this.buildHlsUrl([ts]),
        playerLoading: true,
        playingSegment: ts
      })
    }, 0)
  }

  playMezzFile(adDecision) {
    this.resetPlayer()
    setTimeout(() => {
      this.setState({
        playerUrl: makeProxyUrl(adDecision.mezzFile),
        playerLoading: true,
        playingAdDecision: adDecision
      })
    }, 0)
  }

  resetPlayer(error) {
    const podPlaybackError = error && this.state.playingPOD
      ? error
      : null

    const addMapEntry = (m, k, v) => new Map([...m, [k, v]])

    const segmentPlaybackErrors = error && this.state.playingSegment
      ? addMapEntry(this.state.segmentPlaybackErrors, this.state.playingSegment, error)
      : this.state.segmentPlaybackErrors

    const adDecisionPlaybackErrors = error && this.state.playingAdDecision
      ? addMapEntry(this.state.adDecisionPlaybackErrors, this.state.playingAdDecision, error)
      : this.state.adDecisionPlaybackErrors

    this.setState({
      playerUrl: null,
      playerLoading: false,
      playingPOD: false,
      playingSegment: null,
      playingAdDecision: null,
      podPlaybackError,
      segmentPlaybackErrors,
      adDecisionPlaybackErrors
    })
  }

  onPlayerReady() {
    this.setState({
      playerLoading: false
    })
  }

  onPlayerEnded() {
    this.resetPlayer()
  }

  onPlayerError() {
    this.resetPlayer('Media asset unavailable')
  }

  onPlayerProgress(currentTime) {
    if (!this.state.playingPOD) {
      if (this.state.currentPlayingAdNumber) {
        return this.setState({ currentPlayingAdNumber: false })
      }
    }
    const sgmntNumber = Math.floor(currentTime / SGMNT_DURATION_SECONDS)
    const { firstSgmntNumber, sgmntToAdNumber } = this.props.transactionDetails
    const relativeSgmntNumber = firstSgmntNumber + sgmntNumber
    const currentPlayingAdNumber = sgmntToAdNumber[relativeSgmntNumber]
    if (!currentPlayingAdNumber) {
      if (this.state.currentPlayingAdNumber) {
        return this.setState({ currentPlayingAdNumber: false })
      }
    }
    if (currentPlayingAdNumber !== this.state.currentPlayingAdNumber) {
      this.setState({ currentPlayingAdNumber })
    }
  }

  setBubblesTooltip = element => {
    this.setState({
      bubblesTooltip: element
    })
  }

  setBookmarkIconTooltip = element => {
    this.setState({
      bookmarkIconTooltip: element
    })
  }

  cacheVastResponses = () => {
    try {
      if (this.state.vastResponses.length) {
        return
      }
      const {
        transactionPending,
        adsRawResponseXML = '',
        adEDecisions = []
      } = this.props.transactionDetails
      if (transactionPending || !adsRawResponseXML) {
        return
      }
      const removeEmbeddedNewLines = s => s.replace(/\\n/g, '').replace(/\n/g, '')
      const makePrettyXmlString = R.compose(prettify, beautify, removeEmbeddedNewLines)
      const xmlNode2PrettyXmlString = xmlNode => makePrettyXmlString(xmlNode.outerHTML)
      const xmlCleaned = adsRawResponseXML
        .replace(/<[?]xml.+[?]>/g, '')
        .replace(/<html>.*<[/]html>/g, '')
      const xmlFakeRoot = makePrettyXmlString(`<FakeRoot>${xmlCleaned}</FakeRoot>`)
      const domParser = new DOMParser()
      const xmlDocument = domParser.parseFromString(xmlFakeRoot, 'application/xml')
      const vastElements = Array.from(xmlDocument.documentElement.children)
      const vastResponses = vastElements.map(xmlNode2PrettyXmlString)
      if (vastResponses.length) {
        const { podDecisions, otherDecisions } = podDecisionOrder(adEDecisions, vastElements[0])
        this.setState({
          xmlFakeRoot,
          vastResponses,
          currentVastResponseIndex: 0,
          podDecisions,
          otherDecisions
        })
      }
    } catch (error) {
      log.error(`[cacheVastResponses] ${error.message}`)
      const vastResponse = '<VAST></VAST>'
      this.setState({
        xmlFakeRoot: `<FakeRoot>${vastResponse}</FakeRoot>`,
        vastResponses: [vastResponse],
        currentVastResponseIndex: 0
      })
    }
  }

  renderStatus = fallback => {
    const status = this.props.transactionDetails?.focusedLog?.status
    const statusMessage = this.props.transactionDetails?.focusedLog?.statusMessage

    let statusDescription

    switch (status) {
      case 'decided': statusDescription = null; break
      case 'errored': statusDescription = 'Error'; break
      case 'cancelled': statusDescription = 'Timeout'; break
      case 'race_lost': statusDescription = 'Race Lost'; break
      default: statusDescription = status; break
    }

    if (!statusDescription) {
      return fallback
    }

    return (
      <>
        <div className="debug-item__status">adEngine ADS call status: {statusDescription}</div>
        {statusMessage && <div className="debug-item__status-message">{statusMessage}</div>}
      </>
    )
  }

  renderTransactionDetails() {
    const { partnerId, dispatch, features, transactionDetails, claims, onBookmark, onUpdate } = this.props
    const {
      transactionId,
      selectedAds,
      skippedAds,
      viewedAds,
      transactionPending,
      summaryPending,
      mediaRequestsPending,
      adDecisionServiceDataPending,
      adEngineDecisionsPending,
      newTs,
      focusedLog = {},
      adsRequestHeaders,
      adsRequestCookies,
      adEDecisions,
      tsData,
      traceId,
      adsRequestUrl,
      gamNetworkId,
      sgmntToAdNumber,
      firstSgmntNumber,
      summaryData,
      flagChangePending
    } = transactionDetails

    const aps = R.path(['aps'], focusedLog)
    const settoken = R.path(['settoken'], focusedLog)

    const {
      currentPlayingAdNumber,
      playingPOD
    } = this.state

    const { idType, label, value } = focusedLog.allowlistEntry ?? {}

    const type = idType ? idType.toLowerCase() : 'default'
    const title = label || value || 'Orphaned Transaction'

    const allAdEDecisionsHaveType = R.length(adEDecisions) && R.all(({ status = {} }) => !!status._type, adEDecisions)

    const prefetched = focusedLog.source && focusedLog.source._type === 'Prefetch'

    const selectedDuration = focusedLog.breakDurationSelected
    const totalDuration = focusedLog.breakDurationRequested
    const selectedAdsViewedCount = this.props.transactionDetails.viewedAds.length

    const onSearch = (key, value) => {
      dispatch(setDebugFormField('searchQuery', value))
      dispatch(goToDebugResults({ features }))
    }

    const userCanReadBookmark = hasRole(partnerId, F.DEBUGGING_FLAGGING, P.READ, claims)
    const userCanToggleBookmark =
      hasRole(partnerId, F.DEBUGGING_FLAGGING, P.CREATE, claims) &&
      hasRole(partnerId, F.DEBUGGING_FLAGGING, P.DELETE, claims)

    const onToggleBookmark = () => {
      if (userCanToggleBookmark && !flagChangePending && onBookmark) {
        onBookmark({ transactionId, saved: !focusedLog.saved })
      }
    }

    this.cacheVastResponses()

    return (
      <div className='debug-item'>
        {transactionPending ? (
          <Loaders.HeaderLoader />
        ) : (
          <div className='debug-item__header'>
            <div className='debug-item__header-left'>
              <div className='debug-item__header-left-upper'>
                <Wham.Icon className={`debug-item__icon--${type}`} iconName={icons[type]} />

                <Wham.Heading
                  className={classNames('debug-item__header-text', {
                    'debug-item__header-text--label': label,
                    'debug-item__header-text--value': !label && value
                  })}
                  level={4}
                >{title}</Wham.Heading>

                {userCanReadBookmark ? (
                  <Wham.Icon
                    iconName={focusedLog.saved ? 'bookmark' : 'bookmark_border'}
                    className={classNames('debug-item__flag', { 'debug-item__flag--toggle': userCanToggleBookmark })}
                    onClick={onToggleBookmark}
                    data-tooltip={userCanToggleBookmark ? 'Click to toggle bookmark.' : ''}
                    onMouseEnter={e => this.setBookmarkIconTooltip(e.target)}
                    onMouseLeave={() => this.setBookmarkIconTooltip(null)}
                  />
                ) : null}
                {
                  this.state.bookmarkIconTooltip
                    ? <Wham.Tooltip trigger={this.state.bookmarkIconTooltip} />
                    : null
                }
              </div>

              <div className='debug-item__header-left-lower'>
                <div className='debug-item__header-datetime'>
                  <Moment format='M/D/YYYY @ h:mm a' tz={TZ}>{focusedLog.eventTime}</Moment>
                </div>

                <div className='debug-item__header-pill'>
                  {prefetched ? (
                    <Wham.Badge label='Prefetched' type='primary' size='small' />
                  ) : null}
                </div>
              </div>
            </div>

            <div className='debug-item__header-right'>
              {
                tsData.length > 0 && (
                  this.state.playingPOD
                    ? <span style={{
                      fontSize: 'var(--w-font-size-large)',
                      color: 'var(--w-color-gray-light)'
                    }}>{this.state.playerLoading ? 'Loading' : 'POD playing'}
                    </span>
                    : <Wham.Button
                      primary
                      disabled={!!this.state.podPlaybackError}
                      onClick={this.playPod.bind(this)}
                    >
                      <Wham.Icon iconName={this.state.podPlaybackError ? 'block' : 'play_arrow'} />
                      <span data-cy='play-pod'>Play POD</span>
                    </Wham.Button>
                )
              }
            </div>
          </div>
        )}

        <div className='debug-item__summary'>
          <Wham.Heading level={3}>Summary</Wham.Heading>
          <Wham.Layout colSpacing='none'>
            <Wham.LayoutRow>
              <Wham.LayoutColumn className='debug-item__summary__wrapper'>
                {
                  summaryPending ? (
                    <Loaders.SummaryLoader />
                  ) : (
                    <DebugSummary
                      summaryData={summaryData}
                      onClick={goToAction => this.props.dispatch(goToAction)}
                      onSearch={onSearch}
                    />
                  )
                }
              </Wham.LayoutColumn>

              <Wham.LayoutColumn className='debug-item__summary__right-column'>
                {
                  summaryPending || adEngineDecisionsPending ? (
                    <Loaders.BubblesSlotsLoader />
                  ) : (
                    <div>
                      <div>
                        {allAdEDecisionsHaveType ? (
                          <div>
                            {
                              totalDuration
                                ? (<>
                                  <Wham.Heading level={5} data-cy='stats-time-filled'>
                                    {selectedDuration}s time filled of {totalDuration}s requested
                                  </Wham.Heading>
                                  <Wham.Progress display progress={selectedDuration / totalDuration} />
                                </>
                                )
                                : (
                                  <Wham.Heading level={5} data-cy='stats-time-filled'>
                                    {selectedDuration}s time filled
                                  </Wham.Heading>
                                )
                            }
                            <Wham.Heading level={5} data-cy='stats-ads-returned'>
                              {`${selectedAds.length} ${pluralise('ad', selectedAds.length)} selected of ${adEDecisions.length} returned (${selectedAdsViewedCount} ${pluralise('ad', selectedAdsViewedCount)} viewed)`}
                            </Wham.Heading>
                            {
                              this.state.bubblesTooltip
                                ? <Wham.Tooltip trigger={this.state.bubblesTooltip} />
                                : null
                            }
                            <div className='debug-item__summary__stats'>
                              {selectedAds.map((ad, index) => (
                                <SelectedAdBubble
                                  key={index}
                                  ad={ad}
                                  adNumber={index + 1}
                                  viewedAds={viewedAds}
                                  setTooltip={this.setBubblesTooltip}
                                />
                              ))}
                              {skippedAds.map((ad, index) => (
                                <SkippedAdBubble
                                  key={index}
                                  ad={ad}
                                  setTooltip={this.setBubblesTooltip}
                                />
                              ))}
                            </div>
                          </div>
                        ) : adEDecisions.length === 0 ? (
                          <div>
                            <div>
                              {
                                totalDuration
                                  ? (<>
                                    <Wham.Heading level={5}>0s time filled of {totalDuration}s requested</Wham.Heading>
                                    <Wham.Progress display progress={0} />
                                  </>
                                  )
                                  : (
                                    <Wham.Heading level={5} data-cy='stats-time-filled'>
                                      0s time filled
                                    </Wham.Heading>
                                  )
                              }
                              <Wham.Heading level={5}>No ads returned</Wham.Heading>
                              {this.renderStatus(null)}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                }
              </Wham.LayoutColumn>
            </Wham.LayoutRow>
          </Wham.Layout>
        </div>

        <div className='debug-item__details'>
          <Wham.Tabs title='Details' size='large'>

            <Wham.Tab label='ADS'>
              {
                summaryPending || adDecisionServiceDataPending
                  ? <Loaders.AdsRequestLoader />
                  : <Wham.Tabs title='Request'>
                    <Wham.Tab label='URL'>
                      <Wham.Copyable>
                        <span style={{ wordBreak: 'break-word' }}>
                          {adsRequestUrl || this.renderStatus(<NoData dataType='URL' />)}
                        </span>
                      </Wham.Copyable>
                    </Wham.Tab>

                    <Wham.Tab label='Cookies'>
                      <DebugKeyValueTable items={adsRequestCookies} dataType='cookies' />
                    </Wham.Tab>

                    <Wham.Tab label='Headers'>
                      <DebugKeyValueTable items={adsRequestHeaders} dataType='headers' />
                    </Wham.Tab>
                  </Wham.Tabs>
              }

              {
                summaryPending || adEngineDecisionsPending
                  ? <Loaders.AdsResponseLoader />
                  : <Wham.Tabs title='Response' style={{ marginTop: 50 }}>
                    <Wham.Tab label='AdEngine Decision'>

                      <Wham.Heading level={5}>
                        {selectedAds.length} ad(s) selected
                      </Wham.Heading>
                      {selectedAds.map((decision, index) =>
                        <AdDecision
                          key={index}
                          gamNetworkId={gamNetworkId}
                          sequence={index}
                          decision={decision}
                          playMezzFile={this.playMezzFile.bind(this)}
                          isPlaying={this.state.playingAdDecision === decision}
                          playerLoading={this.state.playerLoading}
                          playerError={this.state.adDecisionPlaybackErrors.get(decision)}
                          dispatch={dispatch}
                        />
                      )}

                      <Wham.Heading level={5} style={{ marginTop: 'var(--w-spacing-xlarge)' }}>
                        {skippedAds.length} ad(s) skipped
                      </Wham.Heading>
                      {skippedAds.map((decision, index) =>
                        <AdDecision
                          key={index}
                          gamNetworkId={gamNetworkId}
                          sequence={index}
                          decision={decision}
                          playMezzFile={this.playMezzFile.bind(this)}
                          isPlaying={this.state.playingAdDecision === decision}
                          playerLoading={this.state.playerLoading}
                          playerError={this.state.adDecisionPlaybackErrors.get(decision)}
                          dispatch={dispatch}
                        />
                      )}
                    </Wham.Tab>

                    <Wham.Tab label='ADS Decision'>

                      <Wham.Heading level={5}>
                        {this.state.podDecisions.length} ad(s) selected
                      </Wham.Heading>
                      {this.state.podDecisions.map((decision, index) =>
                        <AdDecision
                          mode='pod'
                          key={index}
                          gamNetworkId={gamNetworkId}
                          sequence={index}
                          decision={decision}
                          playMezzFile={this.playMezzFile.bind(this)}
                          isPlaying={this.state.playingAdDecision === decision}
                          playerLoading={this.state.playerLoading}
                          playerError={this.state.adDecisionPlaybackErrors.get(decision)}
                          dispatch={dispatch}
                        />
                      )}

                      <Wham.Heading level={5} style={{ marginTop: 'var(--w-spacing-xlarge)' }}>
                        {this.state.otherDecisions.length} ad(s) buffet
                      </Wham.Heading>
                      {this.state.otherDecisions.map((decision, index) =>
                        <AdDecision
                          mode='other'
                          key={index}
                          gamNetworkId={gamNetworkId}
                          sequence={index}
                          decision={decision}
                          playMezzFile={this.playMezzFile.bind(this)}
                          isPlaying={this.state.playingAdDecision === decision}
                          playerLoading={this.state.playerLoading}
                          playerError={this.state.adDecisionPlaybackErrors.get(decision)}
                          dispatch={dispatch}
                        />
                      )}
                    </Wham.Tab>

                    <Wham.Tab label='Raw Response'>
                      <div className='debug-item__raw-response'>
                        <div className='debug-item__raw-response__bar'>
                          <div className='debug-item__raw-response__bar__left'>
                            <Wham.Select
                              size='small'
                              options={this.state.vastResponses.map((_, index) => ({
                                label: `VAST Response ${index + 1}`,
                                value: index.toString(),
                                index
                              }))}
                              value={this.state.currentVastResponseIndex}
                              onChange={(_, data) => {
                                this.setState({
                                  currentVastResponseIndex: data.index
                                })
                              }}
                            >
                            </Wham.Select>
                          </div>
                          <div className='debug-item__raw-response__bar__right'>
                            <Wham.Button
                              type='primary'
                              size='small'
                              disabled={this.state.currentVastResponseIndex < 0}
                              onClick={e => {
                                const number = this.state.currentVastResponseIndex + 1
                                const fileName = `vast-response-${number}`
                                const xml = this.state.vastResponses[this.state.currentVastResponseIndex]
                                this.downloadXml(e, xml, fileName)
                              }}
                            >
                              Download VAST Response
                            </Wham.Button>
                            <Wham.Button
                              type='primary'
                              size='small'
                              disabled={!this.state.xmlFakeRoot}
                              onClick={e => {
                                this.downloadXml(e, this.state.xmlFakeRoot, 'all-vast-responses')
                              }}
                            >
                              Download All VAST Responses
                            </Wham.Button>
                          </div>
                        </div>
                        <MonacoEditor
                          language='xml'
                          value={
                            this.state.currentVastResponseIndex >= 0
                              ? this.state.vastResponses[this.state.currentVastResponseIndex]
                              : ''
                          }
                          options={{
                            lineNumbers: false,
                            showFoldingControls: 'always',
                            minimap: { enabled: false },
                            readOnly: true
                          }}
                        />
                      </div>
                    </Wham.Tab>
                  </Wham.Tabs>
              }
            </Wham.Tab>

            <Wham.Tab label='TS'>
              {
                summaryPending || mediaRequestsPending
                  ? <Loaders.TsListLoader />
                  : <TSTab
                    dispatch={dispatch}
                    traceId={traceId}
                    newTs={newTs}
                    tsData={tsData}
                    sgmntToAdNumber={sgmntToAdNumber}
                    firstSgmntNumber={firstSgmntNumber}
                    playSegment={this.playSegment.bind(this)}
                    playerLoading={this.state.playerLoading}
                    playingSegment={this.state.playingSegment}
                    segmentPlaybackErrors={this.state.segmentPlaybackErrors}
                    onUpdate={onUpdate}
                  />
              }
            </Wham.Tab>

            {
              settoken
                ? <Wham.Tab label='Settoken'>
                  <SetTokenTab settoken={settoken} />
                </Wham.Tab>
                : <React.Fragment />
            }

            {
              aps
                ? <Wham.Tab label='Bidder'>
                  <APSTab aps={aps} />
                </Wham.Tab>
                : <React.Fragment />
            }
          </Wham.Tabs>
        </div>

        {
          this.currentlyPlaying() &&
          <VideoPlayer
            url={this.state.playerUrl}
            onError={this.onPlayerError.bind(this)}
            onReady={this.onPlayerReady.bind(this)}
            onEnded={this.onPlayerEnded.bind(this)}
            onProgress={this.onPlayerProgress.bind(this)}
          />
        }

        {(playingPOD && currentPlayingAdNumber && !this.state.playerLoading) &&
          <Wham.Badge
            style={{
              position: 'fixed',
              right: '440px',
              bottom: '265px'
            }}
            label={`Ad#: ${currentPlayingAdNumber}`}
          />
        }

        {
          this.currentlyPlaying() && !this.state.playerLoading &&
          <span style={{
            position: 'fixed',
            right: '40px',
            bottom: '260px'
          }}
          >
            <Wham.Icon
              iconName='cancel'
              color='var(--w-color-white)'
              onClick={() => this.resetPlayer()}
            />
          </span>
        }
      </div>
    )
  }

  renderFailed(error) {
    const { partnerId, transactionDetails, features, dispatch } = this.props
    const { transactionId } = transactionDetails

    return (
      <ContentFailed
        id={transactionId}
        label='transaction'
        error={error}
        onReturn={() => {
          dispatch(goToDebugResults({ hash: transactionId, features }))
        }}
        onRetry={() => {
          dispatch(push(expandPathTemplate(paths.transaction, { partnerId, transactionId })))
        }}
      />
    )
  }

  render() {
    const { partnerId, dispatch } = this.props
    const {
      transactionPending,
      focusedLog,
      prevTransactionId,
      nextTransactionId,
      error
    } = this.props.transactionDetails

    return (
      <React.Fragment>
        <PageControls
          keyBinds
          showNext={!!nextTransactionId}
          showPrev={!!prevTransactionId}
          nextAction={() => {
            dispatch(goToTransaction(partnerId, nextTransactionId))
            window.scrollTo(0, 0)
          }}
          prevAction={() => {
            dispatch(goToTransaction(partnerId, prevTransactionId))
            window.scrollTo(0, 0)
          }}
        />
        <Wham.LayoutColumn className='results-content'>
          {/* TODO: move explicit style into style.css */}
          <Wham.Box
            alignHorizontal='center' style={{
              paddingBottom: this.currentlyPlaying() ? '270px' : '0'
            }}
          >
            {
              transactionPending || focusedLog
                ? this.renderTransactionDetails(focusedLog)
                : this.renderFailed(error)
            }
          </Wham.Box>
        </Wham.LayoutColumn>
      </React.Fragment>
    )
  }
}

TransactionDetails.propTypes = {
  partnerId: PropTypes.string.isRequired,
  transactionDetails: PropTypes.object.isRequired,
  claims: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  onBookmark: PropTypes.func,
  onUpdate: PropTypes.func
}

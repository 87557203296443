import React from 'react'
import PropTypes from 'prop-types'

import DebugFiltersMeta from './debug-filters-meta'
import DebugFiltersRefresh from './debug-filters-refresh'
import DebugFiltersReset from './debug-filters-reset'

import './style.css'

const DebugFilterControls = ({
  count,
  total,
  filtered,
  pending,
  onRefresh,
  onReset
}) => {
  return (
    <div className='debug-filter-controls'>
      <DebugFiltersMeta count={count} total={total} />
      <DebugFiltersRefresh pending={pending} onClick={onRefresh} />
      <DebugFiltersReset enabled={filtered} pending={pending} onClick={onReset} />
    </div>
  )
}

DebugFilterControls.propTypes = {
  count: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  filtered: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}

export default DebugFilterControls

import { partnerUrl, performReq } from '../../api'

export const getGlobalRule = async ({ partnerId }) => {
  const url = partnerUrl(partnerId) + '/globalrule'
  return performReq('GET', url)
}

export const updateGlobalRule = async ({ partnerId, rule }) => {
  const url = partnerUrl(partnerId) + '/globalrule'
  return performReq('PUT', url, rule)
}

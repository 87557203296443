import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import * as Wham from '../../wham/components'

import MiddleTruncate from '../middle-truncate'

class BadgeUrl extends Component {
  constructor () {
    super()

    this.state = {
      copied: false
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeout)
  }

  handleCopyToClipboard () {
    if (!this.timeout) {
      this.setState({
        copied: true
      })

      this.timeout = setTimeout(() => {
        this.setState({
          copied: false
        })

        this.timeout = null
      }, 500)
    }
  }

  render () {
    const {
      url,
      label
    } = this.props

    return (
      <React.Fragment>
        <Wham.Badge className='debug__ad-decision__url__badge' label={label} />
        <CopyToClipboard text={url} onCopy={e => this.handleCopyToClipboard(e)}>
          <span className={`w-copyable ${this.state.copied ? 'w-copyable--active' : ''}`}>
            <MiddleTruncate maxLength={46}>{url}</MiddleTruncate>
          </span>
        </CopyToClipboard>
      </React.Fragment>
    )
  }
}

BadgeUrl.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired
}

export default BadgeUrl

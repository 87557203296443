import * as R from 'ramda'
import {
  ADD_SIMULATED_TRANSACTION,
  REMOVE_SIMULATED_TRANSACTION,
  REMOVE_ALL_SIMULATED_TRANSACTIONS,
  SELECT_SIMULATED_TRANSACTION,
  SETTOKEN_UPDATED,
  MARK_SIMULATED_TRANSACTION_VIEWED
} from '../../actions/simulator'

const initialState = {
  simulatedTransactions: [],
  selectedTransactionId: undefined,
  setTokenUrlUpdated: false
}

const sortByDescendingTimestamp = ts =>
  ts.sort((t1, t2) => t2.timestamp - t1.timestamp)

const addSimulatedTransaction = (oldSimulatedTransactions, payload) => {
  const found = oldSimulatedTransactions.some(t => t.transactionId === payload.transactionId)
  if (found) return oldSimulatedTransactions
  const newSimulatedTransactions = [
    ...oldSimulatedTransactions,
    {
      transactionId: payload.transactionId,
      timestamp: payload.timestamp,
      brkdur: payload.brkdur,
      viewed: false
    }
  ]
  return sortByDescendingTimestamp(newSimulatedTransactions)
}

const removeSimulatedTransaction = (simulatedTransactions, payload) =>
  simulatedTransactions.filter(t => t.transactionId !== payload.transactionId)

export default (state = initialState, { type, payload }) => {
  state.setTokenUrlUpdated = false

  switch (type) {
    case ADD_SIMULATED_TRANSACTION:
      return {
        ...state,
        simulatedTransactions:
          addSimulatedTransaction(state.simulatedTransactions, payload),
        selectedTransactionId: undefined
      }

    case REMOVE_SIMULATED_TRANSACTION:
      return {
        ...state,
        simulatedTransactions:
          removeSimulatedTransaction(state.simulatedTransactions, payload),
        selectedTransactionId:
          state.selectedTransactionId !== payload.transactionId
            ? state.selectedTransactionId
            : undefined
      }

    case MARK_SIMULATED_TRANSACTION_VIEWED:
      return {
        ...state,
        simulatedTransactions:
          state.simulatedTransactions.map(simulatedTransaction =>
            simulatedTransaction.transactionId === payload.transactionId
              ? R.assoc('viewed', true, simulatedTransaction)
              : simulatedTransaction)
      }

    case REMOVE_ALL_SIMULATED_TRANSACTIONS:
      return {
        ...state,
        simulatedTransactions: [],
        selectedTransactionId: undefined
      }

    case SELECT_SIMULATED_TRANSACTION:
      return {
        ...state,
        selectedTransactionId: payload.transactionId
      }

    case SETTOKEN_UPDATED: {
      return {
        ...state,
        setTokenUrlUpdated: true
      }
    }

    default:
      return state
  }
}

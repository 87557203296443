const REGEX = new RegExp([
  '^',
  '(http|https)://', // schema: mandatory, only http or https
  '[a-z0-9]([a-z0-9-_]{0,61}[a-z0-9])?([.][a-z0-9]([a-z0-9-_]{0,61}[a-z0-9])?)*', // host: mandatory, only lower-case alphanumeric (plus '-' and '_') and a minimum of one level
  '(:[0-9]{1,5})?', // port: optional, between 1 and 5 digits
  '(/([a-z0-9]([a-z0-9-_]{0,61}[a-z0-9])?)?)*', // path: optional, only lower-case alphanumeric (plus '-' and '_') and multiple levels
  '([?].*)?', // query: optional, but can be anything (i.e. no validation)
  '$'
].join(''))

export const isValidUrl = url => {
  if (typeof url !== 'string') {
    return false
  }

  return REGEX.test(url)
}

import React from 'react'
import PropTypes from 'prop-types'
import { push } from 'connected-react-router'
import { paths } from '../../../route-paths'
import { expandPathTemplate } from '../../../lib/path-matcher'
import * as Wham from '../../../wham/components'
import Dialog from '../../../components/dialog'

const AutoEnrollmentDialog = ({ showDialog, onClose, onAcknowledge, email, dispatch }) => {
  const allowListViewPath = expandPathTemplate(paths.whitelist, { partnerId: 'espn' })

  const onNavigateToAllowListView = e => {
    e.preventDefault()
    onClose()
    dispatch(push(allowListViewPath))
  }

  const footer = (
    <div>
      <Wham.Button type='default' onClick={onAcknowledge}>
        Acknowledge and Close
      </Wham.Button>
      <Wham.Button type='default' onClick={onClose}>
        Close
      </Wham.Button>
    </div>
  )

  return (
    <Dialog
      header="Auto Enrollment"
      footer={footer}
      showDialog={showDialog}
      onClose={onClose}
    >
      <div>
        <p>
          adEngine failed to check whether you have an allow list entry of type &quot;SWID&quot;
          because it has not been able to determine your SWID based on email address, &quot;{email}&quot;.
      </p>
        <p>
          Please use the
        <Wham.Button
            type="link"
            href={allowListViewPath}
            style={{ padding: '0 .2rem', height: 'unset', lineHeight: 'unset' }}
            onClick={onNavigateToAllowListView}
          >
            Allow List view
        </Wham.Button>
        to create an allow list entry of type &quot;SWID&quot; e.g. by looking up a different email address.
      </p>
      </div>
    </Dialog>
  )
}

AutoEnrollmentDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAcknowledge: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default AutoEnrollmentDialog

import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ViewContext } from '../../context'

import CreativeDetailTrafficking from '../creative-detail-trafficking'
import CreativeDetailValidation from '../creative-detail-validation'

import styles from './index.module.scss'

const CreativeDetailTabs = ({
  record,
  validation
}) => {
  const { partner } = useContext(ViewContext)
  const { id, vastIds } = record

  const trafficking = !!(partner === 'nhl' && vastIds && vastIds.length)

  const [tab, setTab] = useState(null)

  useEffect(() => {
    setTab(trafficking ? 'trafficking' : 'validation')
  }, [id])

  useEffect(() => {
    if (validation.checking) {
      setTab('validation')
    }
  }, [validation.checking])

  return trafficking || validation.checking || validation.complete ? (
    <section className={styles.tabs}>
      <nav className={styles.nav}>
        <h2 className={styles.nav_title}>Details</h2>

        <ul className={styles.nav_list}>
          {trafficking ? (
            <li className={styles.nav_item}>
              <a
                className={classNames(styles.nav_link, { [styles.nav_active]: tab === 'trafficking' })}
                href={'#creative-detail-record__trafficking'}
                onClick={e => {
                  e.preventDefault()
                  setTab('trafficking')
                }}
              >ADS Trafficking</a>
            </li>
          ) : null}

          {validation.checking || validation.complete ? (
            <li className={styles.nav_item}>
              <a
                className={classNames(styles.nav_link, { [styles.nav_active]: tab === 'validation' })}
                href={'#creative-detail-record__validation'}
                onClick={e => {
                  e.preventDefault()
                  setTab('validation')
                }}
              >Validation</a>
            </li>
          ) : null}
        </ul>
      </nav>

      {trafficking ? (
        <article className={classNames(styles.trafficking, { [styles.trafficking__active]: tab === 'trafficking' })} id='creative-detail-record__trafficking'>
          <CreativeDetailTrafficking id={vastIds[0].id}/>
        </article>
      ) : null}

      {validation.checking || validation.complete ? (
        <article className={classNames(styles.validation, { [styles.validation__active]: tab === 'validation' })} id='creative-detail-record__validation'>
          <CreativeDetailValidation validation={validation} />
        </article>
      ) : null}
    </section>
  ) : null
}

CreativeDetailTabs.propTypes = {
  record: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired
}

export default CreativeDetailTabs

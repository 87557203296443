import React from 'react'
import PropTypes from 'prop-types'
import NoData from '../no-data'
import * as Wham from '../../wham/components'

const APSTab = ({ aps }) => {
  return (
    <>
      <Wham.Tabs title='Request'>
        <Wham.Tab label='URL'>
          <Wham.Copyable>
            <span style={{ wordBreak: 'break-word' }}>
              {aps.request.url || <NoData dataType='URL' />}
            </span>
          </Wham.Copyable>
        </Wham.Tab>
        <Wham.Tab label='Body'>
          <Wham.Copyable>
            {aps.request.body
              ? <pre>{aps.request.body}</pre>
              : <NoData dataType='body' />
            }
          </Wham.Copyable>
        </Wham.Tab>
      </Wham.Tabs>

      <Wham.Tabs title='Response' style={{ marginTop: 50 }}>
        {aps.response ? (
          <Wham.Tab label='Status Code'>
            <Wham.Copyable>
              <span>
                {aps.response.statusCode || <NoData dataType='statusCode' />}
              </span>
            </Wham.Copyable>
          </Wham.Tab>
        ) : <></>
        }
        {aps.response ? (
          <Wham.Tab label='Body'>
            <Wham.Copyable>
              {aps.response.body
                ? <pre>{aps.response.body}</pre>
                : <NoData dataType='body' />
              }
            </Wham.Copyable>
          </Wham.Tab>
        ) : <></>
        }
        {aps.error ? (
          <Wham.Tab label='Error'>
            <Wham.Copyable>
              <span style={{ wordBreak: 'break-word' }}>
                {aps.error}
              </span>
            </Wham.Copyable>
          </Wham.Tab>
        ) : <></>
        }
      </Wham.Tabs>
    </>
  )
}

APSTab.propTypes = {
  aps: PropTypes.shape({
    request: PropTypes.shape({
      url: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired
    }).isRequired,
    response: PropTypes.shape({
      statusCode: PropTypes.number.isRequired,
      body: PropTypes.string.isRequired
    }),
    error: PropTypes.string
  }).isRequired
}

export default APSTab

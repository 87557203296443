import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import * as Wham from '../../../wham/components'

import { logoutAction } from '../../../actions/login'
import { activePartnerGlobalSelector } from '../../../selectors'
import { paths } from '../../../route-paths'
import { expandPathTemplate } from '../../../lib/path-matcher'
import { dismissErrorAction } from '../../../actions/panic'

import './style.css'

const HeaderUserMenu = ({
  partnerId,
  dispatch,
  user,
  initials,
  renderProfile = true
}) => {
  const [active, setActive] = useState(false)

  return renderProfile ? (
    <div className={`user-menu--wrapper ${active && 'active'}`} onBlur={() => setActive(!active)}>
      <div className='user-menu--avatar-wrapper' onClick={() => setActive(!active)}>
        {active
          ? <Wham.Icon color='#fff' iconName='close' />
          : <Wham.Avatar>{initials}</Wham.Avatar>}
      </div>

      {active ? (
        <div className='user-menu--dropdown'>
          <Wham.Menu type='contextual'>
            <Wham.MenuItem
              selected={false}
              label={user.name}
              onClick={e => { }}
              caption={user.email}
            />
            <Wham.MenuItem
              selected={false}
              label='Release Notes'
              onClick={() => {
                dispatch(dismissErrorAction())
                dispatch(push(expandPathTemplate(paths.releaseNotes, { partnerId })))
              }}
            />
            <Wham.MenuItem
              selected={false}
              label='Sign Out'
              onClick={e => {
                e.preventDefault()
                dispatch(logoutAction)
              }}
            />
          </Wham.Menu>
        </div>
      ) : null}
    </div>
  ) : null
}

HeaderUserMenu.propTypes = {
  partnerId: PropTypes.string,
  dispatch: PropTypes.func,
  initials: PropTypes.array,
  logout: PropTypes.func,
  user: PropTypes.object,
  renderProfile: PropTypes.bool
}

const mapStateToProps = state => {
  const partnerId = activePartnerGlobalSelector(state)
  const user = state.user
  const initials = user.name &&
    user.name
      .replace(/[(][^)]*[)]/, '')
      .split(' ')
      .map(n => n[0])

  return {
    partnerId,
    user,
    initials
  }
}

export default connect(mapStateToProps)(HeaderUserMenu)

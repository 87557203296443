import React from 'react'
import PropTypes from 'prop-types'
import { filterAcceptableProps, FORM_PROPS } from '../lib/propLists'
import classNames from 'classnames'
import { WHAM_TYPES } from '../lib/wham'

import './Checkbox.scss'
import '../../scss/wham.scss'

const filterFormProps = filterAcceptableProps(FORM_PROPS)

const isNull = value => {
  return !!((!value && (typeof value === 'object')))
}

export default class Checkbox extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    danger: PropTypes.bool,
    value: PropTypes.oneOf([null, true, false]),
    /**
     * Called upon changing the value.
     * @param {SyntheticEvent} event - React's original SyntheticEvent.
     * @param {boolean} data - The updated value after the change.
     */
    onChange: PropTypes.func,
    // DEPRECATED: this will be removed
    type: PropTypes.oneOf(WHAM_TYPES)
  }

  normalizeOnchange = nextVal => e => {
    const { onChange } = this.props
    if (onChange) {
      e.preventDefault()
      onChange(e, nextVal)
    }
  }

  render () {
    const {
      label,
      className = 'w-checkbox',
      disabled,
      readOnly,
      value,
      onChange,
      type,
      danger = false,
      ...otherProps
    } = this.props
    const indeterminate = isNull(value)
    const checkClasses = classNames(className, {
      'w-checkbox--checked': value,
      'w-checkbox--indeterminate': indeterminate
    })
    /* Pass through all whitelisted props */
    const filteredProps = filterFormProps(this.props, FORM_PROPS)
    const identifier = filteredProps.name || label
    /* Combine Props Object */
    const inputProps = {
      name: label,
      ...filteredProps,
      className: checkClasses,
      type: 'checkbox',
      disabled,
      readOnly,
      value: label,
      ref: input => {
        /* Handles our 'indeterminate' state, as react warns if you pass it as a prop */
        if (input) input.indeterminate = indeterminate
      }
    }

    const labelClasses = classNames('w-field-label', {
      'w-field-disabled': disabled,
      'w-field--indeterminate': indeterminate,
      'w-field--error': (danger || type === 'danger') // TODO: Remove the danger string check here
    })

    return label ? (
      <label
        className={labelClasses}
        htmlFor={identifier}
      >
        <input
          {...otherProps}
          {...inputProps}
          onChange={() => {}} // TODO: Work out why change-handlers on checks in react are so broken
          onClick={this.normalizeOnchange(!value)}
          checked={!!value}
        />
        {label}
      </label>
    ) : (
      <input
        {...otherProps}
        {...inputProps}
        onChange={() => {}} // TODO: Work out why change-handlers on checks in react are so broken
        onClick={this.normalizeOnchange(!value)}
        checked={!!value}
      />
    )
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const DebugFiltersReset = ({
  enabled,
  pending,
  onClick
}) => {
  return (
    <div className='debug-filters-reset'>
      <button
        className='debug-filters-reset__button w-btn'
        disabled={!enabled || pending}
        onClick={onClick}
      >
        <span className='w-icon'>clear</span>
      </button>
    </div>
  )
}

DebugFiltersReset.propTypes = {
  enabled: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DebugFiltersReset

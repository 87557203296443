import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../Layout.scss'

const LAYOUT_COLUMN_TYPE = [
  'major',
  'minor',
  'narrow',
  'sidebar',
  'hidden'
]

const LAYOUT_COLUMN_CONTENT_VERTICAL_ALIGN = [
  'top',
  'center',
  'bottom'
]

const LAYOUT_COLUMN_CONTENT_HORIZONTAL_ALIGN = [
  'left',
  'center',
  'right'
]

class LayoutColumn extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    type: PropTypes.oneOf(LAYOUT_COLUMN_TYPE),
    verticalAlign: PropTypes.oneOf(LAYOUT_COLUMN_CONTENT_VERTICAL_ALIGN),
    horizontalAlign: PropTypes.oneOf(LAYOUT_COLUMN_CONTENT_HORIZONTAL_ALIGN)
  }

  render () {
    const {
      type,
      className,
      children,
      verticalAlign,
      horizontalAlign,
      ...otherProps
    } = this.props

    const componentClass = classNames(
      'w-layout__column',
      (type && `w-layout__column--${type}`),
      (verticalAlign && `w-layout__column--vert-align-${verticalAlign}`),
      (horizontalAlign && `w-layout__column--hor-align-${horizontalAlign}`),
      className
    )

    return (
      <div className={componentClass} {...otherProps}>
        <div className='w-layout__column__content'>
          {children}
        </div>
      </div>
    )
  }
}

export default LayoutColumn

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './index.module.scss'

const ContentFailed = ({
  id,
  label,
  error,
  className,
  onRetry,
  onReturn
}) => {
  const text = `Failed to ${error ? 'load' : 'find'} ${label}: ${id}`

  return (
    <div className={classNames(className, styles.content)}>
      <div className={styles.line} data-cy='content-failed-text'>{text}</div>

      {error ? (
        <div className={styles.line}>
          <p>Click</p>
          <span className={styles.action} onClick={onRetry}>here</span>
          <p>to try again or</p>
          <span className={styles.action} onClick={onReturn}>here</span>
          <p>to go back</p>
        </div>
      ) : (
        <div className={styles.line}>
          <p>Click</p>
          <span className={styles.action} onClick={onReturn}>here</span>
          <p>to go back</p>
        </div>
      )}
    </div>
  )
}

ContentFailed.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.object,
  className: PropTypes.string,
  onReturn: PropTypes.func.isRequired,
  onRetry: PropTypes.func
}

export default ContentFailed

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client'

import {
  activePartnerGlobalSelector,
  userFeaturesSelector
} from '../../selectors'

import store from '../../store'

import { creativesPolicy, creativeAssetRecordsPolicy } from '../type-policies'

export const request = (operation, forward) => {
  const partnerId = activePartnerGlobalSelector(store.getState())

  const headers = {
    'X-AdEngine-Features': btoa(JSON.stringify(userFeaturesSelector(store.getState())))
  }

  if (partnerId) {
    headers['X-Partner-Id'] = partnerId
  }

  operation.setContext({ headers })

  return forward(operation)
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        creatives: creativesPolicy()
      }
    },
    CreativeAssetResults: {
      fields: {
        records: creativeAssetRecordsPolicy()
      }
    }
  }
})

const apolloLink = new ApolloLink(request)
const httpLink = new HttpLink({ uri: '/graphql' })
const link = concat(apolloLink, httpLink)

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  },
  query: {
    fetchPolicy: 'network-only'
  },
  mutation: {
    fetchPolicy: 'network-only'
  }
}

export default new ApolloClient({
  cache,
  link,
  defaultOptions
})

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as R from 'ramda'
import * as Wham from '../../wham/components'

import {
  startMediaRequestPolling,
  stopMediaRequestPolling
} from '../../actions/debug'
import ExpandCollapse from '../actions/expand-collapse'

import TsRequest from '../debug-ts-request'

import './style.css'

const getBitrates = R.compose(
  R.uniq,
  R.sort((x, y) => y - x), // Descending order; show higher bitrates first...
  R.map(ts => ts.$bitrate)
)

const getOptions = R.memoizeWith(R.identity, R.compose(
  R.prepend({ label: 'All', value: '*' }),
  R.when(options => options.length === 1, () => []),
  R.map(bitrate => ({ label: bitrate, value: bitrate }))
))

const TSTab = ({
  dispatch,
  newTs = [],
  tsData = [],
  sgmntToAdNumber,
  traceId,
  playSegment,
  playerLoading,
  playingSegment,
  segmentPlaybackErrors,
  firstSgmntNumber,
  onUpdate
}) => {
  useEffect(() => {
    dispatch(startMediaRequestPolling())
    return () => {
      dispatch(stopMediaRequestPolling())
    }
  }, [])

  const [collapse, setCollapse] = useState(true)
  const [bitrate, setBitrate] = useState('*')
  const [sorting, setSorting] = useState({ attribute: 'segment', ascending: true })

  const onClickNewMediaRequests = () => {
    if (onUpdate) {
      onUpdate()
    }
  }

  const onToggleExpandCollapseAll = () => {
    setCollapse(!collapse)
  }

  const onClickSort = attribute => {
    setSorting({ attribute, ascending: sorting.attribute === attribute ? !sorting.ascending : true })
  }

  const newTsCount = newTs.length - tsData.length

  const bitrates = getBitrates(tsData)
  const options = getOptions(bitrates)

  const sortBy = index => sorting.ascending ? index : R.negate(index)

  const sortByEventTime = (x, y) => sortBy(x.$eventTime - y.$eventTime)
  const sortBySgmnt = (x, y) => sortBy(x.$sgmnt - y.$sgmnt)

  const filterAndSort = R.compose(
    R.sort((x, y) => {
      switch (sorting.attribute) {
        case 'time':
          return sortByEventTime(x, y)
        case 'segment':
          return sortBySgmnt(x, y)
        default:
          return 0
      }
    }),
    R.filter(ts => bitrate === '*' || bitrate === ts.$bitrate)
  )

  const tsDataFilteredSorted = filterAndSort(tsData)

  return (
    <div>
      {(newTsCount > 0) &&
        <div className='ts-tab__new-transactions' onClick={() => onClickNewMediaRequests()}>
          <a>{newTsCount} new transactions</a>
        </div>}

      <div className='ts-tab__controls'>
        <ExpandCollapse collapsed={collapse} onClick={() => onToggleExpandCollapseAll()} />

        <form className='ts-tab__form' onSubmit={e => e.preventDefault()}>
          <div className='ts-tab__select'>
            <Wham.FormField subtle className='ts-tab__field' label='Bitrate:'>
              <Wham.Select
                className='ts-tab__select-bitrate'
                options={options}
                value={bitrate}
                onChange={e => setBitrate(e.target.value)}
              />
            </Wham.FormField>
          </div>
        </form>
      </div>

      <div className='ts-tab__requests'>
        {tsDataFilteredSorted.length ? (
          <div className='ts-tab__header'>
            <div className='ts-tab__header-left'>
              <div className='ts-tab__header-cell ts-tab__header-time' onClick={() => onClickSort('time')}>
                <span className='ts-tab__header-text'>Time</span>

                <div className='ts-tab__header-sort'>
                  <Wham.Icon className={`ts-tab__header-sort-up ${sorting.attribute === 'time' && sorting.ascending ? 'ts-tab__header-sort--selected' : ''}`} iconName='arrow_drop_up' />
                  <Wham.Icon className={`ts-tab__header-sort-down ${sorting.attribute === 'time' && !sorting.ascending ? 'ts-tab__header-sort--selected' : ''}`} iconName='arrow_drop_down' />
                </div>
              </div>
            </div>
            <div className='ts-tab__header-right'>
              <div className='ts-tab__header-cell ts-tab__header-segment' onClick={() => onClickSort('segment')}>
                <span className='ts-tab__header-text'>Segment</span>

                <div className='ts-tab__header-sort'>
                  <Wham.Icon className={`ts-tab__header-sort-up ${sorting.attribute === 'segment' && sorting.ascending ? 'ts-tab__header-sort--selected' : ''}`} iconName='arrow_drop_up' />
                  <Wham.Icon className={`ts-tab__header-sort-down  ${sorting.attribute === 'segment' && !sorting.ascending ? 'ts-tab__header-sort--selected' : ''}`} iconName='arrow_drop_down' />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {tsDataFilteredSorted
          .map((ts, index) =>
            <TsRequest
              className='ts-tab__container'
              collapse={collapse}
              key={index}
              traceId={traceId}
              ts={ts}
              sgmntToAdNumber={sgmntToAdNumber}
              firstSgmntNumber={firstSgmntNumber}
              playSegment={playSegment}
              isPlaying={playingSegment === ts}
              playerLoading={playerLoading}
              playerError={segmentPlaybackErrors.get(ts)}
            />
          )}
      </div>
    </div>
  )
}

TSTab.propTypes = {
  dispatch: PropTypes.func,
  newTs: PropTypes.array,
  tsData: PropTypes.array,
  traceId: PropTypes.string,
  sgmntToAdNumber: PropTypes.object,
  firstSgmntNumber: PropTypes.number,
  playSegment: PropTypes.func,
  playerLoading: PropTypes.bool,
  playingSegment: PropTypes.object,
  segmentPlaybackErrors: PropTypes.object,
  onUpdate: PropTypes.func
}

export default TSTab

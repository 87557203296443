import { singleParamActionCreator } from '../../lib/singleParamActionCreator'

export const PANIC = 'PANIC'
export const ERROR = 'ERROR'
export const DISMISS_ERROR = 'DISMISS_ERROR'

export const panicAction = singleParamActionCreator(PANIC)({
  error: {
    errorTitle: 'We cannot display this content',
    errorDescription: 'Oops! Something isn\'t right here!',
    actionText: 'Reload App',
    dismissable: false
  }
})
export const errorAction = singleParamActionCreator(ERROR)
export const dismissErrorAction = singleParamActionCreator(DISMISS_ERROR)

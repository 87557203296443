import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '../dialog'
import * as Wham from '../../wham/components'

const ConfirmationDialog = ({
  showDialog,
  title,
  message,
  onConfirm,
  onCancel
}) => {
  const footer = (
    <div>
      <Wham.Button type='default' onClick={onCancel}>Cancel</Wham.Button>
      <Wham.Button type='danger' onClick={onConfirm}>OK</Wham.Button>
    </div>
  )

  return (
    <Dialog
      showDialog={showDialog}
      header={title}
      footer={footer}
      onClose={onCancel}
    >
      {message}
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default ConfirmationDialog

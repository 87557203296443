import { partnerUrl, performReq } from '../../api'

export const getRules = async ({ partnerId }) => {
  const url = partnerUrl(partnerId) + '/rules'
  return performReq('GET', url)
}

export const createRule = async ({ partnerId, rule }) => {
  const url = partnerUrl(partnerId) + '/rules'
  return performReq('POST', url, rule)
}

export const updateRule = async ({ partnerId, rule }) => {
  const url = partnerUrl(partnerId) + `/rules/${rule.id}`
  return performReq('PUT', url, rule)
}

export const toggleRuleIsActive = async ({ partnerId, ruleId, active }) => {
  const url = partnerUrl(partnerId) + `/rules/${ruleId}/active/${!active}`
  return performReq('GET', url)
}

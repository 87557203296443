import { parseUrl } from 'query-string'
import * as R from 'ramda'

export const SGMNT_DURATION_SECONDS = 5

const tsToAttributes = ts => {
  const {
    $bitrate,
    $chunk,
    $chunkdur,
    $sgmnt
  } = ts

  return {
    sgmnt: Number($sgmnt),
    chunk: Number($chunk),
    chunkdur: Number($chunkdur),
    bitrate: Number($bitrate),
    redirectUrl: ts.response.redirectUrl
  }
}

const sortBySgmntChunkBitrate = R.sortWith([
  R.ascend(s => s.sgmnt),
  R.ascend(s => s.chunk),
  R.descend(s => s.bitrate)
])

const sgmntAndChunk = s => `${s.sgmnt}-${s.chunk}`

const chooseBest = R.pipe(
  R.map(tsToAttributes),
  sortBySgmntChunkBitrate,
  R.groupBy(sgmntAndChunk),
  R.values,
  R.map(R.head),
  sortBySgmntChunkBitrate
)

export const buildPlaylist = (ts, proxyUrl = R.identity) => {
  const best = chooseBest(ts)
  const proxiedRedirectUrls = R.pluck('redirectUrl', best).map(proxyUrl)
  return [
    '#EXTM3U',
    `#EXT-X-TARGETDURATION:${SGMNT_DURATION_SECONDS}`,
    ...R.chain(redirectUrl => [`#EXTINF:${SGMNT_DURATION_SECONDS},`, redirectUrl], proxiedRedirectUrls),
    '#EXT-X-ENDLIST'
  ].join('\n')
}

export const buildSgmntToAdNumber = (selectedAds, tsData) => {
  const makePair = ts => {
    const sgmnt = Number(ts.$sgmnt)
    const amsId = R.path(['response', 'amsId'], ts)
    if (Number.isInteger(sgmnt) && amsId) {
      const index = selectedAds.findIndex(selectedAd => selectedAd.amsId === amsId)
      if (index >= 0) {
        const adNumber = index + 1
        return [sgmnt, adNumber]
      }
    }
    return null
  }
  return R.compose(
    R.fromPairs,
    R.filter(R.compose(R.not, R.isNil)),
    R.map(makePair)
  )(tsData)
}

// Some Ad Servers make use of ';' as a query string delimiter.
// https://docs.vdms.com/video/index.html#AdIntegration/Freewheel.htm
// This confuses 'parseUrl' so we need to do some extra work to handle this.
// e.g.
//   const url = '/ts?sgmnt=2;inaserv=http://partner.com/media.ts?bitrate=5600'
//   const { query } = parseUrl(url)
//   const sgmnt = query['sgmnt']
// In the above case, 'sgmnt' will be '2;inaserv' but we really want it to be '2'.
const sanitizeValue = (v = '') => {
  const pos = v.indexOf(';')
  return pos >= 0 ? v.substring(0, pos) : v
}

const getTsQueryParams = (url, ...keys) => {
  if (!url) return {}
  const { query } = parseUrl(url)
  return R.compose(
    R.fromPairs,
    R.map(key => [key, sanitizeValue(query[key])])
  )(keys)
}

const getTsQueryParam = key => url => getTsQueryParams(url, key)[key]

export const getBitrate = R.compose(
  getTsQueryParam('bitrate'),
  R.path(['response', 'redirectUrl'])
)

export const getChunkDur = R.compose(
  getTsQueryParam('chunkdur'),
  R.path(['response', 'redirectUrl'])
)

export const getChunk = R.compose(
  getTsQueryParam('chunk'),
  R.path(['response', 'redirectUrl'])
)

export const getEventTime = R.path(['request', 'eventTime'])

export const getSgmnt = R.compose(
  getTsQueryParam('sgmnt'),
  R.path(['request', 'url'])
)

export const makeProxyUrl = R.identity

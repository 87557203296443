import hljs from 'highlight.js/lib/core'
import hljs_javascript from 'highlight.js/lib/languages/javascript'
import hljs_json from 'highlight.js/lib/languages/json'
import hljs_xml from 'highlight.js/lib/languages/xml'

import vk from 'vkbeautify'
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import reactElementToJSXString from 'react-element-to-jsx-string'
import './Code.scss'
import '../../scss/wham.scss'

hljs.registerLanguage('javascript', hljs_javascript)
hljs.registerLanguage('json', hljs_json)
hljs.registerLanguage('xml', hljs_xml)

class Code extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    code: PropTypes.string,
    children: PropTypes.node,
    lang: PropTypes.string,
    pretty: PropTypes.bool
  }

  render () {
    const {
      className = null,
      code = null,
      children = null,
      lang,
      pretty,
      ...otherProps
    } = this.props

    var nodes = ''
    if (children) {
      let allReactElements = true
      React.Children.forEach(children, child => {
        if (!child.type) allReactElements = false
      })
      if (allReactElements) {
        if (children.length > 1) {
          for (var i in children) {
            nodes += reactElementToJSXString(children[i], { maxInlineAttributesLineLength: 200, tabStop: 0, showDefaultProps: false }).replace(/(\r\n|\n|\r)/gm, '')
          }
        } else {
          nodes = reactElementToJSXString(children, { maxInlineAttributesLineLength: 200, tabStop: 0, showDefaultProps: false }).replace(/(\r\n|\n|\r)/gm, '')
        }
      } else {
        nodes = children
      }
    }

    let formattedCode = code || nodes

    hljs.configure({
      tabReplace: '  '
    })

    if (pretty && typeof vk[lang] === 'function') {
      /* Indent/pretty-print if desired and possible */
      formattedCode = vk[lang](formattedCode, '  ')
    }
    if (!lang) {
      /* No lang specified */
      formattedCode = hljs.highlightAuto(formattedCode)
    } else {
      /* lang speficied */
      formattedCode = hljs.highlight(lang, formattedCode, true)
    }

    const codeClassnames = classNames(
      'w-code',
      className
    )

    return (
      <span className={codeClassnames} {...otherProps}>
        <code className='hljs' dangerouslySetInnerHTML={{ __html: formattedCode.value }} />
      </span>
    )
  }
}

export default Code
